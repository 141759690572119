// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publish-promotions {
    background-color: #ff2626;
    text-align: center;
    color: whitesmoke;
    padding-top: 50px;
    padding-bottom: 20px;
    border-radius: 3px;
}

.message {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}`, "",{"version":3,"sources":["webpack://./src/app/structure/publish-promotions/publish-promotions.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".publish-promotions {\n    background-color: #ff2626;\n    text-align: center;\n    color: whitesmoke;\n    padding-top: 50px;\n    padding-bottom: 20px;\n    border-radius: 3px;\n}\n\n.message {\n    margin-left: auto;\n    margin-right: auto;\n    width: 60%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
