import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Url} from "../../models/url";

@Injectable({
  providedIn: 'root'
})
export class ExpenditureService {




  payoutID : any;
  payoutTotal = 0
  payoutDate :any;

  //urls
  getTodaysRevenueURL = this.url.getDomain() + '/Expenditure/GetTodaysOrdersTotal';
  GetDatesDeliveryTotalURL = this.url.getDomain() + '/Expenditure/GetDatesDeliveryTotal';

  GetDatesOrderTotalURL = this.url.getDomain() + '/Expenditure/GetDatesOrderTotal';

  getPayoutsURL = this.url.getDomain() + '/Expenditure/GetPayouts';
  GetDriverPayoutsURL = this.url.getDomain() + '/Expenditure/GetDriverPayouts';



  GetDriverPayoutDetailsURL = this.url.getDomain() +'/Expenditure/GetDriverPayoutDetails';

  getPayoutDetailsURL = this.url.getDomain() +'/Expenditure/GetPayoutDetails';


  constructor(private http: HttpClient, private url: Url) { }

  getDriverPayoutsDetails(post){
    return this.http.post(this.GetDriverPayoutDetailsURL, post);
  }


  getDriverPayouts(post){
    return this.http.post(this.GetDriverPayoutsURL, post);
  }





  getDatesDeliveryTotal(post){
    return this.http.post(this.GetDatesDeliveryTotalURL, post);
  }




  getDatesOrderTotal(post){
    return this.http.post(this.GetDatesOrderTotalURL, post);
  }

  getTodaysRevenue(post){
    return this.http.post(this.getTodaysRevenueURL, post);
  }

  getPayouts(post){
    return this.http.post(this.getPayoutsURL, post);
  }
  getPayoutDetails(post){
    return this.http.post(this.getPayoutDetailsURL, post);


  }

}
