// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .add-reservation-form-col {
        width: 100%;
        padding: 8px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .add-reservation-form-col {
        width: 100%;
        padding: 8px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .add-reservation-form-col {
        width: 50%;
        padding: 8px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .add-reservation-form-col {
        width: 33.33%;
        padding: 8px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .add-reservation-form-col {
        width: 33.33%;
        padding: 8px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/add-reservation/add-reservation.component.css"],"names":[],"mappings":"AAAA,2DAA2D;AAC3D;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA,mDAAmD;AACnD;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ;;AAEA,2CAA2C;AAC3C;IACI;QACI,UAAU;QACV,YAAY;IAChB;AACJ;;AAEA,2CAA2C;AAC3C;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ;;AAEA,wDAAwD;AACxD;IACI;QACI,aAAa;QACb,YAAY;IAChB;AACJ","sourcesContent":["/* Extra small devices (portrait phones, less than 576px) */\n@media (max-width: 575.98px) {\n    .add-reservation-form-col {\n        width: 100%;\n        padding: 8px;\n    }\n}\n\n/* Small devices (landscape phones, 576px and up) */\n@media (min-width: 576px) and (max-width: 767.98px) { \n    .add-reservation-form-col {\n        width: 100%;\n        padding: 8px;\n    }\n}\n\n/* Medium devices (tablets, 768px and up) */\n@media (min-width: 768px) and (max-width: 991.98px) {\n    .add-reservation-form-col {\n        width: 50%;\n        padding: 8px;\n    }\n}\n\n/* Large devices (desktops, 992px and up) */\n@media (min-width: 992px) and (max-width: 1199.98px) {\n    .add-reservation-form-col {\n        width: 33.33%;\n        padding: 8px;\n    }\n}\n\n/* Extra large devices (large desktops, 1200px and up) */\n@media (min-width: 1200px) {\n    .add-reservation-form-col {\n        width: 33.33%;\n        padding: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
