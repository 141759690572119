import { Component, NgZone, OnInit } from '@angular/core';
import { PaymentService } from 'app/services/payments/payment.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-create-restaurant-order',
  templateUrl: './create-restaurant-order.component.html',
  styleUrls: ['./create-restaurant-order.component.css', '../../../assets/css/ts-style.css']
})
export class CreateRestaurantOrderComponent implements OnInit {

  customerName = '';
  customerAddress = '';
  customerApt = '';
  customerPhone = '';
  createOrderErrorMsg = '';
  additionalComments = '';
  customerLat = 0.0;
  customerLng = 0.0;
  address1 = '';
  address = '';
  city = '';
  province = '';
  country = '';
  postal = '';
  restaurantAddress = '';
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  deliveryKm = 0;
  deliveryFee = '';
  tip = '';
  estimateTime = '';
  timeTravel: number;
  distTravel: any;

  applicationFee: any;
  orderID: any;
  hasCard = false;
  isPaying = false;
  stripePromise = loadStripe(environment.stripeKey);
  restaurantUserCards = [];

  constructor(private zone: NgZone, private paymentService: PaymentService, private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {

    let self = this;
    $(document).ready(function ($) {
      self.setupGoogleMaps();
    });

    if (localStorage.getItem('restaurantStripeCustomerID') == null || localStorage.getItem('restaurantStripeCustomerID') === 'null' || localStorage.getItem('restaurantStripeCustomerID') == 'NA') {
      alert('Please logot, and then login again to use TopServe feature.')
      localStorage.setItem('isLogedIn', 'false');
      this.router.navigate(['/logout']);
      return;
    }

    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {

        if (self.customerName == '' || self.deliveryFee == '' || self.tip == '' || self.customerPhone == '' || self.customerLat == 0.0 || self.customerLng == 0.0 || self.estimateTime == '') {
          self.createOrderErrorMsg = 'Please enter all the details to create order!'
        }
        else if (self.customerPhone.length != 10) {
          self.createOrderErrorMsg = 'Please enter valid phone number!'
        }
        else if (isNaN(parseFloat(self.tip))) {
          self.createOrderErrorMsg = 'Please enter valid tip!'
        }
        else {
          self.tip = parseFloat(self.tip).toString();
          self.createOrderErrorMsg = '';

          self.addOrder();
        }
        // form.classList.add('was-validated');
      }, false);
    });

    this.verifyNewCardSession();
    this.getCards();
  }

  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Starts ----*/
  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  setupGoogleMaps() {

    var input = <HTMLInputElement>document.getElementById("pac-input");

    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {

      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';
      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.customerLat = mylocation.geometry.location.lat();
      self.customerLng = mylocation.geometry.location.lng();
      self.getDistTime();

    });

  }
  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Ends ----*/

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }
  getDistTime() {

    var DeliveryAddress2 = this.customerApt == '' ? this.address1 : this.address1 + ' apt: ' + this.customerApt
    const origin1 = { lat: parseFloat(localStorage.getItem('restaurantLatitude')), lng: parseFloat(localStorage.getItem('restaurantLongitude')) };
    const destinationB = { lat: this.customerLat, lng: this.customerLng };

    const service = new google.maps.DistanceMatrixService();
    const request = {
      origins: [origin1],
      destinations: [destinationB],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };

    var stringArray = []
    var eTime: number
    // get distance matrix response
    let self = this
    service.getDistanceMatrix(request, (response) => {
      // put response
      const rows1 = response.rows[0].elements[0].distance
      const rows2 = response.rows[0].elements[0].duration
      const distance1 = rows1['text']
      const time1 = rows2['text']

      self.distTravel = parseFloat(distance1)

      stringArray = time1.split(" ");
      eTime = stringArray[0]
      self.timeTravel = eTime
      self.getDeliveryFee();
    })


  }
  getDeliveryFee() {

    // calculates great-circle distances between the two points – 
    // that is, the shortest distance over the earth’s surface – using the ‘Haversine’ formula.
    var lat1 = parseFloat(localStorage.getItem('restaurantLatitude'));
    var lon1 = parseFloat(localStorage.getItem('restaurantLongitude'));

    var lat2 = this.customerLat;
    var lon2 = this.customerLng;
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    this.deliveryKm = R * c; // Distance in km

    this.deliveryKm += 1; // adding one for the driving distance
    // var permin = 0.4166
    //var stringArray = this.timeTravel.split(" ");
    var fee = this.timeTravel * 0.4166   // $25 per hour...0.4166 per min
    var fee1 = this.distTravel * 0.70
    fee = fee + fee1
    fee = parseFloat(fee.toFixed(2))



    this.zone.run(() => {
      // 0km - 5km $6.50 / 5km - 8km $8.50 / 8km - 12km $9.50 / 12km - 15km $14.25 / no more than that returns error
    if (this.distTravel > 0 && this.distTravel <= 8) {
      if (fee < 6.5) {
        this.deliveryFee = '6.5';

      } else {
        this.deliveryFee = fee.toString()
      }

      this.createOrderErrorMsg = '';
    }
    // else if (this.deliveryKm > 5 && this.deliveryKm <= 8) {
    //   this.deliveryFee = '8.5';
    //   this.createOrderErrorMsg = '';
    // }
    // else if (this.deliveryKm > 8 && this.deliveryKm <= 12) {
    //   this.deliveryFee = '9.5'
    //   this.createOrderErrorMsg = '';
    // }
    // else if (this.deliveryKm > 12 && this.deliveryKm <= 15) {
    //   this.deliveryFee = '14.25';
    //   this.createOrderErrorMsg = '';
    // }
    else {
      this.deliveryFee = '';
      this.createOrderErrorMsg = 'Entered address is out of TopServe delivery area.';
    }
    });
  }

  addOrder() {

    let restaurantOrderTypeNames = localStorage.getItem('restaurantOrderTypeNames');
    let orderTypeId = '';
    for (let i in restaurantOrderTypeNames.split(',')) {
      if (restaurantOrderTypeNames.split(',')[i] == '2') {
        orderTypeId = localStorage.getItem('restaurantOrderTypeIDs').split(',')[i];
      }
    }


    let RestaurantInformation = {
      restaurantContact: localStorage.getItem('restaurantContactName'),
      restaurantName: localStorage.getItem('restaurantName'),
      restaurantAddress: localStorage.getItem('restaurantAddress'),
      restaurantPhone: localStorage.getItem('restaurantPhone')
    };

    let OrderDeliveryInformation = {
      Tip: this.tip,
      DeliveryAddress2: this.customerApt == '' ? this.address1 : this.address1 + ' apt: ' + this.customerApt,
      Subtotal: 0,
      EstimatedTime: this.estimateTime,
      OrderTypeName: 'Delivery',
      DeliveryFee: parseFloat(this.deliveryFee).toFixed(2),
      AdditionalComments: 'Customer Name: ' + this.customerName + (this.additionalComments == '' ? this.additionalComments : ' --- ' + this.additionalComments),
      OrderType: 'Delivery',
      DeliveryCity: this.city,
      OrderTypeID: orderTypeId,
      DeliveryAddress1: this.customerApt == '' ? this.address1 : this.address1 + ' apt: ' + this.customerApt,
      DeliveryLatitude: this.customerLat,
      DeliveryLongitude: this.customerLng,
      UserID: 2,
      ApplicationFee: 0,
      TableNumber: 0,
      DeliveryPostal: this.postal,
      RestaurantID: localStorage.getItem('restaurantID'),
      UserPayed: 0,
      Total: 0,
      firstName: this.customerName,
      lastName: '',
      Tax: 0,
      DeliveryKM: this.deliveryKm,
      DeliveryCountry: this.country,
      Phone: this.customerPhone,
      Discount: 0,
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeID: localStorage.getItem('StripeID'),
      OrderStatus: "2"
    };

    let post = {
      RestaurantInformation: RestaurantInformation,
      OrderDeliveryInformation: OrderDeliveryInformation,
      OrderInformation: [],
      topserveDriveOrder: true
    };



    this.isPaying = true;
    this.paymentService.AddOrder(post).subscribe(data => {
      if (data["orderAdded"]) {
        this.orderID = data["orderID"];
        this.applicationFee = data["applicationFee"];
        localStorage.setItem('tmpOrderID', this.orderID);
        localStorage.setItem('tmpDeliveryFee', this.deliveryFee);
        localStorage.setItem('tmpTip', this.tip);
        localStorage.setItem('tmpEstimateTime', this.estimateTime);
        this.handlePayment()
      } else {
        this.isPaying = false;
        alert("there was an error adding the order information. please try again later.")
      }
    }, err => {
      this.isPaying = false;
      alert("there was an error adding the order. please try again later.")
    });
  }

  handlePayment() {

    if (this.hasCard) {
      this.chargeCustomer();
    } else {
      this.addNewCard();
    }
  }



  async chargeCustomer() {

    let total = (parseFloat(localStorage.getItem('tmpDeliveryFee')) + parseFloat(localStorage.getItem('tmpTip'))) * 100;

    const post = {
      customer_id: localStorage.getItem('restaurantStripeCustomerID'),
      amount: parseInt(total.toString(), 10).toString(),
      currency: 'CAD',
      receipt_email: localStorage.getItem('Email'),
      stripeBusinessID: localStorage.getItem('restaurantStripeBusinessID'),
      applicationFee: parseInt(total.toString(), 10).toString(),
      orderID: localStorage.getItem('tmpOrderID'),
      topserveDriveOrder: true
    };



    let self = this;
    this.paymentService.createPaymentIntent(post).subscribe(async data => {
      if (data['Success']) {
        (await this.stripePromise).confirmCardPayment(data['ClientSecret'], {
          payment_method: localStorage.getItem('restaurant_pm_id'),
        })
          .then(function (result) {
            // Handle result.error or result.paymentIntent

            if (result.error) {
              self.isPaying = false;
              localStorage.removeItem('tmpOrderID');
              localStorage.removeItem('tmpDeliveryFee');
              localStorage.removeItem('tmpTip');
              localStorage.removeItem('tmpEstimateTime');

              if (result.error.message != null) {
                alert('something went wrong while paying for order, you were not charged. Please try again later ' + result.error.message);

              } else {
                alert('something went wrong while paying for order, you were not charged. Please try again later');

              }

            }
            else {
              self.processPayment();
            }

          });
      } else {
        self.isPaying = false;
        alert('something went wrong while paying for order, you were not charged. Please try again later');
      }
    })
  }

  processPayment() {

    const post = {
      OrderID: localStorage.getItem('tmpOrderID'),
      Token: localStorage.getItem('AuthenticationToken'),
      TimeZone: localStorage.getItem('restaurantTimeZone'),
      RestaurantID: localStorage.getItem('restaurantID'),
      EstimatedTime: localStorage.getItem('tmpEstimateTime'),
      topserveDriveOrder: true
    };

    this.paymentService.PayForTopServeDriveOrder(post).subscribe(async data => {
      this.isPaying = false;
      if (data["Success"]) {
        alert('Order Confirmed.\n\nOrderID: ' + localStorage.getItem('tmpOrderID'));
        localStorage.removeItem('tmpOrderID');
        localStorage.removeItem('tmpDeliveryFee');
        localStorage.removeItem('tmpTip');
        localStorage.removeItem('tmpEstimateTime');
        this.router.navigate(['/services/incoming-orders']);
      }
      else {
        this.isPaying = false;
        alert('Could not confirm order. Payment was successful. Please contact TopServe and inform about this situation with given Order ID below! \n\nOrderID: ' + localStorage.getItem('tmpOrderID'));
        localStorage.removeItem('tmpOrderID');
        localStorage.removeItem('tmpDeliveryFee');
        localStorage.removeItem('tmpTip');
        localStorage.removeItem('tmpEstimateTime');
      }
    });
  }

  async addNewCard() {

    const post = {
      customer_id: localStorage.getItem('restaurantStripeCustomerID'),
      LinkName: localStorage.getItem('LinkName'),
      topserveDriveOrder: true
    }

    this.paymentService.createPaymentMethodSession(post).subscribe(async data => {

      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data['sessionId']
      })

      if (error) {
        console.log(error);
      }
    });
  }

  async verifyNewCardSession() {

    if (window.location.href.split('?').pop().includes('session_id')) {
      if (localStorage.getItem('tmpOrderID') != null) {
        this.isPaying = true;
        const post = {
          customer_id: localStorage.getItem('restaurantStripeCustomerID'),
          session_id: window.location.href.split('=').pop()
        }
        this.paymentService.verifyPaymentMethodSession(post).subscribe(async data => {
          if (data['Success']) {
            this.getCards();
            localStorage.setItem('restaurant_pm_id', data['payment_method']);
            this.chargeCustomer();
          }
          else {
            alert('Something went wrong while adding a new card! Please try again later!');
          }
        });
      }
    }
    else if (window.location.href.split('?').pop().includes('failure=true')) {
      this.isPaying = false;
      if (localStorage.getItem('tmpOrderID') != null) {
        localStorage.removeItem('tmpOrderID');
        alert('Add New Card Failed!');
      }
    }
  }

  getCards() {

    this.restaurantUserCards = [];

    const post = {
      customer_id: localStorage.getItem('restaurantStripeCustomerID')
    };

    this.loginService.getPaymentMethods(post).subscribe(
      data => {

        if (data['success']) {
          let cardData = data['response']['data'];

          if (cardData.length > 0 && data['response']['default_payment_method'] != null) {

            this.hasCard = true

            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {

                card['selected'] = true;
                localStorage.setItem('restaurant_pm_id', card['id'].toString());
                localStorage.setItem('restaurant_exp_year', card['exp_year'].toString());
                localStorage.setItem('restaurant_exp_month', card['exp_month'].toString());
                localStorage.setItem('restaurant_last4', card['last4'].toString());
                localStorage.setItem('restaurant_card_brand', card['brand'].toString());

                this.restaurantUserCards.push(card);
              }
            }
          }
          else {
            this.hasCard = false;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });
  }

  deletePaymentMethod() {

    const post = {
      payment_method_id: localStorage.getItem('restaurant_pm_id')
    }

    this.paymentService.removePaymentMethod(post).subscribe(async data => {
      if (data['Success']) {
        this.hasCard = false;
        this.restaurantUserCards = [];
      }
      else {
        alert('Something went wrong while deleting your card! Please try again later!');
      }
    });
  }

}
