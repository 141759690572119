import { Injectable } from '@angular/core';



const DOMAIN = 'http://localhost:3000'; //local rest API


const DOMAIN1 = 'https://meeno.app/RestAPI'; // dev mode
const DOMAIN2 = 'https://TopServe.ca/RestAPI'; // product mode

@Injectable()

export class Url {

  getDomain() {
    return DOMAIN2;
  };


  getDev() {

    if (this.getDomain() === DOMAIN || this.getDomain() === DOMAIN1) {
      return 'dev';
    } else if (this.getDomain() === DOMAIN2) {
      return 'prod'
    }else {
      return 'company'
    }
  }
}


