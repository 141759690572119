import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';



export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  addedRestaurant = false;
  addResUrl = this.url.getDomain() + '/Restaurants/AddRestaurant';
  getResUrl = this.url.getDomain()  + '/Restaurants/GetRestaurantByUser';
  updateResUrl = this.url.getDomain() + '/Restaurants/UpdateRestaurant';
  updateResHoursUrl = this.url.getDomain() + '/Restaurants/UpdateRestaurantHours';
  updateResOrderTypesUrl= this.url.getDomain() + '/Restaurants/UpdateRestaurantOrderTypes';
  updateStoreDescUrl = this.url.getDomain() + '/Restaurants/UpdateStoreDescription';

  addRestaurantHoursURL= this.url.getDomain() + '/Restaurants/AddRestaurantHours';

  pauseRestaurantForCertainHoursURL = this.url.getDomain() + '/Restaurants/PauseRestaurantForCertainHours';
  unpauseRestaurantFromCertainHoursURL = this.url.getDomain() + '/Restaurants/UnpauseRestaurantFromCertainHours';

  checkRestaurantPausedForCertainHoursURL = this.url.getDomain() + '/Restaurants/checkRestaurantPausedForCertainHours';
  getRestaurantHoursURL = this.url.getDomain() + '/Restaurants/GetRestaurantHours';
  checkLinkAvailabilityURL = this.url.getDomain() + '/Restaurants/checkLinkNameAvailability';
  updateLinkNameOfStoreURL = this.url.getDomain() + '/Restaurants/updateLinkNameOfStore';
  enableOnlineOrderingURL = this.url.getDomain() + '/Restaurants/enableOnlineOrderingService';
  manageCustomerServiceFeeUrl = this.url.getDomain() + '/Restaurants/ManageCustomerServiceFee';


  constructor(private http: HttpClient, private url: Url) { }

  addRes(res) {
    return this.http.post<Res>(this.addResUrl, res);

  }
  getRes(userId) {
    return this.http.post<Res>(this.getResUrl, userId);

  }

  addHours(userId) {
    return this.http.post<Res>(this.addRestaurantHoursURL, userId);

  }
  updateRes(post) {
    return this.http.post<Res>(this.updateResUrl, post);

  }
  updateResHours(post) {
    return this.http.post<Res>(this.updateResHoursUrl, post);

  }
  updateResOrderTypes(post) {
    return this.http.post<Res>(this.updateResOrderTypesUrl, post);
  }

  pauseRestaurantForCertainHours(post) {
    return this.http.post<Res>(this.pauseRestaurantForCertainHoursURL, post);
  }

  unpauseRestaurantFromCertainHours(post) {
    return this.http.post<Res>(this.unpauseRestaurantFromCertainHoursURL, post);
  }

  checkRestaurantPausedForCertainHours(post) {
    return this.http.post<Res>(this.checkRestaurantPausedForCertainHoursURL, post);
  }

  updateStoreDescription(post) {
    return this.http.post<Res>(this.updateStoreDescUrl, post);
  }

  getResturantHours(post) {
    return this.http.post<Res>(this.getRestaurantHoursURL, post);
  }

  checkLinkAvailability(post) {
    return this.http.post<Res>(this.checkLinkAvailabilityURL, post);
  }

  updateLinkNameOfStore(post) {
    return this.http.post<Res>(this.updateLinkNameOfStoreURL, post);
  }

  enableOnlineOrdering(post) {
    return this.http.post<Res>(this.enableOnlineOrderingURL, post);
  }

  manageCustomerServiceFee(post) {
    return this.http.post<Res>(this.manageCustomerServiceFeeUrl, post);
  }


}
