import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ExportToCsv } from 'export-to-csv';
import { FarmService } from '../farm.service';

declare var $: any;

@Component({
  selector: 'app-farm-payments',
  templateUrl: './farm-payments.component.html',
  styleUrls: ['./farm-payments.component.css', '../../../../assets/css/ts-style.css']
})
export class FarmPaymentsComponent implements OnInit {

  maxDate = new Date();
  startDate = "";
  endDate = "";
  earnings = [];
  earningsTable: any;
  total = 0;
  
  matStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value.toString();
  }

  matEndDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value.toString();
  }
  constructor(private farmService: FarmService) { }

  ngOnInit(): void {

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          self.getEarnings();
        }
        form.classList.add('was-validated');
      }, false);
    });

  }

  async getEarnings() {

    let start = new Date(this.startDate).toDateString();
    let end = new Date(this.endDate).toDateString();

    const post = {
      StoreID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: start,
      ToDate: end
    };

    this.farmService.getFarmEarnings(post).subscribe(async data => {
      if (data['Success']) {
        this.earnings = data['Response'];
        this.total = 0;
        for (let i in this.earnings) {
          this.total = this.total + this.earnings[i]['NetAmount'];
        }
      }
      else {
        alert('Something went wrong! Could not get your earnings please try again later!')
      }
    });
  }

  convertUTCtoLocal(dateString) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
    return formattedDate;
  }

  exportData() {


    const data = [];

    for (let i in this.earnings) {
      data.push({
        "Date": this.convertUTCtoLocal(this.earnings[i]['Date']),
        "Amount": "$" + ((this.earnings[i]['NetAmount'] / 100).toFixed(2)),
      });
    }



    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TopServe Export ' + this.startDate + ' to ' + this.endDate,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }
}
