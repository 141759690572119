import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../admin/admin.service";
import {RegisterService} from "../../register/register.service";
import {DriverService} from "../driver.service";
import {EmpService} from "../../../emp.service";
declare var $: any;

@Component({
  selector: 'app-show-drivers',
  templateUrl: './show-drivers.component.html',
  styleUrls: ['./show-drivers.component.css']
})
export class ShowDriversComponent implements OnInit {
  restaurantID = 0;
  userType = 0;
  subDrivers = [];
  tempUsers = [];
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  restaurantName = '';
  passwordConfirmed = '';
  restaurantNames = [];
  restaurantIds = [];
  phone = '';
  newResID = '';
  keyword = '';
  newCityName = '';

  restaurants = [];
  subrestaurants = [];
  selectedRestaurants = [];
  topServeRestaurants = [];
  topServeCities = [];
  newResName = '';


   constructor(private emp:EmpService,private admin: AdminService, private registerService: RegisterService, private driverService: DriverService) {
  }

  async ngOnInit() {
    //  $('.dropdown-toggle').dropdown()

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {


          if (event.target.id == "register") {
            self1.addTeamMember()
          }
        }
        form.classList.add('was-validated');
      }, false);
    });


    this.getCities();

    $(".dropdown-menu li").click(function () {

      $(this).parents(".btn-group").find('.selection').text($(this).text());
      $(this).parents(".btn-group").find('.selection').val($(this).text());

    });

    await this.getUsers()




    //dropdown



    // $("#selectRestaurant").select2({
    //   data: dataPrim,
    //   multiple:true,
    //   tags: true,
    // });




    // $('#selectRestaurant').on('select2:select', (e) => {
    //   //  var data = e.params.data.text;
    //   // let categoryFiltered = this.primaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);

    
    //   // this.selectedPrimaryCategories.push({"Name":e.params.data.text})
      
    // });


    // $('#selectedCateselectRestaurantgory').on('select2:unselect', (e) => {
    //   // for(let n in this.selectedPrimaryCategories){
    //   //   if(e.params.data.text == this.selectedPrimaryCategories[n].Name){
    //   //     this.selectedPrimaryCategories.splice(parseInt(n),1)
    // //    }
    // //  }
    // });









  }



  selectedDropCityNew(resIndex){
    this.topServeRestaurants = [];
    this.newCityName = this.topServeCities[resIndex].Name
    const post = {
      Token:localStorage.getItem('AuthenticationToken'),
      Lat:this.topServeCities[resIndex]["Lat"],
      Long:this.topServeCities[resIndex]["Long"]
    };

    this.driverService.getRestaurants(post).subscribe(
        data => {
          for(let res in data["Restaurants"]){
            this.newResName =  data["Restaurants"][res]["Name"];
            this.newResID = data["Restaurants"][res]["RestaurantID"];
            this.topServeRestaurants.push( data["Restaurants"][res]);
          }
        }
    );




  }

  getCities() {

    const post = {
      Token:'adsf'
    };

    this.driverService.getCities(post).subscribe(
      data => {
        for(let city in data["Cities"]){
          this.newCityName =  data["Cities"][city]["Name"];
         this.topServeCities.push( data["Cities"][city]);
        }

        const post = {
          Token:localStorage.getItem('AuthenticationToken'),
          Lat:this.topServeCities[this.topServeCities.length - 1]["Lat"],
          Long:this.topServeCities[this.topServeCities.length - 1]["Long"]
        };

        this.driverService.getRestaurants(post).subscribe(
            data => {
              this.topServeRestaurants = [];
              for(let res in data["Restaurants"]){
                this.newResName =  data["Restaurants"][res]["Name"];
                this.newResID = data["Restaurants"][res]["RestaurantID"];
                this.topServeRestaurants.push( data["Restaurants"][res]);
              }
            }
        );

      }
  );

  }


 
  

  addRestaurant(userindex) {


      const post = {
        Token:localStorage.getItem('AuthenticationToken'),
        RestaurantID:this.selectedRestaurants,
        DriverID:this.subDrivers[userindex]["SubDriverID"],
        PartnerID:localStorage.getItem('PartnerID'),

      };
      

      this.driverService.addRestaurantToSubDriver(post).subscribe(
          data => {

            if(data["Success"]) {
             // $('#registerError').show();
            //  document.getElementById("registerError").innerHTML =  "Restaurant Added";
     //        $('#addRestauant' + userindex.toString()).modal('toggle');
             $('#addRestauant-' + userindex.toString()).modal('hide');

            //  this.getRestaurants();
            }else{
              $('#registerError').show();
              document.getElementById("registerError").innerHTML = data['Message'];

            }
          })
    
  }


  async getUsers() {


    ///get the users
    this.subDrivers = [];
    this.tempUsers = [];
    let partnerID = localStorage.getItem('PartnerID');
    if (partnerID != null || partnerID != undefined) {

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        PartnerID:partnerID
      };


      await this.driverService.getSubDriver(post).subscribe(async data => {
        if (data["Success"]) {
          for (let subDrive in data["SubDriver"]) {


            this.tempUsers.push({
              "Name": data["SubDriver"][subDrive]["FirstName"],
              "RestaurantName": data["SubDriver"][subDrive]["Name"],
              "SubDriverID": data["SubDriver"][subDrive]["SubDriverID"],
              "Phone": data["SubDriver"][subDrive]["Phone"],
              "Editing":false,
            })



            this.subDrivers.push({
              "Name": data["SubDriver"][subDrive]["FirstName"],
              "RestaurantName": data["SubDriver"][subDrive]["Name"],
              "SubDriverID": data["SubDriver"][subDrive]["SubDriverID"],
              "Phone": data["SubDriver"][subDrive]["Phone"],
              "Editing":false,
            })


            this.getRestaurantsForSubDriver(subDrive);

          }


        }
      })//end of subscriib
    }else{

    }
  }

  getRestaurantsForSubDriver(index) {

    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      DriverID: this.subDrivers[index]["SubDriverID"],
    };


    this.driverService.getRestaurantsForSubDriver(post).subscribe(
        data => {
          if (data["Success"]) {
            var subres = [];
            for (let resIndex in data["Response"]) {
              subres.push(data["Response"][resIndex])
            }
            this.subDrivers[index]["Restaurants"] = subres
          } else {

          }



        })
  }


  selectedDropDownRestaurantNew(resIndex,userIndex) {
    this.newResName = this.topServeRestaurants[resIndex].Name;
    this.newResID = this.topServeRestaurants[resIndex].RestaurantID
    let values = $('#restaurantSelected-' + userIndex).val();


    this.selectedRestaurants = values
    

  }

  selectedDropDownRestaurant(resIndex, userIndex) {
    this.subDrivers[userIndex].RestaurantName = this.restaurantNames[resIndex]


  }

  selectedDropDownNew(userType) {
    this.userType = userType;
  }

  selectedDropDown(selectedUserType, userIndex) {
    this.subDrivers[userIndex].UserType = selectedUserType
    //  this.userType = selectedUserType

  }


  cancelUser(userIndex) {


    this.subDrivers[userIndex] = this.tempUsers[userIndex];
    this.subDrivers[userIndex].Editing = false;
    var userEditingBtn = $("#userBtn" + userIndex);
    var userEditing = $("#user" + userIndex);

    userEditingBtn.addClass('edit');
    userEditingBtn.removeClass('saving');
    userEditing.addClass('icmn-pencil');
    userEditing.removeClass('icmn-upload');
  }

  async editUser(userIndex) {

    var userEditing = $("#user" + userIndex);
    var userEditingBtn = $("#userBtn" + userIndex);

    if (this.subDrivers[userIndex].Editing) {
      userEditing.addClass('icmn-pencil');
      userEditing.removeClass('icmn-upload');
      userEditingBtn.addClass('edit');
      userEditingBtn.removeClass('saving');
    } else {
      userEditingBtn.addClass('saving');
      userEditingBtn.removeClass('edit');
      userEditing.removeClass('icmn-pencil');
      userEditing.addClass('icmn-upload');
    }


    if (this.subDrivers[userIndex].Editing) {



      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        FirstName: this.subDrivers[userIndex].Name,
        Phone: this.subDrivers[userIndex].Phone,
        SubDriverID: this.subDrivers[userIndex].SubDriverID
      };



      await this.driverService.updateSubDriver(post).subscribe(async data => {
        if (data["Success"]) {
          alert("Updated")
        } else {
          alert("Something went wrong, did not update")
        }



      });//end of subscriib

      this.tempUsers[userIndex] = this.subDrivers[userIndex];
    }

    this.subDrivers[userIndex].Editing = !this.subDrivers[userIndex].Editing;

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  addTeamMember() {

    // this.users.push({"Name":"","Email":"","RestaurantName":"","UserType":1,"Editing":true});
    // let index = this.users.length;
    // var userEditingBtn =  $("#userBtn" + index.toString());
    // userEditingBtn.addClass('saving');
    // userEditingBtn.removeClass('edit');
    //

    var driverID = localStorage.getItem('DriverID');


    if (driverID != null || driverID != undefined) {

      
    } else {
      driverID = "1"
    }

      const post = {
        Phone: this.phone,
        FirstName: this.firstName,
        LastName: this.lastName,
        DriverID: driverID
      };

      console.log(post);


      this.driverService.addSubDriver(post).subscribe(data => {
        if (data["userAdded"]) {
          this.getUsers();
        } else {
          alert("Something went wrong, may not have updated")
        }
      })

   

  }

  async deleteUser(userIndex) {


    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      SubDriverID: this.subDrivers[userIndex].SubDriverID,
    };


    await this.driverService.deleteSubDriver(post).subscribe(async data => {
      if (data["Success"]) {
        this.subDrivers.splice(parseInt(userIndex), 1);
        alert("Deleted");

      } else {
        alert("Something went wrong, did not delete");
      }


    });//end of subscriib

  }

}
