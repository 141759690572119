// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #FFFBFB;
    height: 100vh;
    font-family: poppins;
}

#page-not-found h3 {
    font-size: 2rem;
    font-family: poppins-medium;
}

#page-not-found h1 {
    font-size: 8rem;
    font-family: poppins-semibold;
    color: #b00505;
}

#page-not-found .small_btn {
    background: var(--main--color);
    border-radius: 5rem;
    color: var(--primary--color) !important;
    font-size: 16px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 3rem;
    border: none;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/structure/page-not-found/page-not-found.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,mBAAmB;IACnB,uCAAuC;IACvC,eAAe;IACf,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,2BAAsB;IAAtB,sBAAsB;IACtB,2BAAsB;IAAtB,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":["#page-not-found {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    background-color: #FFFBFB;\n    height: 100vh;\n    font-family: poppins;\n}\n\n#page-not-found h3 {\n    font-size: 2rem;\n    font-family: poppins-medium;\n}\n\n#page-not-found h1 {\n    font-size: 8rem;\n    font-family: poppins-semibold;\n    color: #b00505;\n}\n\n#page-not-found .small_btn {\n    background: var(--main--color);\n    border-radius: 5rem;\n    color: var(--primary--color) !important;\n    font-size: 16px;\n    height: fit-content;\n    padding: 1rem 3rem;\n    border: none;\n    max-width: fit-content;\n    min-width: fit-content;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
