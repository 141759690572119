import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import {Router} from "@angular/router";


export interface User  {
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loginUrl = this.url.getDomain() + '/Users/LoginRestaurantUser';
  loginNormalUrl = this.url.getDomain() + '/Users/loginUser';
  loginFBUrl = this.url.getDomain() + '/Users/LoginFBUser2';
  getPaymentMethodsURL =  this.url.getDomain() + '/Stripe/getPaymentMethodList';
  forgotPasswordURL = this.url.getDomain() + '/Mail/SendMail'

  constructor(private router:Router,private http: HttpClient, private url: Url) { }

  login(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };


    return this.http.post<User>(this.loginUrl, post, httpOptions);
  }

  getPaymentMethods(post) {

    return this.http.post(this.getPaymentMethodsURL, post);
  }


  loginNormal(post) {

    return this.http.post(this.loginNormalUrl, post);
  }

  loginFB(post) {

    return this.http.post(this.loginFBUrl, post);
  }

  forgotPassword(post){

    return this.http.post(this.forgotPasswordURL, post)
  }

}
