import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Url} from "../../../models/url";


@Injectable({
  providedIn: 'root'
})
export class ContactlessService {

  getTableTopProductsURL = this.url.getDomain() + '/Products/getTableTopProducts';
  getTableTopProductCheckoutSessionURL = this.url.getDomain() + '/Products/getTableTopProductCheckoutSession';
  enableRestaurantOrderTypeURL = this.url.getDomain() + '/Restaurants/EnableRestaurantOrderType';

  constructor(private router:Router,private http: HttpClient, private url: Url) { }

  tableTopProducts(post) {
    return this.http.post(this.getTableTopProductsURL, post);
  }

  getTableTopProductCheckoutSession(post) {
    return this.http.post(this.getTableTopProductCheckoutSessionURL, post);
  }

  EnableRestaurantOrderType(post) {
    return this.http.post(this.enableRestaurantOrderTypeURL, post);
  }
}
