import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ElementRef
} from '@angular/core';
import {AddOptionComponent} from "../add-option/add-option.component";
import {MenuService} from "../../structure/menu/menu.service";


declare var $: any;

@Component({
  selector: 'app-add-full-option',
  templateUrl: './add-full-option.component.html',
  styleUrls: ['./add-full-option.component.css', '../../../assets/css/custom.css']
})

export class AddFullOptionComponent implements OnInit,AfterContentInit {
  @ViewChild('addOptionContainer',{read:ViewContainerRef}) addOptionContainer;
  @ViewChild('checked') checked: ElementRef;

  optionName = "";
  optionDesc = "";
  optionPrice = 0;
  showClose: true ;
  childComponentOf = '';
  optionTitle = {'MenuItemOptionsCategoryID':null,'Name':null,'Description':null,'ParentMenuItemOptionCategory':null,'Selectables':1,'Required':false,'AbleAmount':1};
  optionSides = [];
  options = [];
  test = [];
  optionCategorys = [];
  optionID:any;
  optionCategoryIndex = 0;
  isActive = true;
  finalSides = [];
  constructor(
      private resolver:ComponentFactoryResolver,
      private menuService: MenuService
  ) { }

  async ngOnInit() {
    
    await this.getOptionCategories();
    
    if(this.optionTitle.Required)
    {
      $('#required-amount'+ this.optionCategoryIndex).show();
    }else{
      $('#required-amount'+ this.optionCategoryIndex).hide();
    }

    if(this.optionSides.length == 0){
      var x =  this.resolver.resolveComponentFactory(AddOptionComponent);
      var t = this.addOptionContainer.createComponent(x);
      this.options.push(t.instance);
      t.instance.showClose = false
    }
    else {
        this.optionSides.forEach(async (item, n) => {
          var x = this.resolver.resolveComponentFactory(AddOptionComponent);
          var t = this.addOptionContainer.createComponent(x);

          t.instance.optionID = item.MenuItemOptionID;
          t.instance.optionName = item.Name;
          t.instance.optionDesc = item.Description;
          t.instance.optionPrice = item.Price;

          this.options.push(t.instance);
          if (n != 0) {
            t.instance.showClose = true
          }
        });
    }

    var dataPrim = [];
    var dataSelectable = [];
    var dataAmount = [];
  
    // if(this.optionTitle.Name != null){
    //   dataPrim.push({
    //     id: 1,
    //     text: this.optionTitle.Name,
    //     "selected": true
    //   })
    // }

    // if (this.optionTitle.Selectables != null) {
    //   dataSelectable.push({ 
    //     id: 1,
    //     text: this.optionTitle.Selectables.toString(),
    //     "selected": true
    //   })
    // }

    // if (this.optionTitle.AbleAmount !=  null) {
    //   dataAmount.push({
    //     id: 1,
    //     text: this.optionTitle.AbleAmount.toString(),
    //     "selected": true
    //   })
    // }
     
    var optionExists = false;
    this.optionCategorys.forEach(async (item, n) => {

      if (this.optionTitle.Required) {
        this.checked.nativeElement.checked = true
      }

      if (this.optionTitle.Name == null || this.optionTitle.Name === undefined ){
        this.optionTitle.Name =  item["Name"];
      }

      if (this.optionTitle.Name == item["Name"]) {
        optionExists = true;
        dataPrim.push({
          id: n,
          text: item["Name"],
          "selected": true
        })
      } else{

        dataPrim.push({
          id: n,
          text: item["Name"],
          "selected": false
        })
      }
    });

    if (!optionExists && this.optionTitle.Name !== undefined) {
      dataPrim.splice(0, 0, {
        id: dataPrim.length + 1,
        text: this.optionTitle.Name,
        "selected": true
      })
    }

    dataAmount.push({
      id: 0,
      text: this.optionTitle.AbleAmount,
      "selected": true
    })


    dataSelectable.push({
      id: 0,
      text: this.optionTitle.Selectables,
      "selected": true
    });

    $("#selectable"+ this.optionCategoryIndex).select2({
      data:dataSelectable,
      multiple:false,
      tags: true,
    });

    $("#ableAmount"+ this.optionCategoryIndex).select2({
      data: dataAmount,
      multiple:false,
      tags: true,
    });


    if(dataPrim[0]["text"] == null){
      $("#optionCategory"+ this.optionCategoryIndex).select2({
       // data: dataPrim,
        multiple:false,
        tags: true,
      });
    }else{
      $("#optionCategory"+ this.optionCategoryIndex).select2({
        data: dataPrim,
        multiple:false,
        tags: true,
      });
    }

   

    $('#optionCategory'+ this.optionCategoryIndex).on('select2:select', (e) => {
      this.optionTitle["Name"] = e.params.data.text;
    });

    $('#optionCategory'+ this.optionCategoryIndex).on('select2:unselect', (e) => {
      this.optionTitle["Name"] = null
    });
    
    $('#selectable'+ this.optionCategoryIndex).on('select2:select', (e) => {

      if (!isNaN(e.params.data.text)) {
        this.optionTitle["Selectables"] = e.params.data.text;
      } else {
        this.optionTitle["Selectables"] = 1;
        $('#selectable' + this.optionCategoryIndex).val(1).trigger('change');
      }
    });

    $('#selectable' + this.optionCategoryIndex).on('select2:unselect', (e) => {

      this.optionTitle["Selectables"] = 50
    });

    $('#ableAmount' + this.optionCategoryIndex).on('select2:select', (e) => {

      if(!isNaN(e.params.data.text)){
        this.optionTitle["AbleAmount"] = e.params.data.text;
      }else{
        this.optionTitle["AbleAmount"] = 1;
        $('#ableAmount'+ this.optionCategoryIndex).val(1).trigger('change');
      }
    });

    $('#ableAmount'+ this.optionCategoryIndex).on('select2:unselect', (e) => {

      this.optionTitle["AbleAmount"] = 50
    });
  }


  toggleRequired(){
    var checked = this.checked.nativeElement.checked;
    this.optionTitle["Required"] = checked;

    if(this.optionTitle.Required)
    {
      $('#required-amount'+ this.optionCategoryIndex).show();
    }else{
      $('#required-amount'+ this.optionCategoryIndex).hide();
    }
  }

  ngAfterContentInit(): void {

  }

  addOption() {
    // $('#option').find(".select2").each(function(index)
    // {
    //   if ($(this).data('select2')) {
    //     $(this).select2('destroy');
    //   }
    // });

    var x =  this.resolver.resolveComponentFactory(AddOptionComponent);
    var t = this.addOptionContainer.createComponent(x);
    t.instance.showClose = true;
    this.options.push(t.instance);

    // $('.select2').select2({
    //   multiple:false,
    //   tags: true,
    // });
  }

  async getOptionCategories() {
    //nick restaurant id
    return new Promise(resolve => {
      this.menuService.getOptionCategories(localStorage.getItem('restaurantID'), localStorage.getItem('UserID')).subscribe(
          data => {
            for (let x in data) {
              this.optionCategorys.push(data[x]);
            }
            resolve(true);
          }
      );
    });
  }

  getOptions(){
    var sides = [];
    this.options.forEach(async (item,n) => {
      if (item.instance instanceof AddOptionComponent) {
          if(item.instance.isActive){
            sides.push({ID: item.instance.optionID,Name: item.instance.optionName, Description: item.instance.optionDesc, Price: item.instance.optionPrice});
          }
        }
    });
    return sides;
  }


  closeOption(){
    while (this.options.length > 0) {
      this.options.pop()
    }
      this.isActive = false;
      this.options = [];
  }
}