import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  Directive,
  OnDestroy,
  OnInit,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuService } from '../menu.service';
const AWS = require('aws-sdk');
import { EditItemServiceService } from "../../../services/edit-item-service.service";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { ViewChild } from "@angular/core";
import { AddCategoryComponent } from "../../../components/add-category/add-category.component";
import { AddOptionComponent } from "../../../components/add-option/add-option.component";
import { AddFullOptionComponent } from "../../../components/add-full-option/add-full-option.component";
import { Url } from '../../../models/url';
import { EmpService } from "../../../emp.service";
import { environment } from 'environments/environment.prod';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.css', '../../../../assets/css/custom.css'],
  encapsulation: ViewEncapsulation.None,
})




export class EditItemComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('addCategoryContainer', { read: ViewContainerRef }) addCategoryContainer;
  @ViewChild('addOptionContainer', { read: ViewContainerRef }) addOptionContainer;
  @ViewChild('addFullOptionContainer', { read: ViewContainerRef }) addFullOptionContainer;
  addCategoryComponent = AddCategoryComponent;


  public mains = ['Lunch', 'Dinner', 'Breakfast', 'Supper'];
  public sides = ['Salades'];
  public chosenMains = [];
  name: any;
  category: string[] = [];
  subCategory: string[] = [];
  selectedIngredients = [];
  shortDesc: any;
  desc: any;
  menuItemPrice: any;
  menuItemIngredients = "";
  menuItemDescription: any;
  discountPrice: any;
  menuImageUrl: any;
  fileInput: any;
  image = '';
  itemAdded = false;
  loading = false;
  primaryCat: any;
  secondaryCat: any;
  optionCategorys: string[] = [];
  optionName: string[] = [];
  optionDesc: string[] = [];
  optionPrice: string[] = [];
  optionCategory: string[] = [];
  categoryIndexes: number = 0;
  targetRestaurant: any;
  restaurant: any;
  menuImage: any;
  selectedPrimaryCategories = [];
  selectedSubCategories = [];
  selectedCategoryArray = [];
  menuItemOptions = [];
  menuSecondaryCategoriesFiltered = [];
  categoryIndex = 0;
  optionCategoryIndex = 0;
  addedCategories = [];
  addedOptions = [];
  mainMenu: any;
  menuFile: any;

  tempFalse = false;
  allDay: boolean;
  servedStart = '00:00';
  servedEnd = '00:00';


  option = [
    {
      'option0': this.optionCategory,
      'option1': this.optionName,
      'option2': this.optionDesc,
      'option3': this.optionPrice,

    }
  ]

  opt: any;

  menuItemToEdit: any;
  menuItemName: any;

  constructor(
    private route: ActivatedRoute,
    private menuService: MenuService,
    private editService: EditItemServiceService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private url: Url,
    private emp: EmpService, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Edit item' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Edit item' });
    meta.updateTag({ property: 'og:title', content: "Edit item" });


  }

  ngAfterContentInit(): void {






  }

  async ngOnInit() {

    const forms = document.getElementsByClassName('needs-validation');
    const self1 = this;

    const validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          self1.updateItem();
        }
        form.classList.add('was-validated');
      });
    });

    this.mainMenu = [this.editService.selectedMenuItem]
    if (this.editService.selectedMenuItem == undefined) {

      this.router.navigateByUrl('/services/show-items')
      return
    }

    this.menuItemToEdit = this.editService.selectedMenuItem;
    
    await this.getOptionCategories();
    await this.getCategory();

    this.menuItemName = this.menuItemToEdit["menuItemToEdit"].Name;
    this.menuItemPrice = this.menuItemToEdit["menuItemToEdit"].Price;
    this.menuItemDescription = this.menuItemToEdit["menuItemToEdit"].Description;
    // this.menuItemIngredients = this.menuItemToEdit["menuItemToEdit"].Ingredients;

    if (this.menuItemToEdit['menuItemToEdit'].ImageUrl != null) {
      this.menuImage = this.menuItemToEdit["menuItemToEdit"].image;
      this.fileInput = this.menuItemToEdit["menuItemToEdit"].image;
    }


    if (this.menuItemToEdit["menuItemToEdit"].ServedStart != null && this.menuItemToEdit["menuItemToEdit"].ServedEnd != null) {
      this.servedEnd = this.menuItemToEdit["menuItemToEdit"].ServedEnd;
      this.servedStart = this.menuItemToEdit["menuItemToEdit"].ServedStart;
      this.allDay = true
    }



    let dataSub = [];
    let dataPrim = [];
    let dataIngredients = [];

    this.secondaryCat.forEach(async (item, n) => {
      let categoryFiltered = this.editService.selectedSubCategory.filter(filteredCat => filteredCat.MenuItemCategoryID == item.MenuItemCategoryID);
      if (categoryFiltered.length > 0) {
        this.selectedSubCategories.push(item);

        dataSub.push({
          id: n,
          text: item["Name"],
          "selected": true
        })
      } else {
        dataSub.push({
          id: n,
          text: item["Name"],
          "selected": false
        })
      }
    });


    this.primaryCat.forEach(async (item, n) => {
      let categoryFiltered = this.editService.selectedPrimaryCategory.filter(filteredCat => filteredCat.MenuItemCategoryID == item.MenuItemCategoryID);

      if (categoryFiltered.length > 0) {
        this.selectedPrimaryCategories.push(item);

        dataPrim.push({
          id: n,
          text: item["Name"],
          "selected": true
        })
      } else {
        dataPrim.push({
          id: n,
          text: item["Name"],
          "selected": false
        })
      }
    });

    var preSelectedIngredients = this.menuItemToEdit["menuItemToEdit"].Ingredients.toString().split(',')
    preSelectedIngredients.forEach(async (ingredient, i) => {
      this.selectedIngredients.push({ "Name": ingredient });
      dataIngredients.push({ id: i, text: ingredient, "selected": true })
    });

    $("#selectedIngredients").select2({
      data: dataIngredients,
      multiple: true,
      tags: true,
    });

    $('#selectedIngredients').on('select2:select', (e) => {
      this.selectedIngredients.push({ "Name": e.params.data.text })
    });

    $('#selectedIngredients').on('select2:unselect', (e) => {
      for (let n in this.selectedIngredients) {
        if (e.params.data.text == this.selectedIngredients[n].Name) {
          this.selectedIngredients.splice(parseInt(n), 1)
        }
      }
    });



    if (this.menuItemToEdit["menuItemToEdit"].hasOwnProperty("options")) {
      this.menuItemToEdit["menuItemToEdit"].options.forEach(async (item, n) => {

        var x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
        const t = this.addFullOptionContainer.createComponent(x);
        t.instance.showClose = true;

        item.sides.forEach(async (item, n) => {
          t.instance.optionSides.push(item);
        });


        t.instance.optionTitle = item.title;
        t.instance.optionCategoryIndex = this.optionCategoryIndex;
        this.optionCategoryIndex += 1;


        //testing
        this.menuItemOptions.push({
          "sides": t.instance.options,
          "title": t.instance.optionTitle,
          "isActive": t.instance.isActive
        });
        //testing
        this.addedOptions.push(t);
      });
    }



    //pretend the first tab is clicked so we filted the sub categories
    if (this.selectedPrimaryCategories.length > 0) {
      this.tabClicked(this.selectedPrimaryCategories[0]["Name"]);
    }

    this.filterCategories();



    $(function () {
      $('.clockpicker').clockpicker();
      $('.selectpicker').selectpicker();
      $('.dropify').dropify();
    });

  }




  filterCategories() {

    for (let primIndex in this.primaryCat) {

      let subCategories = this.secondaryCat.filter(filteredCat => filteredCat.ParentMenuItemCategory == this.primaryCat[primIndex].MenuItemCategoryID);

      $('#addCategory').find(".select2").each(function (index) {
        if ($(this).data('select2')) {
          $(this).select2('destroy');
        }
      });


      let x = this.resolver.resolveComponentFactory(AddCategoryComponent); //here we add a reference to AddCategoryComponent and assign its variables
      let t = this.addCategoryContainer.createComponent(x);
      t.instance.selectedSubCategories = subCategories;
      t.instance.selectedPrimaryCategories = [this.primaryCat[primIndex]];
      t.instance.categoryIndex = this.categoryIndex;

      if (primIndex == "0") {
        t.instance.showClose = false;
      } else {
        t.instance.showClose = true;
      }

      var y = { "Primary": t.instance.selectedPrimaryCategories, "Secondary": t.instance.selectedSubCategories };
      this.selectedCategoryArray.push(y);
      this.categoryIndex += 1;
      this.addedCategories.push(t);
      //this.selectedPrimaryCategories += t.instance.selectedPrimaryCategories;

      $('.select2').select2();

    }
  }




  async getCategory() {

    return new Promise(resolve => {
      //nick restaurant id

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID'),
        MenuItemID: this.menuItemToEdit["menuItemToEdit"].MenuItemID,
      };


      this.menuService.getCategories(post).subscribe(
        data => {
          this.primaryCat = data['PrimaryCategories'];
          this.secondaryCat = data['SecondaryCategories']
          resolve(true);
        }
      );
    });

  }

  async getOptionCategories() {

    return new Promise(resolve => {
      //nick restaurant id
      this.menuService.getOptionCategories(localStorage.getItem('restaurantID'), localStorage.getItem('UserID')).subscribe(
        data => {

          for (let x in data) {
            this.optionCategorys.push(data[x]);
          }
          resolve(true);
        }
      );
    });

  }


  async updateItem() {
    this.loading = true;

    await this.upload();
    await this.editObject()

    //this.loading = false;

  }

  async editObject() {

    await this.updateMenuItemPrimaryData();

    await this.updateMenuItemCategoryData();

    await this.updateMenuItemSideData();

    this.router.navigateByUrl('/services/show-items');

  }


  async updateMenuItemSideData() {

    var menuOptions = [];
    this.menuItemOptions.forEach(async (item, n) => {

      if (item.isActive && item.sides.length > 0) {
        let sides = [];
        for (let x in item.sides) {
          if (item.sides[x].isActive && item.sides[x].optionName != '') {
            sides.push({ "name": item.sides[x].optionName, "price": item.sides[x].optionPrice, "description": "" })
          }
        }
        if (sides.length > 0) {
          menuOptions.push({ "sides": sides, "sideCategoryName": item.title.Name, "selectable": item.title.Selectables, "required": item.title.Required, "ableAmount": item.title.AbleAmount })
        }
      }
    });

   // if (menuOptions.length > 0) {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID'),
        MenuItemID: this.menuItemToEdit["menuItemToEdit"].MenuItemID,
        MenuOptions: menuOptions
      };


      await this.updateMenuItemSides(post);
    //}
  }

  cancelUpdate() {
    this.router.navigateByUrl('/services/show-items')
  }

  async updateMenuItemSides(options) {
    return new Promise(resolve => {
      this.menuService.AddMenuItemSides(options).subscribe(
        data => {
          if (data['Success']) {
            // this.menuImageUrl = data['MenuImageURL'];
            // this.upload();
            resolve(true)
          } else {
            resolve(true)
          }
        }
      );
    });
  }



  async updateMenuItemCategoryData() {
    return new Promise(resolve => {

      this.selectedCategoryArray.forEach(async (item, n) => {
        var categoryToSubmitTemp = [];
        var categoryToSubmit = [];

        item.Primary.forEach(async (primaryItem, n) => {
          categoryToSubmitTemp.push(primaryItem.Name)
        });

        let p = { "parentCategories": categoryToSubmitTemp }

        item.Secondary.forEach(async (subItem, n) => {
          categoryToSubmit.push(subItem.Name)
        });
        let s = { "childCategories": categoryToSubmit }

        await this.updateMenuItemCategories(p, s);

        if ((n + 1) == this.selectedCategoryArray.length) {
          resolve(true);
        }
      });

    })
  }

  async updateMenuItemCategories(prim, sub) {
    return new Promise(resolve => {

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID'),
        MenuItemID: this.menuItemToEdit["menuItemToEdit"].MenuItemID,
        parentCategories: prim,
        childCategories: sub
      };



      this.menuService.updateMenuItemCategories(post).subscribe(
        data => {
          if (data['Success']) {
            // this.menuImageUrl = data['MenuImageURL'];
            // this.upload();
            resolve(true)
          } else {
            resolve(true)
          }
        }
      );
    });
  }








  async updateMenuItemPrimaryData() {
    return new Promise(resolve => {
      
      this.selectedIngredients.forEach(async (item, n) => {
        if (item.Name != undefined && item.Name != 'N/A') {
          this.menuItemIngredients += item.Name + ",";
        }
      });

      if (this.menuItemIngredients.length>0) {
        this.menuItemIngredients = this.menuItemIngredients.substr(0,this.menuItemIngredients.length-1);
      }
      else {
        this.menuItemIngredients = "N/A";
      }

      this.servedStart = $("#servedStart").val();
      this.servedEnd = $("#servedEnd").val();

      if (!this.allDay) {
        this.servedStart = null;
        this.servedEnd = null;
      }
      
      const post = {
        Name: this.menuItemName,
        Description: this.menuItemDescription,
        Price: this.menuItemPrice,
        Ingredients: this.menuItemIngredients,
        MenuItemID: this.menuItemToEdit["menuItemToEdit"].MenuItemID,
        ServedStart: this.servedStart,
        ServedEnd: this.servedEnd,
        itemHasImage: (this.fileInput != null && this.fileInput != undefined) ? true : false
      };

      this.menuService.updateMenuItemPrimaryData(post).subscribe(
        data => {
          if (data['Success']) {
            // this.menuImageUrl = data['MenuImageURL'];
            // this.upload();
          }
          resolve(true)

        }
      );
    });
  }


  fileEvent(fileInput: any) {

    this.fileInput = fileInput.target.files[0];

    const file = fileInput.target.files[0];
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

    if (validImageTypes.includes(fileType)) {
      let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
      this.menuImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
      //this.menuImage = fileInput.target.files[0];
    }


  }


  //add error handling on this
  async upload() {


    return new Promise(resolve => {

      const AWSService = AWS;
      const region = 'ca-central-1';



      let bucketName = ""
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-restaurant-menu-images-dev-canada';
      } else {
        bucketName = 'topserve-restaurant-menu-images-prod-canada';

      }


      // const bucketName = 'menu-item-images-meeno';
      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });
      // I store this in a variable for retrieval later
      if (this.fileInput === null || this.fileInput == undefined) {
        resolve(true)
      }


      this.image = this.fileInput.name;
      s3.upload({
        Key: this.menuItemToEdit["menuItemToEdit"].MenuItemID.toString() + ".jpeg",
        Bucket: bucketName,
        Body: this.fileInput,
        ACL: 'public-read'
      }, function (err, data) {
        if (err) {
          resolve(false)
        } else {
          resolve(true)
        }
      });

    });

  }



  addCategory() {
    // $('#addCategory').find(".select2").each(function(index)
    // {
    //   if ($(this).data('select2')) {
    //     $(this).select2('destroy');
    //   }
    // });

    let x = this.resolver.resolveComponentFactory(AddCategoryComponent); //here we add a reference to AddCategoryComponent and assign its variables
    let t = this.addCategoryContainer.createComponent(x);

    t.instance.categoryIndex = this.categoryIndex;
    t.instance.showClose = true;

    var y = { "Primary": t.instance.selectedPrimaryCategories, "Secondary": t.instance.selectedSubCategories };
    this.selectedCategoryArray.push(y);
    this.categoryIndex += 1;
    this.addedCategories.push(t);
    //this.selectedPrimaryCategories += t.instance.selectedPrimaryCategories;
    $('.select2').select2();
  }



  addOptions() {

    $('#allOptions').find(".select2").each(function (index) {
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }
    });

    let x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
    const ty = this.addFullOptionContainer.createComponent(x);
    ty.instance.showClose = true;
    ty.instance.optionCategoryIndex = this.optionCategoryIndex;
    this.optionCategoryIndex += 1;

    this.addedOptions.push(ty);

    //testing
    this.menuItemOptions.push({ "sides": ty.instance.options, "title": ty.instance.optionTitle, "isActive": ty.instance.isActive });
    //testing
    $('.select2').select2();

  }



  tabClicked(category) {



    this.selectedCategoryArray.forEach(async (item, n) => {
      item.Primary.forEach(async (primaryName, n) => {
        if (primaryName["Name"] == category) {
          this.menuSecondaryCategoriesFiltered = item.Secondary
        }
      })
    });

  }

  closeCategoryCheck() {
  }

  // Upload an existing menu json file to Menu Options
  uploadMenu(event) {
    this.menuFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.menuFile, "UTF-8");
    fileReader.onload = () => {
      var json = JSON.parse(fileReader.result as string)
      for (let x in json) {
        if (x.toString() == "MenuOptions") {
          json[x].forEach(async (item, n) => {
            var x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
            const t = this.addFullOptionContainer.createComponent(x);
            t.instance.showClose = true;

            item.sides.forEach(async (item, n) => {
              t.instance.optionSides.push(item);
            });

            let title = { "Name": item.sideCategoryName, "Selectables": item.Selectable, "Required": item.Required, "AbleAmount": item.AbleAmount };
            t.instance.optionTitle = title;
            t.instance.optionCategoryIndex = this.optionCategoryIndex;
            this.optionCategoryIndex += 1;

            //testing
            this.menuItemOptions.push({
              "sides": t.instance.options,
              "title": t.instance.optionTitle,
              "isActive": t.instance.isActive
            });
            //testing
            this.addedOptions.push(t);
          });
        }
      }
    };
    fileReader.onerror = (error) => {
    }
  }

  // Export the Menu Options as a json file to local disk. 
  // File name: "menuOptions.json"
  exportMenu() {
    var menuOptions = [];
    this.menuItemOptions.forEach(async (item, n) => {
      if (item.isActive && item.sides.length > 0) {
        let sides = [];
        for (let x in item.sides) {
          if (item.sides[x].isActive && item.sides[x].optionName != '') {
            sides.push({ "Name": item.sides[x].optionName, "Price": item.sides[x].optionPrice, "Description": "" })
          }
        }
        if (sides.length > 0) {
          menuOptions.push({ "sides": sides, "sideCategoryName": item.title.Name, "Selectable": item.title.Selectables, "Required": item.title.Required, "AbleAmount": item.title.AbleAmount })
        }
      }
    });

    const post = {
      MenuOptions: menuOptions
    };

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(post));
    var dlMenu = document.getElementById('downloadMenu');
    dlMenu.setAttribute("href", dataStr);
    var valueJson = "menuOptions.json";
    dlMenu.setAttribute("download", valueJson);
  }

  ngOnDestroy(): void {
    this.categoryIndexes = 0
    this.editService.selectedPrimaryCategory = [];
    this.editService.selectedSubCategory = [];
  }

  clearMenuItemImage(event) {
    this.menuImage = undefined;
    this.fileInput = null;
    event.stopPropagation();
  }

}




