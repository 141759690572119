// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
    .ts-farm-layout {
        width: 100%;
    }
}

/* Rest All Devices */
@media (min-width: 576px) { 
    .ts-farm-layout {
        max-width: 576px !important;
        margin: 0 auto;
        background-color: white;
        border: 1px solid #e9ecef;
        border-radius: 12px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        margin-top: 2.5rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/structure/farm/farm-order/farm-order.component.css"],"names":[],"mappings":"AAAA,2DAA2D;AAC3D;IACI;QACI,WAAW;IACf;AACJ;;AAEA,qBAAqB;AACrB;IACI;QACI,2BAA2B;QAC3B,cAAc;QACd,uBAAuB;QACvB,yBAAyB;QACzB,mBAAmB;QACnB,uDAAuD;QACvD,kBAAkB;IACtB;AACJ","sourcesContent":["/* Extra small devices (portrait phones, less than 576px) */\n@media (max-width: 575.99px) {\n    .ts-farm-layout {\n        width: 100%;\n    }\n}\n\n/* Rest All Devices */\n@media (min-width: 576px) { \n    .ts-farm-layout {\n        max-width: 576px !important;\n        margin: 0 auto;\n        background-color: white;\n        border: 1px solid #e9ecef;\n        border-radius: 12px;\n        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;\n        margin-top: 2.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
