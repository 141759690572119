import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import {ProfileService} from "../structure/profile/profile.service";
import {EmpService} from "../emp.service";
import {Url} from "../models/url";
import {Meta} from "@angular/platform-browser";


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(private url:Url, private profileService:ProfileService,private authService: AuthService, private router: Router,private meta: Meta,public emp:EmpService) {

      meta.updateTag({name: 'description', content: 'TopServe Auth'} );
      meta.updateTag({name: 'robots', content: 'INDEX, FOLLOW'});
      meta.updateTag({name: 'author', content: 'TopServe'});
      meta.updateTag({name: 'keywords', content: 'TopServe, Deliveries, PEI, Auth'});
      meta.updateTag({property: 'og:title', content: "Auth"});


  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);

  }

  checkLogin(url: string): boolean {


    const isLogedIn = localStorage.getItem('isLogedIn');


    if (isLogedIn === 'true') {

        if( localStorage.getItem('userType') == '1' || localStorage.getItem('userType') == '0'){
          // Here changed landing
            this.router.navigate(['/']);
            return false;
        }


      const loggedInType = localStorage.getItem('type');

      if(loggedInType == 'partner'){



        const post = {
          PartnerID: localStorage.getItem('PartnerID'),
          Token: localStorage.getItem('AuthenticationToken')
        };


        this.profileService.getPartnerProfile(post).subscribe(
            data => {

              if (data['Success']) {
                localStorage.setItem('isLogedIn', 'true');
                localStorage.setItem('type', 'partner');
                localStorage.setItem('userType', '9');
                localStorage.setItem('Name', data['User'][0].Partner);
                localStorage.setItem('AuthenticationToken', post.Token);
                localStorage.setItem('PartnerID', data['User'][0].PartnerID);
                localStorage.setItem('Email', data['User'][0].Email);

                return true;
              } else {
                //return false;

              }
            },err => {
                localStorage.setItem('isLogedIn', 'false');
                this.router.navigate(['/logout']);
                return false
            }
        );









        return true;


      }else if(loggedInType == 'driver'){

        const post = {
          DriverID: localStorage.getItem('DriverID'),
          Token: localStorage.getItem('AuthenticationToken')
        };


        this.profileService.getDriverProfile(post).subscribe(
            data => {

              if (data['Success']) {
                this.emp.loggedInDriver = data['Response'][0];
                localStorage.setItem('driverType', data['Response'][0].DriverType);
                localStorage.setItem('firstName', data['Response'][0].FirstName);
                localStorage.setItem('AuthenticationToken', post.Token);
                localStorage.setItem('DriverID', data['Response'][0].DriverID);
                localStorage.setItem('Email', data['Response'][0].Email);

                return true;
              } else {
                //return false;

              }
            },err => {
                localStorage.setItem('isLogedIn', 'false');
                this.router.navigate(['/logout']);
                return false
            }
        );

      }else {
        const post = {
          UserID: localStorage.getItem('UserID'),
          Token: localStorage.getItem('AuthenticationToken')
        };

        this.profileService.getProfile(post).subscribe(
            data => {
              if (data['Success']) {

                  if (data["Response"].length > 0) {
                      this.emp.loggedInUser = data["Response"][0];
                      var dataSource = data["Response"][0];
                      if (dataSource.hasOwnProperty("RestaurantUserType") && localStorage.getItem('allRestaurants') !== 'null') {
                          this.emp.loggedInUserType = data["Response"][0]["RestaurantUserType"];
                          localStorage.setItem('restaurantUserType', data["Response"][0]["RestaurantUserType"]);

                      } else {
                          this.emp.loggedInUserType = 6;
                          localStorage.setItem('restaurantUserType', 'null');
                      }
                      localStorage.setItem('userType', data["Response"][0]["UserType"]);
                      return true;
                  } else {

                      this.emp.loggedInUserType = 6;
                      localStorage.setItem('restaurantUserType', 'null');
                      localStorage.setItem('userType', '5');
                      return true;

                  }
              }else{
                  localStorage.setItem('isLogedIn', 'false');
                  this.router.navigate(['/logout']);
                  return false
              }
            },err => {
                localStorage.setItem('isLogedIn', 'false');
                this.router.navigate(['/logout']);
                return false
            }
        );
      }
     // return !this.jwtHelper.isTokenExpired( localStorage.getItem('isLogedIn'));

      return true;
    }

    if(this.url.getDev() == "company"){
        // Here changed landing
        localStorage.setItem('isLogedIn', 'false');
        this.router.navigate(['/']);
        return false;
    }else{
        // Here changed landing
        localStorage.setItem('isLogedIn', 'false');
        this.router.navigate(['/']);

        return false;

    }


  }

}
