import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-marketing',
  templateUrl: './customer-marketing.component.html',
  styleUrls: ['./customer-marketing.component.css','../../../assets/css/ts-style.css']
})
export class CustomerMarketingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var my_awesome_script = document.createElement('script');

    my_awesome_script.setAttribute('src','https://js-na1.hs-scripts.com/7764233.js');
    
    document.head.appendChild(my_awesome_script);
  }

}
