import { Component, OnInit, Input } from '@angular/core';
import { EmpService } from "../../emp.service";
declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css', '../../../assets/css/ts-style.css']
})
export class NavBarComponent implements OnInit {

  @Input('activeClass')
  activeClass = '';

  isMobile = false;

  constructor( public emp: EmpService) { }

  ngOnInit(): void {

    $('ul.navbar-nav li.dropdown').hover(function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(300);
    }, function() {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(300);
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

}
