import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EmpService } from 'app/emp.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { LoginService } from 'app/structure/login/login.service';
import { RegisterService } from 'app/structure/register/register.service';
import { RestaurantService } from 'app/structure/restaurant/restaurant.service';
import { Url } from '../../../models/url';

declare var $: any;

@Component({
  selector: 'app-farm-sign-up',
  templateUrl: './farm-sign-up.component.html',
  styleUrls: ['./farm-sign-up.component.css', '../../../../assets/css/ts-style.css']
})
export class FarmSignUpComponent implements OnInit {

  storeType = "sellerstand";
  storeName = '';
  storeAddress = '';
  storeDescription = '';
  NewUserID = '';
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  password = '';
  storeHour = '00:00';
  storeLat = 0.0;
  storeLng = 0.0;
  address1 = "";
  address2 = "";
  address = '';
  city = '';
  province = '';
  country = '';
  postal = '';
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  storeStandErrorMsg = '';

  constructor(private sanitizer: DomSanitizer, public emp: EmpService, private url: Url, private zone: NgZone, private router: Router, private paymentService: PaymentService, private registerService:RegisterService, private loginService:LoginService, private resService: RestaurantService) { }

  ngOnInit(): void {

    let self = this;
    $(document).ready(function () {
      self.setupGoogleMaps();
    });
    
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else if (self.address1 == '' || self.storeLng == 0.0 || self.storeLat == 0.0) {
          self.storeStandErrorMsg = 'Please select valid address from dropdown.';
        } else {
          if (event.target.id == 'farm-info-form') {
            self.storeUserRegister();
          }
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Starts ----*/
  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  setupGoogleMaps() {

    var input = <HTMLInputElement>document.getElementById("pac-input");

    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {

      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.address2 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';
      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.storeLat = mylocation.geometry.location.lat();
      self.storeLng = mylocation.geometry.location.lng();
      self.storeAddress = formatted_address;
    });
  }
  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Ends ----*/

  checkFormValidation() {
    if (this.storeName.trim() == '' || this.storeAddress.trim() == '' || this.email.trim() == '' || this.password.trim() == '' || this.phone.trim().length < 10 || this.firstName.trim() == '' || this.lastName.trim() == '') {
      return true;
    }
    else {
      return false;
    }
  }

  storeUserRegister() {

    this.storeStandErrorMsg = '';
    
    $('#progress-modal').modal('show');

    const post = {
      Password: this.password,
      Email: this.email,
      Phone: this.phone,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: 'farm',
      UserType: 5,
      AuthenticationToken: this.makeRandom(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {
      if (data["success"]) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data => {
      
          if(data["userAdded"]) {
            this.NewUserID = data['UserID'];
            this.addStore();
          }
          else {
            $('#progress-modal').modal('hide');
            this.storeStandErrorMsg = data["Message"];
          }
        });
      }
      else {
        $('#progress-modal').modal('hide');
        this.storeStandErrorMsg = data["Message"];
      }
    });

  }

  addStore() {

    var ot = {};
    
    ot["type"] = '1';
    ot["type2"] = '2';
    ot["type3"] = '3';
 
      const post = {
        UserInfo: {
          UserID: this.NewUserID,
        },
 
      RestaurantInformation: {
        Name: this.storeName,
        Description: '',
        Address1: this.address1,
        Address2: this.address1,
        City: this.city,
        Country: this.country,
        Province: this.province,
        Postal: this.postal,
        Category: '',
        Phone: this.phone,
        Status: '0',
        EmailAddress: this.email,
        Rating: '4.7',
        ContactName: this.firstName + ' ' + this.lastName,
        Longitude: this.storeLng,
        Latitude: this.storeLat,
        StoreType: this.storeType,
        RestaurantType: 0
      },
    
      RestaurantHours: [
        {
          Day: '0',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '1',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '2',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '3',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '4',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '5',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '6',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
      ]
    };
 
    post["OrderTypes"] = ot;
 
    this.resService.addRes(post).subscribe(
        data => {
          if (data[0].hasOwnProperty('ImageURL')) {
             this.emp.isNewUser = false;
             this.loginStore();
          } else {
           $('#progress-modal').modal('hide');
           this.storeStandErrorMsg = data["Message"];
          }
     });
  }
 
   makeRandom() {
     let text = '';
     const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
     for (let i = 0; i < 40; i++) {
       text += possible.charAt(Math.floor(Math.random() * possible.length));
     }
     return text;
   }

   loginStore() {
    
    const post = {
      email: this.email,
      password: this.password,
      Token: this.makeRandom()
    };

    this.loginService.login(post).subscribe(
      data => {
        $('#progress-modal').modal('hide');

        if (data['verified']) {

          if(data['User'][0].UserType == 0){
            alert("it seems this is a user login and not a store login");
            this.router.navigateByUrl('/top-login');
            return;
          }

          if (data['User'].length > 0 && data['User'][0].UserType != 0 && this.password.toLowerCase() == 'password') {
            localStorage.setItem('changePassword','true');
          }
          
          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'admin');
          this.emp.loggedInUser = data['User'][0];

          if(data['Restaurants'].length>0) {
            this.emp.loggedInUserType = data["Restaurants"][0]["UserType"];
            localStorage.setItem("allRestaurants", JSON.stringify(data['Restaurants']));
            localStorage.setItem('restaurantName', data["Restaurants"][0]["Name"]);
            localStorage.setItem('restaurantID', data["Restaurants"][0]["RestaurantID"]);
            localStorage.setItem('restaurantLatitude', data["Restaurants"][0]["Latitude"]);
            localStorage.setItem('restaurantLongitude', data["Restaurants"][0]["Longitude"]);
            localStorage.setItem('restaurantContactName', data["Restaurants"][0]["ContactName"]);
            localStorage.setItem('restaurantAddress', data["Restaurants"][0]["Address1"]);
            localStorage.setItem('restaurantPhone', data["Restaurants"][0]["Phone"]);
            localStorage.setItem('restaurantUserType', data["Restaurants"][0]["UserType"]);
            localStorage.setItem('restaurantTimeZone', data["Restaurants"][0]["TimeZone"]);
            localStorage.setItem('ownDelivery', data["Restaurants"][0]["OwnDelivery"]);
            localStorage.setItem('restaurantType', data["Restaurants"][0]["RestaurantType"]);
            localStorage.setItem('LinkName', data["Restaurants"][0]["LinkName"]);
            localStorage.setItem('restaurantOrderTypeIDs', data["Restaurants"][0]["OrderTypeIDs"]);
            localStorage.setItem('restaurantOrderTypeNames', data["Restaurants"][0]["OrderTypeNames"]);
            localStorage.setItem('restaurantOrderTypeStatuses', data["Restaurants"][0]["OrderTypeStatuses"]);
            localStorage.setItem('restaurantOrderTypePercentages', data["Restaurants"][0]["OrderTypePercentages"]);
            localStorage.setItem('storeOtFeePercentages', data["Restaurants"][0]["OrderTypeFeePercentages"]);
            localStorage.setItem('storeOtFeePrices', data["Restaurants"][0]["OrderTypeFeePrices"]);
            localStorage.setItem('Reservations', data["Restaurants"][0]["Reservations"]);
            localStorage.setItem('ReservationProductID', data["Restaurants"][0]["ReservationProductID"]);
            localStorage.setItem('ReservationPriceID', data["Restaurants"][0]["ReservationPriceID"]);
            localStorage.setItem('DineInProductID', data["Restaurants"][0]["DineInProductID"]);
            localStorage.setItem('DineInPriceID', data["Restaurants"][0]["DineInPriceID"]);
            localStorage.setItem('POS', data["Restaurants"][0]["POS"]);
            localStorage.setItem('restaurantStripeBusinessID', data["Restaurants"][0]["StripeBusinessID"]);
            localStorage.setItem('storeExpressActive', data["Restaurants"][0]["ExpressAccountActive"]);
            localStorage.setItem('storeType', data["Restaurants"][0]["Type"]);
            localStorage.setItem('storeDescription', data["Restaurants"][0]["Description"]);
            localStorage.setItem('storeCity', data["Restaurants"][0]["City"]);
            
            if (data["Restaurants"][0]["OnlineOrderSubscriptionID"] != null) {
              localStorage.setItem('OnlineOrderSubscriptionID', data["Restaurants"][0]["OnlineOrderSubscriptionID"]);
            }
            
            if (data["Restaurants"][0]["DineInSubscriptionID"] != null) {
              localStorage.setItem('DineInSubscriptionID', data["Restaurants"][0]["DineInSubscriptionID"]);
            }
            else {
              localStorage.setItem('DineInSubscriptionID', '');
            }

            if (data["Restaurants"][0]["ReservationSubscriptionID"] != null) {
              localStorage.setItem('ReservationSubscriptionID', data["Restaurants"][0]["ReservationSubscriptionID"]);
            }
            else {
              localStorage.setItem('ReservationSubscriptionID', '');
            }

          }
          else {
            localStorage.setItem("allRestaurants", "null");
            localStorage.setItem('restaurantID', "null");
            localStorage.setItem('restaurantName', "null");
            localStorage.setItem('restaurantLatitude', "null");
            localStorage.setItem('restaurantLongitude', "null");
            localStorage.setItem('restaurantContactName', "null");
            localStorage.setItem('restaurantAddress', "null");
            localStorage.setItem('restaurantPhone', "null");
            localStorage.setItem('restaurantUserType','null');
            localStorage.removeItem('restaurantTimeZone');
            localStorage.removeItem('ownDelivery');
            localStorage.removeItem('restaurantType');
            localStorage.removeItem('LinkName');
            localStorage.removeItem('restaurantOrderTypeIDs');
            localStorage.removeItem('restaurantOrderTypeNames');
            localStorage.removeItem('restaurantOrderTypePercentages');
            localStorage.setItem('DineInSubscriptionID', '');
            localStorage.setItem('ReservationSubscriptionID', '');
            localStorage.removeItem('restaurantStripeBusinessID');
            localStorage.removeItem('POS');
            this.emp.loggedInUserType = 6
          }

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('firstName', data['User'][0].FirstName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('restaurantStripeCustomerID', data['User'][0].StripeCustomerID);
          
          this.router.navigateByUrl('/services/expenditure');

        } else {
          this.router.navigateByUrl('/login');
        }
      }
    );

  }

}
