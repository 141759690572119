import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';
import {Res} from "./../restaurant/restaurant.service";

@Injectable({
  providedIn: 'root'
})
export class SquareIntegrationService {


  addSquareAuthURL = this.url.getDomain() + '/Square/ObtainToken';
  getSquareAuthURL = this.url.getDomain() + '/Square/GetSquareAuthorization'

  revokeTokenURL = this.url.getDomain() + '/Square/RevokeToken'
  constructor(private http: HttpClient, private url: Url) { }

  addSquareAuth(res) {
    return this.http.post<Res>(this.addSquareAuthURL, res);

  }

  getSquareAuth(res) {
    return this.http.post<Res>(this.getSquareAuthURL, res);

  }

  revokeToken(res) {
    return this.http.post<Res>(this.revokeTokenURL, res);

  }


  // getOAuth(res) {
  //   return this.http.post<Res>(this.getOAuthURL, res);

  // }
}
