// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.table {
    --bs-table-bg: transparent;
}

.orderInQueue{

background-color: white;
    
}



.orderInProgress{

    background-color: rgb(255, 200, 132);

    
}


.orderCompleted{

    background-color: rgb(208, 251, 143);

    
}


.orderTypeCancelled{

    background-color: rgb(251, 143, 143);

    
}`, "",{"version":3,"sources":["webpack://./src/app/structure/todays-orders/todays-orders.component.css"],"names":[],"mappings":";AACA;IACI,0BAA0B;AAC9B;;AAEA;;AAEA,uBAAuB;;AAEvB;;;;AAIA;;IAEI,oCAAoC;;;AAGxC;;;AAGA;;IAEI,oCAAoC;;;AAGxC;;;AAGA;;IAEI,oCAAoC;;;AAGxC","sourcesContent":["\n.table {\n    --bs-table-bg: transparent;\n}\n\n.orderInQueue{\n\nbackground-color: white;\n    \n}\n\n\n\n.orderInProgress{\n\n    background-color: rgb(255, 200, 132);\n\n    \n}\n\n\n.orderCompleted{\n\n    background-color: rgb(208, 251, 143);\n\n    \n}\n\n\n.orderTypeCancelled{\n\n    background-color: rgb(251, 143, 143);\n\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
