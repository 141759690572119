import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';
import {Router} from "@angular/router";
import {User} from "../login/login.service";



@Injectable({
  providedIn: 'root'
})
export class ResetpasswordService {

  resetPasswordURL = this.url.getDomain() + '/Users/ResetPassword';
  getResetPasswordURL = this.url.getDomain() + '/Users/GetResetPassword';
  resetUserPasswordURL = this.url.getDomain() + '/Users/ResetUserPassword';

  
  constructor(private router:Router,private http: HttpClient, private url: Url) { }

  resetPassword(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.resetPasswordURL, post, httpOptions);
  }
  resetUserPassword(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.resetUserPasswordURL, post, httpOptions);
  }
  
  getResetPassword(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.getResetPasswordURL, post, httpOptions);
  }
  


}
