import { Component, OnInit } from '@angular/core';
import { AdminService } from "../admin.service";
import { RegisterService } from "../../register/register.service";
import { Meta } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: 'app-show-admin',
  templateUrl: './show-admin.component.html',
  styleUrls: ['./show-admin.component.css']
})







export class ShowAdminComponent implements OnInit {

  restaurantID = 0;
  userType = 0;
  users = [];
  tempUsers = [];
  firstName = "";
  lastName = "";
  email = "";
  password = "";
  restaurantName = "";
  passwordConfirmed = "";
  restaurantNames = [];
  restaurantIds = [];


  constructor(private admin: AdminService, private registerService: RegisterService, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe show admins' });
    meta.updateTag({ name: 'twitter:description', content: 'Order food delivery and take out online in charlottetown prince edward island through topserve. Order food online now!' });

    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, show admins' });
    meta.updateTag({ property: 'og:title', content: "Show admins" });


  }

  async ngOnInit() {
    //  $('.dropdown-toggle').dropdown()

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {


          if (event.target.id == "register") {
            self1.addTeamMember()
          }
        }
        form.classList.add('was-validated');
      }, false);
    });







    let allRestaurants = localStorage.getItem('allRestaurants') !== 'null' ? JSON.parse(localStorage.getItem('allRestaurants')) : [];
    allRestaurants.forEach((restaurant) => {
      this.restaurantNames.push(restaurant['Name']);
      this.restaurantIds.push(restaurant['RestaurantID']);
    });
    
    $(".dropdown-menu li").click(function () {

      $(this).parents(".btn-group").find('.selection').text($(this).text());
      $(this).parents(".btn-group").find('.selection').val($(this).text());

    });

    await this.getUsers()


  }


  async getUsers() {


    ///get the users
    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      RestaurantID: this.restaurantIds.toString(),
    };


    await this.admin.getAttachedUsers(post).subscribe(async data => {


      if (data["Success"]) {
        this.users = [];
        for (let user in data["Users"]) {
          this.users.push({ "LinkID": data["Users"][user]["idUserRestaurantLink"], "Name": data["Users"][user]["FirstName"], "Email": data["Users"][user]["Email"], "RestaurantName": data["Users"][user]["Name"], "UserType": data["Users"][user]["UserTypeID"], "Editing": false })
        }
      }
    })//end of subscriib

  }



  selectedDropDownRestaurantNew(resIndex) {
    this.restaurantName = this.restaurantNames[resIndex];
    this.restaurantID = this.restaurantIds[resIndex];

  }
  selectedDropDownRestaurant(resIndex, userIndex) {
    this.users[userIndex].RestaurantName = this.restaurantNames[resIndex]


  }

  selectedDropDownNew(userType) {
    this.userType = userType;
  }

  selectedDropDown(selectedUserType, userIndex) {
    this.users[userIndex].UserType = selectedUserType
    //  this.userType = selectedUserType

  }


  cancelUser(userIndex) {
    //// this.users[userIndex] = this.tempUsers[userIndex];
    this.users[userIndex].Editing = false;
    var userEditingBtn = $("#userBtn" + userIndex);
    var userEditing = $("#user" + userIndex);

    userEditingBtn.addClass('edit');
    userEditingBtn.removeClass('saving');
    userEditing.addClass('icmn-pencil');
    userEditing.removeClass('icmn-upload');
  }

  async editUser(userIndex) {

    var userEditing = $("#user" + userIndex);
    var userEditingBtn = $("#userBtn" + userIndex);

    if (this.users[userIndex].Editing) {
      userEditing.addClass('icmn-pencil');
      userEditing.removeClass('icmn-upload');
      userEditingBtn.addClass('edit');
      userEditingBtn.removeClass('saving');
    } else {
      userEditingBtn.addClass('saving');
      userEditingBtn.removeClass('edit');
      userEditing.removeClass('icmn-pencil');
      userEditing.addClass('icmn-upload');
    }


    if (this.users[userIndex].Editing) {

      let indexOfRes = this.restaurantNames.indexOf(this.users[userIndex].RestaurantName);

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: this.restaurantIds[indexOfRes],
        LinkID: this.users[userIndex].LinkID,
        UserType: this.users[userIndex].UserType
      };




      await this.admin.updateAttachedUsers(post).subscribe(async data => {
        if (data["Success"]) {

          alert("User updated")

        } else {
          alert("Something went wrong, did not update")
        }


      });//end of subscriib



      this.tempUsers[userIndex] = this.users[userIndex];
    }

    this.users[userIndex].Editing = !this.users[userIndex].Editing;

  }
  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  addTeamMember() {

    // this.users.push({"Name":"","Email":"","RestaurantName":"","UserType":1,"Editing":true});
    // let index = this.users.length;
    // var userEditingBtn =  $("#userBtn" + index.toString());
    // userEditingBtn.addClass('saving');
    // userEditingBtn.removeClass('edit');
    //



    const post = {
      Password: this.password,
      Email: this.email,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserType: this.userType,
      AuthenticationToken: this.makeRandom(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
      RestaurantID: this.restaurantID
    };




    this.registerService.registerTeamMember(post).subscribe(data => {
      if (data["userAdded"]) {
        this.getUsers();
        alert("User added to your team.");
      } else {
        alert(data['Message']);
      }
    })





  }

  async deleteUser(userIndex) {



    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      LinkID: this.users[userIndex].LinkID,
    };


    await this.admin.deleteUserFromRestaurant(post).subscribe(async data => {
      if (data["Success"]) {
        this.users.splice(parseInt(userIndex), 1);
        alert("user deleted");

      } else {
        alert("Something went wrong, did not delete");
      }


    });//end of subscriib

  }


}
