import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

export interface Menu {
  data: any;
}
export interface Item {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  getMenuUrl = this.url.getDomain() + '/MenuItems/MenuItemsNotPaused/';
  getMenuAllUrl = this.url.getDomain() + '/MenuItems/MenuItems/';

  getMenuNameLinkUrl = this.url.getDomain() + '/MenuItems/MenuItemsNameLink/';

  addMenuUrl = this.url.getDomain() + '/MenuItems/AddPrimaryMenuItemData/';
  removeMenuUrl = this.url.getDomain() + '/MenuItems/RemoveMenuItem/';
  pauseMenuItemURL = this.url.getDomain() + '/MenuItems/PauseMenuItem/';
  unPauseMenuItemURL = this.url.getDomain() + '/MenuItems/UnPauseMenuItem/';

  updatePrimaryMenuItemDataUrl = this.url.getDomain() + '/MenuItems/UpdatePrimaryMenuItemData';
  updateMenuItemCategoriesUrl = this.url.getDomain() + '/MenuItems/UpdateMenuCategories';
  getCatUrl = this.url.getDomain() + '/MenuItems/MenuCategories';
  updateMenuItemSidesUrl = this.url.getDomain() + '/MenuItems/AddMenuItemSides';
  getMenuOptionCategoryURL = this.url.getDomain() + '/MenuItems/GetMenuOptionCategories';
  sendLineURL = this.url.getDomain() + '/Restaurants/AddUserRestaurantRecommendation';

  pauseMenuCategoryURL = this.url.getDomain() + '/MenuItems/PauseMenuItemCategory/';

  getRestaurantByLinkNameURL = this.url.getDomain() + '/Restaurants/GetRestaurantByLinkName';
  getRestaurantByLinkNameForReservationsURL = this.url.getDomain() + '/Restaurants/GetRestaurantByLinkNameForReservations';


  updateMenuItemPricesURL = this.url.getDomain() + '/MenuItems/UpdateMenuItemPrice';

  updateImageUrlForNoImageItemsURL = this.url.getDomain() + '/MenuItems/UpdateMultileImageUrlForNoImageItems';

  getRestaurantMenuUrl = this.url.getDomain() + '/MenuItems/RestaurantMenu/';

  constructor(private http: HttpClient, private url: Url) { }


  UpdateMenuItemPrices(post) {
    return this.http.post(this.updateMenuItemPricesURL, post);
  }


  PauseMenuItem(post) {
    return this.http.post(this.pauseMenuItemURL, post);
  }

  UnPauseMenuItem(post) {
    return this.http.post(this.unPauseMenuItemURL, post);
  }

  SendLine(post) {
    return this.http.post(this.sendLineURL, post);
  }


  pauseMenuCategory(post) {
    return this.http.post(this.pauseMenuCategoryURL, post);
  }

  
  
  
  

  MenuItemsAll(post) {

    return this.http.post(this.getMenuAllUrl, post);
  }
  MenuItems(post) {
    return this.http.post(this.getMenuUrl, post);
  }

  GetRestaurantMenu(post) {
    return this.http.post(this.getRestaurantMenuUrl, post);
  }

  MenuItemsNameLink(post) {


    return this.http.post(this.getMenuNameLinkUrl, post);
  }

  GetRestaurantByLinkName(post) {
    return this.http.post(this.getRestaurantByLinkNameURL, post);
  }

  GetRestaurantByLinkNameForReservations(post) {
    return this.http.post(this.getRestaurantByLinkNameForReservationsURL, post);
  }
  




  addMenuItem(menuItem) {
    // return this.http.post(this.addMenuUrl, menuItem).map(res => res.json());
    return this.http.post<Item>(this.addMenuUrl, menuItem);

  }



  RemoveMenuItem(menuItem) {
    // return this.http.post(this.addMenuUrl, menuItem).map(res => res.json());
    return this.http.post<Item>(this.removeMenuUrl, menuItem);

  }
  AddMenuItemSides(menuItem){

    return this.http.post(this.updateMenuItemSidesUrl, menuItem);
  }
  updateMenuItemCategories(menuItem){
    return this.http.post(this.updateMenuItemCategoriesUrl, menuItem);
  }
  updateMenuItemPrimaryData(menuItem){
    return this.http.post(this.updatePrimaryMenuItemDataUrl, menuItem);
  }
  getCategories(post) {
    return this.http.post(this.getCatUrl, post);
  }
  getOptionCategories(restaurantId,userId) {
    return this.http.post(this.getMenuOptionCategoryURL, { 'Token':'NA','RestaurantID': restaurantId,'UserID':userId });
  }


  UpdateImageUrlForNoImageItems(post) {
    return this.http.post(this.updateImageUrlForNoImageItemsURL, post);
  }
  //Orders



  ///


}
