import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
// import {menuItem} from "../../menuItem";
import { BrowserModule } from '@angular/platform-browser';
import { EditItemServiceService } from "../../services/edit-item-service.service";
import { Router } from '@angular/router';
import { Url } from "../../models/url";
import { EmpService } from "../../emp.service";
import { environment } from 'environments/environment.prod';

declare var $: any;
declare var jQuery: any;
const AWS = require('aws-sdk');


@Component({
  selector: 'app-menu-view',
  templateUrl: './menu-view.component.html',
  styleUrls: ['./menu-view.component.css']
})
export class MenuViewComponent implements OnInit, OnDestroy {
  isLoading = true;
  mainMenu: any;
  mainMenuImages: any;
  menuPrimaryCategories = [];
  menuSecondaryCategories = [];
  menuSecondaryCategoriesFiltered = [];
  linkedMenuItemAndCategory = [];
  menuSideLinks = [];
  selectedPrimaryCategory: any;
  selectedSubCategory: any;
  menuSides = [];
  tempMenuSides = [];
  menuSideTitles: any;
  tempMainMenu: any;
  tabSelected: any;
  activeSecondaryTab: any;
  noMenu = false
  searchValue = ""
  constructor(private menuService: MenuService,
    private sanitizer: DomSanitizer,
    private editService: EditItemServiceService,
    private router: Router,
    private url: Url,
    private emp: EmpService
  ) {
  }

  async ngOnInit() {
    await this.getMenu();

    let self = this;
    $('input[type=text]').keyup(function () {
      self.filterMenuItems()
    })

    //   var myapp = (function($){
    //     return function filterMenuItems() { // add a return
    //         console.log(this.searchValue);
    //     }
    // })
    // console.log(myapp);
  }



  async getMenu() {
    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      RestaurantID: localStorage.getItem('restaurantID')
    };
    await this.menuService.MenuItemsAll(post).subscribe(async data => {

      if (data["Success"]) {
        this.noMenu = false;
        this.linkedMenuItemAndCategory = data["Response"]["LinkedMenuItems"];
        this.mainMenu = data["Response"]['MainMenu'];
        this.mainMenuImages = data["Response"]['MenuItemImages'];
        //  await this.getImages();

        this.tempMainMenu = data["Response"]['MainMenu'];

        if (data["Response"].hasOwnProperty("MenuSides") && data["Response"].hasOwnProperty("MenuSidesLinks")) {
          this.menuSideTitles = data["Response"]['MenuSidesTitles'];
          this.menuSideLinks = data["Response"]['MenuSidesLinks'];
          this.menuSides = data["Response"]["MenuSides"];
          this.tempMenuSides = data["Response"]["MenuSides"];
          await this.getMenuSides();
        }
        await this.filterPrimaryCategories(data["Response"]["MenuCategories"]);
        this.isLoading = false
      } else {
        this.noMenu = true
      }
    });
  }


  filterPrimaryCategories(data) {

    for (let x in data) {
      if (!data[x].hasOwnProperty("ParentMenuItemCategory")) {
        this.menuPrimaryCategories.push(data[x])
      } else {
        this.menuSecondaryCategories.push(data[x]);
      }
    }
    if (this.menuPrimaryCategories.length > 0) {
      this.tabClicked(this.menuPrimaryCategories[0], false)
    }
  }

  async getPrimaryCategoriesForMenuItemSelected(menuItemToEdit) {

    for (let x in this.linkedMenuItemAndCategory) {
      if (this.linkedMenuItemAndCategory[x]["MenuItemID"] == menuItemToEdit["menuItemToEdit"]["MenuItemID"]) {
        let primaryCategoriesFiltered = this.menuPrimaryCategories.filter(category => category["MenuItemCategoryID"] == this.linkedMenuItemAndCategory[x]["MenuItemCategoryID"])
        primaryCategoriesFiltered.forEach(async (item, n) => {
          this.editService.selectedPrimaryCategory.push(item);
        });
        // let secondaryCategoriesFiltered = this.menuSecondaryCategories.filter(category => category["MenuItemCategoryID"] == this.linkedMenuItemAndCategory[x]["MenuItemCategoryID"])
        // secondaryCategoriesFiltered.forEach(async (item,n) => {
        //   this.editService.selectedSubCategory.push(item);
        // });

      }
    }
  }


  //what I need to do is see all the categories this menu item is in.
  async didClickEdit(menuItemToEdit) {

    await this.getPrimaryCategoriesForMenuItemSelected(menuItemToEdit);

    if (menuItemToEdit) {
      this.editService.selectedMenuItem = menuItemToEdit;
      this.router.navigateByUrl('/services/edit-item')
    }
  }
  async removeItem(menuItemToEdit) {

    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      RestaurantID: localStorage.getItem('restaurantID'),
      MenuItemID: menuItemToEdit["menuItemToEdit"]["MenuItemID"]

    };
    let self = this;
    await this.menuService.RemoveMenuItem(post).subscribe(async data => {

      if (data["Success"]) {
        self.updatePage()
      }
    })
  }

  updatePage() {
    let url = this.router.url;
    this.router.navigateByUrl('/services', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }

  filterSecondaryCategories(primaryCategories) {

    this.editService.selectedSubCategory = [];

    if (this.menuSecondaryCategories.length > 0) {
      for (let x in this.menuSecondaryCategories) {
        if (this.menuSecondaryCategories[x].ParentMenuItemCategory == primaryCategories.MenuItemCategoryID) {
          this.menuSecondaryCategoriesFiltered.push(this.menuSecondaryCategories[x]);
          this.editService.selectedSubCategory.push(this.menuSecondaryCategories[x]);
          //nick
        }
      }
    }
  }

  tabClicked(category, isSecondary) {
    // if (!isSecondary){
    //   this.menuSecondaryCategoriesFiltered = [];
    // }
    // this.tabSelected = category;
    // if (!isSecondary) {
    //     this.filterSecondaryCategories(category);
    // }

    // this.selectedPrimaryCategory = category;

  }


  filterMenuItems() {

    this.mainMenu = this.tempMainMenu;
    let arrayOfMenuItemsForCategory = [];

    for (let x in this.mainMenu) {
      if (this.mainMenu[x]['Name'].toUpperCase().indexOf(this.searchValue.toUpperCase()) > -1) {
        arrayOfMenuItemsForCategory.push(this.mainMenu[x])
      };
    };
    this.mainMenu = arrayOfMenuItemsForCategory;
  }

  getMenuSides() {

    var menuItemFiltered = [];

    for (let n in this.mainMenu) {
      menuItemFiltered = this.menuSides.filter(side => side["MenuItem_MenuItemID"] == this.mainMenu[n]["MenuItemID"]);
      //nick now filter these menu items and get the title

      var arrayOfTitles = [];
      var arrayOfUniqueTitles = [];
      for (let m in menuItemFiltered) {

        let menuItemFilteredX = this.menuSideLinks.filter(sideLink => sideLink["MenuItemOptionID"] == menuItemFiltered[m]["MenuItemOptionID"]);

        if (menuItemFilteredX.length != 0) {
          let titles = this.menuSideTitles.filter(sideTitle => sideTitle["MenuItemOptionsCategoryID"] == menuItemFilteredX[0]["MenuItemOptionsCategoryID"]);
          arrayOfTitles.push({ title: titles[0], side: menuItemFiltered[m] });
          menuItemFiltered["title"] = titles[0]
          if (!arrayOfUniqueTitles.includes(titles[0])) {
            arrayOfUniqueTitles.push(titles[0]);
          }
        }
      }
      var menuItemSideObject = [];

      for (let v in arrayOfUniqueTitles) {

        var x = arrayOfTitles.filter(titles => titles.title["MenuItemOptionsCategoryID"] == arrayOfUniqueTitles[v].MenuItemOptionsCategoryID);
        menuItemSideObject.push(x)

      }

      var finalResult = []
      for (let xy in menuItemSideObject) {
        var counter = 0
        var result = {};
        var sidesResult = [];

        for (let yz in menuItemSideObject[xy]) {

          if (counter == 0) {

            result["title"] = menuItemSideObject[xy][yz].title;
            sidesResult.push(menuItemSideObject[xy][yz].side);
          } else {
            sidesResult.push(menuItemSideObject[xy][yz].side);
          }
          counter += 1

        }
        result["sides"] = sidesResult;
        finalResult.push(result);
      }

      // if (finalResult.length > 0) {
      this.mainMenu[n]["options"] = finalResult
      //}
    }
  }




  async getImages() {

    var self = this;

    const AWSService = AWS;
    const region = 'ca-central-1';


    let bucketName = "";
    if (this.url.getDev() == "dev") {
      bucketName = 'topserve-restaurant-menu-images-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-menu-images-prod-canada';

    }


    // const bucketName = 'menu-item-images-meeno '+ this.url.getDev();
    const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
    // Configures the AWS service and initial authorization

    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
      secretAccessKey: environment.awsSecret,
      region: region
    });
    // adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });







    self.mainMenuImages.forEach(async (item, n) => {

      $("#itemImg" + n.toString()).on("error", function () {
        $(this).hide();
      });

      await s3.getObject({ Key: item["ImageURL"] }, async function (err, file) {
        if (!err && item) {
          let blob = new Blob([file.Body], { type: "image/jpeg" });
          self.tempMainMenu[n]["image"] = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        } else {
          self.tempMainMenu[n]["image"] = null;
        }
      }, 1);
    });
  }


  delayedLog() {
    return new Promise(resolve => setTimeout(resolve, 300));
  }

  async pause(item, index) {

    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      MenuItemID: item.MenuItemID
    };

    if (item.Paused == 0) {
      let self = this;
      await this.menuService.PauseMenuItem(post).subscribe(async data => {
        if (data["Success"]) {
          self.mainMenu[index].Paused = 1
        } else {
          alert("Did not pause please call support if urgent or try again later")
        }
      })

    } else {
      let self = this;
      await this.menuService.UnPauseMenuItem(post).subscribe(async data => {
        if (data["Success"]) {
          self.mainMenu[index].Paused = 0
        } else {
          alert("Did not pause please call support if urgent or try again later")
        }
      })
    }




  }


  async updatePrice(n, item) {
    $("#spinner-" + n).addClass('spinner-border')
    console.log(JSON.stringify(item))

    item["Token"] = localStorage.getItem('AuthenticationToken')
    const post = item


    await this.menuService.UpdateMenuItemPrices(post).subscribe(async data => {
      $("#spinner-" + n).removeClass('spinner-border')


      ////
      const AWSService = AWS;
      const region = 'ca-central-1';
      let bucketName = ""
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-restaurant-menu-images-dev-canada';
      } else {
        bucketName = 'topserve-restaurant-menu-images-prod-canada';

      }


      // const bucketName = 'menu-item-images-meeno';
      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });
      // I store this in a variable for retrieval later

      s3.copyObject({
        Bucket: bucketName,
        CopySource: 'topserve-restaurant-menu-images-prod-canada/' + item.MenuItemID.toString() + ".jpeg",
        Key: data["Response"]["insertId"] + ".jpeg"
      })
        .promise()
        .then(() =>
          // Delete the old object


          s3.deleteObject({
            Bucket: bucketName,
            Key: item.MenuItemID.toString() + ".jpeg"
          }).promise()
        )
        // Error handling is left up to reader
        .catch((e) => console.error(e))



      ///





    })




  }


  getPausedAreYouSure(item, name) {
    if (item == 0) {
      return "Are you sure you want to pause " + name

    } else {
      return "Are you sure you want to unpause " + name
    }

  }

  getPaused(item) {

    if (item == 0) {
      return "Pause"

    } else {
      return "Unpause"
    }

  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }

  ngOnDestroy(): void {

    // this.editService.selectedMenuItem = this.mainMenu
  }


}
