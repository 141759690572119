// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .reservation-table-item {
        width: 100%;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .reservation-table-item {
        width: 50%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .reservation-table-item {
        width: 33.33%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .reservation-table-item {
        width: 33.33%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .reservation-table-item {
        width: 33.33%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/structure/reservations/reservations/reservations.component.css"],"names":[],"mappings":"AAAA,2DAA2D;AAC3D;IACI;QACI,WAAW;IACf;AACJ;;AAEA,mDAAmD;AACnD;IACI;QACI,UAAU;IACd;AACJ;;AAEA,2CAA2C;AAC3C;IACI;QACI,aAAa;IACjB;AACJ;;AAEA,2CAA2C;AAC3C;IACI;QACI,aAAa;IACjB;AACJ;;AAEA,wDAAwD;AACxD;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["/* Extra small devices (portrait phones, less than 576px) */\n@media (max-width: 575.98px) {\n    .reservation-table-item {\n        width: 100%;\n    }\n}\n\n/* Small devices (landscape phones, 576px and up) */\n@media (min-width: 576px) and (max-width: 767.98px) { \n    .reservation-table-item {\n        width: 50%;\n    }\n}\n\n/* Medium devices (tablets, 768px and up) */\n@media (min-width: 768px) and (max-width: 991.98px) {\n    .reservation-table-item {\n        width: 33.33%;\n    }\n}\n\n/* Large devices (desktops, 992px and up) */\n@media (min-width: 992px) and (max-width: 1199.98px) {\n    .reservation-table-item {\n        width: 33.33%;\n    }\n}\n\n/* Extra large devices (large desktops, 1200px and up) */\n@media (min-width: 1200px) {\n    .reservation-table-item {\n        width: 33.33%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
