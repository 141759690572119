import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import QRCodeStyling from 'qr-code-styling';
import { RestaurantService } from '../restaurant/restaurant.service';
import { Router } from '@angular/router';
import { EmpService } from 'app/emp.service';

declare var $: any;

@Component({
  selector: 'app-service-qr-pay',
  templateUrl: './service-qr-pay.component.html',
  styleUrls: ['./service-qr-pay.component.css', '../../../assets/css/ts-style.css']
})
export class ServiceQrPayComponent implements OnInit {

  @ViewChild('qrcodeCanvas', { static: false }) qrcodeCanvas: ElementRef;
  
  link_valid = false;

  toast_message = '';

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }
  
  constructor(private restaurantService: RestaurantService, private router: Router, private emp: EmpService) { }

  ngOnInit(): void {
    
    let self = this;

    $(document).ready(function() {
      
      $('#qr_link').on('input', function() {
        var inputValue = $(this).val();
        var sanitizedValue = inputValue.replace(/[^\w]/gi, '');
        $(this).val(sanitizedValue);
        if (sanitizedValue.length > 0) {
          self.checkLinkNameAvailability();
        }
      });

      $('#qr_table_number').on('input', function() {
        var inputValue = $(this).val().replace(/\D/g, '');
        $(this).val(inputValue);
      });

    });

    this.manageQrPayContent();
  }

  manageQrPayContent() {

    $('#qr_link_update').hide();
    $('#link_error_msg').hide();
    $('#qr_link_success').hide();
    $('#create_qr_content').hide();
    $('#generated_qr_content').hide();

    if (localStorage.getItem('LinkName') != null && localStorage.getItem('LinkName') !== 'null' && localStorage.getItem('LinkName') !== '') {
      $('#qr_link_success').show();
      $('#create_qr_content').show();
      this.link_valid = true;
    }
    else {
      $('#qr_link_update').show();
    }
  }

  async checkLinkNameAvailability() {

    if ($('#qr_link').val().toString().toLowerCase() == 'null') {
      $('#link_error_msg').show();
      return;
    }
    
    const post = {
      "link": $('#qr_link').val().toString().toLowerCase()
    }

    this.restaurantService.checkLinkAvailability(post).subscribe(data => {
      if (data['Success']) {
        if (data['LinkAvailable']) {
          $('#link_error_msg').hide();
          this.link_valid = true;
        }
        else {
          $('#link_error_msg').show();
          this.link_valid = false;
        }
      }
    });
  }

  async saveQRLink() {

    const post = {
      "RestaurantID": localStorage.getItem('restaurantID'),
      "LinkName": $('#qr_link').val().toString().toLowerCase()
    }

    this.restaurantService.updateLinkNameOfStore(post).subscribe(data => {
      if (data['Success']) {
        localStorage.setItem('LinkName', $('#qr_link').val().toString().toLowerCase());
        $('#qr_link_success').show();
        $('#create_qr_content').show();
        $('#qr_link_update').hide();

      }
      else {
        this.toast_message = 'Something went wrong while saving link name. Please try again!'
        this.showToast(false);
      }
    });
  }

  showToast(success) {
    if (success) {
      $('#qr_pay_toast').addClass('show');
      setTimeout(() => {
        $('#qr_pay_toast').removeClass('show');
      }, 2000);
    }
    else {
      $('#qr_pay_error_toast').addClass('show');
    }
  }

  createQRCode() {

    const table = $('#qr_table_number').val();

    if (table == '') {
      return;
    }

    const qrCode = new QRCodeStyling({
      width: 1025,
      height: 1025,
      margin: 20,
      data: 'https://topserve.ca/order/'+ localStorage.getItem('LinkName') +'/table/'+ table,
      image: 'assets/img/qr_topserve.png',
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 0,
      },
    });

    $('#qr_code_canvas').empty();
    qrCode.append(this.qrcodeCanvas.nativeElement);
    $('#generated_qr_content').show();
    $('#generated_qr_txt').html('Your QR Code for table number ' + table + ' is now available for download.');
  }

  downloadTableQR() {
    const table = $('#qr_table_number').val();

    if (table == '') {
      return;
    }

    const qrCode = new QRCodeStyling({
      width: 1025,
      height: 1025,
      margin: 20,
      data: 'https://topserve.ca/order/'+ localStorage.getItem('LinkName') +'/table/'+ table,
      image: 'assets/img/qr_topserve.png',
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 0,
      },
    });

    qrCode.download(this.qrcodeCanvas.nativeElement);
  }

}
