import { Component, OnInit } from '@angular/core';
import { EmpService } from "../../../emp.service";
import { DomSanitizer, Meta } from "@angular/platform-browser";
const AWS = require('aws-sdk');
import { Url } from "../../../models/url";
import * as moment from 'assets/vendors/by_bower/moment/moment';
import { DriverService } from "../driver.service";
import { loadStripe } from '@stripe/stripe-js';
import { Router } from "@angular/router";
import { PlatformInfoService } from 'app/structure/platform-info/platform-info.service';
import { environment } from 'environments/environment.prod';

declare var $: any;

@Component({
  selector: 'app-driver-signup',
  templateUrl: './driver-signup.component.html',
  styleUrls: ['./driver-signup.component.css', '../../../../assets/css/aos.css', '../../../../assets/css/custom.css', '../../../../assets/css/ts-style.css', '../../../../assets/css/fonts.css', '../../../../assets/css/fx.css', '../../../../assets/css/magnific-popup.css']
})
export class DriverSignupComponent implements OnInit {

  stripePromise = loadStripe(environment.stripeKey);

  driverFirstName = '';
  driverLastName = '';
  driverEmail = '';
  driverPhone = '';
  driverAddress = '';
  driverDeliveryCity = '';

  isLoggingIn = false;
  username = '';
  password = 'password';

  fileInput: any;
  driverLicenseImage: any;
  image = '';
  noLincenseSelected = false;
  selectedProduct : any;

  registrationErrorMsg = '';
  products = [];

  constructor(public emp: EmpService, private sanitizer: DomSanitizer, private url: Url, private driverService: DriverService, private router: Router, private platformService: PlatformInfoService) { }

  ngOnInit(): void {

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          if (event.target.id == 'driverForm') {
            // self.sendDriverMessage();

            localStorage.setItem('tempDriverFirstName', self.driverFirstName);
            localStorage.setItem('tempDriverLastName', self.driverLastName);
            localStorage.setItem('tempDriverEmail', self.driverEmail);
            localStorage.setItem('tempDriverPhone', self.driverPhone);
            localStorage.setItem('tempDriverAddress', self.driverAddress);
            localStorage.setItem('tempDriverDeliveryCity', self.driverDeliveryCity);

            $('.carousel').carousel(1);
            $('.progress_inner__bar').width('34%');
          }
        }
        form.classList.add('was-validated');
      }, false);
    });

    this.getDriverProducts();
    this.verifySession();
  }

  /*---- Verify Driver Product Purchase Session ----*/
  async verifySession() {
    if (window.location.href.split('?').pop().includes('session_id')) {
      $('.carousel').removeClass('slide');
      $('.carousel').carousel(2);
      $('.progress_inner__bar').width('68%');
      $('.carousel').addClass('slide');
      $('#new-registration-check-modal').modal('show');
      $('#new-registration-spinner').show();
      $('#new-registration-success').hide();
      $('#new-registration-cancel').hide();
      if (localStorage.getItem('tempDriverFirstName') != undefined) {
        this.registerNewUser();
      }
      else {
        $('#new-registration-spinner').hide();
        $('#new-registration-success').show();
      }
    }
    else if (window.location.href.split('?').pop().includes('failure=true')) {
      $('.carousel').removeClass('slide');
      $('.carousel').carousel(2);
      $('.progress_inner__bar').width('68%');
      $('.carousel').addClass('slide');
      $('#new-registration-check-modal').modal('show');
      $('#new-registration-spinner').hide();
      $('#new-registration-success').hide();
      $('#new-registration-cancel').show();
      this.registrationErrorMsg = 'Error: Payment Failure!\nPlease try again!';
    }

  }

  /*---- Getting Driver Registraion Products and Integrating Stripe Code Starts ----*/
  getDriverProducts() {
    this.driverService.driverProducts({}).subscribe(
      data => {
        if (data['Success']) {

          for (let i in data['Response']) {
            if (data['Response'][i]['ProductName'] === 'TopServe Bag') {
              data['Response'][i]['selected'] = true;
            }
            else {
              data['Response'][i]['selected'] = false;
            }
            this.products.push(data['Response'][i]);
          }
        }
      });
  }

  selectProduct(i: number) {
    this.products.filter(i => i.selected = false);
    this.products[i]['selected'] = true;
    localStorage.setItem('productSelected',this.products[i]['ProductID'])
  }

  async createProductCheckoutSession() {

    let product = this.products.find(i => i.selected == true);

    localStorage.setItem('tempProduct',JSON.stringify(product));

    const post = {
      Product: product,
    }
    await this.driverService.getDriverProductCheckoutSession(post).subscribe(async data => {

      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data['sessionId']
      })

      if (error) {
        console.log(error);
      }
    });

  }
  /*---- Getting Driver Registraion Products and Integrating Stripe Code Ends ----*/

  /*---- Selecting and Uploading Driver License Image Code Starts ----*/
  fileEvent(fileInput: any) {
    this.fileInput = fileInput.target.files[0];
    const file = fileInput.target.files[0];
    if (file != undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
        this.driverLicenseImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        this.noLincenseSelected = false;
      }
    }
  }

  clearImage(event) {
    this.fileInput = undefined;
    this.driverLicenseImage = undefined;
    event.stopPropagation();
  }

  async upload() {
    return new Promise(resolve => {
      const AWSService = AWS;
      const region = 'ca-central-1';

      let bucketName = "";
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-driver-license-images-dev-canada';
      } else {
        bucketName = 'topserve-driver-license-images-prod-canada';
      }

      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });

      if (this.fileInput != null || this.fileInput != undefined) {
        this.image = this.fileInput;
      } else {
        this.image = this.driverLicenseImage
      }

      if (this.image != '' && this.image != undefined) {
        s3.upload({
          Key: this.driverFirstName + '-' + this.driverLastName + '-' + moment() + ".png",
          Bucket: bucketName,
          Body: this.image
        }, function (err, data) {
          if (err) {
            resolve(false)
          } else {
            resolve(true)
          }
        });
      }
      else {
        resolve(true)
      }
    });
  }

  async uploadImage() {
    if (this.driverLicenseImage == undefined) {
      this.noLincenseSelected = true;
    }
    else {
      await this.upload();
      $('.carousel').carousel(2);
      $('.progress_inner__bar').width('68%');
    }
  }
  /*---- Selecting and Uploading Driver License Image Code Ends ----*/

  /*---- Register & Login New Driver Code Starts ----*/


  registerNewUser() {

    const post = {
      FirstName: localStorage.getItem('tempDriverFirstName'),
      LastName: localStorage.getItem('tempDriverLastName'),
      Email: localStorage.getItem('tempDriverEmail'),
      Phone: '+1' + localStorage.getItem('tempDriverPhone'),
      Address: localStorage.getItem('tempDriverAddress'),
      DeliveryCity: localStorage.getItem('tempDriverDeliveryCity'),
      ProductID:localStorage.getItem('productSelected')

    }
    this.driverService.signUpNewDriver(post).subscribe(
      data => {
        $('#new-registration-spinner').hide();
        if (data['Success']) {
          $('#new-registration-success').show();

          this.sendMessage(post);

          this.username = localStorage.getItem('tempDriverEmail');
          localStorage.removeItem('tempDriverFirstName');
          localStorage.removeItem('tempDriverLastName');
          localStorage.removeItem('tempDriverEmail');
          localStorage.removeItem('tempDriverPhone');
          localStorage.removeItem('tempDriverAddress');
          localStorage.removeItem('tempDriverDeliveryCity');
        }
        else {
          $('#new-registration-cancel').show();
          this.registrationErrorMsg = 'Error: Payment Successful!\nBut could not register as TopServe Driver.\nPlease call 1-902-432-4244 for support.';
        }
      });

  }

  loginSubDriver() {

    if (this.username != '' && this.username != undefined) {
      this.isLoggingIn = true;
      $('#new-registration-check-modal').modal('show');
      $('#new-registration-spinner').show();
      $('#new-registration-success').hide();
      $('#new-registration-cancel').hide();
      const post = {
        email: this.username,
        password: this.password,
        Token: this.makeRandom()
      };

      this.driverService.loginSubDriver(post).subscribe(
        data => {

          this.isLoggingIn = false;
          $('#new-registration-check-modal').modal('hide');
          if (data['verified']) {

            localStorage.setItem('changePassword', 'true');
            localStorage.setItem('isLogedIn', 'true');
            localStorage.setItem('type', 'driver');
            localStorage.setItem('userType', '11');
            this.emp.loggedInDriver = data['User'][0];
            localStorage.setItem('driverType', data['User'][0].DriverType);
            localStorage.setItem('firstName', data['User'][0].FirstName);
            localStorage.setItem('AuthenticationToken', post.Token);
            localStorage.setItem('DriverID', data['User'][0].SubDriverID);
            localStorage.setItem('Email', data['User'][0].Email);
            this.router.navigateByUrl('/services/delivery-dashboard');

          }
          else {
            $('#new-registration-check-modal').modal('hide');
            this.router.navigateByUrl('/driver-login');
          }
        });
    }
    else {
      $('#new-registration-check-modal').modal('hide');
      this.router.navigateByUrl('/driver-login');
    }
  }


  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  /*---- Register & Login New Driver Code Ends ----*/

  /*---- Sending Email To TopServe On New Driver Sign UP Code Starts ----*/

  sendMessage(post) {

    var info = 'New Driver Signed Up With TopServe\n\n';

    info += 'Email = ' + post['Email'] + ' | Driver Name = ' + post['FirstName'] + ' ' + post['LastName'] + ' | Phone = ' + post['Phone'] + ' | Driver Address = ' + post['Address'] + ' | DeliveryCity = ' + post['DeliveryCity'] + '\n\n';

    let product = JSON.parse(localStorage.getItem('tempProduct'));

    info += 'Product Purchased = ' + product['ProductName'];

    const post2 = {
      Message: info,
    };

    this.platformService.sendMessage(post2).subscribe(
      data => {
        localStorage.removeItem('tempProduct');
      })
  }
}

/*---- Sending Email To TopServe On New Driver Sign UP Code Ends ----*/

