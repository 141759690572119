import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-pause-category',
  templateUrl: './pause-category.component.html',
  styleUrls: ['./pause-category.component.css']
})
export class PauseCategoryComponent implements OnInit {



  primaryCat: any;
  secondaryCat: any;

  constructor(    private menuService: MenuService,
    ) { }

  async ngOnInit() {
    await this.getCategory();

  }


  getPausedAreYouSure(item, name) {
    if (item == 0) {
      return "Are you sure you want to pause " + name
    } else {
      return "Are you sure you want to unpause " + name
    }
  }

  getPaused(item) {
    if (item == 0) {
      return "Pause"
    } else {
      return "Unpause"
    }
  }


  async getCategory() {
    return new Promise(resolve => {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID')
      };
      this.menuService.getCategories(post).subscribe(
        data => {
         
          this.primaryCat = data['PrimaryCategories'];
          this.secondaryCat = data['SecondaryCategories']
          resolve(true);
        }
      );
    });
  }


  

  async pause(item, index){


  var pauseItem = '0'
  
  if(this.primaryCat[index].Paused == 0){
    pauseItem = "1"
  }else{
    pauseItem = "0"

  }

  const post = {
    RestaurantID: localStorage.getItem('restaurantID'),
    Token: localStorage.getItem('AuthenticationToken'),
    Paused:pauseItem,
    Name: this.primaryCat[index].Name
};

await this.menuService.pauseMenuCategory(post).subscribe(async data => {
  if(data["Success"]){

    if(pauseItem == '0'){
      this.primaryCat[index].Paused = 0
    }else{
      this.primaryCat[index].Paused = 1
    }


  }else{
      alert("Did not pause please call support if urgent or try again later")
  }
})

  
  






  }


}




