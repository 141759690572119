import { Component, OnInit } from '@angular/core';
import {MenuService} from "../../structure/menu/menu.service";
import {EditItemServiceService} from "../../services/edit-item-service.service";
import {EditItemComponent} from "../../structure/menu/edit-item/edit-item.component";
// declare var $: any;
declare var $: any;

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})

export class AddCategoryComponent implements OnInit {


  opt: any;

  primaryCat = [];
  selectedPrimaryCategories = [];
  selectedSubCategories = [];
  testing:any;

  categoryIndex = 0;
  secondaryCat = [];
  showClose = true;
  superClass:any;
  constructor( private menuService: MenuService,
               private editService:EditItemServiceService,

  ) {

  }

  async ngOnInit() {





      await this.getCategory();





    let dataSub = [];
    let dataPrim= [];




    $('#categorySection' + this.categoryIndex).find(".select2").each(function(index)
    {
      if ($(this).data('select2')) {
        $(this).select2('destroy');
      }
    });


    this.secondaryCat.forEach(async (item,n) => {
        dataSub.push({
          id: n,
          text: item["Name"],
          "selected": false
        })
    });

    this.primaryCat.forEach(async (item,n) => {
        dataPrim.push({
          id: n,
          text: item["Name"],
          "selected": false
        })
    });


    this.selectedSubCategories.forEach(async (item,n) => {
      dataSub.push({
        id: n,
        text: item["Name"],
        "selected": true
      })
    });

    this.selectedPrimaryCategories.forEach(async (item,n) => {
      dataPrim.push({
        id: n,
        text: item["Name"],
        "selected": true
      })
    });



    $("#subCategory" + this.categoryIndex).select2({
      data: dataSub,
      multiple:true,
      tags: true,
    });


    $("#selectedCategory" + this.categoryIndex).select2({
      data: dataPrim,
      multiple:true,
      tags: true,
    });




    $('#selectedCategory'+this.categoryIndex).on('select2:select', (e) => {
      //  var data = e.params.data.text;
      let categoryFiltered = this.primaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);

      if (categoryFiltered.length > 0){
        this.selectedPrimaryCategories.push(categoryFiltered[0]);
      }else{
        this.selectedPrimaryCategories.push({"Name":e.params.data.text})
      }






    });
    $('#selectedCategory'+this.categoryIndex).on('select2:unselect', (e) => {
      for(let n in this.selectedPrimaryCategories){
        if(e.params.data.text == this.selectedPrimaryCategories[n].Name){
          this.selectedPrimaryCategories.splice(parseInt(n),1)
        }
      }
    });


    $('#subCategory'+this.categoryIndex).on('select2:select', (e) => {
      //  var data = e.params.data.text;
      let categoryFiltered = this.secondaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);
      if (categoryFiltered.length > 0){
        this.selectedSubCategories.push(categoryFiltered[0]);
      }else{
        this.selectedSubCategories.push({"Name":e.params.data.text})
      }
      // var value = $(this).value;
    });

    $('#subCategory'+this.categoryIndex).on('select2:unselect', (e) => {
      for(let n in this.selectedSubCategories){
        if(e.params.data.text == this.selectedSubCategories[n].Name){
          this.selectedSubCategories.splice(parseInt(n),1)
        }
      }
    });





  }




  async getCategory() {

    return new Promise(resolve => {
      const post = {
        Token:localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID')
      };


      this.menuService.getCategories(post).subscribe(
          data => {

            this.primaryCat = data['PrimaryCategories'];
            this.secondaryCat = data['SecondaryCategories'];



            resolve(true);
          }
      );
    });

  }


  closeCategory(){

    this.selectedPrimaryCategories.forEach(async (item,n) => {
      this.selectedPrimaryCategories.pop()
    });
    this.selectedPrimaryCategories.pop();
    this.selectedSubCategories.forEach(async (item,n) => {
      this.selectedSubCategories.pop()
    });
    this.selectedSubCategories.pop()
  }



}
