import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'assets/vendors/by_bower/moment/moment';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { ReservationsService } from '../reservations.service';
import { ReservationManagerService } from './reservation-manager.service';
const Pusher = require('pusher-js');
import { ExportToCsv } from 'export-to-csv';


declare var $: any;

@Component({
  selector: 'app-reservation-manager',
  templateUrl: './reservation-manager.component.html',
  styleUrls: ['./reservation-manager.component.css', '../../../../assets/css/ts-style.css']
})
export class ReservationManagerComponent implements OnInit {


  @ViewChild('bookingDatePicker', {
    read: MatDatepicker
  }) bookingDatePicker: MatDatepicker<Date>;

  maxDate = new Date(moment(new Date()).add(120, 'days').format('YYYY-MM-DD'));
  minDate = new Date();

  matDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.changeSelectedDate(event.value.toString());
  }

  matBookingDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.addBooking['Date'] = moment(event.value.toString()).format('YYYY-MM-DD');
    this.addBooking['Day'] = (event.value.getDay() - 1 < 0 ? 6 : event.value.getDay() - 1).toString();
    this.getReservationTimes();
  }

  startDate = moment(new Date()).format('MMM, DD YYYY');

  selectedDate = {
    date: moment(new Date()).format('MMM, DD YYYY'),
    day: moment(new Date()).day() == 0 ? 6 : moment(new Date()).day() - 1,
    full_date: moment(new Date()).format('ddd, MMM DD'),
  };

  changeSelectedDate(new_date) {
    this.selectedDate = {
      date: moment(new_date).format('MMM, DD YYYY'),
      day: moment(new_date).day() == 0 ? 6 : moment(new Date()).day() - 1,
      full_date: moment(new_date).format('ddd, MMM DD'),
    }
    this.getReservationsAndTables();
  }

  nextDate() {
    const next = moment(this.selectedDate.date).add(1, 'days');
    //below isBetween function includes target date equal and between
    if (next.isBetween(this.startDate, this.maxDate, null, '[]')) {
      this.changeSelectedDate(next);
    }
  }

  prevDate() {
    const prev = moment(this.selectedDate.date).subtract(1, 'days');
    //below isBetween function includes target date equal and between
    if (prev.isBetween(this.startDate, this.maxDate, null, '[]')) {
      this.changeSelectedDate(prev);
    }
  }

  bookingToManage = {
    itemIndex: '',
    bookingIndex: '',
    ReservationID: '',
    IdempotencyToken: '',
    ReservationSettingsID: '',
    RestaurantTable: '',
    RestaurantTableID: '',
    StartSecInUTC: '',
    Status: '',
    TimeZone: '',
    Day: '',
    Emai: '',
    GivenName: '',
    FamilyName: '',
    ReservationPhone: '',
    PartySize: '',
    CustomerComments: '',
    RestaurantComments: '',
  };

  assignTables = [];

  reservationTables = [];

  toastMessage = '';

  reservationBeforeUpdatePauseDates = [];
  reservationPauseDates = [];
  isRemoveDatesEnabled = false;
  isUpdateDatesEnabled = false;

  allRestaurants = [];
  restaurantIds = [];

  bookings = [];
  newBookings = [];

  addBooking = {
    PartySize: 2,
    Date: '',
    Time: '',
    TimeInterval: '',
    StartSecInUTC: '',
    Day: '',
    GivenName: '',
    FamilyName: '',
    Email: '',
    Phone: '',
    TableNumber: '',
    RestaurantTableID: '',
    CustomerComments: '',
    RestaurantComments: '',
  }
  addBookingEnabled = true;

  addBookingTimes = [];
  addBookingTables = [];

  selectedReservation = {
    'GivenName': '',
    'FamilyName': '',
    'PartySize': '',
    'Email': '',
    'Phone': '',
  }

  currentTab = 'reservations';

  audio = new Audio('../../assets/sounds/orderAlert.wav');
  audioOn = false;
  soundTimer: any;

  constructor(private reservationManagerService: ReservationManagerService, private router: Router, private resService: ReservationsService) {

  }

  ngOnInit(): void {

    let self = this;
    window.onresize = () => {
      this.showLayout(this.currentTab);
    }

    window.onload = () => {
      this.showLayout(this.currentTab);
    }

    $('#alert').hide();
    $('#no_reservation_div').hide();
    $('#placeholder').hide();

    $('#create_booking_modal').on('shown.bs.modal', function (event) {
      $('#create_booking_modal_body').scrollTop(0);
      $('#noTimeTxt').html('');
      $('#noTableTxt').html('');
      // self.bookingDatePicker._selected = null;
      // self.bookingDatePicker._datepickerInput.value = null;
    });

    this.getNewReservations();
    this.getReservationsAndTables();

    const pusher = new Pusher('1112b666d809129183c1', {
      cluster: 'us2',
      forceTLS: true
    });

    this.allRestaurants = localStorage.getItem('allRestaurants') !== 'null' ? JSON.parse(localStorage.getItem('allRestaurants')) : [];
    if (localStorage.getItem('allRestaurants') == null) {
      this.restaurantIds = localStorage.getItem('allRestaurantID').split(',');
    } else {
      this.allRestaurants.forEach((restaurant) => {
        this.restaurantIds.push(restaurant['RestaurantID']);
      });
    }

    if (this.restaurantIds.length >= 1) {
      for (let i in this.restaurantIds) {
        const channel = pusher.subscribe(this.restaurantIds[i].toString());
        const self = this;
        channel.bind('my-event', function (data) {

          if (data.hasOwnProperty('message')) {
            if (data['message'] === 'new reservation') {
              self.getNewReservations();
            }
            else if (data['message'] === 'update reservation') {
              self.getNewReservations();
            }
            else if (data['message'] === 'cancel reservation') {
              self.getNewReservations();
            }
            else if (data['message'] === 'new order') {

            } else if (data['message'] === 'update') {

            } else if (data['message'] === 'take order') {

            } else {

            }
          }
        });
      }
    }
  }

  ngAfterViewInit() {
    this.getReservationPausedDates();
  }

  async getReservationsAndTables() {

    $('#placeholder').show();
    $('#no_reservation_div').hide();

    this.reservationTables = [];
    this.bookings = [];

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      day: this.selectedDate.day,
      date: this.selectedDate.date,
      TimeZone: localStorage.getItem('restaurantTimeZone')
    };

    await this.reservationManagerService.getTablesWithUpcomingReservions(post).subscribe(async data => {

      $('#placeholder').hide();

      if (data['Success']) {

        this.reservationTables = data['Tables'];
        
        this.bookings = data['Reservations'];

        if (this.bookings.length == 0 && this.newBookings.length == 0) {
          $('#no_reservation_div').show();
        }
        //When reservation data is received then popovers needs to be intialized to show upcoming reservations in pop-ups
        $(function () {
          $('[data-toggle="popover"]').popover();
        });
      }

    });

  }

  validateEmail(email) {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test if email matches the pattern
    return pattern.test(email);
  }

  checkAddBookingValidate() {

    if (!Number.isInteger(this.addBooking['PartySize']) || this.addBooking['Date'].trim().length == 0 || this.addBooking.Time == '' || this.addBooking.RestaurantTableID == '' || this.addBooking.GivenName.trim() == '' || this.addBooking.FamilyName.trim() == '' || !this.validateEmail(this.addBooking.Email) || Number.isNaN(this.addBooking.Phone) || this.addBooking.Phone.length != 10 || this.addBookingEnabled == false) {
      return true;
    }
    else {
      return false;
    }
  }

  async createReservation() {

    this.addBookingEnabled = false;

    let payment_info = {
      prepayment_status: "PREPAYMENT_NOT_PROVIDED"
    }

    let resources = {
      party_size: this.addBooking.PartySize
    }

    let slot = {
      resources: resources,
      confirmation_mode: "CONFIRMATION_MODE_ASYNCHRONOUS",
      duration_sec: this.addBooking.TimeInterval.toString(),
      merchant_id: localStorage.getItem('restaurantID'),
      service_id: localStorage.getItem('restaurantID') + "0",
      start_sec: this.addBooking.StartSecInUTC.toString()
    }

    let user_info = {
      email: this.addBooking.Email,
      family_name: this.addBooking.FamilyName,
      given_name: this.addBooking.GivenName,
      telephone: this.addBooking.Phone,
      user_id: "NA"
    }

    const post = {
      idempotency_token: "web" + new Date().getTime(),
      payment_information: payment_info,
      slot: slot,
      user_information: user_info,
      send_user_email: true,
      reservationStatus: "CONFIRMED",
      table_num: this.addBooking.TableNumber.toString().trim(),
      restaurant_table_id: this.addBooking.RestaurantTableID,
      customerComments: this.addBooking.CustomerComments,
      restaurantComments: this.addBooking.RestaurantComments,
    };

    await this.reservationManagerService.createReservation(post).subscribe(async data => {

      if (data["booking"] != undefined && data["booking"]["status"] == "CONFIRMED") {
        $('#create_booking_modal').modal('hide');
        this.addBookingEnabled = true;
        this.addBooking = {
          PartySize: 2,
          Date: '',
          Time: '',
          TimeInterval: '',
          StartSecInUTC: '',
          Day: '',
          GivenName: '',
          FamilyName: '',
          Email: '',
          Phone: '',
          TableNumber: '',
          RestaurantTableID: '',
          CustomerComments: '',
          RestaurantComments: '',
        };
        // this.bookingDatePicker._selected = null;
        // this.bookingDatePicker._datepickerInput.value = null;
        this.addBookingTimes = [];
        this.addBookingTables = [];
        this.toastMessage = 'A reservation has been made.'
        this.showToast();
        this.getReservationsAndTables();
      }
      else if (data["booking_failure"] != undefined && data["booking_failure"]["cause"] == "SLOT_UNAVAILABLE") {
        $('#create_booking_modal').modal('hide');
        alert('Sorry, This time is no longer available!');
        this.addBookingEnabled = true;
        this.getReservationsAndTables();
      }
      else {
        $('#create_booking_modal').modal('hide');
        alert('Something went wrong! Please contact TopServe support to inform about this situation!');
        this.addBookingEnabled = true;
        this.getReservationsAndTables();
      }
    })

  }

  showConfirmReservationModal(bookingIndex) {
    this.stopAlert();
    this.bookingToManage = JSON.parse(JSON.stringify(this.newBookings[bookingIndex]));
    this.bookingToManage['bookingIndex'] = bookingIndex;
    this.bookingToManage['itemIndex'] = '';
    this.getTableNumbers(false);
  }

  confirmCancellation(bookingIndex) {
    this.stopAlert();
    this.bookingToManage = JSON.parse(JSON.stringify(this.newBookings[bookingIndex]));
    this.bookingToManage['bookingIndex'] = bookingIndex;
    this.bookingToManage['itemIndex'] = '';


    const param = {
      ReservationID: this.bookingToManage['ReservationID'],
      Update:false,
    };

    this.resService.confirmCheckReservation(param).subscribe(data => {
      if (data['Success']) {
        $('#confirm_booking_modal').modal('hide');
          this.stopAlert();
          this.newBookings.splice(Number(this.bookingToManage['bookingIndex']), 1);
          this.toastMessage = 'Reservation has been cleared.'
          this.showToast();
          this.getReservationsAndTables();
      }else if (!data['Success'] && data['Status'] == 404) {
        alert(data['Message']);
      }else {
        alert('Something went wrong while confirming the reservation.\nPleasw try again.');
      }
    });

  }

  confirmNewReservation() {

    const param = {
      ReservationID: this.bookingToManage['ReservationID'],
      RestaurantTableID: $("#select_table_number").val(),
      RestaurantTable: $("#select_table_number :selected").text(),
      RestaurantComments: this.bookingToManage['RestaurantComments'],
      Update:true,
    };

    this.resService.confirmReservation(param).subscribe(data => {
      if (data['Success']) {

        const param1 = {
          booking: {
            booking_id: this.bookingToManage['IdempotencyToken'],
            status: 'CONFIRMED'
          },
          reservation: {
            RestaurantCheck: 1
          }
        };
            
        this.resService.cancelReservation(param1).subscribe(data => {
    
          $('#confirm_booking_modal').modal('hide');
          this.stopAlert();
          this.newBookings.splice(Number(this.bookingToManage['bookingIndex']), 1);
          this.toastMessage = 'Reservation has been confirmed.'
          this.showToast();
          this.getReservationsAndTables();
    
        });


       
      }
      else if (!data['Success'] && data['Status'] == 404) {
        alert(data['Message']);
      }
      else {
        alert('Something went wrong while confirming the reservation.\nPleasw try again.');
      }
    });

  }

  updateReservation() {
    this.stopAlert();
    const param = {
      ReservationID: this.bookingToManage['ReservationID'],
      RestaurantTableID: $("#update_table_number").val(),
      RestaurantTable: $("#update_table_number :selected").text(),
      RestaurantComments: this.bookingToManage['RestaurantComments'],
      Update:true
    };

    this.resService.confirmReservation(param).subscribe(data => {
      if (data['Success']) {
        $('#update_booking_modal').modal('hide');
        this.stopAlert();
        this.toastMessage = 'Updates have been made to the reservation.'
        this.showToast();
        this.getReservationsAndTables();
      }
      else if (!data['Success'] && data['Status'] == 404) {
        alert(data['Message']);
      }
      else {
        alert('Something went wrong while updating the reservation.\nPleasw try again.');
      }
    });
  }

  cancelReservation(itemIndex, bookingIndex) {
    this.stopAlert();
    const bookingToCancel = itemIndex !== '' ? this.bookings[itemIndex]['Reservations'][bookingIndex] : this.newBookings[bookingIndex];

    const param = {
      booking: {
        booking_id: bookingToCancel['IdempotencyToken'],
        status: 'DECLINED_BY_MERCHANT'
      },
      reservation: {
        RestaurantCheck: 1
      }
    };

    this.resService.cancelReservation(param).subscribe(data => {

      if (data.hasOwnProperty('status') && data["status"] == "CANCELED" || data.hasOwnProperty('status') && data["status"] == "DECLINED_BY_MERCHANT") {
        if (itemIndex !== '') {
          this.getReservationsAndTables();
        }
        else {
          this.newBookings.splice(bookingIndex, 1);
          this.stopAlert();
        }

        this.toastMessage = 'The booking has been canceled.'
        this.showToast();
      }
      else {
        alert('Something went wrong while cancelling the reservation.\nPleasw try again.');
      }

    });

  }

  showChangeTableModal(itemIndex, bookingIndex) {
    this.bookingToManage = JSON.parse(JSON.stringify(this.bookings[itemIndex]['Reservations'][bookingIndex]));
    this.bookingToManage['bookingIndex'] = bookingIndex;
    this.bookingToManage['itemIndex'] = itemIndex;
    this.getTableNumbers(true);
  }

  showToast() {
    $('#toast').addClass('show')
    setTimeout(() => {
      $('#toast').removeClass('show')
    }, 2000);
  }

  getIntervalInHour(interval) {
    if (interval == 3600) {
      return "1hr";
    }
    else if (interval == 7200) {
      return "2hr";
    }
    else {
      return "3hr";
    }
  }

  matDatePausedEvent(selected_date) {
    const date = moment(selected_date).format('YYYY-MM-DD HH:mm:ss');
    const index = this._findDate(date);
    if (index === -1) {
      this.reservationPauseDates.push(date);
    }
    else {
      this.reservationPauseDates.splice(index, 1);
    }

    if (this.reservationPauseDates.length == this.reservationBeforeUpdatePauseDates.length) {
      for (let i in this.reservationPauseDates) {
        if (!this.reservationBeforeUpdatePauseDates.includes(this.reservationPauseDates[i])) {
          this.isUpdateDatesEnabled = true;
          break;
        }
        else {
          this.isUpdateDatesEnabled = false;
        }
      }
    }
    else {
      this.isUpdateDatesEnabled = true;
    }
  }

  remove(date: Date): void {
    const index = this._findDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
    this.reservationPauseDates.splice(index, 1);
    if (this.reservationPauseDates.length == this.reservationBeforeUpdatePauseDates.length) {
      for (let i in this.reservationPauseDates) {
        if (!this.reservationBeforeUpdatePauseDates.includes(this.reservationPauseDates[i])) {
          this.isUpdateDatesEnabled = true;
          break;
        }
        else {
          this.isUpdateDatesEnabled = false;
        }
      }
    }
    else {
      this.isUpdateDatesEnabled = true;
    }
  }

  _findDate(date): number {
    return this.reservationPauseDates.indexOf(date);
  }

  async getReservationPausedDates() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    }

    await this.reservationManagerService.getReservationExceptionDates(post).subscribe(async data => {
      if (data["Success"]) {

        this.reservationBeforeUpdatePauseDates = [];
        this.isUpdateDatesEnabled = false;
        this.isRemoveDatesEnabled = false;

        for (let i in data['Response']) {
          this.reservationBeforeUpdatePauseDates.push(data['Response'][i]);
        }

        this.reservationPauseDates = data['Response'];

        if (this.reservationPauseDates.length == this.reservationBeforeUpdatePauseDates.length) {
          for (let i in this.reservationPauseDates) {
            if (!this.reservationBeforeUpdatePauseDates.includes(this.reservationPauseDates[i])) {
              this.isUpdateDatesEnabled = true;
              break;
            }
            else {
              this.isUpdateDatesEnabled = false;
            }
          }
        }
        else {
          this.isUpdateDatesEnabled = true;
        }

        if (this.reservationPauseDates.length > 0) {
          this.isRemoveDatesEnabled = true;
        }
      }
    });
  }

  async updateReservationExceptions() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      dates: this.reservationPauseDates
    }

    await this.reservationManagerService.addReservationsException(post).subscribe(async data => {

      $('#pause_reservation_modal').modal('hide');
      if (data["Success"]) {
        this.getReservationPausedDates();
      }
      else {
        alert('Could not update dates! Please try again!\n OR Please contact support to inform about this situation!');
      }
    });

  }

  async deleteAllReservationPausedDates() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    }

    await this.reservationManagerService.deleteReservationExceptionDates(post).subscribe(async data => {
      $('#pause_reservation_modal').modal('hide');
      if (data["Success"]) {
        this.getReservationPausedDates();
      }
      else {
        alert('Could not update dates! Please try again!\n OR Please contact support to inform about this situation!');
      }
    });
  }

  showLayout(layout) {

    if (window.innerWidth >= 768) {
      $('#reservations_layout').show();
      $('#table_layout').show();
      return;
    }

    this.currentTab = layout;

    if (layout == 'reservations') {
      $('#reservations_layout').show();
      $('#table_layout').hide();

    }
    else {
      $('#reservations_layout').hide();
      $('#table_layout').show();
    }
  }

  alertRestaurant() {
    $('#new_reservation_toast').addClass('show');
    this.showLayout('reservations');
    const self = this;
    this.audioOn = true;
    this.soundTimer = setInterval(function () {
      if (self.audioOn) {
        self.audio.play();
      };
    }, 12000);
  };

  stopAlert() {
    $('#new_reservation_toast').removeClass('show');
    this.audio.pause();
    this.audioOn = false
    clearInterval(this.soundTimer);
    this.soundTimer = []
  }

  async getNewReservations() {

    $('#no_reservation_div').hide();

    const param = {
      RestaurantID: localStorage.getItem('restaurantID'),
      restaurantCheck: '0'
    };

    this.resService.getReservations(param).subscribe(data => {

      if (data["Success"]) {
        this.newBookings = [];
        if (data["Response"].length != 0) {
          this.alertRestaurant();
          this.newBookings = data["Response"];

          if (this.bookings.length == 0 && this.newBookings.length == 0) {
            $('#no_reservation_div').show();
          }

          for (let i in this.newBookings) {
            this.newBookings[i]['Day'] = moment(this.newBookings[i]['Day']).format('ddd, MMM DD hh:mm A');
          }
        }
      }
    });
  }

  async getTableNumbers(isUpdate) {

    const date = moment.unix(Number(this.bookingToManage['StartSecInUTC'])).local()
    const Day = date.day() == 0 ? 6 : date.day() - 1;

    const param = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Day: Day,
      StartSecInUTC: this.bookingToManage['StartSecInUTC'],
      TimeInterval: this.bookingToManage['TimeInterval']
    }

    this.resService.getTableNumbersForAssigning(param).subscribe(data => {
      if (data["Success"]) {
        this.assignTables = [];
        this.assignTables = data['Tables'];
        if (isUpdate) {
          $('#update_booking_modal').modal('show');
        }
        else {
          $('#confirm_booking_modal').modal('show');
        }
      }
      else {
        alert('Something went wrong while getting available tables to assign! Please try again later!!')
      }
    });
  }

  showReservationData(i, j) {
    this.selectedReservation = this.reservationTables[i]['Reservations'][j];
    $('#reservation' + i + '-' + j).popover({ html: true, container: 'body', placement: 'bottom', content: $('#reservation-details' + i + '-' + j), trigger: 'focus' }).popover('toggle');
  }

  getReservationTimes() {

    this.addBookingTimes = [];
    this.addBookingTables = [];
    $('#noTimeTxt').html('');
    $('#noTableTxt').html('');

    if (!Number.isInteger(this.addBooking['PartySize']) || this.addBooking['Date'].trim().length == 0) {
      return;
    }

    const post = {
      ReservationDate: this.addBooking['Date'],
      day: this.addBooking['Day'],
      RestaurantID: localStorage.getItem('restaurantID'),
      PartySize: this.addBooking.PartySize,
    };

    this.resService.getAvailableTimeAndTable(post).subscribe(data => {
      if (data["Success"]) {
        this.addBookingTimes = data['Response'];
        if (this.addBookingTimes.length > 0) {

          for (let i in this.addBookingTimes) {
            this.addBookingTimes[i]['FormattedStartHour'] = moment(this.addBookingTimes[i]['StartHour'], 'HH:mm:ss').format('hh:mm A');
          }
          this.addBooking.Time = this.addBookingTimes[0]['StartHour'];
          this.addBooking.StartSecInUTC = (Number(moment(this.addBooking.Date + ' ' + this.addBooking.Time).format('x')) / 1000).toString();
          this.addBookingTables = this.addBookingTimes[0]['Tables'];
          if (this.addBookingTables.length > 0) {
            this.addBooking.TimeInterval = this.addBookingTables[0]['TimeInterval'];
            this.addBooking.RestaurantTableID = this.addBookingTables[0]['RestaurantTableID'];
            this.addBooking.TableNumber = this.addBookingTables[0]['TableNumber'];
          }
          else {
            $('#noTableTxt').html('There are no tables available for the selected date and time');
          }
        }
        else {
          $('#noTimeTxt').html('There is no time available for booking on the selected date or the party size.');
        }
      }
      else if (data['Status'] == 200) {
        alert(data['Message']);
        $('#create_booking_modal').modal('hide');
      }
      else {
        alert('Something went wrong while getting available reservation times! Please try again later!!');
        $('#create_booking_modal').modal('hide');
      }
    });
  }

  exportGuests() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    };

    this.resService.getPromotionUsersList(post).subscribe(data => {
      if (data["Success"]) {
        
        const guests = [];
        for (let i in data['Users']) {
          guests.push({
            "Email": data['Users'][i]['Email'],
            "GivenName": data['Users'][i]['GivenName'],
            "FamilyName": data['Users'][i]['FamilyName'],
            "Phone": data['Users'][i]['Phone'],
          });
        }
    
    
    
        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'TopServe Export Guest List',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        };
    
        const csvExporter = new ExportToCsv(options);
    
        csvExporter.generateCsv(guests);
      }
      else {
        alert('Something went wrong! Please contact support!');
      }
    });
  }

  changeAddReservationTime() {
    const i = $('#addReservationFormTime').val();
    this.addBooking.Time = this.addBookingTimes[i]['StartHour'];
    this.addBooking.StartSecInUTC = (Number(moment(this.addBooking.Date + ' ' + this.addBooking.Time).format('x')) / 1000).toString();
    this.addBookingTables = this.addBookingTimes[i]['Tables'];
    this.addBooking.TimeInterval = this.addBookingTables[0]['TimeInterval'];
    this.addBooking.RestaurantTableID = this.addBookingTables[0]['RestaurantTableID'];
    this.addBooking.TableNumber = this.addBookingTables[0]['TableNumber'];
  }

  changeAddReservationTable() {
    const i = $('#addReservationFormTable').val();
    this.addBooking.RestaurantTableID = this.addBookingTables[i]['RestaurantTableID'];
    this.addBooking.TableNumber = this.addBookingTables[i]['TableNumber'];
  }
}
