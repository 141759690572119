import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';



@Injectable({
  providedIn: 'root'
})
export class AddShiftService {

  getShiftsURL = this.url.getDomain() + '/Driver/GetAllShiftsForPartner';
  updateAvailableShiftURL = this.url.getDomain() + '/Driver/UpdateAvailableShift';
  addShiftsServiceURL =  this.url.getDomain() + '/Driver/AddNewShift';
  removeShiftURL =  this.url.getDomain() + '/Driver/RemoveDriverShift';
  resetShiftUrl = this.url.getDomain() + '/Driver/ResetDriverShift';
  resetDayShiftsUrl = this.url.getDomain() + '/Driver/ResetDriverAllShiftsOfDay';
  updateWeekForTakenShiftUrl = this.url.getDomain() + '/Driver/UpdateWeekForDriverTakenShift';
  pauseAvailableShiftUrl = this.url.getDomain() + '/Driver/PauseDriverAvailableShift';
  getSubDriversOfCityUrl = this.url.getDomain() + '/Driver/GetSubDriversOfCity';
  assignSubDriverShiftUrl = this.url.getDomain() + '/Driver/AddShiftForDriver2';

  constructor(private http: HttpClient, private url: Url) { }


  removeShiftsService(res) {
    return this.http.post<AddShiftService>(this.removeShiftURL, res);
  }

  addShiftsService(res) {
    return this.http.post<AddShiftService>(this.addShiftsServiceURL, res);

  }

  getShifts(res) {
    return this.http.post<AddShiftService>(this.getShiftsURL, res);
  }

  updateAvailableShift(res) {
    return this.http.post<AddShiftService>(this.updateAvailableShiftURL, res);
  }

  resetShift(res) {
    return this.http.post<AddShiftService>(this.resetShiftUrl, res);
  }

  updateWeekForTakenShift(res) {
    return this.http.post<AddShiftService>(this.updateWeekForTakenShiftUrl, res);
  }

  getSubDriversOfCity(res) {
    return this.http.post<AddShiftService>(this.getSubDriversOfCityUrl, res);
  }

  assignSubDriverShift(res) {
    return this.http.post<AddShiftService>(this.assignSubDriverShiftUrl, res);
  }

  resetDayShifts(res) {
    return this.http.post<AddShiftService>(this.resetDayShiftsUrl, res);
  }

  pauseAvailableShift(res) {
    return this.http.post<AddShiftService>(this.pauseAvailableShiftUrl, res);
  }

}


//