import { Component, OnInit } from '@angular/core';
import { DriverShiftService } from './driver-shift.service'
declare var Chart: any;
declare var $: any;
declare var google: any
var shifts = []

@Component({
  selector: 'app-driver-shift',
  templateUrl: './driver-shift.component.html',
  styleUrls: ['./driver-shift.component.css']
})
export class DriverShiftComponent implements OnInit {

  constructor(private shiftService: DriverShiftService) { }

  ngOnInit(): void {


    const post = {
      PartnerID: localStorage.getItem('PartnerID'),
      City: localStorage.getItem('partnerDataCityName')
    };

    this.shiftService.getDriversOnShiftInCity(post).subscribe(
      data => {
        const self0 = this;


        shifts = [];

        for (let x in data) {

          
          let stDate = data[x]['StartHour'].split(':');
          let enDate = data[x]["EndHour"].split(":");

          if (data[x]['Taken'] == 0) {
            data[x]['SubDriverName'] = 'Not Taken';
            shifts.push([self0.getDay(data[x]["Day"]), data[x]["SubDriverName"], '#bcbcbc', new Date(0, 0, 0, stDate[0], stDate[1], 0), new Date(0, 0, 0, enDate[0], enDate[1], 0)])
          }
          else {
            shifts.push([self0.getDay(data[x]["Day"]), data[x]["SubDriverName"], '', new Date(0, 0, 0, stDate[0], stDate[1], 0), new Date(0, 0, 0, enDate[0], enDate[1], 0)])
          }
        }

        // console.log(shifts);
        // shifts = shifts.filter(function(itm) {
        //   return itm.indexOf(itm[2]) > -1;
        // });
        // console.log(shifts);

        if (shifts.length > 0) {
          self0.setupChart();
        }

      });





    $(function () {

      //   const canvas = <HTMLCanvasElement> document.getElementById('myChart');
      //   const ctx = canvas.getContext('2d');

      //   var myChart = new Chart(ctx, {
      //       type: 'bar',
      //       data: {
      //           labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      //           datasets: [{
      //               label: '# of Votes',
      //               data: [12, 19, 3, 5, 2, 3],
      //               backgroundColor: [
      //                   'rgba(255, 99, 132, 0.2)',
      //                   'rgba(54, 162, 235, 0.2)',
      //                   'rgba(255, 206, 86, 0.2)',
      //                   'rgba(75, 192, 192, 0.2)',
      //                   'rgba(153, 102, 255, 0.2)',
      //                   'rgba(255, 159, 64, 0.2)'
      //               ],
      //               borderColor: [
      //                   'rgba(255, 99, 132, 1)',
      //                   'rgba(54, 162, 235, 1)',
      //                   'rgba(255, 206, 86, 1)',
      //                   'rgba(75, 192, 192, 1)',
      //                   'rgba(153, 102, 255, 1)',
      //                   'rgba(255, 159, 64, 1)'
      //               ],
      //               borderWidth: 1
      //           },{
      //             label: '# of Votes',
      //             data: [15, 33, 4, 6, 2, 3],
      //             backgroundColor: [
      //                 'rgba(255, 99, 132, 0.2)',
      //                 'rgba(54, 162, 235, 0.2)',
      //                 'rgba(255, 206, 86, 0.2)',
      //                 'rgba(75, 192, 192, 0.2)',
      //                 'rgba(153, 102, 255, 0.2)',
      //                 'rgba(255, 159, 64, 0.2)'
      //             ],
      //             borderColor: [
      //                 'rgba(255, 99, 132, 1)',
      //                 'rgba(54, 162, 235, 1)',
      //                 'rgba(255, 206, 86, 1)',
      //                 'rgba(75, 192, 192, 1)',
      //                 'rgba(153, 102, 255, 1)',
      //                 'rgba(255, 159, 64, 1)'
      //             ],
      //             borderWidth: 1
      //         }]
      //       },
      //       options: {
      //           scales: {
      //               yAxes: [{
      //                   ticks: {
      //                       beginAtZero: true
      //                   }
      //               }]
      //           }
      //       }
      //   });



    });
  }


  getDay(day) {


    switch (day) {

      case 0:
        return "0 - Monday";
      case 1:
        return "1 - Tuesday";

      case 2:
        return "2 - Wednesday";

      case 3:
        return "3 - Thusday";

      case 4:
        return "4 - Friday";

      case 5:
        return "5 - Saturday";

      case 6:

        return "6 - Sunday";
      default:
        "Error"





    }




  }

  setupChart() {

    google.load('visualization', '1', { packages: ['timeline'] });
    google.charts.setOnLoadCallback(drawChart);
    function drawChart() {
      var container = document.getElementById('timeline');
      var chart = new google.visualization.Timeline(container);
      var dataTable = new google.visualization.DataTable();
      dataTable.addColumn({ type: 'string', id: 'WeekDay' });
      dataTable.addColumn({ type: 'string', id: 'Name' });
      dataTable.addColumn({ type: 'string', id: 'style', role: 'style' });
      dataTable.addColumn({ type: 'date', id: 'Start' });
      dataTable.addColumn({ type: 'date', id: 'End' });
      var data = [];
      dataTable.addRows(shifts);
      // dataTable.sort([{ column: 1, desc: true }, { column: 0 }]);
      dataTable.sort([{ column: 0 }]);

      var options = {
        timeline: { colorByRowLabel: true }
      };
      chart.draw(dataTable, options);
    }
  }




}
