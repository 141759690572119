// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
textarea:focus { 
    outline: none !important;
    border-color: firebrick;
    box-shadow: 0 0 5px firebrick;
}
.borderrow{
    box-shadow: 0px 0px 13px 0px rgb(189,189,189);
}`, "",{"version":3,"sources":["webpack://./src/app/structure/order-refund/order-refund.component.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,6BAA6B;AACjC;AACA;IAGI,6CAA6C;AACjD","sourcesContent":["\ntextarea:focus { \n    outline: none !important;\n    border-color: firebrick;\n    box-shadow: 0 0 5px firebrick;\n}\n.borderrow{\n    -webkit-box-shadow: 0px 0px 13px 0px rgb(189,189,189);\n    -moz-box-shadow: 0px 0px 13px 0px rgb(189,189,189);\n    box-shadow: 0px 0px 13px 0px rgb(189,189,189);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
