import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from 'app/models/url';

@Injectable({
  providedIn: 'root'
})
export class FarmService {

  getStandEarnigsUrl = this.url.getDomain() + '/Restaurants/GetStandEarningsFromDate';


  constructor(private http: HttpClient, private url: Url) { }

  getFarmEarnings(post) {
    return this.http.post(this.getStandEarnigsUrl, post);
  }
}
