import { AfterContentInit, Component, OnInit, HostListener, ElementRef, ViewChild, NgZone, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { EmpService } from "../../../emp.service";
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Url } from "../../../models/url";
import { Router } from '@angular/router';
import { MenuService } from "../../menu/menu.service";
import { EditItemServiceService } from "../../../services/edit-item-service.service";
import { LoginService } from "../../login/login.service";
// import { } from '@agm/core/services/google-maps-types';
import { RegisterService } from "../../register/register.service";
import { PaymentService } from "../../../services/payments/payment.service";
import { ProfileService } from "../../profile/profile.service";
import 'rxjs/add/operator/catch';
import { loadStripe } from '@stripe/stripe-js';
import { FarmOrderComponent } from 'app/structure/farm/farm-order/farm-order.component';
import { environment } from 'environments/environment.prod';
import * as moment from 'moment';


declare var $: any;
const AWS = require('aws-sdk');
declare const google: any;


@Component({
  selector: 'app-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.css', '../../../../assets/css/ts-style.css', '../../../../assets/css/custom.css']
})
export class OrderCartComponent implements OnInit, AfterContentInit {
  @ViewChild('farmOrderContainer', { read: ViewContainerRef }) farmOrderContainer;
  @ViewChild('checked') checked: ElementRef;
  @ViewChild('checkedCard') checkedCard: ElementRef;

  stripePromise = loadStripe(environment.stripeKey);

  originalDelivery = 0.00;
  loginErrorMsg = ""
  isLoading = true;
  isLoadingDelivery = false;
  mainMenu: any;
  mainMenuImages: any;
  allMenuCategories = [];
  rawCategories = [];
  rawSubCategories = [];
  menuPrimaryCategories = [];
  menuSecondaryCategories = [];
  menuSecondaryCategoriesFiltered = [];
  linkedMenuItemAndCategory = [];
  menuSideLinks = [];
  selectedPrimaryCategory: any;
  selectedSubCategory: any;
  menuSides = [];
  tempMenuSides = [];
  menuSideTitles: any;
  tempMainMenu: any;
  tabSelected: any;
  activeSecondaryTab: any;
  noMenu = false;
  topOfTabs = 0;
  order = [];
  subtotal = 0;
  tax = 0;
  total = 0;
  tip = 0;
  deliveryFee = 0;
  username: any;
  password: any;
  guestEmail = '';
  guestpswd = '';
  token: any;
  registerPassword: any;
  registerConfirmPassword: any;
  firstName = '';
  lastName = '';
  phone = '';
  email: any;
  userType: any;
  loading = false;
  orderRequest = {};
  applicationFee = 15.00;
  orderID = 0;
  isVisible = true;
  orderOptionMessage = '';
  apartment = '';
  tomorrowsDate = ''
  additionalComments = '';
  promoCode = '';
  redeemedPromo = 0.00;
  specialRequest = '';
  appliedPromo = false
  freeDelivery = false
  last4 = "";
  brand = "";
  expMonth = "";
  expYear = "";
  exp = "";
  hasCard = false;
  isCardChecked = false;
  restaurantNameLink = null
  gotDeliveryInfo = false
  autocompleteInput: string;
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  address1 = "";
  address2 = "";
  city = "";
  province = "";
  country = "";
  postal = "";
  maintab = [];
  lat = 0.00;
  lng = 0.00;
  isMobile = false;
  userDeivce = 'web';
  allsubCategory = [];

  address = 'Enter your delivery address';
  arr = [];
  registerErrorMsg = '';
  currentAddress = '';
  guestAcnt = false;
  userCards = [];
  subcount = 0;
  itemsInCart = 0;
  subcats = [];
  subcatsforMenu = [];
  //totprice: any;
  menuItems = [];
  totPrice: any;
  allMenuItemsCategories = [];
  receiptEmail = '';
  payBefore = false;
  isDineIn = false;
  tableNumber = '0';
  selectedMenuItem: any;
  selectedOption: any;
  ageRestrictionItems = false;
  showSignInForm = true;
  customField = '';
  storeTipPercentage = [];
  customTip = false;
  customTipAmount = 5;
  selectedTipPercentage = 0;
  order_frame = false;
  prepare_now: boolean = true; 
  prep_dates: string[] = [];
  prepare_date: string;
  prep_times: string[] = [];
  prepare_time: string;
  walletPay: boolean = false;

  constructor(private menuService: MenuService,
    private sanitizer: DomSanitizer,
    private editService: EditItemServiceService,
    private router: Router,
    public emp: EmpService,
    public loginService: LoginService,
    private registerService: RegisterService,
    private paymentService: PaymentService,
    private profileService: ProfileService,
    private urlObject: Url,
    private zone: NgZone,
    private title: Title,
    private meta: Meta,
    private resolver: ComponentFactoryResolver,
  ) {
  }

  getElementOffset(element) {
    var de = document.documentElement;
    var box = element.getBoundingClientRect();
    var top = box.top + window.pageYOffset - de.clientTop;
    var left = box.left + window.pageXOffset - de.clientLeft;
    return { top: top, left: left };
  }

  ngAfterContentInit(): void {
  }

  async ngOnInit() {

    let self = this;

    $('#card_number').on('input', function () {
      var cardNumber = $(this).val().replace(/\s/g, ''); // Remove existing spaces
      var formattedCardNumber = self.formatCardNumber(cardNumber);
      $(this).val(formattedCardNumber.slice(0, 19)); // Apply maximum input length of 19 characters
    });

    $('#card_expiry').on('input', function () {
      var expiry = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      var formattedExpiry = self.formatExpiry(expiry);
      $(this).val(formattedExpiry);
    });

    $('#card_cvc').on('input', function () {
      var cvc = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      $(this).val(cvc.slice(0, 4));
    });

    $('#delivery-ot-tab').click(function () {
      $('#takeout-ot-tab').removeClass('active');
      $('#delivery-ot-tab').addClass('active');
      self.deliveryFee = self.emp.chosenRestaurant.DeliveryFee;
      self.emp.orderInformation.delivery = true;
      localStorage.setItem('delivery', 'true');
      self.calculateCart();
    });

    $('#takeout-ot-tab').click(function () {
      $('#delivery-ot-tab').removeClass('active');
      $('#takeout-ot-tab').addClass('active');
      self.deliveryFee = 0.00;
      self.emp.orderInformation.delivery = undefined;
      localStorage.setItem('delivery', 'false');
      self.calculateCart();
    });

    $('#storeLayout').hide();
    $('#farmLayout').hide();
    
    this.generatePrepDates();
    this.prepare_date = this.prep_dates[0];
    this.generatePrepTimes();
    this.prepare_time = this.prep_times[0];

    if (localStorage.getItem('guestAccount') == 'true') {
      this.receiptEmail = localStorage.getItem('GuestEmail');
    }


    this.emp.chosenRestaurant = {};
    this.checkDevice();


    this.manageDeliveryTakeoutDineIn();

    this.getCards();

    if (this.emp.orderInformation.isMealService) {
      this.tip = 0
    }
    var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday",
      "Thursday", "Friday", "Saturday"];
    var nextWorkingDay = [1, 2, 3, 4, 5, 1, 1];
    var now = new Date();
    this.tomorrowsDate = dayNames[nextWorkingDay[now.getDay()]].toString();

    this.emp.chosenRestaurant.Name = this.restaurantNameLink;
    await this.getRestaurantByLink();
    this.order = this.emp.orderedItems;

    if (this.emp.chosenRestaurant !== undefined || this.emp.chosenRestaurant !== null) {
      this.setupDelivery();
    }

    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    const validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          if (event.target.id == "register") {
            self.register()
          }
          else if (event.target.id == "guestForm") {
            $('#guest-modal').modal('hide');
            self.createGuest();
          } else if (event.target.id == "updateUserForm") {
            localStorage.setItem('FirstName', self.firstName);
            localStorage.setItem('LastName', self.lastName);
            localStorage.setItem('GuestEmail', self.receiptEmail);
            localStorage.setItem('Phone', self.phone);
            $('#update-user-modal').modal('hide');
            $('#cart-modal').modal('show');
            $('#cart-carousel').carousel(1);
          }
          else {
            self.login(false)
          }
        }
        form.classList.add('was-validated');
      }, false);
    });


    $("#successAlert").hide();
    $("#failAlert").hide();

    await this.getRestaurantMenu();

    if (this.order.length > 0) {
      this.calculateSubTotal();
      this.calculateTax();
      this.calculateTip();
      this.calculateDelivery();
      this.calculateTotal();
    }

    var guestAt = localStorage.getItem('guestAccount')
    if (guestAt == 'true') {
      this.guestAcnt = true;
    }

    //This is to show login form always first when login modal is shown.
    $('#login-modal').on('show.bs.modal', function (event) {
      self.showSignInForm = true;
    });

    //This is to show ordererd Items always first when cart modal is shown.
    $('#cart-modal').on('hidden.bs.modal', function (event) {
      $('#cart-carousel').carousel(0);
    });

    //This is to prevent background content scrolling when modal is open..
    $(document).on('shown.bs.modal', function () {
      $('body').addClass('modal-open');
    });

    //This is to resume content scrolling when modal is closed.
    $(document).on('hidden.bs.modal', function () {
      $('body').removeClass('modal-open');
    });

    $('#cart-carousel').on('slide.bs.carousel', function (event) {
      if (event.from == 0 && event.to == 1) {
        if (!this.payBefore) {
          self.showApplePay()
        }
      }
    });
  }

  formatCardNumber(cardNumber) {
    var formattedNumber = cardNumber.replace(/\D/g, ''); // Remove non-numeric characters
    var chunkSize = 4;
    var regex = new RegExp('.{1,' + chunkSize + '}', 'g');
    var formattedChunks = formattedNumber.match(regex);
    if (formattedChunks) {
      return formattedChunks.join(' ');
    }
    return formattedNumber;
  }

  formatExpiry(expiry) {
    expiry = parseInt(expiry[0]) > 1 ? '0' + expiry : expiry = parseInt(expiry.slice(0, 2)) > 12 ? '0' + expiry : expiry;
    var formattedExpiry = expiry.slice(0, 2) + '/' + expiry.slice(2); // Add '/' after first 2 digits
    var slashIndex = formattedExpiry.indexOf('/');
    if (slashIndex !== 2 && slashIndex !== -1) {
      formattedExpiry = formattedExpiry.slice(0, slashIndex) + formattedExpiry.slice(slashIndex + 1);
    } else if (slashIndex === -1 && formattedExpiry.length > 2) {
      formattedExpiry = formattedExpiry.slice(0, 2) + '/' + formattedExpiry.slice(2);
    } else if (slashIndex === 2 && formattedExpiry.length === 3) {
      formattedExpiry = formattedExpiry.slice(0, 2);
    }
    return formattedExpiry.slice(0, 5); // Limit to 'MM/YY' format
  }

  backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // document.body.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
  }

  checkUserLogin() {
    if (localStorage.getItem('isLogedIn') == 'true') {
      return true;
    } else {
      return false;
    }
    // return localStorage.getItem('isLogedIn') === 'true' ? true : false;
  }

  checkDevice() {
    var ua = navigator.userAgent;


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {

      if (ua.toString().includes('Android')) {
        this.userDeivce = 'web-android';
      }
      else if (ua.toString().includes('iPhone') || ua.toString().includes('iPad') || ua.toString().includes('iPod')) {
        this.userDeivce = 'web-apple';
      }
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  onPrepDateChange() {
    this.generatePrepTimes();
    this.prepare_time = this.prep_times[0];
  }

  generatePrepDates() {
    const today = moment();
    for (let i = 0; i < 6; i++) {
      const date = today.clone().add(i, 'days');
      this.prep_dates.push(date.format('ddd, MMM DD'));
    }
  }

  generatePrepTimes() {
    const isToday = moment(this.prepare_date, 'ddd, MMM DD').isSame(moment(), 'day');
    const startTime = isToday ? this.getStartTime() : '09:00 AM';
    const endTime = isToday ? '11:00 PM' : '11:00 PM';

    const start = moment(`2000-01-01 ${startTime}`, 'YYYY-MM-DD hh:mm A');
    const end = moment(`2000-01-01 ${endTime}`, 'YYYY-MM-DD hh:mm A');

    const interval = 15; // in minutes
    let current = start.clone();

    this.prep_times = [];
    while (current <= end) {
      this.prep_times.push(current.format('hh:mm A'));
      current.add(interval, 'minutes');
    }
  }

  getStartTime(): string {
    const now = moment();
    const roundedMinutes = Math.ceil(now.minutes() / 15) * 15;
    now.minutes(roundedMinutes);
    return now.format('hh:mm A');
  }

  scheduleOrder(now: boolean) {
    this.prepare_now = now;
    this.showCart();
  }


  calculateApplicationFee() {

    // If 1 of the menu items contain an application fee then calculate the application fee 
    // by the MenuItem application fee not the restaurant’s application fee.
    let itemPricedFees = false;
    for (let index in this.emp.orderedItems) // this.emp.orderedItems or this.order?
    {
      let itemApplicationFee = this.emp.orderedItems[index].ApplicationFee;
      if (itemApplicationFee !== undefined && itemApplicationFee != null && itemApplicationFee !== '') {
        itemPricedFees = true;
      }
    }

    let topserveFee = 0;
    if (itemPricedFees) {
      for (let index in this.emp.orderedItems) // this.emp.orderedItems or this.order?
      {
        let item = this.emp.orderedItems[index];
        if (item.ApplicationFee != null) {
          topserveFee += item.Price * item.ApplicationFee / 100.00;
        }
      }
    } else {
      topserveFee = (this.subtotal * (this.applicationFee / 100.00));
    }
    
    const topServetax = topserveFee * 0.15;
    
    if (this.isDineIn) {
      this.applicationFee = (parseFloat((topServetax + topserveFee + 0.3).toFixed(2)) * 100);
    }
    else {
      this.applicationFee = (parseFloat((topServetax + topserveFee).toFixed(2)) * 100);

      if (!this.emp.chosenRestaurant.OwnDelivery) {
        this.applicationFee += this.deliveryFee * 100;  //?? *100
      }
  
      if (this.emp.orderInformation.delivery && !this.emp.chosenRestaurant.OwnDelivery) {
        this.applicationFee += this.tip * 100; //?? *100
      }
  
      if (!this.freeDelivery && this.redeemedPromo > 0.00) {
        this.applicationFee = (this.applicationFee - (this.redeemedPromo * 100));
      }
    }
  }

  setupGoogleMaps() {
    var input = <HTMLInputElement>document.getElementById("pac-input");
    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {
      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.address2 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';

      self.emp.orderInformation.address1 = street_number ? street_number + ' ' + route : route;
      self.emp.orderInformation.address2 = street_number ? street_number + ' ' + route : route;
      self.emp.orderInformation.city = town ? town : '';
      self.emp.orderInformation.province = province ? province : '';
      self.emp.orderInformation.country = country ? country : '';
      self.emp.orderInformation.postal = postal_code ? postal_code : '';

      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.emp.orderInformation.lat = mylocation.geometry.location.lat();
      self.emp.orderInformation.long = mylocation.geometry.location.lng();
      self.lat = mylocation.geometry.location.lat();
      self.lng = mylocation.geometry.location.lng();
      localStorage.setItem('orderInformation', JSON.stringify(self.emp.orderInformation));
      self.didSelectLocation();
    })
  }

  async didSelectLocation() {
    this.isLoadingDelivery = true;
    this.emp.orderInformation.isMealService = false;
    this.emp.orderLat = parseFloat(this.lat.toFixed(6));
    this.emp.orderLng = parseFloat(this.lng.toFixed(6));

    this.emp.orderInformation.lat = this.emp.orderLat;
    this.emp.orderInformation.long = this.emp.orderLng
    this.gotDeliveryInfo = true
    await this.getDeliveryFee(this.emp.chosenRestaurant);
    this.zone.run(() => { });



    if (this.emp.orderLat != 0.0 && this.emp.orderLng != 0.0) {
      //   if (this.emp.orderInformation.address1 === undefined) {

      //   alert("Unable to find location for selected address.\nPlease enter and select valid address from dropdown.")
      // } else {
      //  // this.router.navigate(['/order',this.emp.orderInformation.province + ' Food Deliveries']);

      // }
    } else {
      alert("Unable to find location for selected address.\nPlease enter and select valid address from dropdown.")
    }
  }

  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getDeliveryFee(restaurant) {

    // calculates great-circle distances between the two points – 
    // that is, the shortest distance over the earth’s surface – using the ‘Haversine’ formula.
    var lat1 = restaurant.Latitude;
    var lon1 = restaurant.Longitude;
    var lat2 = this.emp.orderInformation.lat;
    var lon2 = this.emp.orderInformation.long;
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var kmTravelled = R * c; // Distance in km

    kmTravelled += 1; // adding one for the driving distance
    this.isLoadingDelivery = false;
    this.emp.chosenRestaurant["TravelDistance"] = kmTravelled;
    this.emp.orderInformation.travelKM = kmTravelled;



    var fee = kmTravelled * restaurant["DeliveryRate"];


    if (kmTravelled > 6.0) {

      alert("Oops! Seems like " + this.emp.chosenRestaurant.Name + " restaurant's delivery is not available on selected address.");
      kmTravelled = 0;
      localStorage.removeItem('orderInformation');
      this.emp.orderInformation.address1 = undefined;
      this.autocompleteInput = '';
      kmTravelled = 0;
    } else if (kmTravelled * restaurant["DeliveryRate"] >= restaurant["DefaultDeliveryFee"]) {
      restaurant["DeliveryFee"] = kmTravelled * restaurant["DeliveryRate"]
      this.emp.chosenRestaurant["DeliveryFee"] = restaurant["DeliveryFee"];
    } else {
      this.emp.chosenRestaurant["DeliveryFee"] = this.originalDelivery;
    }
  }

  setupDelivery() {

    if (this.emp.orderInformation.delivery == undefined) {
      if (localStorage.getItem('delivery') == 'true') {
        this.emp.orderInformation.delivery = true;
      }
    }

    if (this.emp.orderInformation.delivery == undefined) {
      this.orderOptionMessage += 'Takeout';
    } else {
      if (!this.emp.chosenRestaurant.Delivery || !this.emp.chosenRestaurant.Takeout) {
        if (this.emp.orderInformation.delivery) {
          //  this.checked.nativeElement.checked = true;
          this.orderOptionMessage += 'Delivery';
        } else {
          this.orderOptionMessage += 'Takeout';
        }
      } else {

        if (this.emp.orderInformation.delivery) {

          //  this.checked.nativeElement.checked = true;
          this.orderOptionMessage += "Delivery";
        }

      }
    }

    const isLogedIn = localStorage.getItem('isLogedIn');
    if (isLogedIn === 'true') {
      this.firstName = localStorage.getItem('FirstName');
      this.lastName = localStorage.getItem('LastName');
      this.email = localStorage.getItem('Email');
      this.phone = localStorage.getItem('Phone');
    }

    if (this.emp.orderInformation.delivery) {
      this.deliveryFee = this.emp.chosenRestaurant.DeliveryFee;
    } else {
      this.deliveryFee = 0.00
    }

  }

  getOrderTypes() {

    let res = this.emp.chosenRestaurant;
    this.emp.chosenRestaurant["Takeout"] = false;
    this.emp.chosenRestaurant["TakeoutMessage"] = "";
    this.emp.chosenRestaurant["Delivery"] = false;
    this.emp.chosenRestaurant["DeliveryMessage"] = "";
    let orderTypes = res["OrderTypes"].split(",");
    let resDeliveryStart = res["DeliveryStart"];


    let currentHour = new Date().getHours();
    let currentMinute = new Date().getMinutes();
    let resDeliveryStartHours = new Date('1970/01/01 ' + resDeliveryStart).getHours();
    let resDeliveryStartMin = new Date('1970/01/01 ' + resDeliveryStart).getMinutes();
    let hourOfOp = this.tConvert(resDeliveryStart);
    this.emp.chosenRestaurant["IsDelivering"] = false;
    this.emp.chosenRestaurant["Delivery"] = false;
    this.emp.orderInformation.delivery = false

    if (orderTypes.includes("2") && orderTypes.includes("3") && !this.isDineIn) {
      $('#ot-tabs').show();
      $('#orderType').hide();
    }
    else {
      $('#ot-tabs').hide();

      if (this.isDineIn) {
        localStorage.setItem('delivery', 'false');
        $('#orderType').html('Dine In Order');
      }
      else if (orderTypes.includes("2")) {
        $('#orderType').html('Delivery Order');
      }
      else if (orderTypes.includes("3")) {
        localStorage.setItem('delivery', 'false');
        $('#orderType').html('Takeout Order');
      }
    }

    for (let x in orderTypes) {

      if (orderTypes[x] == "2") {
        this.emp.chosenRestaurant["IsDelivering"] = true;
        this.emp.orderInformation.delivery = true
        if (currentHour >= resDeliveryStartHours && this.emp.chosenRestaurant["isOpen"] && !this.emp.chosenRestaurant["isClosed"]) {
          if (currentHour == resDeliveryStartHours) {
            if (currentMinute > resDeliveryStartMin) {
              this.emp.chosenRestaurant["Delivery"] = true;
              this.emp.chosenRestaurant["DeliveryMessage"] = "Delivering";

            } else {
              this.emp.chosenRestaurant["Delivery"] = false;
              this.emp.chosenRestaurant["DeliveryMessage"] = "Delivering at " + hourOfOp;
            }
          } else {
            this.emp.chosenRestaurant["DeliveryMessage"] = "Delivering";
            this.emp.chosenRestaurant["Delivery"] = true;

          }
        } else {

          this.emp.chosenRestaurant["Delivery"] = false;
          this.emp.chosenRestaurant["DeliveryMessage"] = "Delivering at " + hourOfOp
        }
      } else if (orderTypes[x] == "3") {
        this.emp.chosenRestaurant["Takeout"] = true;
        this.emp.chosenRestaurant["TakeoutMessage"] = "Take out";
      }
    }
    this.setupDelivery();
  }

  getHours() {



    let res = this.emp.chosenRestaurant;
    let currentHour = new Date().getHours();
    let currentMinute = new Date().getMinutes();


    //todo big error here.

    let resOpenHours = new Date('1970/01/01 ' + res.Open).getHours();
    let resOpenMins = new Date('1970/01/01 ' + res.Open).getMinutes();

    let resClosedHours = new Date('1970/01/01 ' + res.Close).getHours();
    let resClosedMins = new Date('1970/01/01 ' + res.Close).getMinutes();



    let hourOfOp = this.tConvert(res.Open);
    let hourOfOpClosed = this.tConvert(res.Close);


    var isOpen = false;
    var isClose = false;



    if (currentHour <= resOpenHours) {
      if (currentHour == resOpenHours) {
        if (currentMinute > resOpenMins) {
          isOpen = true
        }
      } else {
        isOpen = false
      }
    } else {
      isOpen = true
    }

    if (currentHour <= resClosedHours || (resClosedHours < 12 && currentHour >= resClosedHours)) {
      if (currentHour == resClosedHours) {
        if (currentMinute >= resClosedMins) {
          isClose = true
        }
      }
    } else {
      isClose = true
    }

    if (resClosedHours === 0 && resOpenHours === 0) {
      isClose = true
      isOpen = false
    }




    if (isClose || !isOpen || res.ResPaused == 1) {
      this.emp.chosenRestaurant["Delivery"] = false;
      this.emp.chosenRestaurant["Takeout"] = false;
      this.emp.chosenRestaurant["isOpen"] = false;
      this.emp.chosenRestaurant["isClosed"] = true;
      //  this.emp.chosenRestaurant["Open"] = false;
      if (isClose) {
        this.emp.chosenRestaurant["isClosed"] = true;
        this.emp.chosenRestaurant["hoursMessage"] = "Closed at " + hourOfOpClosed;
      } else if (!isOpen) {
        this.emp.chosenRestaurant["isOpen"] = false;
        this.emp.chosenRestaurant["hoursMessage"] = "Opens at " + hourOfOp;
      } else if (res.ResPaused == 1) {
        this.emp.chosenRestaurant["isOpen"] = false;
        this.emp.chosenRestaurant["isClosed"] = true;
        this.emp.chosenRestaurant["hoursMessage"] = "Restaurant currently on pause by staff";
      }
    } else {
      // let rez = this.restaurants[index];
      // this.restaurants.splice(parseInt(index), 1);
      // this.restaurants.push(rez);

      //    this.emp.chosenRestaurant["Open"] = true;
      this.emp.chosenRestaurant["isOpen"] = true;
      this.emp.chosenRestaurant["isClosed"] = false;
      this.emp.chosenRestaurant["hoursMessage"] = "Open Now";
    }

  }


  async getRestaurantByLink() {

    return new Promise(resolve => {

      const post = {
        RestaurantLinkName: this.restaurantNameLink
      };
      this.menuService.GetRestaurantByLinkName(post).subscribe(async data => {

        if (data["Success"]) {
          if (data["Restaurants"].length == 0) {
            this.router.navigateByUrl('/');
            return;
          }
          var chosenRestaurant = data["Restaurants"][0];

          if (chosenRestaurant.Type == 'sellerstand') {
            this.emp.chosenRestaurant = chosenRestaurant;
            $('#farmLayout').show();
            const x = this.resolver.resolveComponentFactory(FarmOrderComponent);
            var t = this.farmOrderContainer.createComponent(x);
          }
          else {

            localStorage.setItem('restaurantName', chosenRestaurant['Name'])
            chosenRestaurant['DefaultDeliveryFee'] = data["Restaurants"][0]['DeliveryFee'];
            this.originalDelivery = chosenRestaurant["DeliveryFee"];
            this.title.setTitle(chosenRestaurant['Name'] + ' | TopServe Take Out, Food Delivery & Dine In');
            this.meta.updateTag({ name: 'description', content: chosenRestaurant['MetaDescription'] });
            this.meta.addTag({ name: 'robots', content: 'INDEX, FOLLOW' }, true);
            this.meta.updateTag({ name: 'twitter:description', content: 'Order food delivery and take out online in charlottetown prince edward island through topserve. Order food online now!' });
            this.meta.addTag({ name: 'author', content: 'TopServe' }, true);
            this.meta.updateTag({ name: 'keywords', content: chosenRestaurant['Name'] + ', TopServe, Deliveries, PEI, Nova Scotia, New Brunswick, Moncton Deliveries, Fredericton Deliveries , For Restaurants' });
            this.meta.addTag({ httpEquiv: 'Content-Type', content: 'text/html' }, true);
            this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
            this.meta.updateTag({ property: 'og:title', content: chosenRestaurant['Name'] + ' | TopServe Take Out, Food Delivery & Dine In' });
            this.meta.addTag({ property: 'og:type', content: "website" });
            this.meta.addTag({ charset: 'UTF-8' });

            if (this.emp.orderLat != 0 && this.emp.orderLng != 0) {
              this.getDeliveryFee(chosenRestaurant)
              this.gotDeliveryInfo = true
            }

            this.emp.chosenRestaurant = chosenRestaurant;

            if (this.emp.chosenRestaurant.CustomFieldEnabled == 1 && localStorage.getItem('customField') !== null) {
              this.customField = localStorage.getItem('customField');
            }

            for (let i in this.emp.chosenRestaurant.OrderTypes.split(',')) {
              let orderTypeID = this.emp.chosenRestaurant.OrderTypes.split(',')[i];
              if (orderTypeID == '1') { //For Dine in order type id == 1
                if (this.emp.chosenRestaurant.OrderTypePayBefore.split(",")[i] == '2' && this.isDineIn) {
                  this.payBefore = true;
                }
              }
            }

            this.storeTipPercentage = this.emp.chosenRestaurant.TipPercentage != null ? this.emp.chosenRestaurant.TipPercentage.split(',') : [15, 20, 25];
            this.selectedTipPercentage = this.storeTipPercentage[0];

            this.getHours();
            this.getDisplayImages();
            this.getOrderTypes();

            $('#storeLayout').show();
          }
        }
        resolve(true)
      });
    });
  };

  async chargeWalletPayment(ev: any) {

    this.calculateApplicationFee();

    let email = '';
    if (localStorage.getItem('guestAccount') == 'true') {
      email = this.receiptEmail;
    } else {
      email = localStorage.getItem('Email');
    }

    const post = {
      customer_id: localStorage.getItem('StripeID'),
      amount: parseInt((this.total * 100).toString(), 0).toString(),
      currency: 'CAD',
      receipt_email: email,
      stripeBusinessID: this.emp.chosenRestaurant.StripeBusinessID,
      applicationFee: parseInt((this.applicationFee).toString(), 0).toString(),
      orderID: this.orderID
    };

    let self = this;
    this.paymentService.createPaymentIntent(post).subscribe(async data => {
      if (data['Success']) {
        (await this.stripePromise).confirmCardPayment(
          data['ClientSecret'],
          {payment_method: ev.paymentMethod.id},
          {handleActions: false}
        ).then(async function (result) {
          if (result.error) {
            ev.complete('fail');
            self.walletPay = false;
            console.log(JSON.stringify(result.error))
            alert('something went wrong while paying for order, you were not charged. Please try again later');
            $('#progress-modal').modal('hide');
          }
          else {
            if (result.paymentIntent.status === "requires_action") {

              console.log("Here in payment requires action");

              (await this.stripePromise).confirmCardPayment(data['ClientSecret'])
              .then(async function (result) {
                if (result.error) {
                  ev.complete('fail');
                  self.walletPay = false;
                  console.log(JSON.stringify(result.error))
                  alert('The payment method in your wallet is not supported. Please enter a different payment method.');
                  $('#progress-modal').modal('hide');
                } else {
                  ev.complete('success');
                  self.walletPay = false;
                  self.processPayment();
                  self.emp.orderedItems = [];
                }
              })
            }
            else {
              console.log('Here in payment complete by apple pay.')
              console.log("Payment Intent Status: " + result.paymentIntent.status)
              ev.complete('success');
              self.walletPay = false;
              self.processPayment();
              self.emp.orderedItems = [];
            }
          }
        })
      }
      else {
        self.walletPay = false;
        alert('something went wrong while paying for order, you were not charged. Please try again later');
        $('#progress-modal').modal('hide');
        ev.complete('fail');
      }
    })

  }

  async chargeCustomer(payment_method_id) {

    this.calculateApplicationFee();

    let email = '';
    if (localStorage.getItem('guestAccount') == 'true') {
      email = this.receiptEmail;
    } else {
      email = localStorage.getItem('Email');
    }

    const post = {
      customer_id: localStorage.getItem('StripeID'),
      amount: parseInt((this.total * 100).toString(), 0).toString(),
      currency: 'CAD',
      receipt_email: email,
      stripeBusinessID: this.emp.chosenRestaurant.StripeBusinessID,
      applicationFee: parseInt((this.applicationFee).toString(), 0).toString(),
      orderID: this.orderID
    };


    let self = this;
    this.paymentService.createPaymentIntent(post).subscribe(async data => {
      if (data['Success']) {
        (await this.stripePromise).confirmCardPayment(data['ClientSecret'], {
          payment_method: payment_method_id,
        })
          .then(function (result) {
            // Handle result.error or result.paymentIntent
            if (result.error) {
              console.log(result.error)
              alert('something went wrong while paying for order, you were not charged. Please try again later');
              $('#progress-modal').modal('hide');
            }
            else {
              self.processPayment();
              self.emp.orderedItems = [];
            }

          });
      } else {
        alert('something went wrong while paying for order, you were not charged. Please try again later');
        $('#progress-modal').modal('hide');
      }
    })
  }

  processPayment() {

    const post = {
      OrderID: this.orderID,
      Token: localStorage.getItem('AuthenticationToken'),
      TimeZone: "America/Halifax",
      RestaurantID: this.emp.chosenRestaurant.RestaurantID,
      isDineIn: 1
    };

    this.paymentService.PayForOrder(post).subscribe(async data => {
      if (data["Success"]) {
        this.emp.completedOrder = this.orderID;
        if (this.order_frame) {
          this.router.navigateByUrl('/order-track/' + this.orderID + '?user=' + localStorage.getItem('UserID'))
        }
        else {
          this.router.navigateByUrl('/user-profile')
        }
      }
    }, err => {
      alert("there was an error with the payment. please try again later.")
    })
  }

  validatePlacingOrder() {

    if (this.firstName == '') {
      return true;
    }
    if (this.guestAcnt && !this.payBefore && this.receiptEmail == '') {
      return true;
    }
    if (!this.isDineIn && this.phone == '') {
      return true;
    }
    if (!this.payBefore && !this.hasCard) {
      return true;
    }
    if (this.emp.chosenRestaurant.CustomFieldEnabled == 1 && this.customField == '') {
      return true;
    }
    return false;
  }

  addOrder(ev) {

    $('#cart-modal').modal('hide');

    if (this.isDineIn) {
      this.emp.chosenRestaurant.Phone = '555-555-5555'
    }

    var RestaurantInformation = {
      restaurantContact: this.emp.chosenRestaurant.ContactName,
      restaurantName: this.emp.chosenRestaurant.Name,
      restaurantAddress: this.emp.chosenRestaurant.Address1,
      restaurantPhone: this.emp.chosenRestaurant.Phone
    };

    var orderType = "";
    if (this.emp.orderInformation.delivery) {

      orderType = "Delivery";
      if (this.subtotal < 10) {
        alert("Subtotal minimum $10.00 for deliveries.")
        return
      }
    } else {
      orderType = "Takeout"
    }

    if (this.isDineIn) {
      orderType = "Dine-In"
    }

    var orderTypeID = 0
    var percentageOfficial = 0

    var orderTypes = this.emp.chosenRestaurant.OrderTypes.split(",");

    var orderTypesID = this.emp.chosenRestaurant.OrderTypesID.split(",");

    var orderTypesPercentages = this.emp.chosenRestaurant.OrderTypesPercentage.split(",");



    for (let x in orderTypes) {
      if (orderTypes[x] == "3" && !this.emp.orderInformation.delivery && !this.isDineIn) {
        orderTypeID = orderTypesID[x];
        percentageOfficial = orderTypesPercentages[x]
      } else if (orderTypes[x] == "2" && this.emp.orderInformation.delivery) {
        orderTypeID = orderTypesID[x];
        percentageOfficial = orderTypesPercentages[x]
      } else if (orderTypes[x] == "1" && this.isDineIn) {
        orderTypeID = orderTypesID[x];
        percentageOfficial = orderTypesPercentages[x]
      }
    }


    if (this.apartment !== '' && this.apartment != null && this.apartment != 'NA') {
      this.apartment = ' apt: ' + this.apartment;
    }

    let comments = '';
    if (this.emp.chosenRestaurant.CustomFieldEnabled == 1) {
      localStorage.setItem('customField', this.customField);
      comments = this.emp.chosenRestaurant.CustomFieldName + ': ' + this.customField + ' ' + this.additionalComments;
    }
    else {
      comments = this.additionalComments;
    }

    const parsedDate = moment(this.prepare_date + ' ' + this.prepare_time, 'ddd, MMM DD hh:mm A');
    const prepDateTime = parsedDate.format('YYYY-MM-DD HH:mm:ss');
    console.log(prepDateTime);

    var OrderDeliveryInformation = {
      Tip: this.tip,
      DeliveryAddress2: this.emp.orderInformation.address1 + this.apartment,
      Subtotal: this.subtotal,
      OrderTypeName: orderType,
      DeliveryFee: this.deliveryFee.toFixed(2),
      AdditionalComments: comments,
      OrderType: orderType,
      DeliveryCity: this.emp.orderInformation.city,
      OrderTypeID: orderTypeID,
      DeliveryAddress1: this.emp.orderInformation.address1 + this.apartment,
      UserID: localStorage.getItem('UserID'),
      ApplicationFee: percentageOfficial,
      TableNumber: this.tableNumber.toString(),
      DeliveryPostal: this.emp.orderInformation.postal,
      RestaurantID: this.emp.chosenRestaurant.RestaurantID,
      UserPayed: 0,
      Total: this.total.toFixed(2),
      firstName: this.firstName,//localStorage.getItem('firstName'),// 
      lastName: this.lastName,//localStorage.getItem('lastName'),
      Tax: this.tax.toFixed(2),
      DeliveryKM: this.emp.orderInformation.travelKM,
      DeliveryCountry: this.emp.orderInformation.country,
      Phone: this.phone,
      Discount: this.redeemedPromo,
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeID: localStorage.getItem('StripeID'),
      OrderStatus: "1",
      Device: this.userDeivce,
    };

    if (!this.prepare_now) {
      OrderDeliveryInformation['PrepareDate'] = prepDateTime;
    }

    if (this.emp.orderInformation.delivery) {
      OrderDeliveryInformation['DeliveryLatitude'] = this.emp.orderInformation.lat;
      OrderDeliveryInformation['DeliveryLongitude'] = this.emp.orderInformation.long;
    }


    let OrderInformation = [];

    this.order.filter(item => {

      let menuItemOptionsPrice = [];
      let menuItemOptionsIds = [];
      let menuItemOptionsName = [];
      let menuItemBundleParentId = [];
      let menuItemOptionsQuantity = [];
      item.Sides.filter(side => {
        if (side.TotalSelectedOptions > 0) {
          side.Options.filter(option => {
            if (option.selected) {
              menuItemOptionsIds.push(option.MenuItemOptionID);
              menuItemOptionsName.push(option.Name);
              menuItemOptionsPrice.push(this.roundNumber(option.Price));
              menuItemOptionsQuantity.push(option.quantity);
              menuItemBundleParentId.push(null);

              option.Sides.filter(bundle_side => {
                if (bundle_side.TotalSelectedOptions > 0) {
                  bundle_side.Options.filter(bundle_option => {
                    if (bundle_option.selected) {
                      menuItemOptionsIds.push(bundle_option.MenuItemOptionID);
                      menuItemOptionsName.push(bundle_option.Name);
                      menuItemOptionsPrice.push(this.roundNumber(bundle_option.Price));
                      menuItemOptionsQuantity.push(bundle_option.quantity);
                      menuItemBundleParentId.push(option.MenuItemOptionID);
                    }
                  });
                }
              });
            }
          });
        }
      });

      let order_info = {
        additionalRequest: item.SpecialRequest,
        menuItemId: item.MenuItemID,
        menuItemName: item.Name,
        menuItemPrice: item.Price,
        menuItemQuantity: item.quantity,
        menuItemOptionsIds: menuItemOptionsIds,
        menuItemOptionsName: menuItemOptionsName,
        menuItemOptionsPrice: menuItemOptionsPrice,
        menuItemBundleParentId: menuItemBundleParentId,
        menuItemOptionsQuantity: menuItemOptionsQuantity
      }

      OrderInformation.push(order_info);
    });

    let post = {
      RestaurantInformation: RestaurantInformation,
      OrderDeliveryInformation: OrderDeliveryInformation,
      OrderInformation: OrderInformation
    };


    this.phone = this.phone.toString().replace(/\D/g, '');

    // if there is no apartment number, set as "not set"
    if (this.apartment == '' || this.apartment == null || this.apartment == 'NA') {
      this.apartment = 'not set';
    }


    // update info
    const infopost = {
      UserID: localStorage.getItem('UserID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserAddress: this.emp.orderInformation.address1,
      Phone: this.phone,
      Email: this.email,
      FirstName: this.firstName,
      LastName: this.lastName,
      PostalCode: this.emp.orderInformation.postal,
      City: this.emp.orderInformation.city,
      ApartmentNumber: this.apartment,
      Country: this.emp.orderInformation.country,
      Lat: this.emp.orderInformation.lat,
      Long: this.emp.orderInformation.long,
    };

    $('#progress-modal').modal('show');

    this.profileService.updateUserInformationV3(infopost).subscribe(data => {
      if (data["Success"]) {
        this.paymentService.AddOrder(post).subscribe(data => {
          if (data["orderAdded"]) {
            if (!this.emp.orderInformation.delivery) {
              data["applicationFee"] = data["applicationFee"]
            }
            this.orderID = data["orderID"];
            this.applicationFee = data["applicationFee"];

            if (this.isDineIn && this.payBefore) {
              this.emp.completedOrder = this.orderID;
              $('#progress-modal').modal('hide');
              this.emp.orderedItems = [];
              this.router.navigateByUrl('/user-profile')

            } else {
              if (this.walletPay) {
                this.chargeWalletPayment(ev)
              }
              else {
                this.handlePayment()
              }
            }

          } else {
            alert("there was an error adding the order information. please try again later.")
            $('#progress-modal').modal('hide');
          }
        }, err => {
          alert("there was an error adding the order. please try again later.")
          $('#progress-modal').modal('hide');
        });
      } else {
        $('#progress-modal').modal('hide');
        alert("error adding the order. please try again later.")
      }
    }, err => {
      alert("there was an error with the user information. please try again later.")
      $('#progress-modal').modal('hide');
    });
  }

  isServing(startTime, endTime) {
    if (startTime == null || endTime == null) {
      return true
    } else {
      let startHour = new Date('1970/01/01 ' + startTime).getHours();
      let startMin = new Date('1970/01/01 ' + startTime).getMinutes();

      let endHour = new Date('1970/01/01 ' + endTime).getHours();
      let endMin = new Date('1970/01/01 ' + endTime).getMinutes();

      let currentHour = new Date().getHours();
      let currentMinute = new Date().getMinutes();


      if (currentHour >= startHour && currentHour <= endHour) {
        if (startHour === currentHour || endHour === currentHour) {
          if (startHour === currentHour) {
            if (currentMinute < startMin) {
              return false
            } else {
              return true
            }
          } else {
            if (endMin > currentMinute) {

              return false
            } else {
              return true
            }
          }
        } else {
          return true
        }
      } else {

        return false
      }
    }
  }

  async getRestaurantMenu() {
    return new Promise(resolve => {

      const post = {
        RestaurantID: this.emp.chosenRestaurant.RestaurantID
      };

      this.menuService.GetRestaurantMenu(post).subscribe(async data => {
        this.allMenuCategories = data['Menu'];
        resolve(true);
      }, err => {
        console.log(err);
        alert("There was an error getting the menu. please try again later.");
        resolve(true);
      });
    });
  }

  async removeItem(index) {

    if (this.emp.orderedItems[index].quantity > 1) {

      var deductprice = this.emp.orderedItems[index].Price / this.emp.orderedItems[index].quantity;
      this.emp.orderedItems[index].quantity -= 1;
      this.emp.orderedItems[index].Price -= deductprice;
      this.emp.orderedItems[index].Price = this.roundNumber(this.emp.orderedItems[index].Price);

    }
    else {
      this.emp.orderedItems.splice(index, 1);
    }

    if (this.order.length == 0) {
      $('#cart-modal').modal('hide');
      $('#sheen').removeClass('sheen');
    }

    this.itemsInCart -= 1;
    this.calculateCart()
  }

  MinusQuantity(item) {

    if (item["quantity"] != 1) {
      item["quantity"] -= 1;
    }

  }

  AddQuantity(item) {
    item["quantity"] += 1;
  }

  didAddItemQuantity(index) {
    this.mainMenu[index]["quantity"] += 1;
  }

  didMinusItemQuantity(index) {
    if (this.mainMenu[index]["quantity"] <= 0) {
      return
    }
    else {
      this.mainMenu[index]["quantity"] -= 1;
    }
  }


  showCart() {

    if (this.order.length > 0) {

      if (this.emp.orderInformation.delivery == undefined || this.isDineIn || this.emp.orderInformation.delivery == false) {
        $("#dfee").hide()

        $('#cart-modal').modal('show');

      }
      else if (this.gotDeliveryInfo && this.emp.orderInformation.delivery && this.emp.orderInformation.address1 != undefined) {
        if (this.emp.chosenRestaurant.DeliveryFee < 30) {
          $('#cart-modal').modal('show');
        }
        else {
          alert("Oops! Seems like " + this.emp.chosenRestaurant.Name + " restaurant's delivery is not available on selected address.");
        }
      } else {
        alert("Add a valid delivery address");
      }

    }

  }

  closePromo() {
    $('#promoModal').modal('hide');
    $('#cart-modal').modal('show');
  }

  addPromo() {
    //check promo


    let deliveryType = ''
    if (this.emp.orderInformation.delivery) {
      deliveryType = 'delivery'
    } else {
      deliveryType = 'takeout'
    }


    const post = {
      Subtotal: this.subtotal,
      OrderType: deliveryType,
      DiscountCode: this.promoCode
    };


    this.paymentService.applyPromo(post).subscribe(async data => {
      if (data['Success'] && !this.appliedPromo) {
        this.appliedPromo = true;
        if (data['FreeDelivery']) {
          this.freeDelivery = true;
          this.redeemedPromo = this.deliveryFee
          this.deliveryFee = 0;
          //this.total =  this.total - 2.50
          $('#promoModal').modal('hide');
          $('#cart-modal').modal('show');
        } else if (data['Percent'] > 0.00) {

          this.redeemedPromo = this.subtotal * parseFloat(data['Percent'])
          this.total = this.total - this.redeemedPromo;
          $('#promoModal').modal('hide');
          $('#cart-modal').modal('show');
        } else if (data['Amount'] > 0.00) {
          if (data['OrderType'] == 'delivery' && this.emp.chosenRestaurant.Delivery) {
            this.freeDelivery = true;
            this.redeemedPromo = data['Amount'];
            this.deliveryFee = this.deliveryFee - data['Amount'];
          } else {
            this.redeemedPromo = data['Amount'];
            this.total = this.total - parseFloat(data['Amount']);
          }

          $('#promoModal').modal('hide');
          $('#cartmoal').modal('show');

        } else {
          alert(data['Message'])
          $('#promoModal').modal('hide');
          $('#cart-modal').modal('show');
        }

        this.calculateCart()
      } else {

        if (this.appliedPromo) {
          data['Message'] = "You have already applied a promo.";
        }

        if (data['Message']) {
          alert(data['Message'])
          $('#promoModal').modal('hide');
          $('#cart-modal').modal('show');
        } else {
          alert('there was an error with the promo code. please try again later.')
          $('#promoModal').modal('hide');
          $('#cart-modal').modal('show');
        }

      }

    }, err => {
      alert('there was an error with the promo code. please try again later.')
      $('#tipModal').modal('hide');
      $('#cart-modal').modal('show');
    })
  }

  showCartModal() {
    $('#cart-modal').modal('show');
  }

  saveCustomTip() {
    this.customTip = true;
    this.calculateTip();
    $('#tipModal').modal('hide');
    $('#cart-modal').modal('show');
  }

  toggleTip() {
    $('#cart-modal').modal('hide');
  }
  togglePromo() {
    $('#cart-modal').modal('hide');
  }

  calculateItemPrice(item) {
    let price = 0;
    price += item.Price * item.quantity;

    for (let orderOptionIndex in item.options) {
      for (let sidesIndex in item.options[orderOptionIndex].sides) {
        if (item.options[orderOptionIndex].sides[sidesIndex].selected) {
          price += item.options[orderOptionIndex].sides[sidesIndex].Price * (item.options[orderOptionIndex].sides[sidesIndex].quantity) * item.quantity;
        }
      }
    }

    this.totPrice = this.roundNumber(price);
    return this.roundNumber(price)
  }

  calculateSubTotal() {

    this.subtotal = 0;
    this.ageRestrictionItems = false;
    this.order.filter(item => {
      if (item.AgeRestriction == 1) {
        this.ageRestrictionItems = true;
      }
      this.subtotal += item.TotalPrice;
    });

    return this.roundNumber(this.subtotal)
  }

  calculateTax() {

    this.tax = 0
    this.order.filter(item => {

      this.tax += item.TotalTax;
    });

    return this.roundNumber(this.tax);
  }

  calculateDelivery() {

    if (this.freeDelivery || !this.emp.orderInformation.delivery) {

      this.deliveryFee = 0.00
    } else {

      let ageRestrictionItems = this.emp.orderedItems.filter(item => item["AgeRestriction"] == 1);
      if (ageRestrictionItems.length > 0) // contains AgeRestriction items
      {
        this.deliveryFee = this.emp.chosenRestaurant.DeliveryFee + 3.49;
      } else {
        this.deliveryFee = this.emp.chosenRestaurant.DeliveryFee;
      }
    }

    return this.roundNumber(this.deliveryFee)
  }

  changeTipPercentage(percentage) {
    this.selectedTipPercentage = percentage;
    this.customTip = false;
    this.calculateTip()
  }

  calculateTip() {

    if (this.payBefore) {
      this.tip = 0.00;
    }
    else if (this.customTip) {
      this.tip = parseFloat(this.roundNumber(this.customTipAmount));
    }
    else {
      this.tip = parseFloat(this.roundNumber((this.subtotal * this.selectedTipPercentage) / 100));
    }
  }
  calculateTotal() {

    if (this.redeemedPromo > 0.00) {
      if (this.freeDelivery) {
        this.total = this.subtotal + this.tax + this.tip + this.deliveryFee;
      } else {
        this.total = this.subtotal + this.tax + this.tip + this.deliveryFee - this.redeemedPromo;
      }
    } else {
      this.total = this.subtotal + this.tax + this.tip + this.deliveryFee;
    }
    return this.roundNumber(this.total)
  }

  checkout() {
    const isLogedIn = localStorage.getItem('isLogedIn');
    if (!(this.emp.isNormalUser && isLogedIn == 'true')) {
      $('#cart-modal').modal('hide');
      if (this.order_frame) {
        $('#guest-modal').modal('show');
      }
      else {
        $('#login-modal').modal('show');
      }
      return;
    }

    $('#cart-carousel').carousel(1);
  }

  async showApplePay() {

    const paymentRequest = (await this.stripePromise).paymentRequest({
      country: 'CA',
      currency: 'cad',
      total: {
        label: 'Total',
        amount: parseInt((this.total * 100).toString(), 0),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const elements = (await this.stripePromise).elements();
    const prButton = elements?.create('paymentRequestButton', {
      paymentRequest,
    });

    const paymentRequestSupport = await paymentRequest.canMakePayment();

    if (paymentRequestSupport) {
      prButton?.mount('#payment-request-button');
    } else {
      document.getElementById('payment-request-button').style.display = 'none';
      console.error('Apple Pay is not available on this browser/device.');
    }

    paymentRequest.on('paymentmethod', async (ev) => {
      console.log('Here in wallet payment request')
      this.walletPay = true;
      this.addOrder(ev)
    });
  }

  calculateCart() {

    this.calculateSubTotal();
    this.calculateTax();
    this.calculateTip();
    if (this.calculateSubTotal() !== '0.00' && this.orderOptionMessage != "Take out") {
      this.calculateDelivery();
    }
    this.calculateTotal();
  }

  roundNumber(num) {
    var number = ((Math.round(num * 100) / 100));
    return number.toFixed(2);
  }

  ngOnDestroy(): void {
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value

      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[3] = '';

    }

    return time.join(''); // return adjusted time or original string
  }

  createGuest() {
    this.loading = true;
    this.registerErrorMsg = '';
    var randomnumber = new Date().getTime();
    this.token = this.makeRandom()
    this.guestEmail = "guestweb" + randomnumber + "@email.com";
    this.guestpswd = this.guestRandom()

    const post = {
      Password: this.guestpswd,
      Email: this.guestEmail,
      Phone: this.phone,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: "guest.png",
      UserType: 0,
      AuthenticationToken: this.token,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {

      if (data["success"]) {

        post['StripeCustomerID'] = data['customerId'];

        this.registerService.register(post).subscribe(data => {

          if (data['userAdded']) {
            localStorage.setItem('guestAccount', 'true');
            localStorage.setItem('GuestEmail', this.receiptEmail);
            this.guestAcnt = true;
            this.login(true)
          } else {
            this.registerErrorMsg = data["Message"];
            this.loading = false;
          }
        })
      } else {
        this.registerErrorMsg = data["Message"];
        this.loading = false;
      }

    }, err => {
      alert("there was an error creating your account. please try again later.")
    });

  }

  login(isRegistering) {

    this.loginErrorMsg = '';

    const post = {
      email: this.guestAcnt ? this.guestEmail : this.username,
      password: this.guestAcnt ? this.guestpswd : this.password,
      Token: this.makeRandom()
    };

    this.loginService.loginNormal(post).subscribe(
      data => {

        if (data['verified']) {
          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'user');
          this.emp.loggedInUser = data['User'][0];
          this.emp.isNormalUser = true;
          this.emp.loggedInUserType = 0;

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('FirstName', data['User'][0].FirstName);
          this.firstName = data['User'][0].FirstName;
          localStorage.setItem('LastName', data['User'][0].LastName);
          this.lastName = data['User'][0].LastName;

          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('StripeID', data['User'][0].StripeCustomerID);
          localStorage.setItem('Email', data['User'][0].Email);
          this.email = data['User'][0].Email
          localStorage.setItem('lat', data['User'][0].Latitude);
          localStorage.setItem('long', data['User'][0].Longitude);
          localStorage.setItem('Address', data['User'][0].UserAddress);
          localStorage.setItem('Postal', data['User'][0].PostalCode);
          localStorage.setItem('Phone', data['User'][0].Phone);
          this.phone = data['User'][0].Phone;
          localStorage.setItem('Country', data['User'][0].Country);
          localStorage.setItem('City', data['User'][0].City);
          this.getCards();

          $('#login-modal').modal('hide');
          if (this.order.length > 0) {
            $('#cart-modal').modal('show');
          }


        } else {

          if (!isRegistering) {
            this.loginErrorMsg = data['Message'];
          }
        }
      }
    );

  }

  register() {

    this.loading = true;
    this.registerErrorMsg = '';

    this.token = this.makeRandom()

    const post = {
      Password: this.registerConfirmPassword,
      Email: this.email,
      Phone: this.phone,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: "guest.png",
      UserType: 0,
      AuthenticationToken: this.token,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {

      if (data["success"]) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data => {

          if (data['userAdded']) {

            this.username = this.email;
            this.password = this.registerConfirmPassword;
            this.login(true)
          } else {
            this.registerErrorMsg = data["Message"];
            this.loading = false;
          }
        })
      } else {
        this.registerErrorMsg = data["Message"];
        this.loading = false;


      }

    }, err => {
      alert("there was an error creating your account. please try again later.")
    });

  }

  async getDisplayImages() {

    var self = this;

    const AWSService = AWS;
    const region = 'ca-central-1';


    let bucketName = ""
    if (this.urlObject.getDev() == "dev") {
      bucketName = 'topserve-restaurant-main-images-display-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-main-images-display-prod-canada';

    }

    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
      region: region
    });
    // adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });

    await s3.getObject({ Key: this.emp.chosenRestaurant["ImageURL"] + 'display' }, async function (err, file) {

      if (!err) {
        let blob = new Blob([file.Body], { type: "image/jpeg" });
        //jss1258
        var div0 = document.getElementById('store-display-img');


        self.emp.chosenRestaurant["displayImage"] = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        div0.style.backgroundImage = "url(" + URL.createObjectURL(blob) + ")";
      } else {

        self.emp.chosenRestaurant["displayImage"] = null;
      }
    }, 1);
  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  guestRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 12; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  handlePayment() {

    if (this.hasCard && this.isCardChecked) {
      this.chargeCustomer(localStorage.getItem('Payment_Method_ID'));
    } else {
      this.addNewCard();
    }

  }

  async addNewCard() {
    $('#cart-modal').modal('hide');
    $('#payment-method-modal').modal('show');
  }

  manageDeliveryTakeoutDineIn() {

    if (window.location.href.includes('&farm=true')) {
      this.restaurantNameLink = window.location.href.split('/').pop()
      this.restaurantNameLink = this.restaurantNameLink.split("?");
      this.restaurantNameLink = this.restaurantNameLink[0];
      return;
    }

    if (window.location.href.includes('frame=true')) {
      this.order_frame = true;
    }

    let linkArray = window.location.href.split('/');

    if (linkArray[5] == 'table') {
      this.restaurantNameLink = linkArray[4];
      this.tableNumber = linkArray[6];
      this.isDineIn = true;
      this.deliveryFee = 0.00;
      this.tip = 3.00;
      this.emp.orderInformation.delivery = false;
      $("#pac-input").hide()
      $("#dfee").hide()
      $("#dfee2").hide()
      $("#dfee3").hide()
      
      localStorage.setItem('delivery', 'false');
      localStorage.setItem('tableOrder', window.location.href);
      localStorage.setItem('tableNumber', this.tableNumber.toString());
    }
    else {
      console.log('Line 1', localStorage.getItem('delivery'));

      if (localStorage.getItem('delivery') == 'false') {
        $("#takeout").prop("checked", true);
      }
      else {
        this.deliveryFee = this.emp.chosenRestaurant.DeliveryFee;
        this.emp.orderInformation.delivery = true;
        localStorage.setItem('delivery', 'true');

        if (this.emp.orderLat == 0 || this.emp.orderLng == 0) {
          var orderInformation = JSON.parse(localStorage.getItem('orderInformation'));
          if (orderInformation != null) {
            orderInformation.travelKM = undefined;
            orderInformation.isMealService = undefined;
            this.emp.orderInformation = orderInformation;
            this.emp.orderLat = parseFloat(orderInformation.lat.toFixed(6));
            this.emp.orderLng = parseFloat(orderInformation.long.toFixed(6));
          }
          else {
            localStorage.removeItem('orderInformation');
          }
        }
      }

      localStorage.removeItem('tableNumber');
      localStorage.removeItem('tableOrder');

      this.restaurantNameLink = window.location.href.split('/').pop()
      this.restaurantNameLink = this.restaurantNameLink.split("?");
      this.restaurantNameLink = this.restaurantNameLink[0];

    }
  }

  getCards() {

    this.userCards = [];

    const stripeID = localStorage.getItem('StripeID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const post = {
      customer_id: stripeID
    };

    this.loginService.getPaymentMethods(post).subscribe(
      data => {

        if (data['success']) {
          let cardData = data['response']['data'];

          if (cardData.length > 0) {

            this.hasCard = true

            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {

                card['selected'] = true;
                this.isCardChecked = true;
                localStorage.setItem('Payment_Method_ID', card['id'].toString());
                localStorage.setItem('Exp_Year', card['exp_year'].toString());
                localStorage.setItem('Exp_Month', card['exp_month'].toString());
                localStorage.setItem('Last4', card['last4'].toString());
                localStorage.setItem('Brand', card['brand'].toString());

                this.last4 = localStorage.getItem('Last4');
                this.exp = localStorage.getItem('Exp_Month') + '/' + localStorage.getItem('Exp_Year');
                this.brand = localStorage.getItem('Brand');
              }
              this.userCards.push(card);
            }
          }
          else {
            this.hasCard = false;
            this.isCardChecked = false;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });


  }

  toggleCard() {
    this.userCards.filter(i => i.selected = false);
    this.isCardChecked = false;
    this.handlePayment();
  }

  changeDefaultCard(i: number) {

    const post = {
      customer_id: localStorage.getItem('StripeID'),
      payment_method_id: this.userCards[i]['id']
    }
    this.paymentService.updateDefaultPaymentMethod(post).subscribe(async data => {
      if (data['success']) {
        this.userCards.filter(i => i.selected = false);
        this.userCards[i]['selected'] = true;
        this.isCardChecked = true;
        localStorage.setItem('Payment_Method_ID', this.userCards[i]['id'].toString());
        localStorage.setItem('Exp_Year', this.userCards[i]['exp_year'].toString());
        localStorage.setItem('Exp_Month', this.userCards[i]['exp_month'].toString());
        localStorage.setItem('Last4', this.userCards[i]['last4'].toString());
        localStorage.setItem('Brand', this.userCards[i]['brand'].toString());

        this.last4 = localStorage.getItem('Last4');
        this.exp = localStorage.getItem('Exp_Month') + '/' + localStorage.getItem('Exp_Year');
        this.brand = localStorage.getItem('Brand');
      }
      else {
        this.emp.orderedItems = [];
        alert('Something went wrong, please try again later!!');
        this.router.navigateByUrl('/logout');
      }
    });
  }

  /*--------- VJ New Code Starts For New Restaurant Menu Call -------------*/


  scrollToCategoryItems(category) {
    document.getElementById(category.Name).scrollIntoView({ behavior: 'smooth' });
  }

  showSelectedItemModal(menuItems) {
    this.selectedMenuItem = JSON.parse(JSON.stringify(menuItems));
    this.selectedMenuItem['quantity'] = 1;
    this.selectedMenuItem['SpecialRequest'] = '';
    this.selectedMenuItem['TotalPrice'] = this.roundNumber(this.selectedMenuItem['Price']);
    this.selectedMenuItem['TotalTax'] = 0.00
    this.selectedMenuItem.Sides.map(side => {
      side['TotalSelectedOptions'] = 0;
      side.Options.map(option => {
        option['selected'] = false;
        option['quantity'] = 0;

        if (option.Sides.length > 0) {
          option.Sides.map(bundle_side => {
            bundle_side['TotalSelectedOptions'] = 0;
            bundle_side.Options.map(bundle_option => {
              bundle_option['selected'] = false;
              bundle_option['quantity'] = 0;
            })
          });
        }
      })
    });
    $('#menu-item-modal').modal('show');
  }

  didPickOption(side, option) {
    if (option.selected) {
      option.Sides.map(bundle_side => {
        bundle_side.TotalSelectedOptions = 0;
        bundle_side.Options.map(bundle_option => {
          bundle_option.selected = false;
          bundle_option.quantity = 0;
        });
      });
      side.TotalSelectedOptions -= option.quantity;
      option.selected = false;
      option.quantity = 0;
    }
    else {
      if (option.Sides.length > 0) {
        if (side.TotalSelectedOptions < side.AbleAmount) {
          this.selectedOption = option;
          $('.carousel').carousel(1);
        }
      }
      else {
        if (side.AbleAmount > 1) {
          if (side.TotalSelectedOptions < side.AbleAmount) {
            option.selected = true;
            option.quantity = 1;
            side.TotalSelectedOptions += 1;
          }
        }
        else {
          side.Options.map(option => {
            option.selected = false;
            option.quantity = 0;
          });
          option.selected = true;
          option.quantity = 1;
          side.TotalSelectedOptions = 1;
        }
      }
    }
  }

  addOptionQuantity(event, side, option) {
    event.preventDefault();
    event.stopPropagation();
    if (side.TotalSelectedOptions < side.AbleAmount) {
      option.quantity += 1;
      side.TotalSelectedOptions += 1;
    }
  }

  minusOptionQuantity(event, side, option) {
    event.preventDefault();
    event.stopPropagation();
    if (option.quantity > 1) {
      option.quantity -= 1;
      side.TotalSelectedOptions -= 1;
    }
  }

  calculateItemTotalPrice(item) {
    item.TotalPrice = item.Price;
    item.TotalTax = 0.00
    item.Sides.filter(side => {
      side.Options.filter(option => {
        let selectedOptionPrice = option.Price;
        if (option.selected && selectedOptionPrice > 0) {
          option.Sides.filter(bundle_side => {
            bundle_side.Options.filter(bundle_option => {
              if (bundle_option.selected && bundle_option.Price > 0) {
                selectedOptionPrice += (bundle_option.Price * bundle_option.quantity);
              }
            });
          })
          item.TotalPrice += (selectedOptionPrice * option.quantity);



          // item.totalTax = ((item.TotalPrice * item.Tax) + item.TaxAmount)
        }
      });
    });

    item.TotalPrice *= item.quantity;
    item.TotalPrice = Number(this.roundNumber(item.TotalPrice));
    item.TotalTax += ((item.TotalPrice * item.TaxPercentage) + item.TaxAmount)


    return item.TotalPrice;
  }


  goBackToMenuItemCarousel(option) {
    $('#menu-item-carousel').carousel(0);
    option.Sides.map(side => {
      side.TotalSelectedOptions = 0;
      side.Options.map(option => {
        option.selected = false;
        option.quantity = 0;
      });
    });
  }

  saveOptionBundle(option) {
    let required_selected = true;
    for (let i in option.Sides) {
      let side = option.Sides[i];
      if (side.Required == 1 && side.TotalSelectedOptions < side.Selectables) {
        required_selected = false;
        break;
      }
    }

    if (!required_selected) {
      alert('Please select all required options.');
      return;
    }

    this.selectedMenuItem.Sides.map(side => {
      side.Options.map(option => {
        if (option.Sides.length > 0) {
          option.Sides.map(bundle_side => {
            if (bundle_side.TotalSelectedOptions > 0) {
              option.selected = true;
              option.quantity = 1;
              side.TotalSelectedOptions += 1;
            }
          });
        }
      });
    });

    $('.carousel').carousel(0);
  }

  addToOrder(item) {

    if (!this.emp.chosenRestaurant.isOpen && this.emp.chosenRestaurant.isClosed) {
      alert("This store is currently closed.");
      return;
    }

    if (item.Paused == 1) {
      alert("This item is currently paused by the store.");
      return;
    }

    if (!this.isServing(item.ServedStart, item.ServedEnd)) {
      alert("This item is only served between " + this.tConvert(item.ServedStart) + " - " + this.tConvert(item.ServedEnd));
      return;
    }

    let required_selected = true;
    for (let i in item.Sides) {
      let side = item.Sides[i];
      if (side.Required == 1 && side.TotalSelectedOptions < side.Selectables) {
        required_selected = false;
        break;
      }
    }

    if (!required_selected) {
      alert('Please select all required options.');
      return;
    }

    this.itemsInCart += item.quantity;
    this.emp.orderedItems.push(JSON.parse(JSON.stringify(item)));
    this.calculateCart()
    $('#menu-item-modal').modal('hide');
  }

  goBackToCartCarousel() {
    $('#cart-carousel').carousel(0);
  }

  validateCardDetails(): boolean {

    // if (!this.newCardSelected) {
    //   let selected = false;
    //   this.paymentCards.filter(card => {
    //     if (card.selected) {
    //       selected = true;
    //     }
    //   })
    //   return !selected;
    // }
    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    // Get input values
    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const cardCvc = cardCvcInput.val()?.toString();

    // Validate card number
    if (cardNumber && cardNumber.length !== 16) {
      return true;
    }

    // Validate card expiry
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;

    if (
      !cardExpiryMonth ||
      !cardExpiryYear ||
      isNaN(cardExpiryMonth) ||
      isNaN(cardExpiryYear) ||
      cardExpiryMonth < 1 ||
      cardExpiryMonth > 12 ||
      cardExpiryYear < currentYear ||
      (cardExpiryYear === currentYear && cardExpiryMonth < currentMonth)
    ) {
      return true;
    }

    // Validate card CVC
    if (!cardCvc || isNaN(parseInt(cardCvc)) || cardCvc.length < 3 || cardCvc.length > 4) {
      return true;
    }

    // All validations passed
    return false;
  }

  async CreatePaymentMethod() {

    const stripeID = localStorage.getItem('StripeID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const currentYear = new Date().getFullYear();
    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;
    const cardCvc = cardCvcInput.val()?.toString();


    const post = {
      customer_id: stripeID,
      card_number: cardNumber,
      exp_month: cardExpiryMonth,
      exp_year: cardExpiryYear,
      cvc: cardCvc
    };

    await this.paymentService.createAndAttachPaymentMethod(post).subscribe(async data => {
      if (data['Success']) {
        this.getCards();
        $('#payment-method-modal').modal('hide');
        $('#cart-modal').modal('show');
      }
      else {
        alert('Something went wrong, please try again later!!');
        this.router.navigateByUrl('/logout');
      }
    }, failure => {
      const error = failure.error.Error;
      if (error.type == 'StripeCardError') {
        alert('Please enter valid card details!');
      }
      else {
        alert(error.message);
      }
    });

  }
}