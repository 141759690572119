import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';
import {Router} from "@angular/router";
import {User} from "../login/login.service";


@Injectable({
  providedIn: 'root'
})
export class RegisterService {


  registerURL = this.url.getDomain() + '/Users/AddUser';
  registerMailURL = this.url.getDomain() + '/Mail/SendSignupSuccessMail';
  registerTeamMemberURL = this.url.getDomain() + '/Users/AddUserWithLink';
  updateUserProfileInformationURL = this.url.getDomain() + '/Users/UpdateUserInformation';
  updateRestaurantUserStripeCustomerIdURL = this.url.getDomain() + '/Restaurants/updateRestaurantUserStripeCustomerID';

  constructor(private router:Router,private http: HttpClient, private url: Url) { }

  registerTeamMember(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.registerTeamMemberURL, post, httpOptions);
  }

  registerMail(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.registerMailURL, post, httpOptions);
  }

  register(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.registerURL, post, httpOptions);
  }


  updateUserInfo(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.updateUserProfileInformationURL, post, httpOptions);
  }

  updateRestaurantUserStripeID(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.updateRestaurantUserStripeCustomerIdURL, post, httpOptions);
  }

  




}
