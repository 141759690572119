import { AfterContentInit, Component, Inject, ElementRef, OnInit } from '@angular/core';
import { MenuService } from "../../../structure/menu/menu.service";
import { Meta } from "@angular/platform-browser";
import { EmpService } from "../../../emp.service";
import { Router } from '@angular/router';
import { UserServiceService } from "../../../services/user-service/user-service.service";
import { OrderServiceService } from "../../../structure/order/order-service.service";
import { ProfileService } from "../../../structure/profile/profile.service";
// import {PusherService} from "../../pusher.service";
import { LoginService } from "../../../structure/login/login.service";
import { Url } from "../../../models/url";
import { PaymentService } from 'app/services/payments/payment.service';
import { DomSanitizer} from '@angular/platform-browser';

const Pusher = require('pusher-js');

declare var $: any;


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css','../../../../assets/css/ts-style.css']
})
export class UserProfileComponent implements OnInit, AfterContentInit {


  name = "";
  email = "";
  message = "";
  userName = "";
  userID = "";
  AuthenticationToken = "";
  Email = "";
  last4 = "";
  brand = "";
  expMonth = "";
  expYear = "";
  exp = "";
  isSub = false;
  phone = "";
  pastOrders: any;

  // trackOrderID = 0;
  trackOrderID: any;
  orderStatus = 0;
  selectedOrder = [];
  subtotal = 0;
  trackedOrder = [];
  estimatedTime = 0;
  trackingURL = null
  userAddress: any;
  firstName: any;
  lastName: any;
  postalCode: any;
  city: any;
  country: any;
  oldPassword = "";
  password = "";
  passwordConfirm = "";
  hasCard = false;
  isCompletedOrder = false;
  userCards = [];
  isDineIn = false
  dineInURL = ""
  restaurantName = ""
  tableNumber = ""
  constructor(private elements: ElementRef, private profileService: ProfileService, private urlObject: Url, private orderService: OrderServiceService, private loginService: LoginService, private userService: UserServiceService, private service: MenuService, private meta: Meta, public emp: EmpService, private router: Router, private paymentService: PaymentService, private domSanitizer: DomSanitizer) {
    meta.updateTag({ name: 'description', content: 'TopServe For restaurants food delivery, order online food through topserve. in charlottetown PEI' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, Login, For Restaurants, Canada' });
    meta.updateTag({ property: 'og:title', content: "User Profile" });
  }

  getTomorrow() {
    var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday",
      "Thursday", "Friday", "Saturday"];
    var nextWorkingDay = [1, 2, 3, 4, 5, 1, 1];
    var now = new Date();

    return dayNames[nextWorkingDay[now.getDay()]].toString();
  }

  ngAfterContentInit(): void {
    if (this.emp.completedOrder !== 0) {
      $('#progressBar').show()
      this.isCompletedOrder = true
      this.trackOrderID = this.emp.completedOrder;
      this.didClickTrack();
      this.getOrderStatus()
      this.emp.completedOrder = 0;
      $('#trackOrder').show()
      
    }
    else {
      $('#progressBar').hide()
      $('#trackOrder').hide()
    };
  }

  ngOnInit() {
    this.getCards();
    $('#successAlert').hide();
    $('#failAlert').hide();
    $('#showProfile').show()
    $('#showOrders').hide()
    $('#showTrack').hide()
    $('#isDelivery').hide()

    if (!this.emp.isNormalUser) {
      this.router.navigateByUrl('/top-login')
    }

    this.userName = localStorage.getItem('firstName');
    this.AuthenticationToken = localStorage.getItem('AuthenticationToken');
    this.userID = localStorage.getItem('UserID');
    this.userAddress = localStorage.getItem('Address');
    this.phone = localStorage.getItem('Phone');
    this.email = localStorage.getItem('Email');
    this.firstName = localStorage.getItem('FirstName');
    this.lastName = localStorage.getItem('LastName');
    this.postalCode = localStorage.getItem('Postal');
    this.city = localStorage.getItem('City');
    this.country = localStorage.getItem('Country');

    if(localStorage.getItem('tableOrder') == null){
        this.isDineIn = false
    }else{
      this.isDineIn = true
      this.dineInURL = localStorage.getItem('tableOrder')
      this.tableNumber = localStorage.getItem('tableNumber')
      this.restaurantName = localStorage.getItem('restaurantName')

      
    }


    const post = {
      UserID: this.userID,
      Token: this.AuthenticationToken
    };
    this.userService.getPastOrdersForUser(post).subscribe(

      data => {

       
        this.pastOrders = data;
      //  console.log(JSON.stringify((data));
        this.pastOrders.sort((val1, val2) => { return new Date(val1.Date).getTime() - new Date(val2.Date).getTime() })
        this.pastOrders.reverse();
      });


    $('.alert').hide();


    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          event.preventDefault();
          self1.updateInformation()
        }
        form.classList.add('was-validated');
      }, false);
    });
   
  }
  sortFunction(a, b) {
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  };

  getCards() {

    this.userCards = [];

    const stripeID = localStorage.getItem('StripeID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const post = {
      customer_id: stripeID
    };

    this.loginService.getPaymentMethods(post).subscribe(
      data => {
        if (data['success']) {
          let cardData = data['response']['data'];

          if (cardData.length > 0) {

            this.hasCard = true

            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {

                card['selected'] = true;
                
                localStorage.setItem('Payment_Method_ID', card['id'].toString());
                localStorage.setItem('Exp_Year', card['exp_year'].toString());
                localStorage.setItem('Exp_Month', card['exp_month'].toString());
                localStorage.setItem('Last4', card['last4'].toString());
                localStorage.setItem('Brand', card['brand'].toString());

                this.last4 = localStorage.getItem('Last4');
                this.exp = localStorage.getItem('Exp_Month') + '/' + localStorage.getItem('Exp_Year');
                this.brand = localStorage.getItem('Brand');
              }
              this.userCards.push(card);
            }
          }
          else {
            this.hasCard = false;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });
  }

  changeDefaultCard(i: number) {

    const post = {
      customer_id: localStorage.getItem('StripeID'),
      payment_method_id: this.userCards[i]['id']
    }
    this.paymentService.updateDefaultPaymentMethod(post).subscribe(async data => {
      if (data['success']) {
        this.userCards.filter(i => i.selected = false);
        this.userCards[i]['selected'] = true;
        localStorage.setItem('Payment_Method_ID', this.userCards[i]['id'].toString());
        localStorage.setItem('Exp_Year', this.userCards[i]['exp_year'].toString());
        localStorage.setItem('Exp_Month', this.userCards[i]['exp_month'].toString());
        localStorage.setItem('Last4', this.userCards[i]['last4'].toString());
        localStorage.setItem('Brand', this.userCards[i]['brand'].toString());

        this.last4 = localStorage.getItem('Last4');
        this.exp = localStorage.getItem('Exp_Month') + '/' + localStorage.getItem('Exp_Year');
        this.brand = localStorage.getItem('Brand');
      }
      else {
        alert('Something went wrong in changing card, please try again later!!');
      }
    });
  }

  deletePaymentMethod(event, i: number) {
    event.stopPropagation();
    
    const post = {
      payment_method_id : this.userCards[i]['id']
    }

    this.paymentService.removePaymentMethod(post).subscribe(async data => {
      if (data['Success']) {
        this.getCards();
      }
      else {
        alert('Something went wrong while deleting your card! Please try again later!');
      }
    });
  }

  getStatus(status) {

    switch (status) {
      case "1":
        return "In Queue";
      case "2":
        return "In Progress Estimated Time: " + this.estimatedTime.toString();
      case "3":
        return "Completed";

      case "4":
        return "Completed";

      case "5":
        return "Cancelled";

      case "7":
        return "Refunded";

      default:
        return "NA";
    }
  }

  convertDate(date) {
    return new Date(date).toLocaleString("en-CA", { timeZone: "America/Halifax" });
  }

  didClickTrack() {
    $('#showProfile').hide()
    $('#showOrders').hide()
    $('#showTrack').show()

    $('#progressBar').hide()
    $('#trackOrder').hide()

    $('#btnTrackOrder').removeClass('tab-not-selected');
    $('#btnTrackOrder').addClass('tab-selected');
    $('#btnOrder').removeClass('tab-selected');
    $('#btnOrder').addClass('tab-not-selected');
    $('#btnProfile').removeClass('tab-selected');
    $('#btnProfile').addClass('tab-not-selected');
  }


  getSelectedOrder(orderID) {
    const post = {
      OrderID: orderID,
      UserID: this.userID
    };
    this.orderService.getOrder(post).subscribe(

     
      data => {

        console.log(JSON.stringify(data))

        this.selectedOrder = [];
        if (data.hasOwnProperty('OrderedItems')) {
          this.selectedOrder.push(data);
          setTimeout(function () {
            // prints out "2", meaning that the callback is not called immediately after 500 milliseconds.
            $('#menuItem').modal('show')
          }, 100);
        }
      }
    );
  }




  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }

  stopAlert() {
    $('.alert').hide();
  }

  didClickProfile() {
    $('#showProfile').show()
    $('#showOrders').hide()
    $('#showTrack').hide()

    $('#btnProfile').removeClass('tab-not-selected');
    $('#btnProfile').addClass('tab-selected');

    $('#btnTrackOrder').removeClass('tab-selected');
    $('#btnTrackOrder').addClass('tab-not-selected');
    $('#btnOrder').removeClass('tab-selected');
    $('#btnOrder').addClass('tab-not-selected');
  }

  didClickOrder() {
    this.trackedOrder = [];
    $('#showProfile').hide()
    $('#showOrders').show()
    $('#showTrack').hide()

    $('#btnOrder').removeClass('tab-not-selected');
    $('#btnOrder').addClass('tab-selected');

    $('#btnTrackOrder').removeClass('tab-selected');
    $('#btnTrackOrder').addClass('tab-not-selected');
    $('#btnProfile').removeClass('tab-selected');
    $('#btnProfile').addClass('tab-not-selected');
  }



  updateInformation() {
    const post = {
      UserID: localStorage.getItem('UserID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserAddress: this.userAddress,
      Phone: this.phone,
      Email: this.email,
      FirstName: this.firstName,
      LastName: this.lastName,
      PostalCode: this.postalCode,
      City: this.city,
      Country: this.country,
    };

    this.profileService.updateProfile(post).subscribe(
      data => {
        if (data['Success']) {
          alert("Profile Updated!");
        } else {
          alert("Profile Not Updated. Try Again Later");
        }
      })
  }

  changePassword() {
    const post = {
      UserID: localStorage.getItem('UserID'),
      Token: localStorage.getItem('AuthenticationToken'),
      Password: this.oldPassword
    };

    const post2 = {
      UserID: localStorage.getItem('UserID'),
      Token: localStorage.getItem('AuthenticationToken'),
      Password: this.passwordConfirm
    };

    if (this.password != "" && this.passwordConfirm != "") {
      if (this.password == this.passwordConfirm) {
        this.profileService.checkPassword(post).subscribe(
          data => {
            if (data['Success']) {
              this.profileService.updatePassword(post2).subscribe(
                data => {
                  if (data['Success']) {
                    $('#change-password-modal').modal('toggle');
                    alert("password updated")
                  } else {
                    alert("could not update new password");
                  }
                })
            } else {
              alert("current password incorrect");
            }
          });
      } else {
        alert("new passwords do not match")
      }
    } else {
      alert("please enter new password")
    }
  }


  validateOrderStatus(status) {
    let s = parseInt(status)
    if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
      $('#isDelivery').show()
    } else {
      $('#isDelivery').hide();
    }
    if (s == 0) {
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'snow';
      }
      document.getElementById('inQueue').style.backgroundColor = 'snow';
      document.getElementById('inProgress').style.backgroundColor = 'snow';
      document.getElementById('completed').style.backgroundColor = 'snow';
      // document.getElementById("status").innerHTML = "Cannot get order status";
    } else if (s == 1) {
      // document.getElementById("status").innerHTML = "Currently In Queue";
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {

        document.getElementById('delivery').style.backgroundColor = 'snow';
      }
      document.getElementById('inQueue').style.backgroundColor = 'limegreen';
      document.getElementById('inProgress').style.backgroundColor = 'snow';
      document.getElementById('completed').style.backgroundColor = 'snow';
    } else if (s == 2) {
      // document.getElementById("status").innerHTML = "Crafting Meal Estimated Time: " + this.estimatedTime;
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'snow';
      }
      document.getElementById("estimateTime").innerHTML = "Estimate Time: " + this.estimatedTime + "mins";
      document.getElementById('inQueue').style.backgroundColor = 'limegreen';
      document.getElementById('inProgress').style.backgroundColor = 'limegreen';
      document.getElementById('completed').style.backgroundColor = 'snow';
    } else if (s == 3) {
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'limegreen';
        document.getElementById('completed').style.backgroundColor = 'snow';
        // document.getElementById("status").innerHTML = "Out on Delivery Track:  " + "<a style='color:blue' href='"+this.trackingURL+"'>Click here to track</a>" ;
      } else {
        document.getElementById('completed').style.backgroundColor = 'limegreen';
        if (this.trackedOrder[0]["OrderType"] != 2) {
          // document.getElementById("status").innerHTML = "Ready!";
        } else {
          // document.getElementById("status").innerHTML = "Delivering, not all couriers offer tracking at this time.";
        }
      }
      document.getElementById('inQueue').style.backgroundColor = 'limegreen';
      document.getElementById('inProgress').style.backgroundColor = 'limegreen';
    } else if (s == 4) {
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'limegreen';
      }
      // document.getElementById("status").innerHTML = "limegreen";
      document.getElementById('inQueue').style.backgroundColor = 'limegreen';
      document.getElementById('inProgress').style.backgroundColor = 'limegreen';
      document.getElementById('completed').style.backgroundColor = 'limegreen';
    } else if (s == 5) {
      // document.getElementById("status").innerHTML = "Cancelled";
      if (this.trackingURL != null && this.trackingURL != 'NA' || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'red';
      }
      document.getElementById('inQueue').style.backgroundColor = 'red';
      document.getElementById('inProgress').style.backgroundColor = 'red';
      document.getElementById('completed').style.backgroundColor = 'red';
    } else if (s == 6) {
      // document.getElementById("status").innerHTML = "Cannot get order status";
      if (this.trackingURL != null && this.trackingURL != "NA" || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'lightgrey';
      }
      document.getElementById('inQueue').style.backgroundColor = 'lightgrey';
      document.getElementById('inProgress').style.backgroundColor = 'lightgrey';
      document.getElementById('completed').style.backgroundColor = 'lightgrey';
    } else if (s == 7) {
      // document.getElementById("status").innerHTML = "Refunded";
      if (this.trackingURL != null && this.trackingURL != "NA" || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'yellow';
      }
      document.getElementById('inQueue').style.backgroundColor = 'yellow';
      document.getElementById('inProgress').style.backgroundColor = 'yellow';
      document.getElementById('completed').style.backgroundColor = 'yellow';
    } else {
      // document.getElementById("status").innerHTML = "Cannot get order status";
      if (this.trackingURL != null && this.trackingURL != "NA" || this.getOrderType()) {
        document.getElementById('delivery').style.backgroundColor = 'lightgrey';
      }
      document.getElementById('inQueue').style.backgroundColor = 'lightgrey';
      document.getElementById('inProgress').style.backgroundColor = 'lightgrey';
      document.getElementById('completed').style.backgroundColor = 'lightgrey';
    }
  }


  getOrderStatus() {

    const pusher = new Pusher('1112b666d809129183c1', {
      cluster: 'us2',
      forceTLS: true
    });


    const post = {
      OrderID: this.trackOrderID,
      UserID: this.userID,
      Token: this.AuthenticationToken
    };

    this.orderService.getOrderStatus(post).subscribe(
      data => {
        const self0 = this;
        if (data["Success"]) {
          if (data.hasOwnProperty('Response')) {
            if (data['Response'].hasOwnProperty('EstimatedTime')) {
              self0.estimatedTime = data["Response"]["EstimatedTime"];
            }

            if (data['Response'].hasOwnProperty('TrackingURL')) {
              this.trackingURL = data["Response"]["TrackingURL"];
            }

            if (!this.isSub) {


              const channel = pusher.subscribe(data['Response']['RestaurantID'].toString() + 'track');
              channel.bind('my-event', function (data) {
                self0.updateOrderStatus();
                this.isSub = true
              });
            }

            let t = this;
            this.orderService.getOrder(post).subscribe(
              data1 => {
                t.trackedOrder = [];
                if (data1.hasOwnProperty('OrderedItems')) {
                  t.trackedOrder.push(data1);
                  $('#progressBar').show();
                  $('#trackOrder').show()
                  t.validateOrderStatus(data["Response"]["OrderStatus"]);
                }
              });
          }
        }
      });
     


  }

  updateOrderStatus() {


    const post = {
      OrderID: this.trackOrderID,
      UserID: this.userID,
      Token: this.AuthenticationToken
    };

    this.orderService.getOrderStatus(post).subscribe(
      data => {
        const self0 = this;
        if (data["Success"]) {
          if (data.hasOwnProperty('Response')) {
            if (data['Response'].hasOwnProperty('EstimatedTime')) {
              self0.estimatedTime = data["Response"]["EstimatedTime"];
            }

            if (data['Response'].hasOwnProperty('TrackingURL')) {
              this.trackingURL = data["Response"]["TrackingURL"];
            }

            $('#progressBar').show();
            $('#trackOrder').show()
            self0.validateOrderStatus(data["Response"]["OrderStatus"]);
          }
        }
      });
  }



  getSubtotal(item) {
    let subtot = 0;
    for (let x in item.OrderedItems) {
      subtot += parseFloat(item.OrderedItems[x].Price);
      for (let i in item.OrderSides[x]) {
        subtot += parseFloat(item.OrderSides[x][i].sidePrice);
      }
    }
    this.subtotal = subtot;
    return this.subtotal;

  }

  getOrderType() {
    if (this.trackedOrder.length == 0) {
      return false;
    }
    if (this.trackedOrder[0]["OrderType"] == 2) {
      return true
    } else {
      return false
    }
  }

  isRestricted(order) {
    let restrictedItems = order.filter(item => item["AgeRestriction"] == 1);

    if (restrictedItems.length > 0) // contains restricted items
    {
      return true;
    }
    return false;
  }



  async sendMessage() {

    let message = this.name + " - " + this.email + " - " + this.message;
    const post = {
      suggestedRestaurant: message
    };

    this.service.SendLine(post).subscribe(
      data => {
        if (data['addedReview']) {
          $("#successAlert").show();
          $("#successAlert").alert();
        } else {
          $("#failAlert").show();
          $("#failAlert").alert();
        }
      }
    );


  }

  gettrackingURL() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.trackingURL);
  }
}