import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';


export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ContactlessDineInService {

  getSubscribeSessionURL = this.url.getDomain() + '/Stripe/CreateSubscribeSession';
  verifyDineInSubscribeSuccessURL = this.url.getDomain() + '/Stripe/VerifyDineInSubscriptionSuccess';
  cancelSubscriptionURL = this.url.getDomain() + '/Stripe/CancelDineInSubscription';
  getProductPriceURL = this.url.getDomain() + '/Stripe/GetStripeProductPrice';

  constructor(private http: HttpClient, private url: Url) { }

  getSubscribeSession(res) {
    return this.http.post<Res>(this.getSubscribeSessionURL, res);
  }

  verifyDineInSubscribeSuccess(res) {
    return this.http.post<Res>(this.verifyDineInSubscribeSuccessURL, res);
  }

  cancelSubscription(res) {
    return this.http.post<Res>(this.cancelSubscriptionURL, res);
  }

  getProductPrice(res) {
    return this.http.post<Res>(this.getProductPriceURL, res);
  }

}
