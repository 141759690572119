import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

import {ExpenditureService} from "../expenditure.service";



@Component({
  selector: 'app-expenditure-details',
  templateUrl: './expenditure-details.component.html',
  styleUrls: ['./expenditure-details.component.css']
})
export class ExpenditureDetailsComponent implements OnInit {

payoutDate :any;
  payoutTotal = 0;
  payoutID = 0;
  payoutDetails : any;
  constructor(private expenditureService:ExpenditureService,private router:Router) { }

 async ngOnInit() {


    if(this.expenditureService.payoutID === undefined){
      this.router.navigateByUrl('/services/expenditure');
      return
    }

    this.payoutDate = this.expenditureService.payoutDate
    this.payoutTotal = this.expenditureService.payoutTotal;
    this.payoutID = this.expenditureService.payoutID;
    await this.setupPage();

  }


  async setupPage(){


    const post = {
      RestaurantID:localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      PayoutID:this.payoutID
    };

    await this.expenditureService.getPayoutDetails(post).subscribe(async data => {
      data = data[0];
      if (data["Success"]) {
        this.payoutDetails = data["Response"];
      }
    });

  }

  getDate(date){
    return new Date(date).toDateString()
  }


    getMealCost(Main){

        let total = 0;
        for(let index in Main){
            if(Main[index].hasOwnProperty("main")){
                total += Main[index]["main"].MenuItemPrice
            }else if(Main[index].hasOwnProperty("side")){
                total += Main[index]["side"].MenuOptionPrice
            }
        }
        return total
    }


}
