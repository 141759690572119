import { Component, OnInit } from '@angular/core';
import { ViewSubscribersService } from './view-subscribers.service'


@Component({
  selector: 'app-view-subscribers',
  templateUrl: './view-subscribers.component.html',
  styleUrls: ['./view-subscribers.component.css']
})
export class ViewSubscribersComponent implements OnInit {

  constructor(private subscribersService: ViewSubscribersService) { }

  subscribers = []

  ngOnInit(): void {
    this.getSubscribers();
  }

  //Use a tableview to list subscribers for the restaurnant
  getSubscribers() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    };

    this.subscribersService.getSubscribers(post).subscribe(
      data => {
        if (data['Success'] && data["Response"].length > 0) {
          for (let i in data["Response"]) {
            let item = {};
            item["firstName"] = data["Response"][i]['FirstName']
            item["email"] = data["Response"][i]['Email']
            this.subscribers.push(item);
          }
        } else {
          //alert("Error loading get subscribers");
        }
      });
  }
}
