import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { EmpService } from 'app/emp.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { LoginService } from 'app/structure/login/login.service';
import { environment } from 'environments/environment';

declare var $: any;

@Component({
  selector: 'app-farm-order',
  templateUrl: './farm-order.component.html',
  styleUrls: ['./farm-order.component.css', '../../../../assets/css/ts-style.css']
})
export class FarmOrderComponent implements OnInit {

  stripePromise = loadStripe(environment.stripeKey);
  hasCard = false;
  isCardChecked = false;
  userCards = [];
  last4 = '';
  brand = '';
  exp = '';
  StripeCustomerID = '';

  farm = {
    Name: '',
    Description: '',
    ImageURL: '',
  };
  
  amount = 5;
  custom_amount = '';
  StandPaymentID = '';
  PaymentIntentID = '';
  receiptEmail = '';

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }

  constructor(public emp: EmpService, private router: Router, private paymentService: PaymentService, private loginService: LoginService) { }

  ngOnInit(): void {

    this.farm = this.emp.chosenRestaurant;

    if (this.emp.isNormalUser && localStorage.getItem('isLogedIn') == 'true') {
      this.StripeCustomerID = localStorage.getItem('StripeID');
      localStorage.removeItem('farmGuestUser');
      localStorage.setItem('farmStripeCustmerID', this.StripeCustomerID);
      localStorage.setItem('farmReceiptEmail', localStorage.getItem('Email'));
    }
    else if(localStorage.getItem('farmGuestUser') == 'true') {
      this.StripeCustomerID = localStorage.getItem('farmStripeCustmerID');
    }

    this.verifyNewCardSession();
    this.getCards();

    $('#custom-amount').keypress((event) => {
      // Get the key code of the pressed key
      var keyCode = event.which;

      // Check if the key code corresponds to a number key
      if (keyCode < 48 || keyCode > 57) {
        // Prevent the input from being entered
        event.preventDefault();
      }
    });

    $('#custom-amount').keyup((event) => {
      // Get the input value and check if it's above 200 or not.
      this.custom_amount = parseInt(this.custom_amount) > 200 ? '200' : this.custom_amount;
    });
  }

  validateCustomAmount() {
    if (Number.isNaN(parseInt(this.custom_amount)) || parseInt(this.custom_amount) < 5 || parseInt(this.custom_amount) > 200) {
      return true;
    }
    else {
      return false;
    }

  }

  changeAmount(amount) {
    if (amount == 'other') {
      this.amount = parseInt(this.custom_amount);
      $('#custom-amount-modal').modal('hide');
    }
    else {
      this.amount = parseInt(amount);
    }
  }

  increaseAmount() {
    this.amount = this.amount + 1 > 200 ? 200 : this.amount + 1;
  }

  decreaseAmount() {
    this.amount = this.amount - 1 < 5 ? 5 : this.amount - 1;
  }

  calculateAppFeePaidByCustomer(farm) {
    // Given that Stripe charges 2.9% + $0.30 per transaction, let x be the amount the customer needs to pay.
    const index =  farm['OrderTypes'].split(',').indexOf('1');
    const percentageFee = Number(farm['OrderTypeFeePercentage'].split(',')[index]);
    const fixedFee = Number(farm['OrderTypeFeePrice'].split(',')[index]);
    const amount = this.amount; //amount converted in dollars
    const totalAmount = (amount + fixedFee) / (1 - percentageFee); //To Calculate actual amount to charge to customers
    return ((Math.round((totalAmount - amount) * 100)) / 100).toFixed(2); //Return the difference of TotalAmount and Amount as AppFee
}

  async verifyNewCardSession() {
    
    if (window.location.href.split('?').pop().includes('session_id')) {

      if (localStorage.getItem('farmAmount') != undefined && localStorage.getItem('farmAmount') != null) {
        this.amount = parseInt(localStorage.getItem('farmAmount'));
      }

      const post = {
        customer_id: this.StripeCustomerID,
        session_id: window.location.href.split('session_id=').pop().replace('&farm=true', '')
      }

      this.paymentService.verifyPaymentMethodSession(post).subscribe(async data => {
        if (data['Success']) {
          this.getCards();
        }
        else {
          alert('Something went wrong while adding a new card! Please try again later!');
        }
      });
    }
    else if (window.location.href.split('?').pop().includes('failure=true')) {

      if (localStorage.getItem('farmAmount') != undefined) {
        this.amount = parseInt(localStorage.getItem('farmAmount'));
      }
      alert('Add New Card Failed!');
    }
  }

  handlePaymentMethod() {
    if (!(this.emp.isNormalUser && localStorage.getItem('isLogedIn') == 'true')) {
      this.createGuest();
    }
    else {
      this.addNewCard();
    }
  }

  validateFarmPay() {
    if (this.hasCard && this.isCardChecked) {
      return true;
    }
    else {
      return false;
    }
  }

  handlePayment() {
    if (this.hasCard && this.isCardChecked) {
      this.chargeCustomer();
    }
  }

  async chargeCustomer() {

    $('#farm-progress-modal').modal('show');

    const post = {
      customer_id: this.StripeCustomerID,
      amount: (this.amount * 100).toString(),
      currency: 'CAD',
      receipt_email: localStorage.getItem('farmReceiptEmail'),
      stripeBusinessID: this.emp.chosenRestaurant.StripeBusinessID,
      StoreID: this.emp.chosenRestaurant.RestaurantID,
      TimeZone: this.emp.chosenRestaurant.TimeZone,
    };

    let self = this;
    this.paymentService.createFarmPaymentIntent(post).subscribe(async data => {
      if (data['Success']) {
        (await this.stripePromise).confirmCardPayment(data['ClientSecret'], {
          payment_method: localStorage.getItem('Payment_Method_ID'),
        })
          .then(function (result) {
            $('#farm-progress-modal').modal('hide');
            // Handle result.error or result.paymentIntent
            if (result.error) {
              console.log(result.error)
              alert('There was a problem with the payment, and you were not charged. Please try again later');
            }
            else {
              $('#success-payment-modal').modal('show');
              self.StandPaymentID = data['StandPaymentID'];
              self.PaymentIntentID = data['PaymentID'];
              self.processPayment();
            }
          });
      } else {
        $('#farm-progress-modal').modal('hide');
        alert('There was a problem with the payment, and you were not charged. Please try again later');
      }
    })
  }

  getApplicationFee() {

    let i = this.emp.chosenRestaurant.OrderTypes.split(',').indexOf('1');
    let tsPercentage = this.emp.chosenRestaurant.OrderTypesPercentage.split(',')[i];
    let stripeFeesInCents = 30;
    let appFeeInCents = Math.round(this.amount * tsPercentage) + stripeFeesInCents;
    return appFeeInCents.toString();
  }

  processPayment() {

    const post = {
      StandPaymentID: this.StandPaymentID,
    };

    this.paymentService.UpdateFarmUserPaid(post).subscribe(async data => {
      //Do nothing. Stripe Payment is already processed just could not update Topserve.
    }, err => {
      //Do nothing. Stripe Payment is already processed just could not update Topserve.
    });
  }

  resetAmountToDefault() {
    localStorage.removeItem('farmAmount');
    this.amount = 5;
    this.custom_amount = '';
    this.StandPaymentID = '';
    this.PaymentIntentID = '';
    this.receiptEmail = '';
  }

  async addNewCard() {

    localStorage.setItem('farmAmount', this.amount.toString());

    const post = {
      customer_id: this.StripeCustomerID,
      LinkName: this.emp.chosenRestaurant.LinkName,
      Farm: true,
    }
    this.paymentService.createPaymentMethodSession(post).subscribe(async data => {

      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data['sessionId']
      });

      if (error) {
        console.log(error);
      }
    });
  }

  getCards() {

    this.userCards = [];


    if (this.StripeCustomerID === undefined || this.StripeCustomerID == null || this.StripeCustomerID === '') {
      return;
    }

    const post = {
      customer_id: this.StripeCustomerID
    };

    this.loginService.getPaymentMethods(post).subscribe(
      data => {

        if (data['success']) {
          let cardData = data['response']['data'];

          if (cardData.length > 0) {

            this.hasCard = true;

            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {

                card['selected'] = true;
                this.isCardChecked = true;
                localStorage.setItem('Payment_Method_ID', card['id'].toString());
                localStorage.setItem('Exp_Year', card['exp_year'].toString());
                localStorage.setItem('Exp_Month', card['exp_month'].toString());
                localStorage.setItem('Last4', card['last4'].toString());
                localStorage.setItem('Brand', card['brand'].toString());

                this.last4 = localStorage.getItem('Last4');
                this.exp = localStorage.getItem('Exp_Month') + '/' + localStorage.getItem('Exp_Year');
                this.brand = localStorage.getItem('Brand');
              }
              this.userCards.push(card);
            }
          }
          else {
            this.hasCard = false;
            this.isCardChecked = false;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });


  }

  createGuest() {
    var randomnumber = new Date().getTime();
    const token = this.makeRandom()
    const email = "standGuestUserWeb" + randomnumber + "@topserve.ca";

    const post = {
      Email: email,
      AuthenticationToken: token,
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {

      if (data["success"]) {

        this.StripeCustomerID = data['customerId'];
        localStorage.setItem('farmGuestUser', 'true');
        localStorage.setItem('farmStripeCustmerID', this.StripeCustomerID);
        localStorage.setItem('farmReceiptEmail', email);
        this.addNewCard();

      } else {
        alert("there was an error creating your account. please try again later.")
      }

    }, err => {
      alert("there was an error creating your account. please try again later.")
    });

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  validateReceiptEmail() {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test if email matches the pattern
    return pattern.test(this.receiptEmail);
  }

  sendEmailReceipt() {

    const post = {
      PaymentID: this.PaymentIntentID,
      receipt_email: this.receiptEmail,
    };

    console.log(post)

    this.paymentService.UpdateFarmForReceipt(post).subscribe(async data => {
      if (data['Success']) {
        $('#email-receipt-modal').modal('hide');
        this.resetAmountToDefault();
      }
      else {
        alert('There was a problem sending an email receipt!\nPlease contact TopServe.')
      }
    });
  }

}
