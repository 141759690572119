import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {EmpService} from "../../../emp.service";
import { SocialAuthService } from "@abacritt/angularx-social-login";
declare var $ : any;

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: SocialAuthService, private router: Router,private emp:EmpService) { }

  ngOnInit() {

    // Here changed landing
    this.authService.signOut();
    this.router.navigateByUrl('/');
    this.emp.isNormalUser = false;
    localStorage.clear();

  }

}
