import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResetpasswordService } from './resetpassword.service'

declare var $: any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css', '../../../assets/css/ts-style.css']
})
export class ResetpasswordComponent implements OnInit {

  isFormValid = false
  userEmail = '';
  isValidLink = true;
  password = ''
  constructor(private router: Router, private resetService: ResetpasswordService) { }

  ngOnInit() {

    let code = this.gup('code', window.location.href);

    if (code != null && code !== undefined) {

      const post = {
        Token: code.trim()
      };

      this.resetService.getResetPassword(post).subscribe(
        data => {
          
          if (data.hasOwnProperty('Response')) {
            if (data['Response'].length > 0) {
              if (this.datetimeDifference(this.getCurrentUTCDateTime(), data['Response'][0]['Date']) < 32) {
                console.log(this.datetimeDifference(this.getCurrentUTCDateTime(), data['Response'][0]['Date']));
                setTimeout(() => {
                  window.location.reload();
                }, (32 - this.datetimeDifference(this.getCurrentUTCDateTime(), data['Response'][0]['Date'])) * 60 * 1000);
                this.userEmail = data['Response'][0].Email;
                this.setupInput();
              }
              else {
                this.isValidLink = false;
              }
            }

          } else {
            this.isValidLink = false
          }
        });
    }
  }


  gup(name, url) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
  }


  setupInput() {

    $('input[type=password]').keyup(function () {
      var ucase = new RegExp('[A-Z]+');
      var lcase = new RegExp('[a-z]+');
      var num = new RegExp('[0-9]+');
      this.isFormValid = true
      if ($('#password1').val().length >= 8) {
        $('#8char').removeClass('fa fa-remove');
        $("#8char").addClass("fa fa-check");
        $("#8char").css("color", "#00A41E");
      } else {
        this.isFormValid = false
        $("#8char").removeClass("fa fa-check");
        $("#8char").addClass("fa fa-remove");
        $("#8char").css("color", "#FF0004");
      }

      if (ucase.test($("#password1").val())) {
        $("#ucase").removeClass("fa fa-remove");
        $("#ucase").addClass("fa fa-check");
        $("#ucase").css("color", "#00A41E");
      } else {
        this.isFormValid = false

        $("#ucase").removeClass("fa fa-check");
        $("#ucase").addClass("fa fa-remove");
        $("#ucase").css("color", "#FF0004");
      }

      if (lcase.test($("#password1").val())) {
        $("#lcase").removeClass("fa fa-remove");
        $("#lcase").addClass("fa fa-check");
        $("#lcase").css("color", "#00A41E");
      } else {
        this.isFormValid = false
        $('#lcase').removeClass('fa fa-check');
        $('#lcase').addClass('fa fa-remove');
        $("#lcase").css("color", "#FF0004");
      }

      if (num.test($("#password1").val())) {
        $("#num").removeClass("fa fa-remove");
        $("#num").addClass("fa fa-check");
        $("#num").css("color", "#00A41E");
      } else {
        this.isFormValid = false
        $("#num").removeClass("fa fa-check");
        $("#num").addClass("fa fa-remove");
        $("#num").css("color", "#FF0004");
      }

      if ($("#password1").val() == $("#password2").val()) {
        $("#pwmatch").removeClass("fa fa-remove");
        $("#pwmatch").addClass("fa fa-check");
        $("#pwmatch").css("color", "#00A41E");
      } else {
        this.isFormValid = false
        $("#pwmatch").removeClass("fa fa-check");
        $("#pwmatch").addClass("fa fa-remove");
        $("#pwmatch").css("color", "#FF0004");
      }
    });

    const forms = document.getElementsByClassName('password-form');

    const self0 = this;
    const validation = Array.prototype.filter.call(forms, function (form) {
      const self1 = self0;
      form.addEventListener('submit', function (event) {
        if (this.isFormValid) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          const post = {
            Email: self1.userEmail,
            Password: self1.password
          };
          self1.resetService.resetUserPassword(post).subscribe(
            data => {
              if (data['Success']) {
                $('#passwordChanged').modal()
              } else {
                alert('Something went wrong! Could not reset password!')
              }
            })
        }
      }, false);
    });
  }

  checkFormValidation() {
    if ($("#pwmatch").hasClass("fa fa-check")) {
      return true;
    }
    else {
      return false;
    }
  }


  confirmUpdate() {
    // Here changed landing
    this.router.navigate(['/']);
  }

  datetimeDifference(datetime1, datetime2) {
    // Convert datetime strings to Date objects
    const date1:any = new Date(datetime1);
    const date2:any = new Date(datetime2);
  
    // Calculate the difference in milliseconds
    const diffMs = Math.abs(date2 - date1);
  
    // Convert milliseconds to minutes, hours, days, etc.
    const diffMinutes = Math.floor(diffMs / (1000 * 60));

    return diffMinutes;
  }

  getCurrentUTCDateTime() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hour = String(now.getUTCHours()).padStart(2, '0');
    const minute = String(now.getUTCMinutes()).padStart(2, '0');
    const second = String(now.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
  }


}
