import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FindOrderService } from '../find-order/find-order.service';


declare var $: any;
@Component({
  selector: 'app-order-refund',
  templateUrl: './order-refund.component.html',
  styleUrls: ['./order-refund.component.css', '../../../assets/css/ts-style.css']
})

export class OrderRefundComponent implements OnInit {

  orderedItems = [];
  orderID = '';
  reverseTransfer = true;
  customMessage = '';
  tax = 0;
  subTotal = 0;
  tip = 0;
  
  order_details: any;
  is_select_all = false;

  constructor(private orderService: FindOrderService, private routes: Router) { }

  ngOnInit(): void {

    let self = this;
    let forms = document.getElementsByClassName('needs-validation');
    let validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          self.filterByID();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }


  async filterByID() {
    
    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      OrderID: this.orderID,
      RestaurantID: localStorage.getItem('restaurantID')
    };

    await this.orderService.findOrderByID(post).subscribe(async data => {

      if (data["Success"]) {

        if (data['Response'].length > 0) {
          this.order_details = data['Response'][0];
        }
        
        for(let i in this.order_details['OrderedItems']) {
          this.order_details['OrderedItems'][i]['selected'] = false;
          this.order_details['OrderedItems'][i]['selected_quantity'] = this.order_details['OrderedItems'][i]['Quantity'];
          for (let j in this.order_details['OrderedItems'][i]['ItemSides']) {
            for (let k in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options']) {
              this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity'] = this.order_details['OrderedItems'][i]['Quantity'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity'];
              this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected'] = false;
              this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] = this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity'];
              for(let l in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides']) {
                this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Quantity'] = this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Quantity'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity']
                this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected'] = false;
                this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] = this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Quantity'];
              }
            }
          }
        }

        this.order_details['full_refund_selected'] = false;
        this.order_details['tip_selected'] = false;
        this.order_details['delivery_fee_selected'] = false;
      }
      else if (data['Status'] == '700'){
        localStorage.clear();
        this.routes.navigateByUrl('/login');
        alert('You will need to login again to complete this action.');
      }
      else {
        alert('Unfortunately, something went wrong!')
      }
    });
  }

  selectItem(i) {
    this.order_details['OrderedItems'][i]['selected'] = !this.order_details['OrderedItems'][i]['selected'];
  }

  selectOption(i, j, k) {
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected'] = !this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected'];
  }

  selectBundle(i, j, k, l) {
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected'] = !this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected'];
  }

  selectFullRefund() {
    this.order_details['full_refund_selected'] = !this.order_details['full_refund_selected'];
  }

  selectTipForRefund() {
    this.order_details['tip_selected'] = !this.order_details['tip_selected'];
  }

  selectDeliveryFeeForRefund() {
    this.order_details['delivery_fee_selected'] = !this.order_details['delivery_fee_selected'];
  }

  plusItemQuantity(event, i) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['selected_quantity'] = (this.order_details['OrderedItems'][i]['selected_quantity'] + 1) > this.order_details['OrderedItems'][i]['Quantity'] ? this.order_details['OrderedItems'][i]['Quantity'] : this.order_details['OrderedItems'][i]['selected_quantity'] + 1;
  }

  minusItemQuantity(event, i) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['selected_quantity'] = (this.order_details['OrderedItems'][i]['selected_quantity'] - 1) < 1 ? 1 : this.order_details['OrderedItems'][i]['selected_quantity'] - 1;
  }

  plusOptionQuantity(event, i, j, k) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] = (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] + 1) > this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity'] ? this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Quantity'] : this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] + 1;
  }

  minusOptionQuantity(event, i, j, k) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] = (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] - 1) < 1 ? 1 : this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] - 1;
  }

  plusBundleQuantity(event, i, j, k, l) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] = (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] + 1) > this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Quantity'] ? this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Quantity'] : this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] + 1;
  }

  minusBundleQuantity(event, i, j, k, l) {
    event.preventDefault();
    event.stopPropagation();
    this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] = (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] - 1) < 1 ? 1 : this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] - 1;
  }

  getRefundTotal() {
    let total = 0;
    let tax = 0;
    if (this.order_details['full_refund_selected']) {
      total = this.order_details['Total']
    }
    else {
      for(let i in this.order_details['OrderedItems']) {
        if(this.order_details['OrderedItems'][i]['selected']) {
          total += (this.order_details['OrderedItems'][i]['Price'] * this.order_details['OrderedItems'][i]['selected_quantity']);
          tax += (this.order_details['OrderedItems'][i]['Price'] * this.order_details['OrderedItems'][i]['selected_quantity'] * this.order_details['OrderedItems'][i]['TaxPercentage']) + (this.order_details['OrderedItems'][i]['TaxAmount'] * this.order_details['OrderedItems'][i]['selected_quantity']);
        }
        for (let j in this.order_details['OrderedItems'][i]['ItemSides']) {
          for (let k in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options']) {
            if(this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected']) {
              total += (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Price'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity']);
              tax += (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['Price'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected_quantity'] * this.order_details['OrderedItems'][i]['TaxPercentage']);
            }
            for(let l in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides']) {
              if(this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected']) {
                total += (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Price'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity']);
                tax += (this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['Price'] * this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected_quantity'] * this.order_details['OrderedItems'][i]['TaxPercentage']);
              }
            }
          }
        }
      }
  
      if (this.order_details['tip_selected']) {
        total += this.order_details['Tip']
      }
  
      if (this.order_details['delivery_fee_selected']) {
        total += this.order_details['DeliveryFee']
      }
    }

    total += tax;
    return this.roundNumber(total);
  }

  // selectAllItems() {
    
  //   this.is_select_all = !this.is_select_all;

  //   for(let i in this.order_details['OrderedItems']) {
  //     this.order_details['OrderedItems'][i]['selected'] = this.is_select_all;
  //     for (let j in this.order_details['OrderedItems'][i]['ItemSides']) {
  //       for (let k in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options']) {
  //         this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['selected'] = this.is_select_all;
  //         for(let l in this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides']) {
  //           this.order_details['OrderedItems'][i]['ItemSides'][j]['Options'][k]['BundleSides'][l]['selected'] = this.is_select_all;
  //         }
  //       }
  //     }
  //   }

  //   if (this.showTip()) {
  //     this.order_details['tip_selected'] = this.is_select_all;
  //   }

  //   if (this.showDeliveryFee()) {
  //     this.order_details['delivery_fee_selected'] = this.is_select_all;
  //   }
  // }

  showDeliveryFee() {
    if (this.order_details['OrderType'] == '2' && this.order_details['OwnDelivery'] == 1 && 
          (this.order_details['OrderStatus'] !== '1' && this.order_details['OrderStatus'] !== '5')) {
            return true;
    }
    return false;
  }

  showTip() {
    if (((this.order_details['OrderType'] == '2' && this.order_details['OwnDelivery'] == 1) || 
          this.order_details['OrderType'] == '1' ||  this.order_details['OrderType'] == '3') &&
          (this.order_details['OrderStatus'] !== '1' && this.order_details['OrderStatus'] !== '5')) {
            return true;
    }
    return false;
  }

  async refundOrder() {
    const post = {
      OrderID: this.order_details.OrderID,
      PaymentID: this.order_details.PaymentID,
      UserID: this.order_details.UserID,
      City: localStorage.getItem('partnerDataCityName'),
      RefundAmount: (Number(this.getRefundTotal()) * 100) - 1,
      ReverseTransfer: true,
      MessageForUser: this.customMessage
    };

    await this.orderService.refundOrder(post).subscribe(async data => {
      if (data['Success']) {
        this.reverseTransfer = true;
        this.customMessage = '';
        this.order_details = undefined;
        alert('Refund Initiated For Order #' + this.order_details.OrderID);
        window.location.reload();
      }
      else {
        this.reverseTransfer = true;
        this.customMessage = '';
        alert(data['Message']);
      }
    });
  }

  getOrderType(orderType) {
    if (orderType == "1") {
      return "Dine In"
    } else if (orderType == "2") {
      return "Delivery"
    } else if (orderType == "3") {
      return "Pick Up"
    }
  }

  getOrderStatus(status) {

    if (status == "0") {
      return ""
    } else if (status == "1") {
      return "In Queue"
    } else if (status == "2") {
      return "In Progress"
    } else if (status == "3") {
      return "Ready At Venue"
    } else if (status == "4") {
      return "Completed"
    } else if (status == "5") {
      return "Cancelled"
    } else if (status == "7") {
      return "Refunded"
    } else {

      return ""
    }
  }

  getOrderStatusTxt(status) {
    if (status == "0") {
      return ""
    } else if (status == "1") {
      return "As the order is still not accepted, a full refund will be issued."
    } else if (status == "2") {
      return "Currently, the order is being processed."
    } else if (status == "3") {
      return ""
    } else if (status == "4") {
      return ""
    } else if (status == "5") {
      return "As the order is canceled, a full refund will be issued."
    } else if (status == "7") {
      return "Refunds of the full or partial order amount have already been issued. Partially refunded orders are still eligible for the unrefunded amount."
    } else {
      return ""
    }
  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }


}
