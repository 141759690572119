import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Url} from "../../models/url";
import {User} from "../login/login.service";



@Injectable({
  providedIn: 'root'
})
export class MasterAccountService {
  getDeliveriesTodayURL = this.url.getDomain() + '/Driver/GetDeliveriesToday';
  getDeliveriesTodaySubDriverURL = this.url.getDomain() + '/Driver/GetDeliveriesTodaySubDriver';
  getDeliveriesFromURL = this.url.getDomain() + '/Driver/GetDeliveriesFrom';
  getDeliveriesFromSubDriverURL = this.url.getDomain() + '/Driver/getDeliveriesFromSubDriver'
  getRestaurantsForPartnerURL = this.url.getDomain() + '/Restaurants/DisplayRestaurantForPartner';
  getRestaurantsForPartnerByCityURL = this.url.getDomain() + '/Restaurants/DisplayRestaurantForPartnerByCity';
  getRestaurantsForMasterURL = this.url.getDomain() + '/Restaurants/DisplayRestaurantForMaster';
  manageStoreStatusURL = this.url.getDomain() + '/Restaurants/ManageStoreStatus';
  deleteStoreUrl = this.url.getDomain() + '/Restaurants/DeleteStore';

  deleteSubDriverURL = this.url.getDomain() + '/Driver/DeleteSubDriver';

  updateSubDriverURL = this.url.getDomain() + '/Driver/UpdateSubDriver';

  AddSubDriverURL = this.url.getDomain() + '/Driver/AddSubDriver';

  addRestaurantToDriverURL = this.url.getDomain() + '/Driver/AddRestaurantToDriver';
  addRestaurantToPartnerURL = this.url.getDomain() + '/Driver/AddRestaurantToPartner';

  addRestaurantToSubDriverURL = this.url.getDomain() + '/Driver/AddRestaurantToSubDriver';

  //this is to register a driver
  getPartnersURL = this.url.getDomain() + '/Users/GetPartners';

  getRestaurantsForDriverURL = this.url.getDomain() + '/Driver/GetRestaurantsForDriver';
  getRestaurantsForSubDriverURL = this.url.getDomain() + '/Driver/GetRestaurantsForSubDriver';

  addDriverURL = this.url.getDomain() + '/Users/AddDriver';
  loginDriverUrl = this.url.getDomain() + '/Users/LoginDriver';
  loginPartnerUrl = this.url.getDomain() + '/Users/LoginPartner';

  loginSubDriverUrl = this.url.getDomain() + '/Driver/LoginSubDriver';

  getCitiesURL = this.url.getDomain() + '/Restaurants/GetCities';

  getSendRemindCallURL = this.url.getDomain() + '/Notifications/SendRemind';




  constructor(private router:Router,private http: HttpClient, private url: Url) { }


  


  getDeliveriesFromSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesFromSubDriverURL, post, httpOptions);
  }

  getDeliveriesFrom(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesFromURL, post, httpOptions);
  }

  

  getDeliveriesTodaySubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesTodaySubDriverURL, post, httpOptions);
  }

  getDeliveriesToday(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesTodayURL, post, httpOptions);
  }


  deleteSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.deleteSubDriverURL, post, httpOptions);
  }

 



  getPartners(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getPartnersURL, post, httpOptions);
  }

  addSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.AddSubDriverURL, post, httpOptions);
  }


  getRestaurantsForPartner(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForPartnerURL, post, httpOptions);
  }

  getRestaurantsForPartnerByCity(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForPartnerByCityURL, post, httpOptions);
  }


   getRestaurantsForMaster(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForMasterURL, post, httpOptions);
  }

  

  
  

  getRestaurantsForSubDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForSubDriverURL, post, httpOptions);
  }

  getRestaurantsForDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForDriverURL, post, httpOptions);
  }

  addRestaurantToPartner(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addRestaurantToPartnerURL, post, httpOptions);
  }

  // addRestaurantToPartner(post){
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //     })
  //   };

  //   return this.http.post<User>(this.addRestaurantToDriverURL, post, httpOptions);
  // }
  addRestaurantToSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addRestaurantToSubDriverURL, post, httpOptions);
  }


  getRestaurants(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForPartnerURL, post, httpOptions);
  }

  addDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addDriverURL, post, httpOptions);
  }


  getCities(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getCitiesURL, post, httpOptions);
  }



  loginSubDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginSubDriverUrl, post, httpOptions);
  }

  


  loginPartner(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginPartnerUrl, post, httpOptions);
  }

  loginDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginDriverUrl, post, httpOptions);
  }

  sendReminderCall(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.getSendRemindCallURL, post, httpOptions);
  }

  manageStoreStatus(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.manageStoreStatusURL, post, httpOptions);
  }

  deleteStore(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.deleteStoreUrl, post, httpOptions);
  }

}
