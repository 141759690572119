import { Component, OnInit} from '@angular/core';

import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { BluetoothDriver } from './BluetoothDriver';
declare var $: any;


@Component({
  selector: 'app-printer-setup',
  templateUrl: './printer-setup.component.html',
  styleUrls: ['./printer-setup.component.css','../../../assets/css/ts-style.css', '../../../assets/css/custom.css','../../../assets/css/fonts.css', '../../../assets/css/fx.css', '../../../assets/css/magnific-popup.css']
})
export class PrinterSetupComponent implements OnInit{
  status: boolean = false;
  usbPrintDriver: UsbDriver;
  webPrintDriver: WebPrintDriver;
  bluetoothDriver: BluetoothDriver;
  ip: string = "";
  PrinterIP = "";  
  printerArray = [];
  closebtns = document.getElementsByClassName("close");
   
  constructor(private printService: PrintService,private secondPrintService:PrintService) {
    
    this.printService.isConnected.subscribe((result) => {
      this.status = result;
      if (result) {        

        if(!this.printerArray.includes(this.ip)){
          this.printerArray.push(this.ip)
          localStorage.setItem('printerIp',this.printerArray.toString())
        }
      }else{
        console.log(result);
      }    
    });  
  }

  ngOnInit(): void {
    this.secondPrintService = new PrintService
   // this.PrinterIP = 
    let x = localStorage.getItem('printerIp').split(",")
    for(let i in x){
      this.printerArray.push(x[i])
    }
  

 
    // if(this.PrinterIP != ""){
    //   this.ip = this.PrinterIP
    //   this.webPrintDriver = new WebPrintDriver(this.PrinterIP);
    //   this.printService.setDriver(this.webPrintDriver, "WebPRNT");
    // }
let zy = this
    $(function () {
    this.closebtns = document.getElementsByClassName("close");
    console.log("this.closebtns.length")

    console.log(this.closebtns.length)
    let i = 0;
    let self = zy
    for (i = 0; i < this.closebtns.length; i++) {
      this.closebtns[i].addEventListener("click", function() {

let text =  this.parentElement.textContent
text = this.parentElement.textContent.substring(0, text.length - 1);
          for(let x in self.printerArray){
            if(self.printerArray[x] == text){

              const index = self.printerArray.indexOf(self.printerArray[x]);
              if (index > -1) { // only splice array when item is found
                self.printerArray.splice(index, 1); // 2nd parameter means remove one item only
                localStorage.setItem('printerIp',self.printerArray.toString())

              }
              this.parentElement.style.display = 'none';

            }
          }
        
        
      });
    }
  })


  }

  requestBluetooth() {
    this.bluetoothDriver = new BluetoothDriver();
    this.bluetoothDriver.requestForBluetoothDevices().subscribe((result) => {
      localStorage.setItem('printerIp',"")
      this.printService.setDriver(this.bluetoothDriver, 'ESC/POS');
    });
  }

  requestUsb() {
   // this.bluetoothDriver = new BluetoothDriver();
   this.usbPrintDriver = new UsbDriver()
    this.usbPrintDriver.requestUsb().subscribe((result) => {
      localStorage.setItem('printerIp',"")

      localStorage.setItem('productId',result.productId.toString())
      localStorage.setItem('vendorId',result.vendorId.toString())
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });

  }
  
  connectToWebPrint() {

    
    this.webPrintDriver = new WebPrintDriver(this.ip);
    this.printService.setDriver(this.webPrintDriver, "WebPRNT");

  }
  
   

   

}
