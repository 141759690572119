import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditItemServiceService {

  constructor() { }

  selectedMenuItem:any;
  selectedPrimaryCategory = [];
  selectedSubCategory = [];


}
