// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans';
	src: url('OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,kDAAsE;CACtE,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;CACzB,wDAA4E;CAC5E,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;CACzB,mDAAuE;CACvE,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;CACzB,qDAAyE;CACzE,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB,gDAAmE;CACnE,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB,iDAAoE;CACpE,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,uBAAuB;CACvB,mDAAsE;CACtE,gBAAgB;CAChB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Montserrat';\n\tsrc: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');\n\tfont-weight: 700;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'Montserrat';\n\tsrc: url('../fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');\n\tfont-weight: 200;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'Montserrat';\n\tsrc: url('../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');\n\tfont-weight: 300;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'Montserrat';\n\tsrc: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');\n\tfont-weight: 400;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'OpenSans';\n\tsrc: url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');\n\tfont-weight: 700;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'OpenSans';\n\tsrc: url('../fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');\n\tfont-weight: 300;\n\tfont-style: normal;\n}\n\n@font-face {\n\tfont-family: 'OpenSans';\n\tsrc: url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');\n\tfont-weight: 400;\n\tfont-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
