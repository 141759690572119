import { Component, OnInit } from '@angular/core';
import { PastReservationsService } from "./past-reservations.service";
import { Meta } from "@angular/platform-browser";
import { ExportToCsv } from 'export-to-csv';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-past-reservations',
  templateUrl: './past-reservations.component.html',
  styleUrls: ['./past-reservations.component.css']
})
export class PastReservationsComponent implements OnInit {

  maxDate = new Date();

  reservationItems = [];
  sortDateDes = false;
  startDate = "";
  endDate = "";
  pastReservations: any;
  pastReservationTables: any;

  constructor(private pastResService: PastReservationsService, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Past Restaurant Reservations' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Past Restaurant Reservations' });
    meta.updateTag({ property: 'og:title', content: "Past Reservations" });


  }

  async ngOnInit() {

    $('#loadingBtn').hide();
    $('#filterBtn').show();

    this.getPastReservationList()
  }

  matStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value.toString();
  }

  matEndDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value.toString();
  }

  async getPastReservationList() {
    var dt = new Date();

    var month = dt.getMonth();
    var year = dt.getFullYear();
    var day = dt.getDate();

    var FirstDay = new Date(year, month, day);
    var LastDay = new Date(year, month, day + 2);


    this.startDate = FirstDay.toDateString();
    this.endDate = LastDay.toDateString();


    let start = new Date(FirstDay).toDateString();
    let end = new Date(LastDay).toDateString();

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: start,
      ToDate: end
    };

    let self = this;
    await this.pastResService.getPastReservations(post).subscribe(async data => {

      // console.log("Here in reservations");
      // console.log(data);

      $('#loadingBtn').hide();
      $('#filterBtn').show();
      if (data["Success"]) {
        this.reservationItems = [];
        for (let i in data["Response"]) {
          let responseObject = {};

          responseObject["ReservationID"] = data["Response"][i]["ReservationID"];
          responseObject["Date"] = this.getReservationDate(data["Response"][i]["StartSecInUTC"],data["Response"][i]["TimeZone"]);
          responseObject["TimeInterval"] = this.getIntervalInHour(data["Response"][i]["TimeInterval"]);
          responseObject["PartySize"] = data["Response"][i]["PartySize"];
          responseObject["GivenName"] = data["Response"][i]["GivenName"];
          responseObject["FamilyName"] = data["Response"][i]["FamilyName"];
          responseObject["Phone"] = data["Response"][i]["Phone"];
          responseObject["Email"] = data["Response"][i]["Email"];
          responseObject["Status"] = data["Response"][i]["Status"];

          this.reservationItems.push(responseObject);
        }

        let self0 = self;
        $(document).ready(function () {
          self0.pastReservationTables = $('#pastReservationTables').DataTable({
            "paging": true,
            "ordering": true,
            "info": true
          });
        });
      }

    })

  }

  getIntervalInHour(interval) {
    if (interval == 3600) {
      return "1hr";
    }
    else if (interval == 7200) {
      return "2hr";
    }
    else {
      return "3hr";
    }
  }


   convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

  getReservationDate(timestamp,timeZone) {

    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.

    
    
    var date = new Date(timestamp * 1000);

    
    return this.convertTZ(date, timeZone).toDateString() + '\n' + this.convertTZ(date, timeZone).toTimeString().substring(0,5);
  }

  async filterDates() {

    $('#loadingBtn').show();
    $('#filterBtn').hide();

    if ($.fn.DataTable.isDataTable("#pastReservationTables")) {
      $('#pastReservationTables').DataTable().clear().destroy();
    }

    let start = new Date(this.startDate).toDateString();
    //let end = new Date(this.endDate).toDateString();

    var month = new Date(this.endDate).getMonth();
    var year = new Date(this.endDate).getFullYear();
    var day = new Date(this.endDate).getDate();

    var end = new Date(year, month, day + 1).toDateString();

    console.log(start+'   '+end)
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: start,
      ToDate: end
    };


    let self = this;
    await this.pastResService.getPastReservations(post).subscribe(async data => {




      // console.log("below here is data 2");
      // console.log(data);

      $('#loadingBtn').hide();
      $('#filterBtn').show();
      if (data["Success"]) {
        this.reservationItems = [];
        for (let i in data["Response"]) {
          let responseObject = {};

          responseObject["ReservationID"] = data["Response"][i]["ReservationID"];
          responseObject["Date"] = this.getReservationDate(data["Response"][i]["StartSecInUTC"],data["Response"][i]["TimeZone"]);
          responseObject["TimeInterval"] = this.getIntervalInHour(data["Response"][i]["TimeInterval"]);
          responseObject["PartySize"] = data["Response"][i]["PartySize"];
          responseObject["GivenName"] = data["Response"][i]["GivenName"];
          responseObject["FamilyName"] = data["Response"][i]["FamilyName"];
          responseObject["Phone"] = data["Response"][i]["Phone"];
          responseObject["Email"] = data["Response"][i]["Email"];
          responseObject["Status"] = data["Response"][i]["Status"];

          this.reservationItems.push(responseObject);
        }

        let self0 = self;
        $(document).ready(function () {
          self0.pastReservationTables = $('#pastReservationTables').DataTable({
            "paging": true,
            "ordering": true,
            "info": true
          });
        });
      }



    })
  }

  sortByDate() {

    this.sortDateDes = !this.sortDateDes
    let self = this;
    this.reservationItems.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (self.sortDateDes) {
        return new Date(a.OrderDetails.Date).getTime() - new Date(b.OrderDetails.Date).getTime();
      } else {
        return new Date(b.OrderDetails.Date).getTime() - new Date(a.OrderDetails.Date).getTime();

      }
    });
  }



  exportData() {


    var data = []

    // tslint:disable-next-line: forin
    for (let x in this.reservationItems) {
      data.push({
        "ReservationID" : this.reservationItems[x]["ReservationID"],
          "Date" : this.reservationItems[x]["StartSecInUTC"],
          "TimeInterval" : this.reservationItems[x]["TimeInterval"],
          "PartySize" : this.reservationItems[x]["PartySize"],
          "GivenName" : this.reservationItems[x]["GivenName"],
          "FamilyName" : this.reservationItems[x]["FamilyName"],
          "Phone" : this.reservationItems[x]["Phone"],
          "Email" : this.reservationItems[x]["Email"],
          "Status" : this.reservationItems[x]["Status"]
      })
    }



    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TopServe Export ' + this.startDate + ' to ' + this.endDate,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }


}
