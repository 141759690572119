import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../models/url';
import {Res} from "./restaurant/restaurant.service";

@Injectable({
  providedIn: 'root'
})
export class StripeService {


  RetrieveStripeBusinessAccountURL = this.url.getDomain() + '/Stripe/RetrieveStripeBusinessAccount';
  getOAuthURL = this.url.getDomain() + '/Stripe/GetStripeOAuthToken';
  createExpressAccountURL = this.url.getDomain() + '/Stripe/CreateExpressAccount';
  linkExpressAccountURL = this.url.getDomain() + '/Stripe/LinkExpressAccount';
  updateStandardToExpressURL = this.url.getDomain() + '/Stripe/UpdateStandardToExpress';

  GetExpressLoginLinkURL = this.url.getDomain() + '/Stripe/GetExpressLoginLink';
  subDriverSetUpExpressURL = this.url.getDomain() + '/Stripe/SetUpSubDriverExpressAccount';
  retrieveSubDriverStripeBusinessAccountURL = this.url.getDomain() + '/Stripe/RetrieveSubDriverStripeBusinessAccount'
  



  constructor(private http: HttpClient, private url: Url) { }

  RetrieveStripeBusinessAccount(res) {
    return this.http.post<Res>(this.RetrieveStripeBusinessAccountURL, res);

  }

  getOAuth(res) {
    return this.http.post<Res>(this.getOAuthURL, res);

  }


  createExpressAccount(res) {
    return this.http.post<Res>(this.createExpressAccountURL, res);

  }



  getExpressLoginLink(res) {
    return this.http.post<Res>(this.GetExpressLoginLinkURL, res);

  }


  updateStandardToExpress(res) {
    return this.http.post<Res>(this.updateStandardToExpressURL, res);

  }

  retrieveSubDriverStripeBusinessAccount(res) {
    return this.http.post<Res>(this.retrieveSubDriverStripeBusinessAccountURL, res);
  }

  setUpSubDriverExpressAccount(res) {
    return this.http.post<Res>(this.subDriverSetUpExpressURL, res);
  }




  linkExpressAccount(res) {
    return this.http.post<Res>(this.linkExpressAccountURL, res);

  }



}


