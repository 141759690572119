import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

export interface Menu {
  data: any;
}
export interface Item {
  data: any;
}

@Injectable({
  providedIn: 'root'
})


export class OrderServiceService {


  getOrdersURL =  this.url.getDomain() + '/Orders/GetOrders';

  getRestaurantByCityUrl =  this.url.getDomain() + '/Orders/GetOrders';
  getRestaurantsURL =  this.url.getDomain() + '/Restaurants/DisplayRestaurant';
  getRestaurantsMealServiceURL =  this.url.getDomain() + '/Restaurants/DisplayRestaurantMealService';
  getRestaurantsCityURL =  this.url.getDomain() + '/Restaurants/DisplayRestaurantOfCity';

  getOrderStatusURL =  this.url.getDomain() + '/Orders/GetOrderStatus';


  constructor(private http: HttpClient, private url: Url) { }

  getOrder(post){
    return this.http.post(this.getOrdersURL, post);
  }

  getOrderStatus(post){
    return this.http.post(this.getOrderStatusURL, post);
  }

  getRestaurantByCity(post) {

    return this.http.get('/Restaurants', post);
  };

  getRestaurantsMealService(post) {
    return this.http.post(this.getRestaurantsMealServiceURL, post);
  }
  getRestaurantsCity(post) {
    return this.http.post(this.getRestaurantsCityURL, post);
  }
  getRestaurants(post){
    return this.http.post(this.getRestaurantsURL, post);
  }

}
