import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import {Router} from "@angular/router";
import {User} from "../login/login.service";
@Injectable({
  providedIn: 'root'
})
export class AdminService {


  deleteUserFromRestaurantURL = this.url.getDomain() + '/Users/DeleteUserFromRestaurant';

  updateAttachedUsersURL = this.url.getDomain() + '/Users/UpdateRestaurantLinkUserTypeAndRestaurant';

  getAttachedUsersURL = this.url.getDomain() + '/Users/GetUsersAttachedToRestaurant';

  constructor(private router:Router,private http: HttpClient, private url: Url) { }


  updateAttachedUsers(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.updateAttachedUsersURL, post, httpOptions);
  }

  deleteUserFromRestaurant(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.deleteUserFromRestaurantURL, post, httpOptions);
  }


  getAttachedUsers(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getAttachedUsersURL, post, httpOptions);
  }




}
