import { Component, OnInit } from '@angular/core';
import { EmpService } from "../../../emp.service";
import { ProfileService } from "../profile.service";
import { FormsModule, NgModel } from '@angular/forms';


declare var $: any;

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css', '../../../../assets/css/custom.css']
})



export class ProfileComponent implements OnInit {




    userAddress: any;
    phone: any;
    email: any;
    firstName: any;
    lastName: any;
    postalCode: any;
    city: any;
    country: any;
    oldPassword = "";
    password = "";
    passwordConfirm = "";

    constructor(private profileService: ProfileService, private emp: EmpService) { }

    ngOnInit() {

        $('.alert').hide();


        let self = this;
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        let self0 = self;
        var validation = Array.prototype.filter.call(forms, function (form) {
            let self1 = self0;
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    event.preventDefault();
                    self1.updateInformation()
                }
                form.classList.add('was-validated');
            }, false);
        });



        const post = {
            UserID: localStorage.getItem('UserID'),
            Token: localStorage.getItem('AuthenticationToken')
        };


        this.profileService.getProfile(post).subscribe(
            data => {

                if (data['Success']) {

                    this.userAddress = data["Response"][0]["UserAddress"];
                    this.phone = data["Response"][0]["Phone"];
                    this.email = data["Response"][0]["Email"];
                    this.firstName = data["Response"][0]["FirstName"];
                    this.lastName = data["Response"][0]["LastName"];
                    this.postalCode = data["Response"][0]["PostalCode"];
                    this.city = data["Response"][0]["City"];
                    this.country = data["Response"][0]["Country"];

                } else {

                }
            })


    }

    updateInformation() {

        // console.log("below is the first name");
        // console.log(this.emp.loggedInUser['FirstName']);

        const post = {
            UserID: localStorage.getItem('UserID'),
            Token: localStorage.getItem('AuthenticationToken'),
            UserAddress: this.userAddress,
            Phone: this.phone,
            Email: this.email,
            FirstName: this.firstName,
            LastName: this.lastName,
            PostalCode: this.postalCode,
            City: this.city,
            Country: this.country,
        };

        this.profileService.updateProfile(post).subscribe(
            data => {
                if (data['Success']) {
                    $('.alert').show();
                } else {

                }
            })
    }

    changePassword() {

        const post = {
            UserID: localStorage.getItem('UserID'),
            Token: localStorage.getItem('AuthenticationToken'),
            Password: this.oldPassword
        };

        const post2 = {
            UserID: localStorage.getItem('UserID'),
            Token: localStorage.getItem('AuthenticationToken'),
            Password: this.passwordConfirm
        };



        if (this.password != "" && this.passwordConfirm != "") {
            if (this.password == this.passwordConfirm) {
                this.profileService.checkPassword(post).subscribe(
                    data => {
                        if (data['Success']) {
                            this.profileService.updatePassword(post2).subscribe(
                                data => {
                                    if (data['Success']) {
                                        $('#modalRegisterForm').modal('toggle');
                                        alert("password updated")
                                    } else {
                                        alert("could not update new password");
                                    }
                                })
                        } else {
                            alert("current password incorrect");
                        }
                    });
            } else {
                alert("new passwords do not match")
            }
        } else {
            alert("please enter new password")
        }
    }



    stopAlert() {
        $('.alert').hide();
    }

}
