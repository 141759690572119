import { Component, OnInit } from '@angular/core';
import { EmpService } from "../../emp.service";
import { LoginService } from "../../structure/login/login.service";
import { Router } from "@angular/router";
import { RegisterService } from "../../structure/register/register.service";
import { PaymentService } from "../../services/payments/payment.service";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider } from "@abacritt/angularx-social-login";
import * as moment from 'assets/vendors/by_bower/moment/moment';

declare var $: any;


@Component({
  selector: 'app-top-login',
  templateUrl: './top-login.component.html',
  styleUrls: ['./top-login.component.css','../../../assets/css/aos.css', '../../../assets/css/custom.css', '../../../assets/css/ts-style.css','../../../assets/css/fonts.css', '../../../assets/css/fx.css', '../../../assets/css/magnific-popup.css']
})
export class TopLoginComponent implements OnInit {
  username: any;
  password: any;
  token: any;
  registerPassword: any;
  registerConfirmPassword: any;
  firstName: any;
  lastName: any;
  phone: any;
  loginErrorMsg = '';
  email: any;
  userImgUrl = "restaurantImg";
  userType: any;
  loading = false;
  address: any;
  registerErrorMsg = '';
  PasswordResetMsg = '';

  constructor(private authService: SocialAuthService, private paymentService: PaymentService, public emp: EmpService, private loginService: LoginService, private router: Router, private registerService: RegisterService) { }

  ngOnInit() {

    if (this.emp.isNormalUser) {
      this.router.navigateByUrl('/user-profile')
    }

    this.authService.authState.subscribe((socialUser) => {
      this.loginFBUser(socialUser);
    });

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {


          if (event.target.id == "register") {
            self1.registerErrorMsg = '';
            self1.register()
          } else {
            self1.loginErrorMsg = '';
            self1.login(false)
          }
        }
        form.classList.add('was-validated');
      }, false);
    });

    ///user is logged in.




  }




  login(isRegistering) {
  
    const post = {
      email: this.username,
      password: this.password,
      Token: this.makeRandom()
    };

    this.loginService.loginNormal(post).subscribe(
      data => {

        if (data['verified']) {

          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'user');
          this.emp.loggedInUser = data['User'][0];
          this.emp.isNormalUser = true;
          this.emp.loggedInUserType = 0;

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('FirstName', data['User'][0].FirstName);
          localStorage.setItem('LastName', data['User'][0].LastName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('StripeID', data['User'][0].StripeCustomerID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('lat', data['User'][0].Latitude);
          localStorage.setItem('long', data['User'][0].Longitude);
          localStorage.setItem('Address', data['User'][0].UserAddress);
          localStorage.setItem('Postal', data['User'][0].PostalCode);
          localStorage.setItem('Phone', data['User'][0].Phone);
          localStorage.setItem('Country', data['User'][0].Country);
          localStorage.setItem('City', data['User'][0].City);

          if (this.emp.orderedItems.length > 0) {
            this.router.navigateByUrl('/order/' + this.emp.chosenRestaurant.LinkName);
          } else {
            this.router.navigateByUrl('/user-profile');
          }
        } else {
          if (isRegistering) {
            this.registerErrorMsg = data['Message'];
          } else {
            this.loginErrorMsg = data['Message'];

          }
        }
      }
    );

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  register() {

    this.loading = true;

    this.token = this.makeRandom()

    const post = {
      Password: this.registerConfirmPassword,
      Email: this.email,
      Phone: this.phone,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: this.userImgUrl,
      UserType: 0,
      AuthenticationToken: this.token,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: 'NA',
    };


    this.paymentService.createNewCustomer(post).subscribe(data => {


      if (data['success']) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data1 => {
          if (data1['userAdded']) {
            this.username = this.email;
            this.password = this.registerConfirmPassword;


            const post3 = {
              UserID: data1['UserID'],
              UserAddress: this.address,
              Phone: this.phone,
              FirstName: this.firstName,
              LastName: this.lastName,
              UserImageURL: 'online.png',
              Lat: '0.000000',
              Long: '0.000000',
              PostalCode: 'NA',
              City: 'NA',
              Country: 'CA',
              Token: this.token
            };

            const self = this;
            this.registerService.updateUserInfo(post3).subscribe(data0 => {
              if (data0['Success']) {
                self.username = self.email;
                self.password = self.password;
                this.login(true)
              } else {

              }
            })

          } else {
            this.registerErrorMsg = data1["Message"];
            this.loading = false;
          }
        })
      } else {
        this.registerErrorMsg = data["Message"];
        this.loading = false;
      }
    });
  }

  isLoading() {
    if (this.loading) {
      return "Registering..."
    } else {
      return "Sign up"
    }
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  loginFBUser(socialUser) {

    const post = {
      email: socialUser.email,
      FBID: socialUser.id,
      Token: this.makeRandom(),
      AccessToken: socialUser.authToken
    };
    
    this.loginService.loginFB(post).subscribe(
      data => {

        if (data['verified']) {

          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'user');
          this.emp.loggedInUser = data['User'][0];
          this.emp.isNormalUser = true;
          this.emp.loggedInUserType = 0;

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('firstName', data['User'][0].FirstName);
          localStorage.setItem('lastName', data['User'][0].LastName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('StripeID', data['User'][0].StripeCustomerID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('lat', data['User'][0].Latitude);
          localStorage.setItem('long', data['User'][0].Longitude);
          localStorage.setItem('Address', data['User'][0].UserAddress);
          localStorage.setItem('Postal', data['User'][0].PostalCode);
          localStorage.setItem('Phone', data['User'][0].Phone);
          localStorage.setItem('Country', data['User'][0].Country);
          localStorage.setItem('City', data['User'][0].City);

          $('#register-modal').modal('hide');
          if (this.emp.orderedItems.length > 0) {
            this.router.navigateByUrl('/order/' + this.emp.chosenRestaurant.LinkName);
          } else {
            this.router.navigateByUrl('/user-profile');
          }

        } else {
          this.registerFBUser(socialUser);
        }
      });
  }

  registerFBUser(socialUser) {

    this.loading = true;

    this.token = this.makeRandom()

    const post = {
      Password: 'pass' + moment(),
      Email: socialUser.email,
      Phone: null,
      FirstName: socialUser.firstName,
      LastName: socialUser.lastName,
      UserImageURL: 'na',
      UserType: 0,
      AuthenticationToken: this.token,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: 'NA',
    };


    this.paymentService.createNewCustomer(post).subscribe(data => {

      if (data['success']) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data1 => {
          if (data1['userAdded']) {

            const post3 = {
              UserID: data1['UserID'],
              UserAddress: 'NA',
              Phone: null,
              FirstName: socialUser.firstName,
              LastName: socialUser.lastName,
              UserImageURL: 'NA',
              Lat: '0.000000',
              Long: '0.000000',
              PostalCode: 'NA',
              City: 'NA',
              Country: 'CA',
              Token: this.token
            };

            const self = this;
            this.registerService.updateUserInfo(post3).subscribe(data0 => {
              if (data0['Success']) {
                this.loginFBUser(socialUser);
              } else {

              }
            })

          } else {
            alert(data1["Message"])
            this.loading = false;
          }
        })
      } else {
        alert(data["Message"])
        this.loading = false;
      }

    });

  }

  generateUniqueRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  }

  forgotPassword(){

    if (!!this.username == false){
      this.PasswordResetMsg = "Please enter your email to send a reset link"
      return
    }

    const post ={
      Email: this.username,
      Token: this.generateUniqueRandomString(40)
    }
    this.PasswordResetMsg = "Loading..."

    this.loginService.forgotPassword(post).subscribe(data => {
      if(data['Success']){
        this.PasswordResetMsg = "An email with reset link has been sent to your account"
      }
      else{
        this.PasswordResetMsg = "please contact customer service"
      }
      
    })

  }

}

