// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serach{
    size: 20px;
    font-size: 20px;
    color: royalblue;
}`, "",{"version":3,"sources":["webpack://./src/app/structure/restaurant/show-restaurant/show-restaurant.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".serach{\n    size: 20px;\n    font-size: 20px;\n    color: royalblue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
