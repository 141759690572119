import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import {Router} from "@angular/router";


export interface User  {
  data: any;
}


@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  chargeStripeURL = this.url.getDomain() + '/Stripe/chargeWithPartnerCard';
  newCustomerURL = this.url.getDomain() + '/Stripe/new_customer';
  AddOrderURL = this.url.getDomain() + '/Orders/AddOrder';
  newCardURL = this.url.getDomain() + '/Stripe/new_card';
  chargeURL = this.url.getDomain() + '/Stripe/chargeWithPartner';

  PayForOrderURL = this.url.getDomain() + '/Orders/PayForOrder';
  PayForTopServeDriveOrderURL = this.url.getDomain() + '/Orders/PayForTopServeDriveOrder';
  ApplyPromoURL = this.url.getDomain() + '/Orders/GetDiscount';
  createPaymentIntentURL = this.url.getDomain() + '/Stripe/CreatePaymentIntent';
  createFarmPaymentIntentURL = this.url.getDomain() + '/Stripe/CreateStandPaymentIntent';
  updateFarmUserPaidURL = this.url.getDomain() + '/Stripe/UpdateStandUserPaid';
  updateFarmForReceiptURL = this.url.getDomain() + '/Stripe/UpdateStandPaymentIntentForReceipt';
  confirmCardPaymentURL = this.url.getDomain() + '/Stripe/ConfirmCardPayment';
  createPaymentMethodSessionURL = this.url.getDomain() + '/Stripe/CreatePaymentMethodSession';
  updateDefaultPaymentMethodURL = this.url.getDomain() + '/Stripe/updateDefaultPaymentMethod';
  verifyPaymentMethodSessionURL = this.url.getDomain() + '/Stripe/VerifyPaymentMethodSession';
  removePaymentMethodURL = this.url.getDomain() + '/Stripe/removePaymentMethod';
  createAndAttachPaymentMethodURL = this.url.getDomain() + '/Stripe/createAndAttachPaymentMethod';
  createSubscriptionURL = this.url.getDomain() + '/Stripe/createSubscription';
  retrieveSubscriptionURL = this.url.getDomain() + '/Stripe/retrieveSubscription';
  cancelSubscriptionURL = this.url.getDomain() + '/Stripe/CancelSubscription';


  constructor(private router:Router,private http: HttpClient, private url: Url) { }


  applyPromo(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.ApplyPromoURL, post, httpOptions);
  }

  PayForOrder(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.PayForOrderURL, post, httpOptions);
  }

  PayForTopServeDriveOrder(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.PayForTopServeDriveOrderURL, post, httpOptions);
  }


  chargeCustomer(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.chargeURL, post, httpOptions);
  }

  newCard(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.newCardURL, post, httpOptions);
  }

  
  AddOrder(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.AddOrderURL, post, httpOptions);
  }

  processPayment(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.chargeStripeURL, post, httpOptions);
  }

  createNewCustomer(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.newCustomerURL, post, httpOptions);
  }

  createPaymentIntent(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.createPaymentIntentURL, post, httpOptions);
  }

  createFarmPaymentIntent(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.createFarmPaymentIntentURL, post, httpOptions);
  }

  confirmCardPayment(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.confirmCardPaymentURL, post, httpOptions);
  }

  createPaymentMethodSession(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.createPaymentMethodSessionURL, post, httpOptions);
  }

  updateDefaultPaymentMethod(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.updateDefaultPaymentMethodURL, post, httpOptions);
  }

  verifyPaymentMethodSession(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.verifyPaymentMethodSessionURL, post, httpOptions);
  }

  removePaymentMethod(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.removePaymentMethodURL, post, httpOptions);
  }

  UpdateFarmUserPaid(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.updateFarmUserPaidURL, post, httpOptions);
  }

  UpdateFarmForReceipt(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.updateFarmForReceiptURL, post, httpOptions);
  }

  createAndAttachPaymentMethod(res) {
    return this.http.post<User>(this.createAndAttachPaymentMethodURL, res);
  }

  createSubscription(res) {
    return this.http.post<User>(this.createSubscriptionURL, res);
  }

  retrieveSubscription(res) {
    return this.http.post<User>(this.retrieveSubscriptionURL, res);
  }

  cancelSubscription(res) {
    return this.http.post<User>(this.cancelSubscriptionURL, res);
  }

}
