import { Component, OnInit } from '@angular/core';
import {FindOrderService} from '../find-order/find-order.service';




declare var $: any;



@Component({
  selector: 'app-find-order',
  templateUrl: './find-order.component.html',
  styleUrls: ['./find-order.component.css']
})



export class FindOrderComponent implements OnInit {


  orderedItems = [];
  sortDateDes = false;
  startDate = "";
  endDate = "";
  pastOrdersTables :any;
  orderID = 0;
  constructor(private orderService:FindOrderService) { }

  ngOnInit(): void {


    const self0 = this;

    $(document).ready(function() {

      self0.getLiveOrders();
      self0.setupPage();
    })



  }




async getLiveOrders(){


  var dt = new Date();

    var month = dt.getMonth();
    var year = dt.getFullYear();
    var day = dt.getDate();

    var FirstDay = new Date(year, month, day);
    var LastDay = new Date(year, month, day + 2);


    this.startDate = FirstDay.toDateString();
    this.endDate = LastDay.toDateString();


    let start = new Date(FirstDay).toDateString();
    let end = new Date(LastDay).toDateString();

    const post = {
      PartnerID:localStorage.getItem('PartnerID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate:start,
      ToDate:end
    };

    let self = this;




await this.orderService.getLiveOrder(post).subscribe(async data => {

      data = data[0];
      if (data["Success"]) {

        let orderedItems = []
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let orderTime0 = new Date(orderTime).toLocaleString();


          let prepareTime = new Date(data["Response"][i]["AllOrderResult"].PrepareDate).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let prepareTime0 = new Date(prepareTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["PrepareDate"] = prepareTime0;


          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };
            orders.push(t);
          }
          orderedObject["Order"] = orders;
          orderedItems.push(orderedObject);
        }
        this.orderedItems = orderedItems
        // $('#dtBasicExample').DataTable();

      }
    });
  }


  async filterByID() {


    const post = {
      PartnerID:localStorage.getItem('PartnerID'),
      Token: localStorage.getItem('AuthenticationToken'),
      OrderID:this.orderID,
    };

    let self = this;




await this.orderService.getOrderByID(post).subscribe(async data => {

      data = data[0];
      if (data["Success"]) {

        let orderedItems = []
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let orderTime0 = new Date(orderTime).toLocaleString();


          let prepareTime = new Date(data["Response"][i]["AllOrderResult"].PrepareDate).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let prepareTime0 = new Date(prepareTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["PrepareDate"] = prepareTime0;


          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };
            orders.push(t);
          }
          orderedObject["Order"] = orders;
          orderedItems.push(orderedObject);
        }
        this.orderedItems = orderedItems
        // $('#dtBasicExample').DataTable();

      }
    });

    





  }

  async filterDates() {


    if ($.fn.DataTable.isDataTable("#pastOrderedItems")) {
      $('#pastOrderedItems').DataTable().clear().destroy();
    }

    var dt = new Date();

    var month = dt.getMonth();
    var year = dt.getFullYear();
    var day = dt.getDate();

    var FirstDay = new Date(year, month, day);
    var LastDay = new Date(year, month, day + 2);


    this.startDate = FirstDay.toDateString();
    this.endDate = LastDay.toDateString();


    let start = new Date(FirstDay).toDateString();
    let end = new Date(LastDay).toDateString();

    const post = {
      PartnerID:localStorage.getItem('PartnerID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate:start,
      ToDate:end
    };

    let self = this;




await this.orderService.getLiveOrder(post).subscribe(async data => {

      data = data[0];
      if (data["Success"]) {

        let orderedItems = []
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let orderTime0 = new Date(orderTime).toLocaleString();


          let prepareTime = new Date(data["Response"][i]["AllOrderResult"].PrepareDate).toLocaleString("en-US", {timeZone: "America/Halifax"});
          let prepareTime0 = new Date(prepareTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["PrepareDate"] = prepareTime0;


          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", {timeZone: "America/Halifax"});
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };
            orders.push(t);
          }
          orderedObject["Order"] = orders;
          orderedItems.push(orderedObject);
        }
        this.orderedItems = orderedItems
        // $('#dtBasicExample').DataTable();

      }
    });
  }




  getDriverStatus(driverName){

    if(driverName.DriverName == "null" || driverName.DriverName == null || driverName.DriverName == undefined) {
        return false;
    }else{

            return true;
    }

  }


  async setupPage() {

    var self = this;

    $(document).ready(function() {
      var self0 = self;

      $('#startDate').datetimepicker({
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'LL'
      }).on('dp.change', function (e) {


        self0.startDate = e.date._d

      });



      $('#endDate').datetimepicker({
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'LL'
      }).on('dp.change', function (e) {
        var self0 = self;
        self0.endDate = e.date._d
      });

    });
  }




  getStatus(orderType){


    if(orderType == "1"){
      return "Dine In"
    }else if (orderType == "2"){
      return "Delivery"
    }else if (orderType == "3"){
      return "Pick Up"
    }else if (orderType == "4"){
      return "error"
    }else if (orderType == "5"){
      return "Cancelled"

    }else{
      return ""
    }


  }


  getDriver(driverName){

    if(driverName.DriverName == "null" || driverName.DriverName == null || driverName.DriverName == undefined) {
        return "No Driver Yet"
    }else{

        if(driverName.TrackingURL == "NA" || driverName.TrackingURL == null || driverName.TrackingURL == undefined) {
            return driverName.DriverName + ' Onway'
        } else {
            return driverName.DriverName + ' Got'

        }

      
    }

  }

  getOrderStatus(status){

    if(status == "0"){
      return ""
    }else if(status == "1") {
      return "In Queue"
    }else if(status == "2") {
      return "In Progress"
    }else if(status == "3") {
      return "Ready At Venue"
    }else if(status == "4") {
      return "Completed"
    }else if(status == "5") {
      return "Cancelled"
    }else if (status == "7") {
      return "Refunded"
    }else{

      return ""
    }

    return ""
  }

  roundNumber(num){
    var number = Math.round(num * 100) / 100;
   return number.toFixed(2);
  }


}
