import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../../models/url';

export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ReservationsSubscriptionService {

  getSubscribeSessionURL = this.url.getDomain() + '/Stripe/CreateSubscribeSession';
  verifyReservationSubscriptionSuccessURL = this.url.getDomain() + '/Stripe/VerifyReservationSubscriptionSuccess';
  cancelSubscriptionURL = this.url.getDomain() + '/Stripe/CancelReservationSubscription';
  getProductPriceURL = this.url.getDomain() + '/Stripe/GetStripeProductPrice';
  getReservationProductsURL = this.url.getDomain() + '/Products/getReservationProducts';
  createAndAttachPaymentMethodURL = this.url.getDomain() + '/Stripe/createAndAttachPaymentMethod';
  createSubscriptionURL = this.url.getDomain() + '/Stripe/createSubscription';
  retrieveSubscriptionURL = this.url.getDomain() + '/Stripe/retrieveSubscription';
  updateSubscriptionURL = this.url.getDomain() + '/Stripe/updateReservationSubscription';


  constructor(private http: HttpClient, private url: Url) { }

  getSubscribeSession(res) {
    return this.http.post<Res>(this.getSubscribeSessionURL, res);
  }

  verifyReservationSubscriptionSuccess(res) {
    return this.http.post<Res>(this.verifyReservationSubscriptionSuccessURL, res);
  }

  cancelSubscription(res) {
    return this.http.post<Res>(this.cancelSubscriptionURL, res);
  }

  getProductPrice(res) {
    return this.http.post<Res>(this.getProductPriceURL, res);
  }

  getReservationProducts(res) {
    return this.http.post<Res>(this.getReservationProductsURL, res);
  }

  createAndAttachPaymentMethod(res) {
    return this.http.post<Res>(this.createAndAttachPaymentMethodURL, res);
  }

  createSubscription(res) {
    return this.http.post<Res>(this.createSubscriptionURL, res);
  }

  retrieveSubscription(res) {
    return this.http.post<Res>(this.retrieveSubscriptionURL, res);
  }

  updateSubscription(res) {
    return this.http.post<Res>(this.updateSubscriptionURL, res);
  }

}
