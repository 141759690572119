import { Component, OnInit } from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-add-option',
  templateUrl: './add-option.component.html',
  styleUrls: ['./add-option.component.css', '../../../assets/css/custom.css']
})



export class AddOptionComponent implements OnInit {


  optionID = null;
  optionName = "";
  optionDesc = "";
  optionPrice = 0;
  showClose = true;
  isActive = true;

  constructor() { }

  ngOnInit() {
  }
  closeOption(){
    this.isActive = false;
  }
}
