import { Component, OnInit } from '@angular/core';
import { OrdersService } from "../orders.service";
import { Meta } from "@angular/platform-browser";
import { ExportToCsv } from 'export-to-csv';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

declare var $: any;


@Component({
  selector: 'app-past-orders',
  templateUrl: './past-orders.component.html',
  styleUrls: ['./past-orders.component.css']
})

export class PastOrdersComponent implements OnInit {


  maxDate = new Date();

  userType = parseInt(localStorage.getItem('restaurantUserType'));
  orderedItems = [];
  sortDateDes = false;
  startDate = "";
  endDate = "";
  orderID = 0;
  pastOrdersTables: any;
  loadingMessage = '';
  
  constructor(private ordersService: OrdersService, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Past orders' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Past orders' });
    meta.updateTag({ property: 'og:title', content: "Past orders" });


  }


  async ngOnInit() {

    this.setupPage()
    this.getPastOrders()

  }

  matStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value.toString();
  }

  matEndDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value.toString();
  }
  

  async getPastOrders() {

    $('#pastOrderSpinner').show();
    $('#filterBtn').hide();
    this.loadingMessage = 'Getting Today\'s Orders..';

    var dt = new Date();

    var month = dt.getMonth();
    var year = dt.getFullYear();
    var day = dt.getDate();

    var FirstDay = new Date(year, month, day);
    var LastDay = new Date(year, month, day);


    this.startDate = FirstDay.toDateString();
    this.endDate = LastDay.toDateString();


    let start = new Date(FirstDay).toDateString();
    let end = new Date(LastDay).toDateString();

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: start,
      ToDate: end
    };

    let self = this;
    await this.ordersService.getOrdersFromDate(post).subscribe(async data => {

      data = data[0];
      if (data["Success"]) {

        $('#pastOrderSpinner').hide();
        $('#filterBtn').show();

        if ($.fn.DataTable.isDataTable("#pastOrderedItems")) {
          $('#pastOrderedItems').DataTable().clear().destroy();
        }

        //let orderedItems = []
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          let orderTime0 = new Date(orderTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["TSFee"] = data["Response"][i]["AllOrderResult"]["TSFee"] / 100
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };
            orders.push(t);
          }
          orderedObject["Order"] = orders;
          this.orderedItems.push(orderedObject);
        }

        $(document).ready(function () {
          self.pastOrdersTables = $('#pastOrderedItems').DataTable({
            "paging": true,
            "ordering": true,
            "info": true
          });
        });
        // $('#dtBasicExample').DataTable();

      }
    });
  }


  async setupPage() {

    var self = this;

    $(document).ready(function () {

      self.pastOrdersTables = $('#pastOrderedItems').DataTable({
        "paging": true,
        "ordering": true,
        "info": true
      });

    });
  }

  getStatus(orderType) {

    if (orderType == 1) {
      return "Dine in"
    } else if (orderType == 2) {
      return "Delivery"
    } else {
      return "Pick Up"
    }

  }



  getOrderType(orderType) {


    if (orderType == "1") {
      return "Dine In"
    } else if (orderType == "2") {
      return "Delivery"
    } else if (orderType == "3") {
      return "Pick Up"
    } else if (orderType == "4") {
      return "error"
    } else if (orderType == "5") {
      return "Cancelled"

    } else {
      return ""
    }


  }


  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }
 



  async filterDates() {

    $('#pastOrderSpinner').show();
    this.loadingMessage = 'Getting Orders..';
    $('#filterBtn').hide();

    let start = new Date(this.startDate).toDateString();
    let end = new Date(this.endDate).toDateString();
    

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: start,
      ToDate: end
    };

 
  

    let self = this;
   
    await this.ordersService.getOrdersFromDate(post).subscribe(async data => {
      data = data[0];

      $('#pastOrderSpinner').hide();
      $('#filterBtn').show();
      if (data["Success"]) {
        this.orderedItems = [];
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          let orderTime0 = new Date(orderTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["TSFee"] = data["Response"][i]["AllOrderResult"]["TSFee"] / 100
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };
            orders.push(t);
          }
          orderedObject["Order"] = orders;
          this.orderedItems.push(orderedObject);
        }

        if ($.fn.DataTable.isDataTable("#pastOrderedItems")) {
          $('#pastOrderedItems').DataTable().clear().destroy();
        }

        let self0 = self;
        $(document).ready(function () {
          self0.pastOrdersTables = $('#pastOrderedItems').DataTable({
            "paging": true,
            "ordering": true,
            "info": true
          });
        });
      }



    })
    
  }
 
 

  isMenuItemFee(index) {


    try {
      return (this.orderedItems[index]['Order'][0]["Primary"]["ApplicationFee"] > 0);
    } catch (error) {
      return false
    }



  }

  getTopServeFee(index) {

    return 'Ordered Item Specific'
  }


  getOrderStatus(status) {

    if (status == "0") {
      return ""
    } else if (status == "1") {
      return "In Queue"
    } else if (status == "2") {
      return "In Progress"
    } else if (status == "3") {
      return "Completed"
    } else if (status == "4") {
      return "Completed"
    } else if (status == "5") {
      return "Cancelled"
    } else if (status == "7") {
      return "Refunded"
    } else {

      return ""
    }

    return ""
  }

  sortByDate() {

    this.sortDateDes = !this.sortDateDes
    let self = this;
    this.orderedItems.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      if (self.sortDateDes) {
        return new Date(a.OrderDetails.Date).getTime() - new Date(b.OrderDetails.Date).getTime();
      } else {
        return new Date(b.OrderDetails.Date).getTime() - new Date(a.OrderDetails.Date).getTime();

      }
    });



  }


  calculateTSFee(tsFee) {


   // var topServeFee = this.roundNumber(parseFloat(tsFee.TSFee) - parseFloat(tsFee.DeliveryFee) - parseFloat(tsFee.Tip))

    //if (parseFloat(topServeFee) <= 0) {


    
  if(tsFee.OrderType == "2"){
    console.log("here is the topserve info " + JSON.stringify(tsFee)); 
    var topServeFee = this.roundNumber(((tsFee.Subtotal * (tsFee.ApplicationFee / 100)) + (tsFee.Subtotal * (tsFee.ApplicationFee / 100)) * 0.15));
   
  }else if(tsFee.OrderType == "1"){
    console.log("here is the topserve info " + JSON.stringify(tsFee)); 
    var topServeFee = this.roundNumber(tsFee.TSFee);


  }else{
    console.log("here is the topserve info " + JSON.stringify(tsFee)); 
    var topServeFee = this.roundNumber(((tsFee.Subtotal * (tsFee.ApplicationFee / 100)) + (tsFee.Subtotal * (tsFee.ApplicationFee / 100)) * 0.05));
   
  }
    
    
    
    
     //}
    return topServeFee

  }



  exportData() {


    var data = []

    // tslint:disable-next-line: forin
    for (let x in this.orderedItems) {
      console.log("order tpye is : " + this.getStatus(this.orderedItems[x]["OrderDetails"]["OrderType"]))
      if(this.getStatus(this.orderedItems[x]["OrderDetails"]["OrderType"]) != "Delivery"){

        data.push({
          "OrderID": this.orderedItems[x]["OrderDetails"]["OrderID"],
          "Date": this.orderedItems[x]["OrderDetails"]["Date"],
          "Order Type": this.getStatus(this.orderedItems[x]["OrderDetails"]["OrderType"]),
          "Order Status": this.getOrderStatus(this.orderedItems[x]["OrderDetails"]["OrderStatus"]),
          "Items Subtotal": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["Subtotal"]),
          "Items Tax": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["Subtotal"] * 0.15),
          "Items Total": '$' + this.roundNumber(((this.orderedItems[x]["OrderDetails"]["Subtotal"] * 0.15) + this.orderedItems[x]["OrderDetails"]["Subtotal"])),
          "Net Restaurant Income": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["NetTotal"]),
          // "DeliveryFee": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["DeliveryFee"]),
          "Tip": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["Tip"]),
          "TopServe Fee": '$' + this.calculateTSFee(this.orderedItems[x]["OrderDetails"])

        })

      }else{
        
        data.push({
          "OrderID": this.orderedItems[x]["OrderDetails"]["OrderID"],
          "Date": this.orderedItems[x]["OrderDetails"]["Date"],
          "Order Type": this.getStatus(this.orderedItems[x]["OrderDetails"]["OrderType"]),
          "Order Status": this.getOrderStatus(this.orderedItems[x]["OrderDetails"]["OrderStatus"]),
          "Items Subtotal": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["Subtotal"]),
          "Items Tax": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["Subtotal"] * 0.15),
          "Items Total": '$' + this.roundNumber(((this.orderedItems[x]["OrderDetails"]["Subtotal"] * 0.15) + this.orderedItems[x]["OrderDetails"]["Subtotal"])),
          "Net Restaurant Income": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["NetTotal"]),
          // "DeliveryFee": '$' + this.roundNumber(this.orderedItems[x]["OrderDetails"]["DeliveryFee"]),
          "Tip": 'NA',
          "TopServe Fee": '$' + this.calculateTSFee(this.orderedItems[x]["OrderDetails"])

        })
      }
    }



    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TopServe Export ' + this.startDate + ' to ' + this.endDate,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);

  }

  exportMenuData() {

    let data = [];

    for (let x in this.orderedItems) {
      let item = this.orderedItems[x];
      let order = this.orderedItems[x]['Order'];
      for (let j in order) {
        let sides = order[j]['Sides'];
        if (sides.length > 0) {
          for (let k in sides) {
            let options = sides[k]['Sides'];
            for (let l in options) {
              data.push({ 
                "OrderID": item["OrderDetails"]["OrderID"],
                "Date": item["OrderDetails"]["Date"],
                "MenuItem": order[j]["Primary"]['Name'],
                "Quantity": order[j]['Primary']['Quantity'],
                "ItemPrice": order[j]['Primary']['Price'].toFixed(2),
                "optionName": options[l]['sideName'],
                "optionPrice": options[l]['sidePrice'].toFixed(2),
                "optionCategory": options[l]['sideTitle'],
                "optionItemType": options[l]['ItemType']
              });
            }
          }
        }
        else {
          data.push({ 
            "OrderID": item["OrderDetails"]["OrderID"],
            "Date": item["OrderDetails"]["Date"],
            "MenuItem": order[j]["Primary"]['Name'],
            "Quantity": order[j]['Primary']['Quantity'],
            "ItemPrice": order[j]['Primary']['Price'].toFixed(2),
            "optionName": '',
            "optionPrice": '',
            "optionCategory": '',
            "optionItemType": ''
          });
        }
      }
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TopServe Export ' + this.startDate + ' to ' + this.endDate,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }



}
