import { Component, OnInit, ɵɵresolveDocument } from '@angular/core';
import { PublishPromotionsServiceService } from './publish-promotions-service.service'


@Component({
  selector: 'app-publish-promotions',
  templateUrl: './publish-promotions.component.html',
  styleUrls: ['./publish-promotions.component.css', '../../../assets/css/custom.css']
})
export class PublishPromotionsComponent implements OnInit {

  constructor(private publishService: PublishPromotionsServiceService) { }
  eligibleMessage = ""
  publishedPromotion = []
  //restaurantsPromotionsID = []
  //restaurantID = []
  //promotionMessage = []
  //datePublished = []
  isEligible = true;
  
  ActiveSpecialID : any;

  ngOnInit(): void {
    this.getLastPublishDate();
    this.getPublishedPromotions();
    this.getCurrentSpecialID();
  }

  getLastPublishDate() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID')
    };

    this.publishService.getLastPublishDate(post).subscribe(
      data => {
        if (data['Success']) {
          const response = data['Response']
          let lastDate = String()
          if (response.length > 0) {
            lastDate = response[0]['DatePublished']
          }


          const lastPublishTime = new Date(lastDate).toLocaleString('en-US', { timeZone: 'America/Halifax' });

          // get current date
          const currentTime = new Date().toLocaleString('en-US', { timeZone: 'America/Halifax' });
          //const test = new Date(new Date(currentTime).getTime() - 1000 * 60 * 60 * 24 * 1 - 1000 * 60 * 2 - 1000*5).toLocaleString('en-US', { timeZone: 'America/Halifax' });

          // calculate time diference
          var oneSecond = 1000
          var oneMin = oneSecond * 60
          var oneHour = oneMin * 60
          var oneDay = oneHour * 24
          var oneWeek = oneDay * 7
          const t1 = new Date(lastPublishTime).getTime();
          const t2 = new Date(currentTime).getTime();
          var timeDifference = t2 - t1;

          if (timeDifference >= oneWeek) {
            this.eligibleMessage = "You are eligible to send a new promotion";
          }
          else {
            if (timeDifference > 0) {
              this.isEligible = false
              const timeBeforeEligible = oneWeek - timeDifference;
              const timeDifferenceInDays = Math.floor(timeBeforeEligible / oneDay);
              const timeDifferenceInHours = Math.floor(timeBeforeEligible % oneDay / oneHour);
              const timeDifferenceInMins = Math.floor(timeBeforeEligible % oneDay % oneHour / oneMin);
              const timeDifferenceInSeconds = Math.floor(timeBeforeEligible % oneDay % oneHour % oneMin / oneSecond);
              this.eligibleMessage = "You will be eligible to send a new promotion in " + timeDifferenceInDays + " days " +
                timeDifferenceInHours + " hours " + timeDifferenceInMins + " minutes " + timeDifferenceInSeconds + " seconds";
            }else {
              this.eligibleMessage = "Error loading publish information.";
            }
          }
        } else {
          alert("Error loading publish information: Could not get last publish date");
        }
      });
  }

  //Post promotion code to the server and notify the user if code is sent successfully
  submitPromotionCode(value: any) {
    //console.log(value.code);
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Message: localStorage.getItem('restaurantName') + ' -' +  value.code
    };

    this.publishService.getPublishPromotion(post).subscribe(
      data => {
        if (data['Success']) {
          alert("Promotion code sent successfully")
        } else {
          alert("Error loading publish information");
        }
      });
  }

  //Use a tableview to display the previous published promotions
  getPublishedPromotions() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    };

    this.publishService.getPublishedPromotions(post).subscribe(
      data => {
        if (data['Success']) {
          for (let i in data["Response"]) {
            let item = {};
            item["restaurantsPromotionsID"] = data["Response"][i]['RestaurantsPromotionsID']
            item["restaurantID"] = data["Response"][i]['RestaurantID']
            item["promotionMessage"] = data["Response"][i]['Message']
            item["datePublished"] = new Date(data["Response"][i]['DatePublished']).toLocaleString("en-US", { timeZone: "America/Halifax" });
            this.publishedPromotion.push(item);
          }
        } else {
          alert("Error loading publish information!");
        }
      });
  }

  async getCurrentSpecialID() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    };

    this.publishService.getCurrentSpecialIDForRestaurant(post).subscribe(
      data => {
        if (data['Success']) {
          this.ActiveSpecialID = data['SpecialID'];
        } else {
          alert("Could not get currently active promotion!");
        }
      });
  }

  async enableInstantPromotion(specialId) {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      SpecialID: specialId
    };

    this.publishService.enableInstantPrmotionForRestaurant(post).subscribe(
      data => {
        if(data['Success']) {
          this.ActiveSpecialID = data['SpecialID'];
        }
        else {
          alert("Could not get enable selected promotion! Please try again later!!");
        }
      });
  }

  async stopPromotion(event) {
    
    event.stopPropagation();

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      SpecialID: 0
    };

    this.publishService.enableInstantPrmotionForRestaurant(post).subscribe(
      data => {
        if(data['Success']) {
          this.ActiveSpecialID = '';
        }
      });
  }
}
