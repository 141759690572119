
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import {EmpService} from "../emp.service";
import {ProfileService} from "../structure/profile/profile.service";
import {Observable} from "rxjs";

// import decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class RoleguardService  {
  constructor(public auth: AuthService, public router: Router,private emp:EmpService,private profileService:ProfileService) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean{

    //return true
    const url: string = state.url;




    const loggedInType = localStorage.getItem('type');

    if(loggedInType == 'driver'){
      return true
    }else if (loggedInType == 'partner'){
      return true
    }




    let loggedInuserType =  localStorage.getItem('userType').toString();
    var userType = loggedInuserType;



     switch (url){

       case "/services":
         return true;

       case "/services/show-drivers":
         if(loggedInuserType == "6" || userType == "7"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/delivery-dashboard":
         if(loggedInuserType == "6" || userType == "7"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }

       case "/services/incoming-orders":
         if(userType == "5" || userType == "4" || userType == "3" || userType == "2" || userType == "1"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/past-orders":
         if(userType == "5" || userType == "4" || userType == "3" || userType == "2" || userType == "1"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/show-items":
         if(userType == "5" || userType == "4" || userType == "3" || userType == "2"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/add-item":
         if(userType == "5" || userType == "4" || userType == "3" || userType == "2"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/edit-item":
         if(userType == "5" || userType == "4" || userType == "3" || userType == "2"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }

       case "/services/add-restaurant":
         if(userType == "5" || userType == "4" || userType == "3"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }

       case "/services/show-restaurant-details":
         if(userType == "5" || userType == "4" || userType == "3"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/show-restaurant":
         if(userType == "5" || userType == "4" || userType == "3"){
           return true
         }else{
           return false
         }

       case "/services/expenditure":
         if(userType == "5" || userType == "4" ){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/expenditure-help":
         if(userType == "5" || userType == "4" ){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       case "/services/show-admin":
         if(userType == "5"){
           return true
         }else{
           this.router.navigateByUrl('/services');
           return false
         }
       default:
         this.getRoleResult(userType);
     }



     return true;




     // this.getRole().then(res => {
     //   return  res;
     // })

     //return this.getRole();




  }


  getRoleResult(userType){
    if(userType == "5"){
      return true
    }else{
      return false
    }

  }

 async getRole(){
    const post = {
      UserID: localStorage.getItem('UserID'),
      Token: localStorage.getItem('AuthenticationToken')
    };

      return new Promise<boolean>(resolve => {
         this.profileService.getProfile(post).subscribe(
            data=>{
              if (data['Success']) {
                this.emp.loggedInUser = data["Response"][0];
                this.emp.loggedInUserType = data["Response"][0]["RestaurantUserType"];

                resolve(true)
                // return true;
              }else{
                resolve(false)
              }
            }
        );
      });

  }


  }