// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-section1{
    margin-top:-15%;
    padding: 10%;
}
.how-section1 h4{
    color: #ffa500;
    font-weight: bold;
    font-size: 30px;
}
.how-section1 h5{
    color: #0ea834;
    font-weight: bold;
    font-size: 30px;
}
.how-section1 .subheading{
    color: #3931af;
    font-size: 20px;
}
.how-section1 .row
{
    margin-top: 10%;
}
.how-img
{
    text-align: center;
}
.how-img img{
    width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/app/structure/expenditure/expenditure-help/expenditure-help.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;;IAEI,eAAe;AACnB;AACA;;IAEI,kBAAkB;AACtB;AACA;IACI,UAAU;AACd","sourcesContent":[".how-section1{\n    margin-top:-15%;\n    padding: 10%;\n}\n.how-section1 h4{\n    color: #ffa500;\n    font-weight: bold;\n    font-size: 30px;\n}\n.how-section1 h5{\n    color: #0ea834;\n    font-weight: bold;\n    font-size: 30px;\n}\n.how-section1 .subheading{\n    color: #3931af;\n    font-size: 20px;\n}\n.how-section1 .row\n{\n    margin-top: 10%;\n}\n.how-img\n{\n    text-align: center;\n}\n.how-img img{\n    width: 40%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
