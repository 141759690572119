import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, AfterViewInit, Type } from '@angular/core';
import { AddReservationComponent } from "../../../components/add-reservation/add-reservation.component";
import { ViewChild } from "@angular/core";
import { ReservationsService } from '../reservations.service';

declare var $: any;


@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css','../../../../assets/css/ts-style.css']
})
export class ReservationComponent implements OnInit, AfterViewInit {
  @ViewChild('addReservationContainer', { read: ViewContainerRef }) addReservationContainer;
  @ViewChild('addReservationTableContainer', { read: ViewContainerRef }) addReservationTableContainer;



  reservation: {
    startHour: any;
    partySize: any;
    totalTables: any;
    interval: any;
    reservationID: any;
    day: any;
  }
  intervalWording = 'Time Limit';
  interval = 0;
  partySizeWording = 'Party Size';
  startDate = "";
  totalTables = 1;
  totalTablesWording = "Total Tables";
  dayWording = 'Every Day'
  mondayReservations = [];
  tuesdayReservations = [];
  wednesdayReservations = [];
  thursdayyReservations = [];
  fridayReservations = [];
  saturdayReservations = [];
  sundayReservations = [];



  mondayHours = [];
  tuesdayHours = [];
  wednesdayHours = [];
  thursdayyHours = [];
  fridayHours = [];
  saturdayHours = [];
  sundayHours = [];

  mondayAvailability = [];
  tables = [];
  availability;
  changePassword = '';

  constructor(private resolver: ComponentFactoryResolver, private resService: ReservationsService) { }

  ngOnInit(): void {

    this.changePassword = localStorage.getItem('changePassword');

  }

  ngAfterViewInit() {

    const x = this.resolver.resolveComponentFactory(AddReservationComponent);
    var t = this.addReservationContainer.createComponent(x);

    t.instance.addReservation.subscribe($event => {
      this.getAvailability();
    });

    this.getAvailability()


  }

  getAvailability() {

    //Clearing tableData and container that contains table views before creating new data
    this.removePreExistingComponents();

    const param = {
      RestaurantID: localStorage.getItem('restaurantID')
    };

    this.resService.getRestaurantTables(param).subscribe(data => {

      //console.log(data);

      this.availability = data["Response"];

      let tb = data["ResponseWithTimes"]

      tb.forEach(table => {

        let hours = this.availability.filter(filteredCat => filteredCat.RestaurantTableID == table.RestaurantTableID);
        let hoursExtracted = []
        let hoursExtracteda = hours.filter(function (e) {
          let hh = {
            "startHours": e.StartHour,
            "hourID": e.RestaurantTableHourID
          }
          hoursExtracted.push(hh)
          return e.StartHour;
        });


        let t = {
          partySize: table.PartySize,
          tableNumber:table.TableNumber,
          changeTableNumber:false,
          //interval :this.secondsToHms(table.TimeInterval),
          interval: table.TimeInterval,
          tableID: table.RestaurantTableID,
          day: table.Day,
          isEditing: false,
          hours: hoursExtracted
        }

        // this.addComponent(RestaurantTableComponentComponent, t);

       //re initialized the select 2 
      //  $('.select2').select2();

      this.tables.push(JSON.stringify(t));

    });

  })
}

  addComponent(componentClass: Type<any>, tableData) {
    // Create component dynamically inside the ng-template
    const componentFactory = this.resolver.resolveComponentFactory(componentClass);
    const component = this.addReservationTableContainer.createComponent(componentFactory);
    component.instance.table = tableData;

    component.instance.updateReservation.subscribe($event => {

      this.getAvailability();
    });

    component.instance.deleteReservation.subscribe($event => {

      this.getAvailability();
    });

    this.tables.push(tableData)
  }

  removePreExistingComponents() {
    // this.addReservationTableContainer.clear();
    this.tables = [];
  }

  convertTo24Hour(time) {
    var hours = parseInt(time.substr(0, 2));
    if (time.indexOf(' AM') != -1 && hours == 12) {
      time = time.replace('12', '0');
    }
    if (time.indexOf(' PM') != -1 && hours < 12) {
      time = time.replace(hours, (hours + 12));
    }
    return time.replace(/( AM| PM)/, '');
  }



  removeReservation(reservationID) {


    const param = {
      ReservationSettingsID: reservationID
    };

    this.resService.removeAvailability(param).subscribe(data => {
      console.log(data);
      this.getAvailability()

    });


  }


  editReservation(tableID) {


    let self = this;
    $(document).ready(function () {

      let self0 = self;
      $('#startDate-' + tableID).datetimepicker({
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: 'fa fa-arrow-left',
          next: 'fa fa-arrow-right'
        },
        format: 'HH mm'
      }).on('dp.change', function (e) {
        const x = new Date(e.date._d).getHours();
        const y = new Date(e.date._d).getMinutes();
        //  self0.startHour = x.toString() + ':' + y.toString();
      });
    })




    let hours = this.tables.filter(filteredCat => filteredCat.tableID == tableID);
    if (hours.length > 0) {

      if (hours[0].isEditing == true) {
        hours[0].isEditing = false
      } else {
        hours[0].isEditing = true
      }
    }

    // const param = {
    //   ReservationSettingsID: reservationID
    // };

    // this.resService.removeAvailability(param).subscribe(data => {
    //   console.log(data);
    //   this.getAvailability()

    // });


  }


  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    return hDisplay + mDisplay;
  }

  tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    var hh = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = hh + ts.substr(2, 3) + ampm;
    return ts;
  };










}
