import { AfterContentInit, Component, OnInit } from '@angular/core';
import { DriverService } from "../driver.service";
import { EmpService } from "../../../emp.service";
import { MasterAccountService } from 'app/structure/master-account/master-account.service';
import { OrdersService } from 'app/structure/orders/orders.service';
import { StripeService } from 'app/structure/stripe.service';


declare var $: any;

@Component({
  selector: 'app-delivery-dashboard',
  templateUrl: './delivery-dashboard.component.html',
  styleUrls: ['./delivery-dashboard.component.css']
})
export class DeliveryDashboardComponent implements OnInit, AfterContentInit {



  restaurants = [];
  
  changePassword = '';
  loginType = '';
  SubDriverStripeBusinessID = '';

  constructor(private driverService: DriverService, private emp: EmpService,  private masterServices: MasterAccountService, private ordersService: OrdersService, private stripe: StripeService) { }

  ngOnInit() {

    this.changePassword = localStorage.getItem('changePassword');
    this.loginType = localStorage.getItem('type');

    if (this.loginType !== 'partner') {
      $('#connectToStripe').hide();
      $('#loginExpress').hide();
    }

  }


  ngAfterContentInit(): void {

    if (this.loginType === 'partner') {
      this.getPartnerRestaurantsByCity();
    }
    else{
      this.retrieveDriverStripeAccount();
    }

  }

  getPartnerRestaurantsByCity() {
    
    const post = {
      PartnerID: localStorage.getItem('PartnerID'),
      City: localStorage.getItem('partnerDataCityName')
    };

    this.masterServices.getRestaurantsForPartnerByCity(post).subscribe(
        data => {
          for (let resIndex in data["Restaurants"]) {
            if(data["Restaurants"][resIndex]['HourPaused'] != null && data["Restaurants"][resIndex]['HourPaused'].includes(1)) {
              data["Restaurants"][resIndex]['Paused'] = 1;
            }
            else {
              data["Restaurants"][resIndex]['Paused'] = 0;
            }
            this.restaurants.push(data["Restaurants"][resIndex]);
          }
        }
    );
  }

  updatePausedRestaurant(resIndex, pauseStatus) {
    
    const post = {
      RestaurantID: this.restaurants[resIndex]['RestaurantID'],
      Paused: pauseStatus,
    };

    this.ordersService.updatePausedRestaurant(post).subscribe(async data => {
      this.restaurants = [];
      this.getPartnerRestaurantsByCity();
    });
  }

  sendReminderCall(resIndex) {
    const post = {
      Phone: this.restaurants[resIndex]['Phone'],
    };

    this.masterServices.sendReminderCall(post).subscribe(
      data => {
    });

    alert('Reminder Call Sent To ' + this.restaurants[resIndex]['Name']);

  }

  async loginExpress() {
    
    const post = {
      StripeBusinessID: this.SubDriverStripeBusinessID
    };

    let windowReference = window.open();

    await this.stripe.getExpressLoginLink(post).subscribe(async data => {
      if (data.hasOwnProperty("url")) {
        window.open(data["url"], "_blank");
        return;
      } else {
        alert(data['Message']);
      }
    });



  }

  async retrieveDriverStripeAccount() {
    const post = {
      SubDriverID : localStorage.getItem('DriverID')
    }

    await this.stripe.retrieveSubDriverStripeBusinessAccount(post).subscribe(async data => {
      if (data['success']) {
        this.SubDriverStripeBusinessID = data['stripeInfo'][0]['StripeBusinessID'];
        if (data['stripeInfo'][0]['ExpressAccountActive'] == 1) {
          $('#loginExpress').show();
        }
        else {
          $('#connectToStripe').show();
        }
      }
      else {
        alert('Something went wrong while getting your stripe business account information.\n\nPlease contact TopServe!')
      }
    })
  }

  async setupSubDriverExpress() {

    const post = {
      SubDriverID : localStorage.getItem('DriverID')
    }
    await this.stripe.setUpSubDriverExpressAccount(post).subscribe(async data => {

      if (data.hasOwnProperty("url")) {
        window.open(data["url"], "_blank");
        return
      } else {
        alert("there was an error that happen");
      }
    })
  }

  manageStoreStatus(i: number) {
    
    const post = {
      RestaurantID : this.restaurants[i]['RestaurantID'],
      Status: this.restaurants[i]['Status'] == 'Y' ? 'N' : 'Y',
      Email: this.restaurants[i]['EmailAddress'],
      StoreName: this.restaurants[i]['Name'],
      UserName: this.restaurants[i]['ContactName']
    }

    this.masterServices.manageStoreStatus(post).subscribe(
      data => {
        if (data['Success']) {
          this.restaurants[i]['Status'] = post['Status'];
        }
        else {
          alert(data['Message']);
        }
      });
  }

  deleteStore(i: number) {
    
    const post = {
      RestaurantID : this.restaurants[i]['RestaurantID']
    }

    this.masterServices.deleteStore(post).subscribe(
      data => {
        if (data['Success']) {
          this.restaurants.splice(i, 1);
        }
        else {
          alert(data['Message']);
        }
      });
  }

}
