import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

@Injectable({
  providedIn: 'root'
})
export class PauseMenuModifiersService {

  constructor(private http: HttpClient, private url: Url) { }

  getMenuModifierURL = this.url.getDomain() + '/Restaurants/GetAllMenuItemOptions';
  getMenuModifier(post) {
    return this.http.post<PauseMenuModifiersService>(this.getMenuModifierURL, post);
  }

  pauseModifierURL = this.url.getDomain() + '/MenuItems/PauseMenuItemModifier2';
  pauseModifier(post) {
    return this.http.post<PauseMenuModifiersService>(this.pauseModifierURL, post);
  }

  unPauseModifierURL = this.url.getDomain() + '/MenuItems/UnPauseMenuItemModifier2';
  unPauseModifier(post) {
    return this.http.post<PauseMenuModifiersService>(this.unPauseModifierURL, post);
  }
}
