import { Component, OnInit } from '@angular/core';
import { ExpenditureService } from "../expenditure.service";
import { Router } from "@angular/router";
import { Url } from "../../../models/url";
import { StripeService } from "../../stripe.service";

declare var $: any;


@Component({
  selector: 'app-expenditure',
  templateUrl: './expenditure.component.html',
  styleUrls: ['./expenditure.component.css', '../../../../assets/css/ts-style.css']
})
export class ExpenditureComponent implements OnInit {

  nextPayout = 0;
  payout: any;
  payoutsTable: any;
  payoutDay: any;
  payoutAmount: any;
  previousPayoutDay: any;
  stripeAccountID: any;
  constructor(private stripe: StripeService, private url: Url, private expenditureService: ExpenditureService, private router: Router) { }

  async ngOnInit() {

    $('#connectToStripe').hide();
    $('#tableOfPayouts').hide();


    let code = this.gup('code', window.location.href);


    await this.getStripeAccountInformation();

    if (code != null && code != undefined) {
      this.createStripeBusinessAccount(code)
    }




  }

  async getStripeAccountInformation() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserID: localStorage.getItem('UserID'),
    };

    await this.stripe.RetrieveStripeBusinessAccount(post).subscribe(async data => {
      if (data["success"]) {

        if (data["customer"]["type"] == "standard") {
          $('#connectToStripe').show();
          $('#standardLogin').show();
          $('#loginExpress').hide();


          $('#connectToStripe').hide();
          $('#tableOfPayouts').show();
        } else if (data["customer"]["type"] == "express") {
          $('#standardLogin').hide();


          if (data["stripeInfo"][0].hasOwnProperty('ExpressAccountActive')) {
            console.log('in express active')
            this.stripeAccountID = data["stripeInfo"][0]['StripeBusinessID'];
            localStorage.setItem('storeExpressActive', data["stripeInfo"][0]["ExpressAccountActive"]);

            if (data["stripeInfo"][0]['ExpressAccountActive']) {

              $('#updateBanking1').hide();
              $('#connectToStripe').hide();
              $('#tableOfPayouts').show();
              $('#loginExpress').show();


            } else {
              $('#updateBanking1').show();

              $('#connectToStripe').show();
              $('#tableOfPayouts').hide();

            }
          } else {
            console.log('in express not active')
            $('#tableOfPayouts').show();
            $('#loginExpress').hide();


          }

        } else {

          console.log("here in none")
        }





        //  $('#connectToStripe').hide();
        //  $('#tableOfPayouts').show();

        //  this.getPayoutDate();
        //  this.getPayoutInfo();
      } else {

        $('#connectToStripe').show();
        $('#tableOfPayouts').hide()

      }


      //save the account number and get the stripe weekly balance



    });

  }




  async loginExpress() {



    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserID: localStorage.getItem('UserID'),
      Email: localStorage.getItem('Email'),
      StripeBusinessID: this.stripeAccountID

    };

    let windowReference = window.open();
    await this.stripe.getExpressLoginLink(post).subscribe(async data => {
      if (data.hasOwnProperty("url")) {
        windowReference.location = data['url'];
        return;
      } else {
        alert("there was an error that happen")
      }
    });



  }


  async setupUpdateExpress() {




    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserID: localStorage.getItem('UserID'),
      Email: localStorage.getItem('Email'),
      StripeBusinessID: this.stripeAccountID

    };
    let windowReference = window.open();
    await this.stripe.updateStandardToExpress(post).subscribe(async data => {
      if (data.hasOwnProperty("url")) {
        windowReference.location = data['url'];
        return;
      } else {
        alert("there was an error that happen")
      }
    });
  }

  async setupExpress() {


    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      UserID: localStorage.getItem('UserID'),
      Email: localStorage.getItem('Email'),
      StripeBusinessID: this.stripeAccountID

    };

    let windowReference = window.open();
    await this.stripe.createExpressAccount(post).subscribe(async data => {
      if (data.hasOwnProperty("url")) {
        windowReference.location = data['url'];
        return;
      } else {
        alert("there was an error that happen")
      }
      //save the account number and get the stripe weekly balance
    });





  }




  async createStripeBusinessAccount(account) {

    const post = {
      code: account,
      restaurantID: localStorage.getItem('restaurantID'),

    };


    await this.stripe.getOAuth(post).subscribe(async data => {

      if (data["success"]) {
        this.getStripeAccountInformation();
      } else {


      }




    });



  }


  gup(name, url) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
  }



  async getPayoutInfo() {




    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
      FromDate: this.previousPayoutDay,
      ToDate: this.payoutDay,
    };








    await this.expenditureService.getDatesOrderTotal(post).subscribe(async data => {
      data = data[0];
      if (data["Success"]) {
        this.nextPayout = data["Response"][0]["TotalItemsOrdered"];
      }
    });


    const post2 = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Token: localStorage.getItem('AuthenticationToken'),
    };


    await this.expenditureService.getPayouts(post2).subscribe(async data => {
      data = data[0];


      if (data["Success"]) {
        this.payout = data["Response"];
        this.setupPage()
      }
    });
  }

  setupPage() {

    let self = this;
    $(document).ready(function () {
      self.payoutsTable = $('#payouts').DataTable({
        "paging": true,
        "ordering": true,
        "info": true
      });
    })
  }

  getDate(date) {
    return new Date(date).toDateString()
  }

  getPayoutDate() {
    var today = new Date(),
      theseFriday = this.getFriday(today.getMonth() - 1, today.getFullYear()),
      next;


    let self = this;

    theseFriday.some(function (friday, index) {
      if (index % 2 === 1 && friday > today) {
        next = friday;
        self.payoutDay = new Date(next).toDateString();
        self.previousPayoutDay = new Date(theseFriday[index - 2]).toDateString();
        return true;
      }
      return false;
    });


    if (this.previousPayoutDay == undefined && this.payoutDay == undefined) {
      this.previousPayoutDay = today;
      this.payoutDay = today;
    }



  }


  getFriday(month, year) {
    var d = new Date(year, month, 1),
      friday = [];

    d.setDate(d.getDate() + (5 - d.getDay()) % 7);
    while (d.getMonth() === month || d.getMonth() === month + 1 || d.getMonth() === month - 1) {
      friday.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    }

    return friday;
  }

  moreDetailsClicked(payoutID, payout, payoutDate) {

    this.expenditureService.payoutID = payoutID;
    this.expenditureService.payoutTotal = payout
    this.expenditureService.payoutDate = payoutDate;
    this.router.navigateByUrl('/services/expenditure-details');
    return

  }




}//eof
