import {Component, OnDestroy, OnInit} from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import {RegisterService} from "../register/register.service";
import {EmpService} from "../../emp.service";
import {Title, Meta} from "@angular/platform-browser";
import { PaymentService } from 'app/services/payments/payment.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../../assets/css/ts-style.css']
})

export class LoginComponent implements OnInit {
  email = '';
  password = '';
  loginErrorMsg = '';
  
  constructor(public emp:EmpService,private loginService: LoginService, private paymentService: PaymentService, private router: Router,private registerService:RegisterService, private meta: Meta, private title: Title) {
    title.setTitle('Restaurant Login Management Portal | TopServe');
    meta.updateTag({name: 'description', content: 'Login to your profile to access restaurants in your area.'});
    meta.updateTag({property: 'og:title', content: 'Restaurant Login Management Portal | TopServe'});
  }

  ngOnInit() {

      let self = this;
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }else{
            self.login();
          }
          form.classList.add('was-validated');
        }, false);
      });
  }
  
  login() {
  
    $('#progress-modal').modal('show');
    this.loginErrorMsg = '';

    const post = {
      email: this.email,
      password: this.password,
      Token: this.makeRandom()
    };

    this.loginService.login(post).subscribe(
      async data => {
        $('#progress-modal').modal('hide');
        if (data['verified']) {

          if(data['User'][0].UserType == 0){
            alert("it seems this is a user login and not a restaurant login");
            this.router.navigateByUrl('/top-login');
            return
          }

          if (data['User'].length > 0 && data['User'][0].UserType != 0 && this.password.toLowerCase() == 'password') {
            localStorage.setItem('changePassword','true');
          }

          if(data['User'][0]['StripeCustomerID'] == 'NA' || data['User'][0]['StripeCustomerID'] == 'null' || data['User'][0]['StripeCustomerID'] == null ) {
            data['User'][0]['StripeCustomerID'] = await this.updateStripeCustomerID(this.email, data['User'][0]['UserID']);
          }
          
          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'admin');
          this.emp.loggedInUser = data['User'][0];

          if(data['Restaurants'].length>0) {

            this.emp.loggedInUserType = data["Restaurants"][0]["UserType"];
            localStorage.setItem("allRestaurants", JSON.stringify(data['Restaurants']));
            localStorage.setItem('restaurantName', data["Restaurants"][0]["Name"]);
            localStorage.setItem('restaurantID', data["Restaurants"][0]["RestaurantID"]);
            localStorage.setItem('restaurantLatitude', data["Restaurants"][0]["Latitude"]);
            localStorage.setItem('restaurantLongitude', data["Restaurants"][0]["Longitude"]);
            localStorage.setItem('restaurantContactName', data["Restaurants"][0]["ContactName"]);
            localStorage.setItem('restaurantAddress', data["Restaurants"][0]["Address1"]);
            localStorage.setItem('restaurantPhone', data["Restaurants"][0]["Phone"]);
            localStorage.setItem('restaurantUserType', data["Restaurants"][0]["UserType"]);
            localStorage.setItem('restaurantTimeZone', data["Restaurants"][0]["TimeZone"]);
            localStorage.setItem('ownDelivery', data["Restaurants"][0]["OwnDelivery"]);
            localStorage.setItem('restaurantType', data["Restaurants"][0]["RestaurantType"]);
            localStorage.setItem('LinkName', data["Restaurants"][0]["LinkName"]);
            localStorage.setItem('restaurantOrderTypeIDs', data["Restaurants"][0]["OrderTypeIDs"]);
            localStorage.setItem('restaurantOrderTypeNames', data["Restaurants"][0]["OrderTypeNames"]);
            localStorage.setItem('restaurantOrderTypeStatuses', data["Restaurants"][0]["OrderTypeStatuses"]);
            localStorage.setItem('restaurantOrderTypePercentages', data["Restaurants"][0]["OrderTypePercentages"]);
            localStorage.setItem('storeOtFeePercentages', data["Restaurants"][0]["OrderTypeFeePercentages"]);
            localStorage.setItem('storeOtFeePrices', data["Restaurants"][0]["OrderTypeFeePrices"]);
            localStorage.setItem('Reservations', data["Restaurants"][0]["Reservations"]);
            localStorage.setItem('ReservationProductID', data["Restaurants"][0]["ReservationProductID"]);
            localStorage.setItem('ReservationPriceID', data["Restaurants"][0]["ReservationPriceID"]);
            localStorage.setItem('DineInProductID', data["Restaurants"][0]["DineInProductID"]);
            localStorage.setItem('DineInPriceID', data["Restaurants"][0]["DineInPriceID"]);
            localStorage.setItem('POS', data["Restaurants"][0]["POS"]);
            localStorage.setItem('restaurantStripeBusinessID', data["Restaurants"][0]["StripeBusinessID"]);
            localStorage.setItem('storeExpressActive', data["Restaurants"][0]["ExpressAccountActive"]);
            localStorage.setItem('storeType', data["Restaurants"][0]["Type"]);
            localStorage.setItem('storeDescription', data["Restaurants"][0]["Description"]);
            localStorage.setItem('storeCity', data["Restaurants"][0]["City"]);

            if (data["Restaurants"][0]["OnlineOrderSubscriptionID"] != null) {
              localStorage.setItem('OnlineOrderSubscriptionID', data["Restaurants"][0]["OnlineOrderSubscriptionID"]);
            }

            if (data["Restaurants"][0]["DineInSubscriptionID"] != null) {
              localStorage.setItem('DineInSubscriptionID', data["Restaurants"][0]["DineInSubscriptionID"]);
            }
            else {
              localStorage.setItem('DineInSubscriptionID', '');
            }

            if (data["Restaurants"][0]["ReservationSubscriptionID"] != null) {
              localStorage.setItem('ReservationSubscriptionID', data["Restaurants"][0]["ReservationSubscriptionID"]);
            }
            else {
              localStorage.setItem('ReservationSubscriptionID', '');
            }

          }
          else {
            localStorage.setItem("allRestaurants", "null");
            localStorage.setItem('restaurantID', "null");
            localStorage.setItem('restaurantName', "null");
            localStorage.setItem('restaurantLatitude', "null");
            localStorage.setItem('restaurantLongitude', "null");
            localStorage.setItem('restaurantContactName', "null");
            localStorage.setItem('restaurantAddress', "null");
            localStorage.setItem('restaurantPhone', "null");
            localStorage.setItem('restaurantUserType','null');
            localStorage.removeItem('restaurantTimeZone');
            localStorage.removeItem('ownDelivery');
            localStorage.removeItem('restaurantType');
            localStorage.removeItem('LinkName');
            localStorage.removeItem('restaurantOrderTypeIDs');
            localStorage.removeItem('restaurantOrderTypeNames');
            localStorage.removeItem('restaurantOrderTypePercentages');
            localStorage.setItem('DineInSubscriptionID', '');
            localStorage.setItem('ReservationSubscriptionID', '');
            localStorage.removeItem('restaurantStripeBusinessID');
            localStorage.removeItem('POS');
            this.emp.loggedInUserType = 6
          }

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('firstName', data['User'][0].FirstName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('restaurantStripeCustomerID', data['User'][0].StripeCustomerID);
          
          this.router.navigateByUrl('/services');
        } else {
          this.loginErrorMsg = data['Message'];
        }
      }
    );

  }

  async updateStripeCustomerID(email, UserID) {
    return new Promise(resolve => {
      this.paymentService.createNewCustomer({email}).subscribe(customerData => {
        if (customerData["success"]) {
  
          const post1 = {
            UserID: UserID,
            StripeCustomerID : customerData['customerId']
          }
          this.registerService.updateRestaurantUserStripeID(post1).subscribe(data1 => {
            resolve(customerData['customerId']);
          });
        }
        else {
          resolve('NA');
        }
      });
    });
  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  validateEmail(email) {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test if email matches the pattern
    return pattern.test(email);
  }

  checkFormValidation() {
    console.log('Here')
    if (!this.validateEmail(this.email) || this.password.trim() == '') {
      return true;
    }
    else {
      return false;
    }
  }

  generateUniqueRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
  }

  sendForgotPasswordLink(){

    const post ={
      Email: this.email,
      Token: this.generateUniqueRandomString(40)
    }

    this.loginService.forgotPassword(post).subscribe((data) =>{
      if (data['Success']) {
        $('#forgot-password-modal').modal('hide');
        $('#email-sent-modal').modal('show');
      }
      else {
        $('#forgot-password-modal').modal('hide');
        alert('Something went wrong! Could not send reset password link to your email!/');
      }
    });
  }

}
