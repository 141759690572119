// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-images {
  width: 80px;
  height: 80px;
}

.login-container {
  border-radius: 0 0 10px 10px;
}

.login-card{
  border-radius: 10px;
}

.ts-modal-width {
  max-width: 80%;
  margin: auto;
}

.modal-content {
  padding: 12px;
  border-radius: 20px;
}

.modal-header {
  padding-bottom: 12px;
}

@media (max-width: 576px) {
  .ts-modal-width {
    min-width: 100%;
    margin: auto;
  }

  .modal-content {
    border-radius: 0px;
  }

  .modal-dialog {
    margin: 0%;
    height: 100%;
  }
}
@media screen and (min-width: 576px) {
  .login-card {
    width: 80% !important;
  }
  .login-images {
    width: 100px;
    height: 100px;
  }
}

@media screen and (min-width: 768px) {
  .main {
    min-height: 100vh;
  }
  .login {
    height: 50%;
    width: 80%;
    margin: 0 auto;
    flex-grow: 1;
  }
  .login-container {
    border-radius: 0 10px 10px 0;
  }
  .login-card {
    width: 100% !important;
    height: 530px;
    max-width: 800px !important;
    border-radius: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/structure/top-login/top-login.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,eAAe;IACf,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,UAAU;IACV,YAAY;EACd;AACF;AACA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,YAAY;IACZ,aAAa;EACf;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,UAAU;IACV,cAAc;IACd,YAAY;EACd;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,sBAAsB;IACtB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;EACrB;AACF","sourcesContent":[".login-images {\n  width: 80px;\n  height: 80px;\n}\n\n.login-container {\n  border-radius: 0 0 10px 10px;\n}\n\n.login-card{\n  border-radius: 10px;\n}\n\n.ts-modal-width {\n  max-width: 80%;\n  margin: auto;\n}\n\n.modal-content {\n  padding: 12px;\n  border-radius: 20px;\n}\n\n.modal-header {\n  padding-bottom: 12px;\n}\n\n@media (max-width: 576px) {\n  .ts-modal-width {\n    min-width: 100%;\n    margin: auto;\n  }\n\n  .modal-content {\n    border-radius: 0px;\n  }\n\n  .modal-dialog {\n    margin: 0%;\n    height: 100%;\n  }\n}\n@media screen and (min-width: 576px) {\n  .login-card {\n    width: 80% !important;\n  }\n  .login-images {\n    width: 100px;\n    height: 100px;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  .main {\n    min-height: 100vh;\n  }\n  .login {\n    height: 50%;\n    width: 80%;\n    margin: 0 auto;\n    flex-grow: 1;\n  }\n  .login-container {\n    border-radius: 0 10px 10px 0;\n  }\n  .login-card {\n    width: 100% !important;\n    height: 530px;\n    max-width: 800px !important;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
