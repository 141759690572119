import { Component, OnInit } from '@angular/core';
import { ExpenditureService } from "../expenditure/expenditure.service";
import { EmpService } from "../../emp.service";
import { Meta } from "@angular/platform-browser";
import { SwPush } from '@angular/service-worker';
import { NotificationService } from '../notification.service';
import { RestaurantService } from '../restaurant/restaurant.service';
const AWS = require('aws-sdk');

declare var $: any;
declare var jQuery: any;
declare var autosize: any;
declare var Ladda: any;
declare var Chartist: any;
declare var c3: any;

@Component({
  selector: 'app-dashboards',
  templateUrl: './alpha.html',
  styleUrls: ['./alpha.css', '../../../assets/css/ts-style.css']
})

export class DashboardsAlpha implements OnInit {

  currentOrderType = "OutStanding";
  firstName: any;
  payoutTotal = 0.00;

  orderCount = 0;
  payoutDay: any;

  userType = parseInt(localStorage.getItem('userType'));

  changePassword = '';
  restaurantLinkName = '';

  standDescription = '';

  loggedInType = '';

  serviceTitle = '';

  isCustomerPayingServiceFees = false;

  constructor(public emp: EmpService, private expenditureService: ExpenditureService, private meta: Meta, private swPush: SwPush, private service:NotificationService, private resService: RestaurantService) {

    meta.updateTag({ name: 'description', content: 'TopServe Home' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Home' });
    meta.updateTag({ property: 'og:title', content: "Home" });

  }

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }

  async ngOnInit() {

    this.changePassword = localStorage.getItem('changePassword');

    this.standDescription = localStorage.getItem('storeDescription');

    const otIndex = localStorage.getItem('restaurantOrderTypeNames').split(',').indexOf('1');
    const FeePercentage = Number(localStorage.getItem('storeOtFeePercentages').split(',')[otIndex]);
    const FeePrice = Number(localStorage.getItem('storeOtFeePrices').split(',')[otIndex]);

    this.isCustomerPayingServiceFees = FeePrice != 0 && FeePercentage != 0;

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.restaurantLinkName = localStorage.getItem('LinkName');

    this.manageStoreSetup();

    this.getRestaurantHours();
  }

  checkReservationEnabled() {
    return localStorage.getItem('Reservations') === '1' && localStorage.getItem('ReservationSubscriptionID') != ''
  }

  checkDineInEnabled() {
    return localStorage.getItem('DineInSubscriptionID') != ''
  }

  checkRestaurantId() {
    return localStorage.getItem('allRestaurants') !== 'null';
  }

  redirectToSubscription(type) {
    if (this.checkRestaurantId() && type === 'reservation') {
      window.location.href = '/services/reservations-subscription';
    }
    else if (this.checkRestaurantId() && type === 'dine-in') {
      window.location.href = '/services/dine-in-subscription';
    }
    else {
      alert('Please add your restaurant before enabling features!!');
    }
  }

  chargeFeesToCustomer() {
    
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      chargeFeesToCustomer: !this.isCustomerPayingServiceFees
    }

    this.resService.manageCustomerServiceFee(post).subscribe(async data => {
      if (data['Success']) {
        this.isCustomerPayingServiceFees = !this.isCustomerPayingServiceFees;
        localStorage.setItem('storeOtFeePercentages', data['FeePercentage'] + ',' + data['FeePercentage'] + ',' + data['FeePercentage']);
        localStorage.setItem('storeOtFeePrices', data['FeePrice'] + ',' + data['FeePrice'] + ',' + data['FeePrice']);
        if (data['FeePercentage']  != 0) {
          alert('Charging service fees to customers is now enabled!');
        }
        else {
          alert('Charging service fees to customers is now disabled!');
        }
      }
      else{
        alert(data['Message']);
      }
      
    });
  }



  filterOrder(orderType) {
    this.currentOrderType = orderType;
  }



  getFriday(month, year) {
    var d = new Date(year, month, 1),
      friday = [];

    d.setDate(d.getDate() + (5 - d.getDay()) % 7)
    while (d.getMonth() === month) {
      friday.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
    }

    return friday;
  }

  updateStoreDescription() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Description: this.standDescription,
    }


    this.resService.updateStoreDescription(post).subscribe(async data => {

      if (data['Success']) {
        $('#description-modal').modal('hide');
        localStorage.setItem('storeDescription', this.standDescription);
      }
      else{
        alert('Something went wrong! Could not update description!' + data['Message']);
      }
      
    });
  }

  downloadImage() {
    let imageUrl = "https://topserve-qr.s3.ca-central-1.amazonaws.com/" + localStorage.getItem('restaurantID') + "qr.jpeg";
    
    fetch(imageUrl)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'farm-stand-qr-image.jpg';
      link.click();
      window.URL.revokeObjectURL(url);
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  async getRestaurantHours() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    }

    this.resService.getResturantHours(post).subscribe(async data => {

      if (data['Success']) {
        localStorage.setItem('storeHoursExist', data['Hours'].toString());
      }
      else{
        alert('Something went wrong! Could not check for hours of opetation!' + data['Message']);
      }
      
    });
  }

  manageStoreSetup() {

    this.loggedInType = localStorage.getItem('type');

    if (this.loggedInType !== 'partner' && this.loggedInType !== 'driver' && localStorage.getItem('storeType') !== 'sellerstand' && localStorage.getItem('restaurantType') == '1') {
      // $('#new-store-modal').modal('show')
    }
  }

  reservationsEnabled() :boolean {
    return localStorage.getItem('Reservations') == '1' && localStorage.getItem('ReservationSubscriptionID') != '';
  }

  onlineOrderingEnabled() : boolean {
    return localStorage.getItem('OnlineOrderSubscriptionID') !== null && localStorage.getItem('OnlineOrderSubscriptionID') !== '';
  }

  qrPayEnabled(): boolean {
    return localStorage.getItem('LinkName') != null && localStorage.getItem('LinkName') !== 'null' && localStorage.getItem('LinkName') !== '';
  }


}

