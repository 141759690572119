import { Component, NgZone, OnInit } from '@angular/core';
import { EmpService } from "../../../emp.service";
import { Url } from '../../../models/url';
import { PlatformInfoService } from '../../platform-info/platform-info.service';
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
const AWS = require('aws-sdk');
import * as moment from 'assets/vendors/by_bower/moment/moment';
import { ContactlessService } from './contactless.service';
import { loadStripe } from '@stripe/stripe-js';
import { RegisterService } from 'app/structure/register/register.service';
import { RestaurantService } from 'app/structure/restaurant/restaurant.service';
import { ContactlessDineInService } from 'app/structure/contactless-dine-in/contactless-dine-in.service';
import { LoginService } from 'app/structure/login/login.service';
import { Router } from '@angular/router';
import { ProductService } from '../product-reservations/product.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { environment } from 'environments/environment.prod';

declare var $: any;
declare var AOS: any;


@Component({
  selector: 'app-product-contactless',
  templateUrl: './product-contactless.component.html',
  styleUrls: ['./product-contactless.component.css', '../../../../assets/css/aos.css', '../../../../assets/css/custom.css', '../../../../assets/css/ts-style.css', '../../../../assets/css/fonts.css', '../../../../assets/css/fx.css', '../../../../assets/css/magnific-popup.css']
})
export class ProductContactlessComponent implements OnInit {

  constructor(public emp: EmpService, private url: Url, private platformService: PlatformInfoService, private meta: Meta, private title: Title, private sanitizer: DomSanitizer, private zone: NgZone, private tableOderingService: ContactlessService, private registerService: RegisterService, private paymentService: PaymentService, private resService: RestaurantService, private contactlessDineInService: ContactlessDineInService, private loginService: LoginService,  private router: Router, private productService: ProductService) {
    title.setTitle('Contactless Dining & Table Ordering | TopServe')
    meta.updateTag({ name: 'description', content: 'Contactless dining and table ordering enables your guest to view, order, and pay from your restaurant\'s menu. It greatly improves table turnover and can reduce friction in the dine-in process. Check it out.' });
    meta.updateTag({ property: 'og:title', content: 'Contactless Dining & Table Ordering | TopServe' });
    meta.updateTag({ name: 'keywords', content: 'contactless dine in,table order app,order at table app,order to table,contactless card on phone,contactless mobile phone payment,contactless pay with phone, pay at table, table solutions' });

  }


  stripePromise = loadStripe(environment.stripeKey);

  isMobile = false;

  //Restaurant User Contact Info Parameters
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  password = 'password'
  userInfoErrorMsg = '';
  NewUserID = '';

  //Restaurant Info Parameters
  restaurantName = '';
  description = '';
  restaurantLogo: any;
  fileInput: any;
  image = '';
  restaurantLogoSelected = false;
  restaurantLat = 0.0;
  restaurantLng = 0.0;
  address1 = '';
  address = '';
  city = '';
  province = '';
  country = '';
  postal = '';
  restaurantAddress = '';
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  restaurantInfoErrorMsg = '';
  newRestaurantID = '';
  newRestaurantImageName = '';

  subscriptionID = '';
  stripeErrorMsg = '';
  isLoggingIn = false;

  selectedPlan = '1';
  tableTopsCount = '';
  customTableTopErrorMsg = '';

  products = [];

  ngOnInit(): void {
    var my_awesome_script = document.createElement('script');

    my_awesome_script.setAttribute('src','https://js-na1.hs-scripts.com/7764233.js');
    
    document.head.appendChild(my_awesome_script);
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }

    $(function () {
      AOS.init();
    });

    $(document).ready(function ($) {
      var pAgree = '1';
      $('.video-popup').each(function (indx, el) {
        if ($(el).closest('#gallery').length === 0) {

          $(el).magnificPopup({
            type: 'iframe',
            iframe: {
              patterns: {
                youtube: {
                  index: 'www.youtube.com/',
                  id: 'v=',
                  src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                }
                , vimeo: {
                  index: 'vimeo.com/',
                  id: '/',
                  src: 'https://player.vimeo.com/video/%id%?autoplay=1'
                }
              }
            },
            disableOn: function () {
              if (!pAgree || pAgree !== '1') {
                return false;
              }
              return true;
            }
          });
        }
      });

    });

    let self = this;

    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          // if (event.target.id == 'restaurantInfoForm') {
          //   self.restaurantLogoSelected = self.restaurantLogo == undefined;
          // }
          event.preventDefault();
          event.stopPropagation();
        } else {

          if (event.target.id == 'restaurantUserInfoForm') {
            self.register();
          }
          else if (event.target.id == 'restaurantInfoForm') {
            self.uploadRestaurantInfo();
          }
          else if(event.target.id == 'customTableTopsForm') {
            self.sendMessage('2');
          }
        }
        form.classList.add('was-validated');
      }, false);
    });

    this.getTableTopProducts();
    this.verifySession();
  }

  /*---- User and Restaurant Registration Code Starts ----*/
  register() {

    const post = {
      Password: this.password,
      Email: this.email,
      Phone: this.phoneNumber,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: 'contactlessimg',
      UserType: 5,
      AuthenticationToken: this.makeRandom(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {
      if (data["success"]) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data => {

          if (data["userAdded"]) {
            this.userInfoErrorMsg = '';
            this.NewUserID = data['UserID'];
            $('.carousel').carousel(1);
            $('.progress_inner__bar').width('25%');
    
            const post2 = {
              FirstName: this.firstName,
              Email: this.email
            }
    
            this.productService.sendRestaurantRegistrationSuccessMail(post2).subscribe(
              data => {
            });
    
          } else {
            this.userInfoErrorMsg = data['Message'];
          }
    
        });
      }
      else {
        this.userInfoErrorMsg = data['message'];
      }

    });
  }

  addRestaurant() {

    var ot = {};
    
    ot["type"] = '1';
    ot["type2"] = '2';
    ot["type3"] = '3';

    const post = {
      UserInfo: {
        UserID: this.NewUserID,
      },

      RestaurantInformation: {
        Name: this.restaurantName,
        Description: this.description,
        Address1: this.address1,
        Address2: this.address1,
        City: this.city,
        Country: this.country,
        Province: this.province,
        Postal: this.postal,
        Category: '',
        Phone: this.phoneNumber,
        Status: '0',
        EmailAddress: this.email,
        Rating: '4.7',
        ContactName: this.firstName + ' ' + this.lastName,
        Longitude: this.restaurantLng,
        Latitude: this.restaurantLat,
      },
      RestaurantHours: [
        {
          Day: '0',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '1',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '2',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '3',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '4',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '5',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '6',
          Open: '00:00:00',
          Close: '00:00:00',
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },

      ]
    };


    post["OrderTypes"] = ot;

    this.resService.addRes(post).subscribe(
      data => {

        if (data[0].hasOwnProperty('ImageURL')) {

          this.newRestaurantID =  data[0]['RestaurantID'];
          this.newRestaurantImageName = data[0]['ImageURL'];
          this.upload();
          $('.carousel').carousel(2);
          $('.progress_inner__bar').width('50%');

          //Set email and id of restaurant in local storage for login on success payment
          localStorage.setItem('tempRestaurantEmail', this.email);
          localStorage.setItem('tempRestaurantID', this.newRestaurantID);
        } else {
          alert('Something went wrong! Please try again later.')
        }
      }
    );
    // }

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  /*---- User and Restaurant Registration Code Ends ----*/

  /*---- Selecting and Uploading Restaurant Logo Image Code Starts ----*/
  fileEvent(fileInput: any) {
    this.fileInput = fileInput.target.files[0];
    const file = fileInput.target.files[0];
    if (file != undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
        this.restaurantLogo = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        this.restaurantLogoSelected = false;
      }
    }
  }

  clearImage(event) {
    this.fileInput = undefined;
    this.restaurantLogo = undefined;
    event.stopPropagation();
  }

  async upload() {
    return new Promise(resolve => {
      const AWSService = AWS;
      const region = 'ca-central-1';

      let bucketName = "";
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-restaurant-main-images-dev-canada';
      } else {
        bucketName = 'topserve-restaurant-main-images-prod-canada';
      }

      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });

      if (this.fileInput != null || this.fileInput != undefined) {
        this.image = this.fileInput;
      } else {
        this.image = this.restaurantLogo
      }

      if (this.image != '' && this.image != undefined) {
        s3.upload({
          Key: this.newRestaurantImageName,
          Bucket: bucketName,
          Body: this.image
        }, function (err, data) {
          if (err) {
            resolve(false)
          } else {
            resolve(true)
          }
        });
      }
      else {
        resolve(true)
      }
    });
  }

  async uploadRestaurantInfo() {
    // if (this.restaurantLogo == undefined) {
    //   this.restaurantLogoSelected = true;
    // }
    // else {
    //   if (this.address1 == '' || this.province == '' || this.country == '' || this.postal == '' || this.restaurantLat == 0.0 || this.restaurantLng == 0.0) {
    //     this.restaurantInfoErrorMsg = 'Please select a valid restaurant address from dropdown.'
    //   }
    //   else {
    //     this.addRestaurant();
    //   }
    // }
    if (this.address1 == '' || this.province == '' || this.country == '' || this.postal == '' || this.restaurantLat == 0.0 || this.restaurantLng == 0.0) {
      this.restaurantInfoErrorMsg = 'Please select a valid restaurant address from dropdown.'
    }
    else {
      this.addRestaurant();
    }
  }
  /*---- Selecting and Uploading Restaurant Logo Image Code Ends ----*/

  /*---- Selecting Plan and Sneding Email Code Starts ----*/
  purchaseTableTops() {
    if (this.selectedPlan == '2') {
      $('#sendEmailModal').modal('show');
    }
    else {
      $('.carousel').carousel(3);
      $('.progress_inner__bar').width('75%');
    }
  }

  sendMessage(selectedPlan) {

    var info = 'Table Ordering Partner\n\n';

    if (selectedPlan == '1') {

      info += 'Selected Plan = Ligt Plan.\n\n';
      
      info += 'Restaurant Resgistered & Subscribed Successfully.\n\n';

      let product = JSON.parse(localStorage.getItem('tempProduct'));

      info += 'Email = ' + localStorage.getItem('tempRestaurantEmail') + ' | Name = ' + localStorage.getItem('tempFirstName') + ' ' + localStorage.getItem('tempLastName') + ' | Phone = ' + localStorage.getItem('tempPhoneNumber') + ' | Restaurant Name = ' + localStorage.getItem('tempRestaurantName') + ' | Address = ' + localStorage.getItem('tempRestaurantAddress') + ', '+ localStorage.getItem('tempRestaurantCity') + ', ' + localStorage.getItem('tempPostalCode') + ', '+ localStorage.getItem('tempRestaurantCountry') + '\n\n';

      info += 'Product Subscribed = ' + product['ProductName'] + ' | Product Price = $' + product['ProductPrice'] + ' per month.';

    }
    else {

      info += 'Selected Plan = Spark Plan.\n\n'

      info += 'Email = ' + this.email + ' | Name = ' + this.firstName + ' ' + this.lastName + ' | Phone = ' + this.phoneNumber + ' | Restaurant Name = ' + this.restaurantName + ' | Address = ' + this.restaurantAddress + ' | Number of TableTops = ' + this.tableTopsCount;
    }

    const post = {
      Message: info,
    };


    this.platformService.sendMessage(post).subscribe(
      data => {
        if (data['Success']) {
          this.email = '';
          this.firstName = '';
          this.lastName = '';
          this.phoneNumber = '';
          this.restaurantName = '';
          this.restaurantAddress = '';
          this.tableTopsCount = '';
          this.description = '';
          this.restaurantLogo = undefined;
          this.selectedPlan = '1';
          $('.carousel').carousel(0);
          $('.progress_inner__bar').width('0%');
          $('#customTableTopsForm').removeClass('was-validated');
          $('#restaurantUserInfoForm').removeClass('was-validated');
          $('#restaurantInfoForm').removeClass('was-validated');
          $('#sendEmailModal').modal('hide');

          localStorage.removeItem('tempProduct');
          localStorage.removeItem('tempFirstName');
          localStorage.removeItem('tempLastName');
          localStorage.removeItem('tempPhoneNumber');
          localStorage.removeItem('tempRestaurantName');
          localStorage.removeItem('tempRestaurantAddress');
          localStorage.removeItem('tempRestaurantCity');
          localStorage.removeItem('tempRestaurantCountry');
          localStorage.removeItem('tempPostalCode');

          if (selectedPlan != '1') {
            alert("Thank you we will email you back within 24 hours.");
          }
        } else {
          this.email = '';
          this.firstName = '';
          this.lastName = '';
          this.phoneNumber = '';
          this.restaurantName = '';
          this.restaurantAddress = '';
          this.tableTopsCount = '';
          this.description = '';
          this.restaurantLogo = undefined;
          this.selectedPlan = '1';
          $('.carousel').carousel(0);
          $('.progress_inner__bar').width('0%');
          $('#customTableTopsForm').removeClass('was-validated');
          $('#restaurantUserInfoForm').removeClass('was-validated');
          $('#restaurantInfoForm').removeClass('was-validated');
          $('#sendEmailModal').modal('hide');

          localStorage.removeItem('tempProduct');
          localStorage.removeItem('tempFirstName');
          localStorage.removeItem('tempLastName');
          localStorage.removeItem('tempPhoneNumber');
          localStorage.removeItem('tempRestaurantName');
          localStorage.removeItem('tempRestaurantAddress');
          localStorage.removeItem('tempRestaurantCity');
          localStorage.removeItem('tempRestaurantCountry');
          localStorage.removeItem('tempPostalCode');
          
          alert("Error submitting your request, please call 1-902-432-4244 for inquires.");
        }
      })
  }
  /*---- Selecting Plan and Sneding Email Code Starts ----*/

  /*---- Getting Table Ordering Products and Integrating Stripe Code Starts ----*/
  getTableTopProducts() {
    this.tableOderingService.tableTopProducts({}).subscribe(
      data => {
        if (data['Success']) {

          for (let i in data['Response']) {
            if (i === '0') {
              data['Response'][i]['selected'] = true;
            }
            else {
              data['Response'][i]['selected'] = false;
            }
            this.products.push(data['Response'][i]);
          }
        }
      });
  }

  selectProduct(i: number) {
    this.products.filter(i => i.selected = false);
    this.products[i]['selected'] = true;
  }

  async createTableTopCheckoutSession() {

    let product = this.products.find(i => i.selected == true);

    localStorage.setItem('tempProduct',JSON.stringify(product));
    localStorage.setItem('tempFirstName', this.firstName);
    localStorage.setItem('tempLastName', this.lastName);
    localStorage.setItem('tempPhoneNumber', this.phoneNumber);
    localStorage.setItem('tempRestaurantName', this.restaurantName);
    localStorage.setItem('tempRestaurantAddress', this.address1);
    localStorage.setItem('tempRestaurantCity', this.city);
    localStorage.setItem('tempRestaurantCountry', this.country);
    localStorage.setItem('tempPostalCode', this.postal);

    const post = {
      Product: product,
    }
    await this.tableOderingService.getTableTopProductCheckoutSession(post).subscribe(async data => {

      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data['sessionId']
      })

      if (error) {
        console.log(error);
      }
    });

  }
  /*---- Getting Table Ordering Products and Integrating Stripe Code Ends ----*/

  /*---- Verify Stripe Success-Failure URL Session Code Starts ----*/
  async verifySession() {
    if (window.location.href.split('?').pop().includes('session_id')) {
      $('.carousel').removeClass('slide');
      $('.carousel').carousel(3);
      $('.progress_inner__bar').width('75%');
      $('.carousel').addClass('slide');
      $('#new-stripe-check-modal').modal('show');
      $('#new-stripe-spinner').show();
      $('#new-stripe-success').hide();
      $('#new-stripe-cancel').hide();
      
      const post = {
        RestaurantID: localStorage.getItem('tempRestaurantID'),
        session_id : window.location.href.split('=').pop()
      }
      
      await this.contactlessDineInService.verifyDineInSubscribeSuccess(post).subscribe(async data => {
        
        if(data['Success']) {

          const post2 = {
            RestaurantID: localStorage.getItem('tempRestaurantID'),
            Name : '1'
          }

          this.tableOderingService.EnableRestaurantOrderType(post2).subscribe(async data => {
            $('#new-stripe-spinner').hide();
            if(data['enabledOrderType']) {
              $('#new-stripe-success').show();
              localStorage.removeItem('tempRestaurantID');
              this.sendMessage('1');
            }
            else {
              $('#new-stripe-cancel').show();
              this.stripeErrorMsg = 'Error: Your payment is successful. Unfortunately, Something went wrong! Please contact support to inform about this situation!';
            }
          });
        }
        else {
          $('#new-stripe-spinner').hide();
          $('#new-stripe-cancel').show();
          if(data['SubscriptionID'] !== undefined) {
            this.subscriptionID = data['SubscriptionID'];
            this.stripeErrorMsg = 'Error: Your payment is successful. ' + data['message'] + ' Please contact support to inform about this situation!';
          }
          else {
            this.subscriptionID = '';
            this.stripeErrorMsg = data['message'];
          }
        }
      })
      
    }
    else if (window.location.href.split('?').pop().includes('failure=true')) {
      $('.carousel').removeClass('slide');
      $('.carousel').carousel(3);
      $('.progress_inner__bar').width('75%');
      $('.carousel').addClass('slide');
      $('#new-stripe-check-modal').modal('show');
      $('#new-stripe-spinner').hide();
      $('#new-stripe-success').hide();
      $('#new-stripe-cancel').show();
      this.stripeErrorMsg = 'Error: Payment Failure!\nPlease try again!';
    }

  }
  /*---- Verify Stripe Success-Failure URL Session Code Ends ----*/

  /*---- Login Restaurant On Success Stripe Payment Code Starts ----*/

  loginRestaurant() {
    const post = {
      email: localStorage.getItem('tempRestaurantEmail'),
      password: this.password,
      Token: this.makeRandom()
    };

    this.isLoggingIn = true;
    $('#new-stripe-check-modal').modal('show');
    $('#new-stripe-spinner').show();
    $('#new-stripe-success').hide();
    $('#new-stripe-cancel').hide();

    this.loginService.login(post).subscribe(
      data => {

        localStorage.removeItem('tempRestaurantEmail');

        if (data['verified']) {

          this.isLoggingIn = false;
          $('#new-stripe-check-modal').modal('hide');
          $('#new-stripe-spinner').hide();
          $('#new-stripe-success').hide();
          $('#new-stripe-cancel').hide();

          if(data['User'][0].UserType == 0){
            alert("it seems this is a user login and not a restaurant login");
            this.router.navigateByUrl('/top-login');
            return
          }

          if (data['User'].length > 0 && data['User'][0].UserType != 0 && this.password.toLowerCase() == 'password') {
            localStorage.setItem('changePassword','true');
          }
          
          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'admin');
          this.emp.loggedInUser = data['User'][0];


          if(data['Restaurants'].length>0) {
            this.emp.loggedInUserType = data["Restaurants"][0]["UserType"];
            localStorage.setItem("allRestaurants", JSON.stringify(data['Restaurants']));
            localStorage.setItem('restaurantName', data["Restaurants"][0]["Name"]);
            localStorage.setItem('restaurantID', data["Restaurants"][0]["RestaurantID"]);
            localStorage.setItem('restaurantLatitude', data["Restaurants"][0]["Latitude"]);
            localStorage.setItem('restaurantLongitude', data["Restaurants"][0]["Longitude"]);
            localStorage.setItem('restaurantContactName', data["Restaurants"][0]["ContactName"]);
            localStorage.setItem('restaurantAddress', data["Restaurants"][0]["Address1"]);
            localStorage.setItem('restaurantPhone', data["Restaurants"][0]["Phone"]);
            localStorage.setItem('restaurantUserType', data["Restaurants"][0]["UserType"]);
            localStorage.setItem('restaurantTimeZone', data["Restaurants"][0]["TimeZone"]);
            localStorage.setItem('ownDelivery', data["Restaurants"][0]["OwnDelivery"]);
            localStorage.setItem('restaurantType', data["Restaurants"][0]["RestaurantType"]);
            localStorage.setItem('LinkName', data["Restaurants"][0]["LinkName"]);
            localStorage.setItem('restaurantOrderTypeIDs', data["Restaurants"][0]["OrderTypeIDs"]);
            localStorage.setItem('restaurantOrderTypeNames', data["Restaurants"][0]["OrderTypeNames"]);
            localStorage.setItem('restaurantOrderTypeStatuses', data["Restaurants"][0]["OrderTypeStatuses"]);
            localStorage.setItem('restaurantOrderTypePercentages', data["Restaurants"][0]["OrderTypePercentages"]);
            localStorage.setItem('storeOtFeePercentages', data["Restaurants"][0]["OrderTypeFeePercentages"]);
            localStorage.setItem('storeOtFeePrices', data["Restaurants"][0]["OrderTypeFeePrices"]);
            localStorage.setItem('Reservations', data["Restaurants"][0]["Reservations"]);
            localStorage.setItem('ReservationProductID', data["Restaurants"][0]["ReservationProductID"]);
            localStorage.setItem('ReservationPriceID', data["Restaurants"][0]["ReservationPriceID"]);
            localStorage.setItem('DineInProductID', data["Restaurants"][0]["DineInProductID"]);
            localStorage.setItem('DineInPriceID', data["Restaurants"][0]["DineInPriceID"]);
            localStorage.setItem('POS', data["Restaurants"][0]["POS"]);
            localStorage.setItem('restaurantStripeBusinessID', data["Restaurants"][0]["StripeBusinessID"]);
            localStorage.setItem('storeExpressActive', data["Restaurants"][0]["ExpressAccountActive"]);
            localStorage.setItem('storeType', data["Restaurants"][0]["Type"]);
            localStorage.setItem('storeDescription', data["Restaurants"][0]["Description"]);
            localStorage.setItem('storeCity', data["Restaurants"][0]["City"]);
            
            if (data["Restaurants"][0]["OnlineOrderSubscriptionID"] != null) {
              localStorage.setItem('OnlineOrderSubscriptionID', data["Restaurants"][0]["OnlineOrderSubscriptionID"]);
            }
            
            if (data["Restaurants"][0]["DineInSubscriptionID"] != null) {
              localStorage.setItem('DineInSubscriptionID', data["Restaurants"][0]["DineInSubscriptionID"]);
            }
            else {
              localStorage.setItem('DineInSubscriptionID', '');
            }

            if (data["Restaurants"][0]["ReservationSubscriptionID"] != null) {
              localStorage.setItem('ReservationSubscriptionID', data["Restaurants"][0]["ReservationSubscriptionID"]);
            }
            else {
              localStorage.setItem('ReservationSubscriptionID', '');
            }

          }
          else {
            localStorage.setItem("allRestaurants", "null");
            localStorage.setItem('restaurantID', "null");
            localStorage.setItem('restaurantName', "null");
            localStorage.setItem('restaurantLatitude', "null");
            localStorage.setItem('restaurantLongitude', "null");
            localStorage.setItem('restaurantContactName', "null");
            localStorage.setItem('restaurantAddress', "null");
            localStorage.setItem('restaurantPhone', "null");
            localStorage.setItem('restaurantUserType','null');
            localStorage.removeItem('restaurantTimeZone');
            localStorage.removeItem('ownDelivery');
            localStorage.removeItem('restaurantType');
            localStorage.removeItem('LinkName');
            localStorage.removeItem('restaurantOrderTypeIDs');
            localStorage.removeItem('restaurantOrderTypeNames');
            localStorage.removeItem('restaurantOrderTypePercentages');
            localStorage.setItem('DineInSubscriptionID', '');
            localStorage.setItem('ReservationSubscriptionID', '');
            localStorage.removeItem('restaurantStripeBusinessID');
            localStorage.removeItem('POS');
            this.emp.loggedInUserType = 6
          }

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('firstName', data['User'][0].FirstName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('restaurantStripeCustomerID', data['User'][0].StripeCustomerID);
          
          this.router.navigateByUrl('/services');

        } else {
          this.isLoggingIn = false;
          $('#new-stripe-check-modal').modal('hide');
          $('#new-stripe-spinner').hide();
          $('#new-stripe-success').hide();
          $('#new-stripe-cancel').hide();
          this.router.navigateByUrl('/login');
        }
      }
    );

  }

  /*---- Login Restaurant On Success Stripe Payment Code Ends ----*/
  
  scroll() {
    document.getElementById('request-table-tops-form').scrollIntoView({ behavior: 'smooth' });
  }

  rotateIcon(divId, iconId) {

    if (!$('#' + divId).is(":visible")) {
      $('#' + iconId).removeClass('fa-chevron-down');
      $('#' + iconId).addClass('fa-chevron-down open');
    }
    else {
      $('#' + iconId).removeClass('fa-chevron-down open');
      $('#' + iconId).addClass('fa-chevron-down');
    }
  }



}
