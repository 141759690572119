import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Url} from "../../models/url";
import {User} from "../login/login.service";

@Injectable({
  providedIn: 'root'
})
export class DriverService {



  getDeliveriesTodayURL = this.url.getDomain() + '/Driver/GetDeliveriesToday';
  getDeliveriesTodaySubDriverURL = this.url.getDomain() + '/Driver/GetDeliveriesTodaySubDriver';
  getDeliveriesFromURL = this.url.getDomain() + '/Driver/GetDeliveriesFrom';
  getDeliveriesFromSubDriverURL = this.url.getDomain() + '/Driver/getDeliveriesFromSubDriver';
  



  getRestaurantsURL = this.url.getDomain() + '/Restaurants/DisplayRestaurantForDriver';

  deleteSubDriverURL = this.url.getDomain() + '/Driver/DeleteSubDriver';

  updateSubDriverURL = this.url.getDomain() + '/Driver/UpdateSubDriver';

  AddSubDriverURL = this.url.getDomain() + '/Driver/AddSubDriver';

  addRestaurantToDriverURL = this.url.getDomain() + '/Driver/AddRestaurantToDriver';
  addRestaurantToSubDriverURL = this.url.getDomain() + '/Driver/AddRestaurantToSubDriver';

  //this is to register a driver
  getSubDriverURL = this.url.getDomain() + '/Driver/GetSubDriver';

  getRestaurantsForDriverURL = this.url.getDomain() + '/Driver/GetRestaurantsForDriver';
  getRestaurantsForSubDriverURL = this.url.getDomain() + '/Driver/GetRestaurantsForSubDriver';

  addDriverURL = this.url.getDomain() + '/Users/AddDriver';
  loginDriverUrl = this.url.getDomain() + '/Users/LoginDriver';
  loginPartnerUrl = this.url.getDomain() + '/Users/LoginPartner';

  loginSubDriverUrl = this.url.getDomain() + '/Driver/LoginSubDriver';

  getCitiesURL = this.url.getDomain() + '/Restaurants/GetCities';

  getDriverProductsURL = this.url.getDomain() + '/Products/getDriverProducts';
  getDriverProductCheckoutSessionURL = this.url.getDomain() + '/Products/getDriverProductCheckoutSession';
  registerSubDriverURL = this.url.getDomain() + '/Driver/RegisterSubDriver';
  updateSubDriverPasswordURL = this.url.getDomain() + '/Driver/UpdateSubDriverPassword'

  constructor(private router:Router,private http: HttpClient, private url: Url) { } 


  getDeliveriesFromSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesFromSubDriverURL, post, httpOptions);
  }

  getDeliveriesFrom(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesFromURL, post, httpOptions);
  }

  

  getDeliveriesTodaySubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesTodaySubDriverURL, post, httpOptions);
  }

  getDeliveriesToday(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getDeliveriesTodayURL, post, httpOptions);
  }


  deleteSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.deleteSubDriverURL, post, httpOptions);
  }

  updateSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.updateSubDriverURL, post, httpOptions);
  }



  getSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.getSubDriverURL, post, httpOptions);
  }

  addSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<User>(this.AddSubDriverURL, post, httpOptions);
  }


  


  getRestaurantsForSubDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForSubDriverURL, post, httpOptions);
  }

  getRestaurantsForDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsForDriverURL, post, httpOptions);
  }

  addRestaurantToDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addRestaurantToDriverURL, post, httpOptions);
  }
  addRestaurantToSubDriver(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addRestaurantToSubDriverURL, post, httpOptions);
  }


  getRestaurants(post){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getRestaurantsURL, post, httpOptions);
  }

  addDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.addDriverURL, post, httpOptions);
  }
  getCities(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.getCitiesURL, post, httpOptions);
  }



  loginSubDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginSubDriverUrl, post, httpOptions);
  }

  


  loginPartner(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginPartnerUrl, post, httpOptions);
  }

  loginDriver(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };



    return this.http.post<User>(this.loginDriverUrl, post, httpOptions);
  }

  driverProducts(post) {
    return this.http.post(this.getDriverProductsURL, post);
  }

  getDriverProductCheckoutSession(post) {
    return this.http.post(this.getDriverProductCheckoutSessionURL, post);
  }

  signUpNewDriver(post) {
    return this.http.post(this.registerSubDriverURL, post);
  }

  updateSubDriverPassword(post) {
    return this.http.post(this.updateSubDriverPasswordURL, post);
  }
}
