import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

export interface Menu {
  data: any;
}
export interface Item {
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class PlatformInfoService {

  sendInfoURL =  this.url.getDomain() + '/Mail/SendInfoMail';
  constructor(private http: HttpClient, private url: Url) { }




sendMessage(post){
  return this.http.post(this.sendInfoURL, post);
}


}