import { Component, OnInit } from '@angular/core';
import { DriverService } from "../driver.service";
import { Meta } from "@angular/platform-browser";
import { ExportToCsv } from 'export-to-csv';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

declare var $: any;


@Component({
  selector: 'app-delivery-expenditure',
  templateUrl: './delivery-expenditure.component.html',
  styleUrls: ['./delivery-expenditure.component.css']
})
export class DeliveryExpenditureComponent implements OnInit {

  maxDate = new Date();
  tipTotals = 0;
  deliveries = [];
  startDate = "";
  endDate = "";
  pastOrdersTables: any;
  rowTotal = 0;
  subDrivers = [];
  subDriverSelected = "Select Driver"
  subDriverSelectedID = localStorage.getItem('DriverID');

  constructor(private driverService: DriverService, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Delivery Expenditure' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Delivery Expenditure' });
    meta.updateTag({ property: 'og:title', content: "Delivery Expenditure" });

  }

  ngOnInit() {
    $('#loadingBtn').hide();
    $('#filterBtn').show();

    // this.getDeliveries();
    this.setupPage()
    this.getDeliveries();
    this.getUsers();

  }

  matStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value.toString();
  }

  matEndDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value.toString();
  }


  calculateRowTotal(total) {

    this.rowTotal += total;
    return total
  }



  setupPage() {

    var self = this;

    $(document).ready(function () {
      var self0 = self;

      $.fn.dataTable.Api.register('sum()', function () {
        return this.flatten().reduce(function (a, b) {
          if (typeof a === 'string') {
            a = a.toString();
            a = a.replace(/[^\d.-]/g, '') * 1;
          }
          if (typeof b === 'string') {
            b = b.toString()
            b = b.replace(/[^\d.-]/g, '') * 1;
          }

          return a + b;
        }, 0);
      });


      this.pastOrdersTables.on('search.dt', function () {
        self0.rowTotal = self0.pastOrdersTables.column(12, { "filter": "applied" }).data().sum();
        self0.tipTotals = self0.pastOrdersTables.column(9, { "filter": "applied" }).data().sum();
      });


      self0.pastOrdersTables = $('#pastOrderedItems').DataTable({
        "paging": true,
        "ordering": true,
        "info": true
      });




    });






  }


  getTimeZone(date) {



    return new Date(date).toLocaleString("en-CA", { timeZone: "America/Halifax" });


  }




  async filterDates() {


    if (this.startDate === '' || this.endDate === '') {
      alert('Add a date range');
      return
    }

    $('#loadingBtn').show();
    $('#filterBtn').hide();


    if ($.fn.DataTable.isDataTable("#pastOrderedItems")) {
      $('#pastOrderedItems').DataTable().clear().destroy();
    }


    let start = new Date(this.startDate).toDateString();
    let end = new Date(this.endDate).toDateString();

    let driverID = localStorage.getItem('DriverID');
    let driverType = localStorage.getItem('userType');

    if ((driverID != null || driverID != undefined) && driverType === '9') {

      const post = {
        DriverID: this.subDriverSelectedID,
        Token: localStorage.getItem('AuthenticationToken'),
        FromDate: start,
        ToDate: end
      };



      let self = this;
      await this.driverService.getDeliveriesFrom(post).subscribe(async data => {
        $('#loadingBtn').hide();
        $('#filterBtn').show();

        if (data["Success"]) {

          this.deliveries = [];
          this.rowTotal = 0;
          this.tipTotals = 0;
          for (let dIndex in data["Response"]) {
            this.deliveries.push(data["Response"][dIndex])
            this.rowTotal += this.deliveries[dIndex].Tip + this.deliveries[dIndex].DeliveryFee + this.deliveries[dIndex].TopUp
            this.tipTotals += this.deliveries[dIndex].Tip
            //  this.setupPage()
          }
          let self0 = self;
          this.setupPage()
          // $(document).ready(function() {

          //     self0.pastOrdersTables = $('#pastOrderedItems').DataTable({
          //       "paging": true,
          //       "ordering": true,
          //       "info": true
          //     });



          //   $.fn.dataTable.Api.register( 'sum()', function ( ) {
          //     return this.flatten().reduce( function ( a, b ) {
          //       if ( typeof a === 'string' ) {
          //        a = a.toString();
          //        a = a.replace(/[^\d.-]/g, '') * 1;
          //       }
          //       if ( typeof b === 'string' ) {
          //         b = b.toString()
          //         b = b.replace(/[^\d.-]/g, '') * 1;
          //       }

          //       return a + b;
          //     }, 0 );
          //   } );
          //   //search

          // });


          // this.pastOrdersTables.on('search.dt', function() {
          //   self0.rowTotal = self0.pastOrdersTables.column(8, {"filter": "applied"} ).data().sum();
          //   self0.tipTotals = self0.pastOrdersTables.column( 6, {"filter": "applied"} ).data().sum();

          // });
        }
      })

    } else {


      const post = {
        DriverID: this.subDriverSelectedID,
        Token: localStorage.getItem('AuthenticationToken'),
        FromDate: start,
        ToDate: end
      };



      let self = this;
      await this.driverService.getDeliveriesFromSubDriver(post).subscribe(async data => {
        $('#loadingBtn').hide();
        $('#filterBtn').show();

        if (data["Success"]) {
          this.deliveries = [];
          this.rowTotal = 0;
          this.tipTotals = 0;
          for (let dIndex in data['Response']) {
            this.deliveries.push(data["Response"][dIndex])
            this.rowTotal += this.deliveries[dIndex].Tip + this.deliveries[dIndex].DeliveryFee + this.deliveries[dIndex].TopUp
            this.tipTotals += this.deliveries[dIndex].Tip
          }
          let self0 = self;



          // $(document).ready(function() {

          //     self0.pastOrdersTables = $('#pastOrderedItems').DataTable({
          //       "paging": true,
          //       "ordering": true,
          //       "info": true
          //     });



          //   $.fn.dataTable.Api.register( 'sum()', function ( ) {
          //     return this.flatten().reduce( function ( a, b ) {
          //       if ( typeof a === 'string' ) {
          //        a = a.toString();
          //        a = a.replace(/[^\d.-]/g, '') * 1;
          //       }
          //       if ( typeof b === 'string' ) {
          //         b = b.toString()
          //         b = b.replace(/[^\d.-]/g, '') * 1;
          //       }

          //       return a + b;
          //     }, 0 );
          //   } );
          //   //search

          // });


          // this.pastOrdersTables.on('search.dt', function() {
          //   self0.rowTotal = self0.pastOrdersTables.column( 12, {"filter": "applied"} ).data().sum();
          //   self0.tipTotals = self0.pastOrdersTables.column( 9, {"filter": "applied"} ).data().sum();

          // });
          this.setupPage()
        }
      })




    }
  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }


  async getDeliveries() {

    let driverID = localStorage.getItem('DriverID');
    let driverType = localStorage.getItem('userType');

    if ((driverID != null || driverID != undefined) && driverType === '9') {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        DriverID: this.subDriverSelectedID
      };



      await this.driverService.getDeliveriesToday(post).subscribe(async data => {
        this.rowTotal = 0;
        this.tipTotals = 0;

        if (data["Success"]) {
          for (let dIndex in data["Response"]) {
            this.deliveries.push(data["Response"][dIndex])
            this.tipTotals += this.deliveries[dIndex].Tip
            this.rowTotal += this.deliveries[dIndex].Tip + this.deliveries[dIndex].DeliveryFee + this.deliveries[dIndex].TopUp

          }
        }
      })

    } else {

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        DriverID: driverID
      };



      await this.driverService.getDeliveriesTodaySubDriver(post).subscribe(async data => {
        this.rowTotal = 0;
        this.tipTotals = 0;
        if (data["Success"]) {
          for (let dIndex in data["Response"]) {
            this.deliveries.push(data["Response"][dIndex])
            this.tipTotals += this.deliveries[dIndex].Tip
            this.rowTotal += this.deliveries[dIndex].Tip + this.deliveries[dIndex].DeliveryFee + this.deliveries[dIndex].TopUp

          }
        }
      })


    }
  }


  getMinutes(date1, date2, estimateTime) {


    let startTime = new Date(date1);
    let endTime = new Date(date2);
    let diff = endTime.getTime() - startTime.getTime();
    let dif = diff - estimateTime
    return (Math.floor((dif / 1000) / 60) + ' minutes')


  }


  async getUsers() {


    ///get the users

    let partnerID = localStorage.getItem('PartnerID');

    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      PartnerID: partnerID
    };


    await this.driverService.getSubDriver(post).subscribe(async data => {
      if (data["Success"]) {
        for (let subDrive in data["SubDriver"]) {

          this.subDrivers.push({
            "Name": data["SubDriver"][subDrive]["FirstName"],
            "RestaurantName": data["SubDriver"][subDrive]["Name"],
            "SubDriverID": data["SubDriver"][subDrive]["SubDriverID"],
            "Phone": data["SubDriver"][subDrive]["Phone"],
            "Editing": false,
          })

        }


      }
    })//end of subscriib


  }

  subDriverClicked(index) {
    this.subDriverSelected = this.subDrivers[index].Name
    this.subDriverSelectedID = this.subDrivers[index].SubDriverID
    this.filterDates();

  }


  exportData() {


    var data = []

    // tslint:disable-next-line: forin
    for (let x in this.deliveries) {
      data.push({
        "OrderID": this.deliveries[x]["OrderID"],
        "Date": this.getTimeZone(this.deliveries[x]["Date"]),
        "Prep Time": this.deliveries[x]["EstimatedTime"],
        "Completed": this.getTimeZone(this.deliveries[x]["DeliveryCompletedDate"]),
        "Delivery Time": this.getMinutes(this.deliveries[x]["Date"], this.deliveries[x]["DeliveryCompletedDate"], this.deliveries[x]["EstimatedTime"]),
        "Driver Name": this.deliveries[x]["DriverName"],
        "Restaurant": this.deliveries[x]["RestaurantName"],
        "User Address": this.deliveries[x]["UserAddress"],
        "KM Travelled": this.deliveries[x]["KMTravelled"],
        'Tip': '$' + (this.roundNumber(this.deliveries[x]["Tip"])),
        "Driver Topup": '$' + this.roundNumber(this.deliveries[x]["TopUp"] + this.deliveries[x]["DeliveryFee"]),
        "Total": '$' + this.roundNumber(this.deliveries[x]["Tip"] + this.deliveries[x]["DeliveryFee"] + this.deliveries[x]["TopUp"]),

      })
    }



    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'TopServe Export ' + this.startDate + ' to ' + this.endDate,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

}
