import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Url } from 'app/models/url';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  getResWebPushSubscriptionURL = this.url.getDomain() + '/Restaurants/SubscribeWebPushNotification';

  constructor(private http: HttpClient, private url: Url) { }

  subscribeWebPush(post){
    return this.http.post(this.getResWebPushSubscriptionURL, post);
  }

}
