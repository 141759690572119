import { Component, OnInit, NgZone, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from "@angular/common"; import { MenuService } from "../menu/menu.service";
import { Title, Meta } from '@angular/platform-browser';
// import { } from "@agm/core/services/google-maps-types";
import { EmpService } from "../../emp.service";
import { PlatformInfoService } from '../platform-info/platform-info.service';

declare var $: any;
declare var AOS: any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css', '../../../assets/css/aos.css', '../../../assets/css/custom.css', '../../../assets/css/ts-style.css', '../../../assets/css/fonts.css', '../../../assets/css/fx.css', '../../../assets/css/magnific-popup.css'],

})
export class LandingComponent implements OnInit {

  autocompleteInput: string;

  name = "";
  email = "";
  message = "";
  lat = 0.0;
  lng = 0.0;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  address1 = "";
  address = "";
  address2 = "";
  city = "";
  province = "";
  country = "";
  postal = "";
  formattedAddress: string;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  // Variables for Partner Form
  partnerName = '';
  partnerCompany = '';
  partnerEmail = '';
  partnerPhone = '';
  partnerAddress = '';
  partnerExtraInfo = '';
  partnerClickedOn = '';

  constructor(private location: Location, private service: MenuService, private platformService: PlatformInfoService, private meta: Meta, private title: Title, private zone: NgZone, public emp: EmpService, private router: Router, private renderer: Renderer2) {
    title.setTitle('Order Restaurant Food Delivery Online & Take Out | TopServe')
    meta.updateTag({ name: 'description', content: 'Order food for fast and reliable delivery. Find all your local favorite restaurants with cheap delivery rates. You can find exactly what your in the mood food. Try it out today.' });
    meta.addTag({ name: 'robots', content: 'INDEX, FOLLOW' }, true);
    meta.updateTag({ name: 'twitter:description', content: 'Order food delivery and take out online in charlottetown prince edward island through topserve. Order food online now!' });
    meta.addTag({ name: 'author', content: 'TopServe' }, true);
    meta.updateTag({ name: 'keywords', content: 'food near me,food delivery,take out near me,food delivery near me,restaurant take out near me,take out restaurant , restaurant delivery near me,local restaurant food delivery,food delivery service near me,order food delivery' });
    meta.addTag({ httpEquiv: 'Content-Type', content: 'text/html' }, true);
    meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    meta.updateTag({ property: 'og:title', content: "Order Restaurant Food Delivery Online & Take Out | TopServe" });
    meta.addTag({ property: 'og:type', content: "website" });
    meta.addTag({ charset: 'UTF-8' });
  }

  preloadResource(url: string, asType: string, fetchPriority: string) {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'preload');
    this.renderer.setAttribute(link, 'as', asType);
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.setAttribute(link, 'fetch', fetchPriority);
    this.renderer.appendChild(document.head, link);
  }

  clickEvent() {
    console.log('CLICKED');
  }


  ngOnInit() {


    let self = this;
    
    let form = document.getElementsByClassName('search_field');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(form, function (form) {
      form.addEventListener('submit', function (event) {
        console.log('Here')
        self.didSelectLocation();
        event.preventDefault();
      }, false);
    });

    $(document).ready(function ($) {

      self.setupGoogleMaps();

      $(".partners").owlCarousel({
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {

          600: {
            items: 3,
          },
          1000: {
            items: 6,
          }
        },
        nav: false,
        autoplay: true,
        autoplayTimeout: 5000,
      });
    });

    $('#successAlert').hide();
    $('#failAlert').hide();

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });






    $(document).ready(function ($) {

      var pAgree = '1';
      $('.video-popup').each(function (indx, el) {
        if ($(el).closest('#gallery').length === 0) {

          $(el).magnificPopup({
            type: 'iframe',
            iframe: {
              patterns: {
                youtube: {
                  index: 'www.youtube.com/',
                  id: 'v=',
                  src: 'https://www.youtube.com/embed/%id%?autoplay=1'
                }
                , vimeo: {
                  index: 'vimeo.com/',
                  id: '/',
                  src: 'https://player.vimeo.com/video/%id%?autoplay=1'
                }
              }
            },
            disableOn: function () {
              if (!pAgree || pAgree !== '1') {
                return false;
              }
              return true;
            }
          });
        }
      });

    });


    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          if (event.target.id == 'partnerForm') {
            self.sendPartnerMessage();
          }
        }
        form.classList.add('was-validated');
      }, false);
    });





  }




  didSelectLocation() {


    this.emp.orderInformation.isMealService = false;
    this.emp.orderLat = parseFloat(this.lat.toFixed(6));
    this.emp.orderLng = parseFloat(this.lng.toFixed(6));

    if (this.emp.orderLat != 0.0 && this.emp.orderLng != 0.0) {
      this.router.navigate(['/find-restaurants', this.emp.orderInformation.city.toString().toLowerCase()]);
      localStorage.setItem('delivery', 'true');

    } else {
      alert("Unable to find location for selected address.\nPlease enter and select valid address from dropdown.");
    }

  }

  getAddress(place: object) {
    this.address = place['formatted_address'];


    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);

  }




  setupGoogleMaps() {

    var input = <HTMLInputElement>document.getElementById("landing-pac-input");

    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {

      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.address2 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';
      self.emp.orderInformation.address1 = street_number ? street_number + ' ' + route : route;;
      self.emp.orderInformation.address2 = street_number ? street_number + ' ' + route : route;
      self.emp.orderInformation.city = town ? town : '';
      self.emp.orderInformation.province = province ? province : '';
      self.emp.orderInformation.country = country ? country : '';
      self.emp.orderInformation.postal = postal_code ? postal_code : '';

      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.emp.orderInformation.lat = mylocation.geometry.location.lat();
      self.emp.orderInformation.long = mylocation.geometry.location.lng();
      self.lat = mylocation.geometry.location.lat();
      self.lng = mylocation.geometry.location.lng();

      localStorage.setItem('orderInformation', JSON.stringify(self.emp.orderInformation));
    });

  }


  sendPartnerMessage() {

    var info = 'Partner With TopServe Request:\n\n';

    info += 'Email = ' + this.partnerEmail + ' | Name = ' + this.partnerName + ' | Phone = ' + this.partnerPhone + ' | Company Name = ' + this.partnerCompany + ' | Address = ' + this.partnerAddress + ' | Extra Info = ' + this.partnerExtraInfo;

    info += '\n\nUser Clicked For' + this.partnerClickedOn + ' On Landing Page Of TopServe.';
    const post = {
      Message: info,
    };


    this.platformService.sendMessage(post).subscribe(
      data => {
        if (data['Success']) {
          this.partnerName = '';
          this.partnerCompany = '';
          this.partnerEmail = '';
          this.partnerPhone = '';
          this.partnerAddress = '';
          this.partnerExtraInfo = '';
          $('#partnerForm').removeClass('was-validated');
          $('#partnerApplyModal').modal('hide');
          alert("Thank you we will email you back within 24 hours.");
        } else {
          this.partnerName = '';
          this.partnerCompany = '';
          this.partnerEmail = '';
          this.partnerPhone = '';
          this.partnerAddress = '';
          this.partnerExtraInfo = '';
          $('#partnerForm').removeClass('was-validated');
          $('#partnerApplyModal').modal('hide');
          alert("Error submitting your request, please call 1-902-432-4244 for inquires.");
        }
      })
  }

  async sendMessage() {

    let message = this.name + " - " + this.email + " - " + this.message;
    const post = {
      suggestedRestaurant: message
    };

    this.service.SendLine(post).subscribe(
      data => {
        if (data['addedReview']) {
          $("#successAlert").show();
          $("#successAlert").alert();
        } else {
          $("#failAlert").show();
          $("#failAlert").alert();
        }
      }
    );

  }

}
