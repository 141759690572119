import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.html',
})
export class AppComponent implements OnInit {
    frame = true;
    constructor(private router: Router) { }

    ngOnInit() {

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            document.body.scrollTop = 0;
        });

        if (!this.frame) {
            // Here changed landing
            this.router.navigate(['/']);
        }
    }
}
