import { Component, OnDestroy, OnInit } from '@angular/core';
import { PauseMenuModifiersService } from './pause-menu-modifiers.service'

declare var $: any;

@Component({
  selector: 'app-pause-menu-modifiers',
  templateUrl: './pause-menu-modifiers.component.html',
  styleUrls: ['./pause-menu-modifiers.component.css']
})

export class PauseMenuModifiersComponent implements OnInit {
  allMenuSides:any;

  menuSides: any;
  tempMenuSides:any;
  searchValue = ""
  constructor(private pauseMenuModifiersService: PauseMenuModifiersService) { }

  async ngOnInit() {
    this.getMenuModifiers();

    let self = this;
    $('input[type=text]').keyup(function() {
      self.filterModifiers()
    })
  }


  // Get the modifiers list
  getMenuModifiers() {
    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      RestaurantID: localStorage.getItem('restaurantID')
    };

    this.pauseMenuModifiersService.getMenuModifier(post).subscribe(
      data => {
        if (data['Success'] && data['Response']['MenuSides'].length > 0) {


          this.menuSides = data["Response"]['MenuSides'].filter((v,i,a)=>a.findIndex(t=>(t.Name === v.Name))===i);
          this.allMenuSides = data["Response"]['MenuSides']
          this.tempMenuSides = data["Response"]['MenuSides'].filter((v,i,a)=>a.findIndex(t=>(t.Name === v.Name))===i);
        } else {
          alert("Error loading modifier list");
        }
      });
  }

  

  // For search
  filterModifiers() {
    this.menuSides = this.tempMenuSides;
    let arrayOfModifiersForCategory = [];

      for(let x in this.menuSides) {
        if (this.menuSides[x]['Name'].toUpperCase().indexOf(this.searchValue.toUpperCase()) > -1) {
          arrayOfModifiersForCategory.push(this.menuSides[x])
        }; 
      };
      this.menuSides = arrayOfModifiersForCategory;
  }


  // Pause menu modifiers
  async pause(item, index){

    let menuItemsToChange = this.allMenuSides.filter(function (e) {
      return e.Name == item.Name;
  });
  let idToPause = menuItemsToChange.map(a => a.MenuItemOptionID);

// return;

      const post = {
          Token: localStorage.getItem('AuthenticationToken'),
          MenuItemOptionID:idToPause
      };

      if(item.Paused == 0){
          let self = this;
          await this.pauseMenuModifiersService.pauseModifier(post).subscribe(async data => {
              if(data["Success"]){
                 self.menuSides[index].Paused = 1
              }else{
                  alert("Did not pause please call support if urgent or try again later")
              }
          })
      }else{
          let self = this;
          await this.pauseMenuModifiersService.unPauseModifier(post).subscribe(async data => {
            if(data["Success"]){
               self.menuSides[index].Paused = 0
            }else{
                alert("Did not unpause please call support if urgent or try again later")
            }
        })
      }
  }


  getPausedAreYouSure(item, name) {
    if (item == 0) {
      return "Are you sure you want to pause " + name
    } else {
      return "Are you sure you want to unpause " + name
    }
  }

  getPaused(item) {
    if (item == 0) {
      return "Pause"
    } else {
      return "Unpause"
    }
  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }
}
