import { Component, OnInit, Input } from '@angular/core';
import { DriverService } from '../driver/driver.service';
import { ProfileService } from '../profile/profile.service';

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input('changePassword')
  changePassword = 'true';

  newPassword = '';
  confirmPassword = '';
  passwordErrMsg = '';

  constructor(private driverService: DriverService, private profileService:ProfileService) { }

  ngOnInit(): void {

    // console.log('DriverID',localStorage.getItem('DriverID'));
    // console.log('RestaurantID',localStorage.getItem('restaurantID'));
    if (this.changePassword) {
      $('#change-password-modal').modal('show');
    }

  }

  updatePassword() {

    if (this.newPassword != '' && this.confirmPassword != '') {
      if (this.newPassword != this.confirmPassword) {
        this.passwordErrMsg = 'Password does not match with confirm password!';
      }
      else {
        this.passwordErrMsg = '';

        if (localStorage.getItem('type') == 'driver') {

          const post = {
            DriverID: localStorage.getItem('DriverID'),
            Password: this.newPassword
          }

          this.driverService.updateSubDriverPassword(post).subscribe(
            data => {
              if (data['Success']) {
                localStorage.removeItem('changePassword');
                $('#change-password-modal').modal('hide');
              }
              else {
                this.passwordErrMsg = 'Something went wrong!\nPlease call 1-902-432-4244 for support.';
              }
            });
        }
        else {

          const post = {
            UserID: localStorage.getItem('UserID'),
            Token: localStorage.getItem('AuthenticationToken'),
            Password: this.newPassword
          };

          this.profileService.updatePassword(post).subscribe(
            data=> {
              if (data['Success']) {
                localStorage.removeItem('changePassword');
                $('#change-password-modal').modal('hide');
              }else{
                this.passwordErrMsg = 'Something went wrong!\nPlease call 1-902-432-4244 for support.';
              }
            })
        }
      }
    }
  }

}
