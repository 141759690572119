import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EmpService } from "../../../emp.service";
import { DriverService } from "../driver.service";
import { Router } from "@angular/router";
import { Meta } from "@angular/platform-browser";


declare var $: any;

@Component({
  selector: 'app-driver-login',
  templateUrl: './driver-login.component.html',

  styleUrls: ['./driver-login.component.css', '../../../../assets/css/ts-style.css']
})
export class DriverLoginComponent implements OnInit {
  @ViewChild('checked') checked: ElementRef;

  username: any;
  password: any;
  token: any;

  loginErrorMsg = '';
  managerLogin = false;

  constructor(public emp: EmpService, private loginService: DriverService, private router: Router, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Driver Login for restaurant delivery owners' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI,Driver Login,delivery' });
    meta.updateTag({ property: 'og:title', content: "Driver Login" });

  }

  ngOnInit() {


    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {

          self.login();
        }
        form.classList.add('was-validated');
      }, false);
    });

  }

  toggleRequired() {
    var checked = this.checked.nativeElement.checked;
    this.managerLogin = checked;

  }




  login() {


    this.loginErrorMsg = '';

    const post = {
      email: this.username,
      password: this.password,
      Token: this.makeRandom()
    };


    if (this.managerLogin) {

      this.loginService.loginPartner(post).subscribe(
        data => {


          if (data['verified']) {

            if (data['User'][0].Master) {
              localStorage.setItem('master', 'true');
            } else {
              localStorage.setItem('master', 'false');
            }


            localStorage.setItem('isLogedIn', 'true');
            localStorage.setItem('type', 'partner');
            localStorage.setItem('userType', '9');
            localStorage.setItem('Name', data['User'][0].Partner);
            localStorage.setItem('AuthenticationToken', post.Token);
            localStorage.setItem('PartnerID', data['User'][0].PartnerID);
            localStorage.setItem('Email', data['User'][0].Email);
            this.router.navigateByUrl('/services/delivery-dashboard');

          } else {

            this.loginErrorMsg = data['Message'];
          }
        }
      );
    } else {
      this.loginService.loginSubDriver(post).subscribe(
        data => {

          if (data['verified']) {

            if (data['User'].length > 0 && this.password.toLowerCase() == 'password') {
              localStorage.setItem('changePassword', 'true');
            }
            localStorage.setItem('isLogedIn', 'true');
            localStorage.setItem('type', 'driver');
            localStorage.setItem('userType', '11');
            this.emp.loggedInDriver = data['User'][0];
            localStorage.setItem('driverType', data['User'][0].DriverType);
            localStorage.setItem('firstName', data['User'][0].FirstName);
            localStorage.setItem('AuthenticationToken', post.Token);
            localStorage.setItem('DriverID', data['User'][0].SubDriverID);
            localStorage.setItem('Email', data['User'][0].Email);
            this.router.navigateByUrl('/services/delivery-dashboard');

          } else {

            this.loginErrorMsg = data['Message'];
          }
        }
      );

    }

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


}
