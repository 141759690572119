import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../restaurant.service';
import { DomSanitizer } from "@angular/platform-browser";
import { Url } from "../../../models/url";
import { EmpService } from "../../../emp.service";
import { Router } from "@angular/router";
import { environment } from 'environments/environment.prod';

declare var $: any;
declare var jQuery: any;
const AWS = require('aws-sdk');

@Component({
  selector: 'app-show-restaurant',
  templateUrl: './show-restaurant.component.html',
  styleUrls: ['./show-restaurant.component.css']
})
export class ShowRestaurantComponent implements OnInit {

  mainMenu: any;
  res: any;
  active = false
  constructor(private router: Router, private emp: EmpService, private ResService: RestaurantService, private sanitizer: DomSanitizer, private url: Url) { }

  ngOnInit() {

    // if(this.ResService.addedRestaurant){
    //   this.ResService.addedRestaurant = false
    //   $('#restaurantAdded')
    //
    // }



    const param = {
      UserID: localStorage.getItem('UserID')
    };
    this.ResService.getRes(param).subscribe(data => {

      this.res = data;

      localStorage.setItem("allRestaurants", JSON.stringify(this.res));

      if (this.res.length > 0) {
        this.res.forEach(element => {
          if (element.Status == "Y") {
            this.active = true
          }
        }

        );
        this.getImages()
      }
    });




  }



  async getImages() {

    var self = this;

    const AWSService = AWS;
    const region = 'ca-central-1';


    let bucketName = ""
    if (this.url.getDev() == "dev") {
      bucketName = 'topserve-restaurant-main-images-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-main-images-prod-canada';

    }
    //}    sudo curl "https://s3.amazonaws.com/aws-cli/awscli-bundle.zip" -o "awscli-bundle.zip"
    //     sudo unzip awscli-bundle.zip
    //     sudo ./awscli-bundle/install -i /usr/local/aws -b /usr/local/bin/aws

    //


    // const config = new AWSService.Config({
    //   accessKeyId: 'AKIAIJ76Y3SFAAKZ4PZQ',
    //   secretAccessKey: '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',
    //   region: region
    // });
    //
    // AWS.config.update(config);


    // const bucketName = 'menu-item-images-meeno '+ this.url.getDev();
    const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
    // Configures the AWS service and initial authorization
    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
      secretAccessKey: environment.awsSecret,
      region: region
    });
    // adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });






    self.res.forEach(async (item, n) => {
      await s3.getObject({ Key: item["ImageURL"] }, async function (err, file) {


        if (!err && item) {
          let blob = new Blob([file.Body], { type: "image/jpeg" });
          self.res[n]["image"] = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        } else {
          // self.res[n]["image"] = require('assets/img/no-image.png');
        }
      }, 1);
    });
  }


  dataTable() {
    $(function () {

      // Datatables
      $('#menu-items').DataTable({
        'lengthMenu': [[6, 12, 20, -1], [6, 12, 20, 'All']],
        responsive: true,
        'autoWidth': false
      });
      // Editable
      $('#menu-items').editableTableWidget({
        preventColumns: [1, 2, 9, 10]
      }
      );

    })
  }

}
