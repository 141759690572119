import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
@Injectable({
  providedIn: 'root'
})
export class FindOrderService {

  getLiveOrderURL = this.url.getDomain() + '/Partner/GetPartnerOrdersByDate';
  getLiveOrderByIDURL = this.url.getDomain() + '/Partner/GetPartnerOrdersByOrderID';
  getRefundOrderURL = this.url.getDomain() + '/Orders/RefundOrder';
  findOrderByIDURL = this.url.getDomain() + '/Orders/FindOrderByIDForApp';

  constructor(private http: HttpClient, private url: Url) { }

  getLiveOrder(post) {
    return this.http.post(this.getLiveOrderURL, post);
  }

  getOrderByID(post) {
    return this.http.post(this.getLiveOrderByIDURL, post);
  }

  refundOrder(post) {
    return this.http.post(this.getRefundOrderURL, post);
  }

  findOrderByID(post) {
    return this.http.post(this.findOrderByIDURL, post);
  }

}
