import {
  Component,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  NgZone,
  Input,
  Output,
  EventEmitter, AfterViewInit, ViewChild
} from '@angular/core';
import { RestaurantService } from '../restaurant.service';
import { Url } from '../../../models/url';
import { EmpService } from "../../../emp.service";
import { Router } from "@angular/router";
// import { } from "@agm/core/services/google-maps-types";
import { Meta } from "@angular/platform-browser";
import { environment } from 'environments/environment.prod';

const AWS = require('aws-sdk');
declare var $: any;


@Component({
  selector: 'app-add-restaurant',
  templateUrl: './add-restaurant.component.html',
  styleUrls: ['./add-restaurant.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddRestaurantComponent implements OnInit, AfterViewInit {
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;


  autocompleteInput: string;
  queryWait: boolean;


  name = "";
  description = "";
  address1 = "";
  address2 = "";
  city = "";
  province = "";
  country = "";
  postal = "";
  phone = "";
  email = "";
  contactName = "";
  contactEmail: any;
  confirmEmail: any;
  monday: boolean;
  mondayOpen = '00:00';
  mondayClose = '00:00';
  tuesday: boolean;
  tuesdayOpen = '00:00';
  tuesdayClose = '00:00';
  wednesday: boolean;
  wednesdayOpen = '00:00';
  wednesdayClose = '00:00';
  thursday: boolean;
  thursdayOpen = '00:00';
  thursdayClose = '00:00';
  friday: boolean;
  fridayOpen = '00:00';
  fridayClose = '00:00';
  saturday: boolean;
  saturdayOpen = '00:00';
  saturdayClose = '00:00';
  sunday: boolean;
  sundayOpen = '00:00';
  sundayClose = '00:00';
  selectedCategories = []
  dineIn: boolean;
  takeOut: boolean;
  delivery: boolean;

  imageUrl: any;
  fileInput: any;
  image = '';

  lat = 46.236226;
  lng = -63.126524;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';

  itemAdded = false;
  loading = false;

  address: Object;
  establishmentAddress: Object;

  formattedAddress: string;
  formattedEstablishmentAddress: string;


  constructor(public zone: NgZone, private router: Router, private emp: EmpService, private resService: RestaurantService, private url: Url, private meta: Meta) {

    meta.updateTag({ name: 'description', content: 'TopServe Add Restaurant' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI, Add Restaurant' });
    meta.updateTag({ property: 'og:title', content: "Add Restaurant" });


  }



  ngAfterViewInit(): void {


  }




  ngOnInit() {


    let body = document.getElementsByTagName('body');
    if (body[0].classList.contains('modal-open')) body[0].classList.remove('modal-open');

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
    $("#error").hide();

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();


        } else {
          event.preventDefault();
          self.addNew();
          //   self1.updateInformation()
        }
        form.classList.add('was-validated');
      }, false);
    });


    let dataCate = [];
    dataCate.push({
      id: 1,
      text: "Indian",
      "selected": false
    });
    dataCate.push({
      id: 2,
      text: "Pizza",
      "selected": false
    });
    dataCate.push({
      id: 3,
      text: "Family",
      "selected": false
    });
    dataCate.push({
      id: 4,
      text: "Breakfast",
      "selected": false
    });
    dataCate.push({
      id: 5,
      text: "Thai",
      "selected": false
    });
    dataCate.push({
      id: 6,
      text: "Chinese",
      "selected": false
    });

    $("#selectedCategory").select2({
      data: dataCate,
      multiple: true,
      tags: true,
      maximumSelectionLength: 2
    });

    $('#selectedCategory').on('select2:unselect', (e) => {
      for (let n in this.selectedCategories) {
        if (e.params.data.text == this.selectedCategories[n].Name) {
          this.selectedCategories.splice(parseInt(n), 1)
        }
      }
    });


    $('#selectedCategory').on('select2:select', (e) => {
      //  var data =


      this.selectedCategories.push(e.params.data.text);

      // var value = $(this).value;
    });



    $(function () {
      $('.clockpicker').clockpicker();
      $('.selectpicker').selectpicker();
      $('.dropify').dropify();
    });


    $(function () {      //my logic here
      self.setupGoogleMaps()
    });



  }



  setupGoogleMaps() {


    var input = <HTMLInputElement>document.getElementById("pac-input");
    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {
      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.address2 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';

      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng()
      self.lat = mylocation.geometry.location.lat();
      self.lng = mylocation.geometry.location.lng();




    })
  }



  ////NEW MAPS
  getAddress(place: object) {
    this.address = place['formatted_address'];

    this.phone = this.getPhone(place);
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }


  getAddrComponent(place, componentTemplate) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }



  getPhone(place) {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
      phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }



  //NEW MAPS





  onMapReady(map) {
    // console.log('map', map);
    // console.log('markers', map.markers);  // to get all markers as an array 
  }

  onIdle(event) {
    // console.log('map', event.target);
  }

  onMarkerInit(marker) {
    // console.log('marker', marker);
  }

  onMapClick(event) {


    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(event.latLng);

    let geocoder = new google.maps.Geocoder;

    var self = this;
    var latLong = matches[1].split(",");
    if (latLong.length > 1) {
      this.lat = parseFloat(latLong[0]);
      this.lng = parseFloat(latLong[1]);
    }


    geocoder.geocode({ 'location': event.latLng }, function (results) {
      if (results[0]) {

        let splittedAddress = results[0].formatted_address.split(",");

        if (splittedAddress.length > 3) {
          self.address1 = splittedAddress[0].trim();
          self.address2 = splittedAddress[0].trim();
          self.city = splittedAddress[1].trim();
          self.postal = splittedAddress[2].trim();
          self.country = splittedAddress[3].trim();
        }
        self.center = results[0].formatted_address;
      } else {
        console.log('No results found');
      }
    });
    //  }
    this.position = matches[1].toString();

    event.target.panTo(event.latLng);
  }

  loadClock() {
    $('.clockpicker').clockpicker();
  }

  fileEvent(fileInput: any) {

    this.fileInput = fileInput.target.files[0];

  }

  async upload() {


    const AWSService = AWS;
    const region = 'ca-central-1';

    let bucketName = "";
    if (this.url.getDev() == "dev") {
      bucketName = 'topserve-restaurant-main-images-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-main-images-prod-canada';

    }

    // const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
    const file = this.fileInput;
    // Configures the AWS service and initial authorization
    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
      secretAccessKey: environment.awsSecret,
      region: region
    });


    //adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });

    this.image = file.name;


    let self = this;
    s3.upload({ Key: this.imageUrl, Bucket: bucketName, Body: file }, function (err, data) {
      this.loading = false;

      if (err) {

        // self.router.navigateByUrl('/services/show-restaurant');

        self.router.navigateByUrl('/services/show-restaurant', { skipLocationChange: true }).then(() =>
          self.router.navigate(['/services/show-restaurant']));

      } else {

        this.itemAdded = true;
        this.name = null;
        this.desc = null;
        this.category = null;
        this.subCategory = null;
        this.totalPrice = null;
        this.discountPrice = null;
        this.loading = false;
        //  this.resService.addedRestaurant = true;



        self.router.navigateByUrl('/services/show-restaurant', { skipLocationChange: false }).then(() =>
          window.location.reload());
      }
    });

  }

  getRestaurantHours() {


    this.mondayOpen = $("#mondayOpen").val();
    this.mondayClose = $("#mondayClose").val();
    this.tuesdayClose = $("#tuesdayClose").val();
    this.tuesdayOpen = $("#tuesdayOpen").val();
    this.wednesdayClose = $("#wednesdayClose").val();
    this.wednesdayOpen = $("#wednesdayOpen").val();
    this.thursdayClose = $("#thursdayClose").val();
    this.thursdayOpen = $("#thursdayOpen").val();
    this.fridayClose = $("#fridayClose").val();
    this.fridayOpen = $("#fridayOpen").val();
    this.saturdayOpen = $("#saturdayOpen").val();
    this.saturdayClose = $("#saturdayClose").val();
    this.sundayClose = $("#sundayClose").val();
    this.sundayOpen = $("#sundayOpen").val();

    if (!this.monday) {
      this.mondayOpen = "00:00:00";
      this.mondayClose = "00:00:00";
    }
    if (!this.tuesday) {
      this.tuesdayClose = "00:00:00";
      this.tuesdayOpen = "00:00:00";
    }
    if (!this.wednesday) {
      this.wednesdayClose = "00:00:00";
      this.wednesdayOpen = "00:00:00";
    }
    if (!this.thursday) {
      this.thursdayClose = "00:00:00";
      this.thursdayOpen = "00:00:00";
    }
    if (!this.friday) {
      this.fridayClose = "00:00:00";
      this.fridayOpen = "00:00:00";
    }
    if (!this.saturday) {
      this.saturdayOpen = "00:00:00";
      this.saturdayClose = "00:00:00";
    }
    if (!this.sunday) {
      this.sundayClose = "00:00:00";
      this.sundayOpen = "00:00:00";
    }
  }

  addNew() {



    if (this.address1 == "" || this.fileInput === undefined || this.province == "" || this.country == "" || this.postal == "") {
      $("#error").show();
      this.loading = false;
      return
    }


    this.getRestaurantHours();

    const latLong = this.position.split(',');
    if (latLong.length > 1) {
      this.lat = parseFloat(latLong[0]);
      this.lng = parseFloat(latLong[1]);
    }

    // end




    var ot = {};


    ot["type"] = '1';

    ot["type2"] = '2';

    ot["type3"] = '3';




    var categories = "";
    this.selectedCategories.forEach(async (item, n) => {
      if (n > 0) {
        categories += ", "
      }
      categories += this.selectedCategories[n]
    });


    this.loading = true;
    const post = {
      UserInfo: {
        UserID: localStorage.getItem('UserID'),
      },

      RestaurantInformation: {
        Name: this.name,
        Description: this.description,
        Address1: this.address1,
        Address2: this.address2,
        City: this.city,
        Country: this.country,
        Province: this.province,
        Postal: this.postal,
        Category: categories,
        Phone: this.phone,
        Status: '0',
        EmailAddress: this.email,
        Rating: '4.7',
        ContactName: this.contactName,
        Longitude: this.lng,
        Latitude: this.lat,
      },
      RestaurantHours: [
        {
          Day: '0',
          Open: this.mondayOpen,
          Close: this.mondayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '1',
          Open: this.tuesdayOpen,
          Close: this.tuesdayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '2',
          Open: this.wednesdayOpen,
          Close: this.wednesdayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '3',
          Open: this.thursdayOpen,
          Close: this.thursdayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '4',
          Open: this.fridayOpen,
          Close: this.fridayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '5',
          Open: this.saturdayOpen,
          Close: this.saturdayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '6',
          Open: this.sundayOpen,
          Close: this.sundayClose,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },

      ]
    };


    post["OrderTypes"] = ot;

    this.resService.addRes(post).subscribe(
      data => {

        if (data[0].hasOwnProperty('ImageURL')) {
          this.emp.isNewUser = false;
          this.emp.loggedInUserType = 5
          localStorage.setItem('restaurantID', data[0]["RestaurantID"]);

          let x = [{
            "RestaurantID": data[0]["RestaurantID"],
            "Name": this.name
          }

          ];

          localStorage.setItem("allRestaurants", JSON.stringify(x))
          this.imageUrl = data[0]['ImageURL'];
          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'admin');
          localStorage.setItem('userType', '5');
          localStorage.setItem('restaurantName', this.name);

          localStorage.setItem('restaurantUserType', '5');

          this.upload();
        } else {
          this.loading = false;
        }
      }
    );
    // }

  }
}
