import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Url } from 'app/models/url';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  getReservationProductsURL = this.url.getDomain() + '/Products/getReservationProducts';
  getReservationProductCheckoutSessionURL = this.url.getDomain() + '/Products/getReservationProductCheckoutSession';
  sendRestaurantRegistrationSuccessMailURL = this.url.getDomain() + '/Mail/SendRestaurantRegistrationSuccessMail'

  constructor(private router:Router,private http: HttpClient, private url: Url) { }
  
  reservationProducts(post) {
    return this.http.post(this.getReservationProductsURL, post);
  }

  getReservationProductCheckoutSession(post) {
    return this.http.post(this.getReservationProductCheckoutSessionURL, post);
  }

  sendRestaurantRegistrationSuccessMail(post) {
    return this.http.post(this.sendRestaurantRegistrationSuccessMailURL, post);
  }
}
