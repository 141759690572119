export const environment = {
  production: true,
  pusher: {
    key: '1112b666d809129183c1',
    cluster: 'us2',
  },
  stripeKey:'pk_live_907FG4OuNVXdsUCfoXGFynuV',
  awsAccess: 'AKIAQZTUXEGMP3F2PLQS',
  awsSecret: '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'
};
