import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReservationsService } from '../../structure/reservations/reservations.service';
import { HostListener } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-add-reservation',
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.css','../../../assets/css/ts-style.css']
})


export class AddReservationComponent implements OnInit {

  isMobile = false;
  interval = 0;
  intervalWording = 'Time Limit';
  partySizeWording = 'Party Size';
  startDate = "";
  totalTables = 1;
  totalTablesWording = "Total Tables";
  dayWording = 'Every Day'
  day = 10
  startHour = '';
  selectedPartySize = [];
  selectedDay = [];
  selectedHour = [];
  numbersOnly = false;
  numberOfTables = 1;

  dataDays = [
    {
      id: 0,
      text: "Monday",
      "selected": false
    },
    {
      id: 1,
      text: "Tuesday",
      "selected": false
    },
    {
      id: 2,
      text: "Wednesday",
      "selected": false
    },
    {
      id: 3,
      text: "Thursday",
      "selected": false
    },
    {
      id: 4,
      text: "Friday",
      "selected": false
    },
    {
      id: 5,
      text: "Saturday",
      "selected": false
    },
    {
      id: 6,
      text: "Sunday",
      "selected": false
    }
  ];

  dataHours = [
    {
      id: 0,
      text: "8:00 AM",
      "24hrText": "08:00:00",
      "selected": false
    },
    {
      id: 1,
      text: "8:15 AM",
      "24hrText": "08:15:00",
      "selected": false
    },
    {
      id: 2,
      text: "8:30 AM",
      "24hrText": "08:30:00",
      "selected": false
    },
    {
      id: 3,
      text: "8:45 AM",
      "24hrText": "08:45:00",
      "selected": false
    },
    {
      id: 4,
      text: "9:00 AM",
      "24hrText": "09:00:00",
      "selected": false
    },
    {
      id: 5,
      text: "9:15 AM",
      "24hrText": "09:15:00",
      "selected": false
    },
    {
      id: 6,
      text: "9:30 AM",
      "24hrText": "09:30:00",
      "selected": false
    },
    {
      id: 7,
      text: "9:45 AM",
      "24hrText": "09:45:00",
      "selected": false
    },
    {
      id: 8,
      text: "10:00 AM",
      "24hrText": "10:00:00",
      "selected": false
    },
    {
      id: 9,
      text: "10:15 AM",
      "24hrText": "10:15:00",
      "selected": false
    },
    {
      id: 10,
      text: "10:30 AM",
      "24hrText": "10:30:00",
      "selected": false
    },
    {
      id: 11,
      text: "10:45 AM",
      "24hrText": "10:45:00",
      "selected": false
    },
    {
      id: 12,
      text: "11:00 AM",
      "24hrText": "11:00:00",
      "selected": false
    },
    {
      id: 13,
      text: "11:15 AM",
      "24hrText": "11:15:00",
      "selected": false
    },
    {
      id: 14,
      text: "11:30 AM",
      "24hrText": "11:30:00",
      "selected": false
    },
    {
      id: 15,
      text: "11:45 AM",
      "24hrText": "11:45:00",
      "selected": false
    },
    {
      id: 16,
      text: "12:00 PM",
      "24hrText": "12:00:00",
      "selected": false
    },
    {
      id: 17,
      text: "12:15 PM",
      "24hrText": "12:15:00",
      "selected": false
    },
    {
      id: 18,
      text: "12:30 PM",
      "24hrText": "12:30:00",
      "selected": false
    },
    {
      id: 19,
      text: "12:45 PM",
      "24hrText": "12:45:00",
      "selected": false
    },
    {
      id: 20,
      text: "1:00 PM",
      "24hrText": "13:00:00",
      "selected": false
    },
    {
      id: 21,
      text: "1:15 PM",
      "24hrText": "13:15:00",
      "selected": false
    },
    {
      id: 22,
      text: "1:30 PM",
      "24hrText": "13:30:00",
      "selected": false
    },
    {
      id: 23,
      text: "1:45 PM",
      "24hrText": "13:45:00",
      "selected": false
    },
    {
      id: 24,
      text: "2:00 PM",
      "24hrText": "14:00:00",
      "selected": false
    },
    {
      id: 25,
      text: "2:15 PM",
      "24hrText": "14:15:00",
      "selected": false
    },
    {
      id: 26,
      text: "2:30 PM",
      "24hrText": "14:30:00",
      "selected": false
    },
    {
      id: 27,
      text: "2:45 PM",
      "24hrText": "14:45:00",
      "selected": false
    },
    {
      id: 28,
      text: "3:00 PM",
      "24hrText": "15:00:00",
      "selected": false
    },
    {
      id: 29,
      text: "3:15 PM",
      "24hrText": "15:15:00",
      "selected": false
    },
    {
      id: 30,
      text: "3:30 PM",
      "24hrText": "15:30:00",
      "selected": false
    },
    {
      id: 31,
      text: "3:45 PM",
      "24hrText": "15:45:00",
      "selected": false
    },
    {
      id: 32,
      text: "4:00 PM",
      "24hrText": "16:00:00",
      "selected": false
    },
    {
      id: 33,
      text: "4:15 PM",
      "24hrText": "16:15:00",
      "selected": false
    },
    {
      id: 34,
      text: "4:30 PM",
      "24hrText": "16:30:00",
      "selected": false
    },
    {
      id: 35,
      text: "4:45 PM",
      "24hrText": "16:45:00",
      "selected": false
    },
    {
      id: 36,
      text: "5:00 PM",
      "24hrText": "17:00:00",
      "selected": false
    },
    {
      id: 37,
      text: "5:15 PM",
      "24hrText": "17:15:00",
      "selected": false
    },
    {
      id: 38,
      text: "5:30 PM",
      "24hrText": "17:30:00",
      "selected": false
    },
    {
      id: 39,
      text: "5:45 PM",
      "24hrText": "17:45:00",
      "selected": false
    },
    {
      id: 40,
      text: "6:00 PM",
      "24hrText": "18:00:00",
      "selected": false
    },
    {
      id: 41,
      text: "6:15 PM",
      "24hrText": "18:15:00",
      "selected": false
    },
    {
      id: 42,
      text: "6:30 PM",
      "24hrText": "18:30:00",
      "selected": false
    },
    {
      id: 43,
      text: "6:45 PM",
      "24hrText": "18:45:00",
      "selected": false
    },
    {
      id: 44,
      text: "7:00 PM",
      "24hrText": "19:00:00",
      "selected": false
    },
    {
      id: 45,
      text: "7:15 PM",
      "24hrText": "19:15:00",
      "selected": false
    },
    {
      id: 46,
      text: "7:30 PM",
      "24hrText": "19:30:00",
      "selected": false
    },
    {
      id: 47,
      text: "7:45 PM",
      "24hrText": "19:45:00",
      "selected": false
    },
    {
      id: 48,
      text: "8:00 PM",
      "24hrText": "20:00:00",
      "selected": false
    },
    {
      id: 49,
      text: "8:15 PM",
      "24hrText": "20:15:00",
      "selected": false
    },
    {
      id: 50,
      text: "8:30 PM",
      "24hrText": "20:30:00",
      "selected": false
    },
    {
      id: 51,
      text: "8:45 PM",
      "24hrText": "20:45:00",
      "selected": false
    },
    {
      id: 52,
      text: "9:00 PM",
      "24hrText": "21:00:00",
      "selected": false
    },
    {
      id: 53,
      text: "9:15 PM",
      "24hrText": "21:15:00",
      "selected": false
    },
    {
      id: 54,
      text: "9:30 PM",
      "24hrText": "21:30:00",
      "selected": false
    },
    {
      id: 55,
      text: "9:45 PM",
      "24hrText": "21:45:00",
      "selected": false
    },
    {
      id: 56,
      text: "10:00 PM",
      "24hrText": "22:00:00",
      "selected": false
    },
    {
      id: 57,
      text: "10:15 PM",
      "24hrText": "22:15:00",
      "selected": false
    },
    {
      id: 58,
      text: "10:30 PM",
      "24hrText": "22:30:00",
      "selected": false
    },
    {
      id: 59,
      text: "10:45 PM",
      "24hrText": "22:45:00",
      "selected": false
    },
    {
      id: 60,
      text: "11:00 PM",
      "24hrText": "23:00:00",
      "selected": false
    },
    {
      id: 61,
      text: "11:15 PM",
      "24hrText": "23:15:00",
      "selected": false
    },
    {
      id: 62,
      text: "11:30 PM",
      "24hrText": "23:30:00",
      "selected": false
    }
  ]


  @Output() addReservation = new EventEmitter();


  constructor(private resService: ReservationsService) { }

  ngOnInit(): void {

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
      this.isMobile = true;
    }
    
  }

  ngAfterViewInit() {

    let self = this;
    
    $('#hourPicker').selectpicker({
      size: 5,
      noneSelectedText: '',
      actionsBox: true,
      iconBase: 'fa',
      tickIcon: 'fa-check',
      mobile: this.isMobile
    });

    // $('#hourPicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    //   console.log(self.selectedHour);
    // });

    $('#dayPicker').selectpicker({
      noneSelectedText: '',
      actionsBox: true,
      iconBase: 'fa',
      tickIcon: 'fa-check',
      mobile: this.isMobile
    });

    // $('#dayPicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    //   console.log(self.selectedDay);
    // });


    // for selecting party size
    $('#selectedPartySize').select2({
      multiple: true,
      tags: true,
    });

    //for allowing only numbers and non-zero value to be entered in party size
    $(document).on('keypress', '.select2-search__field', function (e) {

      const target = e.target;
      //console.log(target.attributes)
      if ('aria-controls' in target.attributes) {
        if (target.attributes['aria-controls'].textContent == 'select2-selectedPartySize-results') {
          this.numbersOnly = true
        }
        else {
          this.numbersOnly = false
        }

      }

      if (this.numbersOnly) {
        //$(this).val($(this).val().replace(/[^\d].+/, ""));
        if ((e.which < 48 || e.which > 57) || (this.value.length == 0 && e.which== 48)) {
          e.preventDefault();
        }
        
      }


    });

    $('#selectedPartySize').on('select2:select', (e) => {
      var data = e.params.data.text;

      if(this.selectedPartySize.length >0) {
        for (let i in this.selectedPartySize) {
          if (parseInt(data)<parseInt(this.selectedPartySize[i])) {
            this.selectedPartySize.splice(parseInt(i),0,data);
            break
          }
          else if(parseInt(i)== this.selectedPartySize.length-1){
            this.selectedPartySize.splice(parseInt(i+1),0,data);
            break
          }
          else {
            continue
          }
        }
      }
      else {
        this.selectedPartySize.push(data);
      }
      
      
      // console.log("here in select")
      // console.log(this.selectedPartySize)
    });

    $('#selectedPartySize').on('select2:unselect', (e) => {
      var data = e.params.data.text;
      for (let n in this.selectedPartySize) {
        if (data == this.selectedPartySize[n]) {
          this.selectedPartySize.splice(parseInt(n), 1)
        }
      }

      // console.log("here in unselect")
      // console.log(this.selectedPartySize)
    });

  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // event.returnValue = false;
    // event.preventDefault();
    // console.log(event);
    $('.bootstrap-select').removeClass('open');
  }

  didSelectDropDown(interval) {
    this.intervalWording = this.getIntervalInHour(interval)

  }

  getIntervalInHour(interval) {
    this.interval = interval;
    if (interval == 3600) {

      return "1hr";
    } else if (interval == 7200) {

      return "2hr";
    } else {

      return "3hr";
    }
  }

  didClickAdd() {

    if (this.interval == 0 || this.selectedPartySize.length == 0 || this.selectedHour.length == 0 || this.selectedDay.length == 0 || this.numberOfTables == null || this.numberOfTables <= 0) {
      alert('Please select all values');
      return
    }

    const param = {
      RestaurantID: localStorage.getItem('restaurantID'),
      Day: this.selectedDay.toString(),
      PartySize: this.selectedPartySize.toString(),
      TimeInterval: this.interval,
      TableHours: this.selectedHour,
      numberOfTables: this.numberOfTables,
      TimeZone:localStorage.getItem('restaurantTimeZone')

    };


    this.resService.addTable(param).subscribe(data => {
      this.selectedDay = [];
      this.selectedHour = [];
      this.selectedPartySize = [];
      this.interval = 0;
      this.intervalWording = "Time Limit";
      this.numberOfTables = 1;
      $('#hourPicker').selectpicker('val', []);
      $('#dayPicker').selectpicker('val', []);
      $('#selectedPartySize').val(null).trigger('change');
      this.addReservation.emit();

      //console.log(data);
      if (data["Success"] == "true") {
        alert("Tables Created!")
      }

    });

  }


}
