import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';


export interface Token {
  status: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;
  checkTokenUrl = 'https://panel.toranj-store.ir/api/v1/user/user/checkToken?version=3';

  constructor(private http: HttpClient) { }


  checkAccessToken(): Observable<Token> {
    if (localStorage.getItem('isLogedIn') === 'true') {
      const accessToken = localStorage.getItem('accessToken');
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        })
      };
      return this.http.get<Token>(this.checkTokenUrl, httpOptions)
    } else {
    }
  }

  setAccess() {

  }

  logout(): void {
    this.isLoggedIn = false;
  }
}
