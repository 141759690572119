import { Component, OnInit } from '@angular/core';
import { EmpService } from 'app/emp.service';
import { OrderServiceService } from '../order/order-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Location} from '@angular/common';
const Pusher = require('pusher-js');

declare var $: any;

@Component({
  selector: 'app-order-track',
  templateUrl: './order-track.component.html',
  styleUrls: ['./order-track.component.css','../../../assets/css/ts-style.css']
})

export class OrderTrackComponent implements OnInit {

  orderId = '';
  userId = '';
  estimatedTime = 0;
  orderStatus = '';
  orderInfo: any;
  subtotal = 0;
  trackingURL = null;

  constructor(private emp: EmpService, private domSanitizer: DomSanitizer, private orderService: OrderServiceService, private routeLocation: Location) { }

  ngOnInit(): void {

    if (!window.location.href.split('/').pop().includes('?user')) {
      $('#error_order').show();
      $('#order_info').hide();
      return;
    }
    
    const order = window.location.href.split('/').pop();
    
    this.orderId = order.split('?')[0];
    this.userId = order.split('?user=').pop();

    if (isNaN(parseInt(this.orderId)) && isNaN(parseInt(this.userId))) {
      $('#error_order').show();
      $('#order_info').hide();
      return;
    }

    $('#error_order').hide();
    $("#order_info").hide();

    this.getOrderStatus();
  }

  getOrderStatus() {

    const post = {
      OrderID: this.orderId,
      UserID: this.userId,
    };

    this.orderService.getOrderStatus(post).subscribe(
      data => {
        if (data["Success"] && data.hasOwnProperty('Response')) {
          if (data['Response'].hasOwnProperty('EstimatedTime')) {
            this.estimatedTime = data["Response"]["EstimatedTime"];
          }

          if (data['Response'].hasOwnProperty('TrackingURL')) {
            this.trackingURL = data["Response"]["TrackingURL"];
          }

          if (data['Response'].hasOwnProperty('OrderStatus')) {
            this.orderStatus = data["Response"]["OrderStatus"];
          }

          this.orderService.getOrder(post).subscribe(
            data1 => {

              if (JSON.stringify(data1).includes("{\"Status\":\"500\"}")) {
                $('#error_order').show();
                return;
              }

              let self = this;

              const pusher = new Pusher('1112b666d809129183c1', {
                cluster: 'us2',
                forceTLS: true
              });

              const channel = pusher.subscribe(data['Response']['RestaurantID'].toString() + 'track');
                channel.bind('my-event', function (data) {
                  self.updateOrderStatus();
              });

              $('#order_info').show();
              this.orderInfo = data1;
              this.updateOrderProgess();
          });
        }
        else {
          $('#error_order').show();
        }
      });
  }

  updateOrderStatus() {

    const post = {
      OrderID: this.orderId,
      UserID: this.userId,
    };

    this.orderService.getOrderStatus(post).subscribe(
      data => {
        if (data["Success"] && data.hasOwnProperty('Response') && this.orderInfo) {
          if (data['Response'].hasOwnProperty('EstimatedTime')) {
            this.estimatedTime = data["Response"]["EstimatedTime"];
          }

          if (data['Response'].hasOwnProperty('TrackingURL')) {
            this.trackingURL = data["Response"]["TrackingURL"];
          }

          if (data['Response'].hasOwnProperty('OrderStatus')) {
            this.orderStatus = data["Response"]["OrderStatus"];
          }

          this.updateOrderProgess();
        }
      });
  }

  updateOrderProgess() {
        
    let status = parseInt(this.orderStatus);

    const stepButtons = document.querySelectorAll('.step-button');
    const progress = document.querySelector('#progress');

    $("#order_info_txt").hide();

    if (status == 1) {
      Array.from(stepButtons).forEach((button,index) => {
        button.classList.remove('done');
        if (index == 0) {
          button.classList.add('done');
        }
      });
      progress.setAttribute('value', '0');
    }
    else if (status == 2) {

      Array.from(stepButtons).forEach((button,index) => {
        button.classList.remove('done');
        if (index < 2) {
          button.classList.add('done');
        }
      });
      progress.setAttribute('value', '50');
    }
    else if (status == 3) {

      if (this.orderInfo['OrderType'] == '2') {
        Array.from(stepButtons).forEach((button,index) => {
          button.classList.remove('done');
          if (index < 2) {
            button.classList.add('done');
          }
        });
        progress.setAttribute('value', '50');

        if (this.trackingURL !== null && this.trackingURL !== 'NA' && this.trackingURL.trim() !== '') {
          $("#order_info_txt").html('The order is currently being delivered by the courier!');
          $("#order_info_txt").show();
        }
      }
      else {
        Array.from(stepButtons).forEach((button,index) => {
          button.classList.add('done');
        });
        progress.setAttribute('value', '100');
      }
    }
    else if (status == 4) {
      
      Array.from(stepButtons).forEach((button,index) => {
        button.classList.add('done');
      });
      progress.setAttribute('value', '100');
      
      $('#estimate_time_txt').hide();
      $("#order_info_txt").html('The order has been delivered!');
      $("#order_info_txt").show();
    }
    else if (status == 5) {

      Array.from(stepButtons).forEach((button,index) => {
        button.classList.remove('done');
      });
      progress.setAttribute('value', '0');

      $("#order_info_txt").html('The order has been canceled!');
      $("#order_info_txt").show();
    }
    else if (status == 7) {
      Array.from(stepButtons).forEach((button,index) => {
        button.classList.remove('done');
      });
      progress.setAttribute('value', '0');
      $("#order_info_txt").html('The payment for this order has been refunded.');
      $("#order_info_txt").show();
    }
  }

  getSubtotal(order) {
    let subtot = 0;
    for (let x in order.OrderedItems) {
      subtot += parseFloat(order.OrderedItems[x].Price);
      for (let i in order.OrderSides[x]) {
        subtot += parseFloat(order.OrderSides[x][i].sidePrice);
      }
    }
    this.subtotal = subtot;
    return this.subtotal;
  }

  isRestricted(order) {
    let restrictedItems = order.filter(item => item["AgeRestriction"] == 1);

    if (restrictedItems.length > 0) // contains restricted items
    {
      return true;
    }
    return false;
  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }

  gettrackingURL() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.trackingURL);
  }

  goBack() {
    this.routeLocation.back();
  }

}
