import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../../models/url';

export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ReservationManagerService {

  getReservationSettingsTableURL = this.url.getDomain() + '/v3/GetReservationSettingsTables';
  upadteReservationTableAvailabiltyURL = this.url.getDomain() + '/v3/upadteReservationSettingsTableSpot'
  createReservationURL = this.url.getDomain() + '/v3/CreateBooking';
  getTableTimesURL = this.url.getDomain() + '/v3/GetReservationSettingsTableTimeOfDay'
  addReservationsExceptionURL = this.url.getDomain() + '/v3/AddReservationExceptions';
  getReservationExceptionDatesURL = this.url.getDomain() + '/v3/GetReservationExceptionDates';
  deleteReservationExceptionDatesURL = this.url.getDomain() + '/v3/DeleteAllReservationExceptionDates'
  getTableManagerTableURL = this.url.getDomain() + '/v3/GetTableManagerTables';
  getTablesWithUpcomingReservionsURL = this.url.getDomain() + '/v3/getTablesWithUpcomingReservions';

  constructor(private http: HttpClient, private url: Url) { }



  getReservationSettingsTables(res) {
    return this.http.post<Res>(this.getReservationSettingsTableURL, res);
  }

  getTableManagerTables(res) {
    return this.http.post<Res>(this.getTableManagerTableURL, res);
  }

  upadteReservationTableAvailabilty(res) {
    return this.http.post<Res>(this.upadteReservationTableAvailabiltyURL, res);
  }

  createReservation(res) {
    return this.http.post<Res>(this.createReservationURL, res);
  }

  getTableTimesOfDay(res) {
    return this.http.post<Res>(this.getTableTimesURL, res);
  }

  addReservationsException(res) {
    return this.http.post<Res>(this.addReservationsExceptionURL, res);
  }

  getReservationExceptionDates(res) {
    return this.http.post<Res>(this.getReservationExceptionDatesURL, res);
  }

  deleteReservationExceptionDates(res) {
    return this.http.post<Res>(this.deleteReservationExceptionDatesURL, res);
  }

  getTablesWithUpcomingReservions(res) {
    return this.http.post<Res>(this.getTablesWithUpcomingReservionsURL, res);
  }

}
