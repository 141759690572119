import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import { FormsModule, NgModel } from "@angular/forms";

export interface PublishPromotions {
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class PublishPromotionsServiceService {
  constructor(private http: HttpClient, private url: Url) { }

  getLastPublishDateURL = this.url.getDomain() + '/Restaurants/GetLastPublishDate';
  getPublishPromotionURL = this.url.getDomain() + '/Restaurants/PublishPromotion';
  getPublishedPromotionsURL = this.url.getDomain() + '/Restaurants/GetPublishedPromotions';
  enableInstantPrmotionForRestaurantURL =  this.url.getDomain() + '/Restaurants/EnableInstantPrmotionForRestaurant';
  getCurrentSpecialIDForRestaurantURL =  this.url.getDomain() + '/Restaurants/GetCurrentSpecialIDForRestaurant';

  getLastPublishDate(post) {
    return this.http.post<PublishPromotionsServiceService>(this.getLastPublishDateURL, post);
  }

  getPublishPromotion(post) {
    return this.http.post<PublishPromotionsServiceService>(this.getPublishPromotionURL, post);
  }

  getPublishedPromotions(post) {
    return this.http.post<PublishPromotionsServiceService>(this.getPublishedPromotionsURL, post);
  }

  enableInstantPrmotionForRestaurant(post) {
    return this.http.post<PublishPromotionsServiceService>(this.enableInstantPrmotionForRestaurantURL, post);
  }

  getCurrentSpecialIDForRestaurant(post) {
    return this.http.post<PublishPromotionsServiceService>(this.getCurrentSpecialIDForRestaurantURL, post);
  }
}
