import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()
export class EmpService {

  private updateServicesNavbar = new BehaviorSubject<boolean>(false);

  updateServicesNavbar$ = this.updateServicesNavbar.asObservable();

  isNewUser = false;
  loggedInUser = [];
  loggedInDriver = {};
  isNormalUser = false;
  loggedInUserType = 0;
  orderLat = 0.0;
  orderLng = 0.0;
  chosenRestaurant: any;
  orderedItems = [];
  completedOrder = 0;

  orderInformation = {
    travelKM: undefined,
    delivery: undefined,
    lat: undefined,
    long: undefined,
    address1: undefined,
    address2: undefined,
    city: undefined,
    postal: undefined,
    province: undefined,
    country: undefined,
    isMealService: undefined,
    isReservationOnly: undefined
  };

  notifyServicesNavbar(newValue: boolean) {
    this.updateServicesNavbar.next(newValue);
  }

}
