import { Component, OnInit } from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-online-ordering',
  templateUrl: './online-ordering.component.html',
  styleUrls: ['./online-ordering.component.css']
})


export class OnlineOrderingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var my_awesome_script = document.createElement('script');

    my_awesome_script.setAttribute('src','https://js-na1.hs-scripts.com/7764233.js');
    
    document.head.appendChild(my_awesome_script);


    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      navText: ["←", "→"],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });


  }

}
