import { Component, NgZone, OnInit } from '@angular/core';
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { RestaurantService } from 'app/structure/restaurant/restaurant.service';
import { RegisterService } from 'app/structure/register/register.service';
import { Url } from 'app/models/url';
import { LoginService } from 'app/structure/login/login.service';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { ReservationsSubscriptionService } from 'app/structure/reservations/reservations-subscription/reservations-subscription.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { EmpService } from 'app/emp.service';
import { PlatformInfoService } from '../platform-info/platform-info.service';
import { ProductService } from '../products/product-reservations/product.service';

declare var $: any;
@Component({
  selector: 'app-google-reservations',
  imports: [],
  templateUrl: './google-reservations.component.html',
  styleUrls: [ '../../../assets/css/aos.css','./google-reservations.component.css', '../../../assets/css/aos.css', '../../../assets/css/custom.css','../../../assets/css/ts-style.css', '../../../assets/css/fonts.css', '../../../assets/css/fx.css', '../../../assets/css/magnific-popup.css']
})
export class GoogleReservationsComponent implements OnInit {

  businessName = '';
  businessAddress = '';
  email = '';
  phone = '';
  businessType = '';
  extraInfo = '';

  constructor(public emp:EmpService, private url: Url, private platformService: PlatformInfoService, private meta: Meta, private title: Title, private sanitizer: DomSanitizer, private zone: NgZone, private resService: RestaurantService, private registerService: RegisterService, private paymentService: PaymentService, private loginService: LoginService,  private router: Router, private productService: ProductService, private reservationsSubscriptionService: ReservationsSubscriptionService){

    title.setTitle('Google Reservations | Restaurant Reservation Software | TopServe')
    meta.updateTag({ name: 'description', content: 'Restaurant Reservation Software help guest reserve at your restaurant easily. Get Google Reservations, the best tool for restaurant discovery and reservations for restaurants.' });
    meta.updateTag({ property: 'og:title', content: 'Google Reservations | Restaurant Reservation Software | TopServe' });
    meta.updateTag({ name: 'keywords', content: 'Google Reservations,get reservations for restaurant,Get google reservations,Restaurant reservation system,how to use google reservations,free reservation system,topserve,Reserve with Google,Restaurant Reservation Software, Reservation Software, OpenTable, Resy' });
  }

  restaurantLat = 0.0;
  restaurantLng = 0.0;
  address1 = '';
  address = '';
  city = '';
  province = '';
  country = '';
  postal = '';
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  
  ngOnInit(): void {

    let self = this;
    $(document).ready(function ($) {
      self.setupGoogleMaps();
    });

    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          if (event.target.id == 'reservation-info-form') {
            self.sendInfoMessage();
          }
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Starts ----*/
  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  setupGoogleMaps() {

    var input = <HTMLInputElement>document.getElementById("business-address-input");

    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {

      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';

      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.restaurantLat = mylocation.geometry.location.lat();
      self.restaurantLng = mylocation.geometry.location.lng();

    });

  }
  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Ends ----*/

  validatePhoneNumber(phoneNumber) {
    var regex = /^\d{10}$/; // regular expression to match 10 digits
    return regex.test(phoneNumber); // test the phone number against the regex
  }

  validateEmail(email) {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test if email matches the pattern
    return pattern.test(email);
  }

  checkFormValidation() {
    if (!this.validateEmail(this.email) || !this.validatePhoneNumber(this.phone) || this.businessName.trim() == '' || this.businessAddress.trim() == '' || this.businessType.trim() == '') {
      return true;
    }
    else {
      return false;
    }
  }

  sendInfoMessage() {

    if (this.address1 == '' || this.province == '' || this.country == '' || this.postal == '' || this.restaurantLat == 0.0 || this.restaurantLng == 0.0) {
      alert('Please select a valid business address from dropdown.');
      return;
    }

    var info = 'Reservation Inquiry Partner\n\n';

    info += 'Email = ' + this.email + ' | Phone = ' + this.phone + ' | Business Name = ' + this.businessName + ' | Business Type = ' + this.businessType + ' | Address = ' + this.address1 + ', ' + this.city + ', ' + this.province + ', ' + this.country + ', Postal Code: ' + this.postal  + ', Latitude: ' + this.restaurantLat  + ', Longitude: ' + this.restaurantLng+ ' | Extra Info = ' + this.extraInfo;

    const post = {
      Message: info,
    };


    this.platformService.sendMessage(post).subscribe(
      data => {
        if (data['Success']) {
          this.email = '';
          this.businessName = '';
          this.businessType = '';
          this.businessAddress = '';
          this.phone = '';
          this.address = '';
          this.extraInfo = '';

          alert("Thank you. TopServe Team will contact you back within 24 hours.");
        } else {
          this.email = '';
          this.businessName = '';
          this.businessAddress = '';
          this.businessType = '';
          this.phone = '';
          this.address = '';
          this.extraInfo = '';
          alert("Something went wrong while submitting your request, please submit your request at support@topserve.ca.");
        }
      });
  }
}