import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EmpService } from 'app/emp.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { LoginService } from 'app/structure/login/login.service';
import { RegisterService } from 'app/structure/register/register.service';
import { Url } from '../../../models/url';
import { RestaurantService } from '../restaurant.service';
import { environment } from 'environments/environment.prod';
const AWS = require('aws-sdk');

declare var $: any;

@Component({
  selector: 'app-store-sign-up',
  templateUrl: './store-sign-up.component.html',
  styleUrls: ['./store-sign-up.component.css', '../../../../assets/css/ts-style.css']
})
export class StoreSignUpComponent implements OnInit {

  serviceType = 0;
  storeType = "";
  storeLogo: any;
  storeName = '';
  storeAddress = '';
  storeDescription = '';
  fileInput: any;
  image = '';
  imageUrl: any;
  NewUserID = '';
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  password = '';
  storeHour = '00:00';
  storeLat = 0.0;
  storeLng = 0.0;
  address1 = "";
  address2 = "";
  address = '';
  city = '';
  province = '';
  country = '';
  postal = '';
  formattedAddress: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  storeInfoErrorMsg = '';

  constructor(private sanitizer: DomSanitizer, public emp: EmpService, private url: Url, private zone: NgZone, private router: Router, private paymentService: PaymentService, private registerService: RegisterService, private loginService: LoginService, private resService: RestaurantService) { }

  ngOnInit(): void {

    let self = this;
    $(document).ready(function () {

      self.setupGoogleMaps();

      $('#all-services').on('change', function () {
        if ($(this).is(':checked')) {
          $('#mobile-app').prop('checked', true);
          $('#online-ordering').prop('checked', true);
          $('#qr-pay').prop('checked', true);
          $('#reservations').prop('checked', true);

          $('#mobile-app').prop('disabled', true);
          $('#online-ordering').prop('disabled', true);
          $('#qr-pay').prop('disabled', true);
          $('#reservations').prop('disabled', true);
        }
        else {
          $('#mobile-app').prop('checked', false);
          $('#online-ordering').prop('checked', false);
          $('#qr-pay').prop('checked', false);
          $('#reservations').prop('checked', false);

          $('#mobile-app').prop('disabled', false);
          $('#online-ordering').prop('disabled', false);
          $('#qr-pay').prop('disabled', false);
          $('#reservations').prop('disabled', false);
        }
        //no need to call manageServiceType Here.. as it will be automatically called from below input checkbox listener
      });

      $('input[type=checkbox]').on('change', function () {
        if ($('#mobile-app').is(':checked') && $('#online-ordering').is(':checked') && $('#qr-pay').is(':checked') && $('#reservations').is(':checked')) {

          $('#all-services').prop('checked', true);

          $('#mobile-app').prop('disabled', true);
          $('#online-ordering').prop('disabled', true);
          $('#qr-pay').prop('disabled', true);
          $('#reservations').prop('disabled', true);

        }
        self.manageServiceType();
      });

      //Default On Page Load
      $('#all-services').prop('checked', true);

      $('#mobile-app').prop('checked', true);
      $('#online-ordering').prop('checked', true);
      $('#qr-pay').prop('checked', true);
      $('#reservations').prop('checked', true);

      $('#mobile-app').prop('disabled', true);
      $('#online-ordering').prop('disabled', true);
      $('#qr-pay').prop('disabled', true);
      $('#reservations').prop('disabled', true);
    });

    $(document).on('shown.bs.offcanvas', function () {
      self.hideAllErrors();
    });

    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (self.checkFormValidation()) {
          self.storeUserRegister();
        }
        form.classList.add('was-validated');
      }, false);
    });

    this.hideAllErrors();



    $('#phone').on('input', function () {
      const text = $('#phone').val().replace(/\D/g, '');
      const validText = text.slice(0, 10);
      $(this).val(validText);
    });
  }

  hideAllErrors() {
    $('#fname_error').hide();
    $('#lname_error').hide();
    $('#email_error').hide();
    $('#password_error').hide();
    $('#password_info').show();
    $('#phone_error').hide();
    $('#store_name_error').hide();
    $('#store_address_error').hide();
    $('#store_desc_error').hide();
    $('#services_error').hide();
  }

  validateEmail(email) {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test if email matches the pattern
    return pattern.test(email);
  }

  validatePhoneNumber(phoneNumber) {
    var regex = /^\d{10}$/; // regular expression to match 10 digits
    return regex.test(phoneNumber); // test the phone number against the regex
  }

  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Starts ----*/
  getAddress(place: object) {
    this.address = place['formatted_address'];
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  setupGoogleMaps() {

    var input = <HTMLInputElement>document.getElementById("store-pac-input");

    var autocomplete = new google.maps.places.Autocomplete(input);
    let self = this;
    autocomplete.addListener('place_changed', function () {

      var mylocation = autocomplete.getPlace();

      let formatted_address = mylocation.formatted_address;
      let address = mylocation.address_components;
      let json_address = JSON.stringify(address);

      let street_number, route, town, province, country, postal_code

      for (let i in address) {
        if (address[i]['types'].toString().includes('street_number')) {
          street_number = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('route')) {
          route = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
          town = address[i].short_name;
        }
        else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
          province = address[i].short_name;
        }
        else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
          province = address[i].short_name;
        }
        else if (address[i]['types'].toString().includes('country')) {
          country = address[i].long_name;
        }
        else if (address[i]['types'].toString().includes('postal_code')) {
          postal_code = address[i].short_name;
        }
      }

      self.address1 = street_number ? street_number + ' ' + route : route;
      self.address2 = street_number ? street_number + ' ' + route : route;
      self.city = town ? town : '';
      self.province = province ? province : '';
      self.country = country ? country : '';
      self.postal = postal_code ? postal_code : '';
      self.center = mylocation.formatted_address;
      self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng();
      self.storeLat = mylocation.geometry.location.lat();
      self.storeLng = mylocation.geometry.location.lng();
      self.storeAddress = formatted_address;
    });

  }
  /*---- Google Maps Setup Code For Selecting Restaurant Address Code Ends ----*/

  /*---- Selecting and Uploading Restaurant Logo Image Code Starts ----*/
  fileEvent(fileInput: any) {
    this.fileInput = fileInput.target.files[0];
    const file = fileInput.target.files[0];
    if (file != undefined) {
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

      if (validImageTypes.includes(fileType)) {
        let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
        this.storeLogo = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
      }
    }
  }

  clearImage(event) {
    this.fileInput = undefined;
    this.storeLogo = undefined;
    event.stopPropagation();
  }

  async upload() {
    return new Promise(resolve => {
      const AWSService = AWS;
      const region = 'ca-central-1';

      let bucketName = "";
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-restaurant-main-images-dev-canada';
      } else {
        bucketName = 'topserve-restaurant-main-images-prod-canada';
      }

      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });

      if (this.fileInput != null || this.fileInput != undefined) {
        this.image = this.fileInput;
      } else {
        this.image = this.storeLogo
      }

      if (this.image != '' && this.image != undefined) {
        s3.upload({
          Key: this.imageUrl,
          Bucket: bucketName,
          Body: this.image
        }, function (err, data) {
          if (err) {
            resolve(false)
          } else {
            resolve(true)
          }
        });
      }
      else {
        resolve(true)
      }
    });
  }
  /*---- Selecting and Uploading Restaurant Logo Image Code Ends ----*/

  checkFormValidation(): boolean {

    this.hideAllErrors();

    let valid = true;

    if ($('#fname').val().trim() == '') {
      $('#fname_error').show();
      valid = false;
    }

    if ($('#lname').val().trim() == '') {
      $('#lname_error').show();
      valid = false;
    }

    if (!this.validateEmail($('#email').val())) {
      $('#email_error').show();
      valid = false;
    }

    if ($('#password').val().trim().length < 8) {
      $('#password_error').show();
      $('#password_info').hide();
      valid = false;
    }

    if (!this.validatePhoneNumber($('#phone').val())) {
      $('#phone_error').show();
      valid = false;
    }

    if ($('#store_name').val().trim() == '') {
      $('#store_name_error').show();
      valid = false;
    }

    if (this.address1 == '' || this.storeLng == 0.0 || this.storeLat == 0.0) {
      $('#store_address_error').show();
      valid = false;
    }

    if ($('#store_desc').val().trim() == '') {
      $('#store_desc_error').show();
      valid = false;
    }

    if (!$('#all-services').is(':checked') && !$('#mobile-app').is(':checked') && !$('#online-ordering').is(':checked') && !$('#qr-pay').is(':checked') && !$('#reservations').is(':checked')) {
      $('#services_error').show();
      valid = false;
    }

    return valid;
  }

  manageServiceType() {
    if ($('#all-services').is(':checked')) {
      this.serviceType = 0;
    }
    else if ($('#reservations').is(':checked') && !$('#mobile-app').is(':checked') && !$('#online-ordering').is(':checked') && !$('#qr-pay').is(':checked')) {
      this.serviceType = 3;
    }
    else {
      this.serviceType = 0;
    }
  }

  storeUserRegister() {

    this.storeInfoErrorMsg = '';

    $('#progress-modal').modal('show');

    const post = {
      Password: this.password,
      Email: this.email,
      Phone: this.phone,
      FirstName: this.firstName,
      LastName: this.lastName,
      UserImageURL: 'na',
      UserType: 5,
      AuthenticationToken: this.makeRandom(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      StripeCustomerID: "NA",
    };

    this.paymentService.createNewCustomer(post).subscribe(data => {
      if (data["success"]) {
        post['StripeCustomerID'] = data['customerId'];
        this.registerService.register(post).subscribe(data => {

          if (data["userAdded"]) {
            this.NewUserID = data['UserID'];
            this.addStore();
          }
          else {
            $('#progress-modal').modal('hide');
            this.storeInfoErrorMsg = data["Message"];
          }
        });
      }
      else {
        $('#progress-modal').modal('hide');
        this.storeInfoErrorMsg = data["Message"];
      }
    });

  }

  addStore() {

    var ot = {};

    ot["type"] = '1';
    ot["type2"] = '2';
    ot["type3"] = '3';

    const post = {
      UserInfo: {
        UserID: this.NewUserID,
      },

      RestaurantInformation: {
        Name: this.storeName,
        Description: this.storeDescription,
        Address1: this.address1,
        Address2: this.address1,
        City: this.city,
        Country: this.country,
        Province: this.province,
        Postal: this.postal,
        Category: '',
        Phone: this.phone,
        Status: '0',
        EmailAddress: this.email,
        Rating: '4.7',
        ContactName: this.firstName + ' ' + this.lastName,
        Longitude: this.storeLng,
        Latitude: this.storeLat,
        StoreType: this.storeType,
        RestaurantType: this.serviceType,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },

      RestaurantHours: [
        {
          Day: '0',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '1',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '2',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '3',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '4',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '5',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
        {
          Day: '6',
          Open: this.storeHour,
          Close: this.storeHour,
          Status: 'y',
          HolidayDescription: 'date',
          Date: '2009-00-00 00:00:00'
        },
      ]
    };

    post["OrderTypes"] = ot;

    this.resService.addRes(post).subscribe(
      data => {
        if (data[0].hasOwnProperty('ImageURL')) {
          this.emp.isNewUser = false;
          if (this.storeType !== 'sellerstand') {
            this.imageUrl = data[0]['ImageURL'];
            this.upload();
          }
          this.loginStore();
        } else {
          $('#progress-modal').modal('hide');
          this.storeInfoErrorMsg = data["Message"];
        }
      });
  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  loginStore() {

    const post = {
      email: this.email,
      password: this.password,
      Token: this.makeRandom()
    };

    this.loginService.login(post).subscribe(
      data => {
        $('#progress-modal').modal('hide');

        if (data['verified']) {

          if (data['User'][0].UserType == 0) {
            alert("it seems this is a user login and not a restaurant login");
            this.router.navigateByUrl('/top-login');
            return;
          }

          if (data['User'].length > 0 && data['User'][0].UserType != 0 && this.password.toLowerCase() == 'password') {
            localStorage.setItem('changePassword', 'true');
          }

          localStorage.setItem('isLogedIn', 'true');
          localStorage.setItem('type', 'admin');
          this.emp.loggedInUser = data['User'][0];

          if (data['Restaurants'].length > 0) {
            this.emp.loggedInUserType = data["Restaurants"][0]["UserType"];
            localStorage.setItem("allRestaurants", JSON.stringify(data['Restaurants']));
            localStorage.setItem('restaurantName', data["Restaurants"][0]["Name"]);
            localStorage.setItem('restaurantID', data["Restaurants"][0]["RestaurantID"]);
            localStorage.setItem('restaurantLatitude', data["Restaurants"][0]["Latitude"]);
            localStorage.setItem('restaurantLongitude', data["Restaurants"][0]["Longitude"]);
            localStorage.setItem('restaurantContactName', data["Restaurants"][0]["ContactName"]);
            localStorage.setItem('restaurantAddress', data["Restaurants"][0]["Address1"]);
            localStorage.setItem('restaurantPhone', data["Restaurants"][0]["Phone"]);
            localStorage.setItem('restaurantUserType', data["Restaurants"][0]["UserType"]);
            localStorage.setItem('restaurantTimeZone', data["Restaurants"][0]["TimeZone"]);
            localStorage.setItem('ownDelivery', data["Restaurants"][0]["OwnDelivery"]);
            localStorage.setItem('restaurantType', data["Restaurants"][0]["RestaurantType"]);
            localStorage.setItem('LinkName', data["Restaurants"][0]["LinkName"]);
            localStorage.setItem('restaurantOrderTypeIDs', data["Restaurants"][0]["OrderTypeIDs"]);
            localStorage.setItem('restaurantOrderTypeNames', data["Restaurants"][0]["OrderTypeNames"]);
            localStorage.setItem('restaurantOrderTypeStatuses', data["Restaurants"][0]["OrderTypeStatuses"]);
            localStorage.setItem('restaurantOrderTypePercentages', data["Restaurants"][0]["OrderTypePercentages"]);
            localStorage.setItem('storeOtFeePercentages', data["Restaurants"][0]["OrderTypeFeePercentages"]);
            localStorage.setItem('storeOtFeePrices', data["Restaurants"][0]["OrderTypeFeePrices"]);
            localStorage.setItem('Reservations', data["Restaurants"][0]["Reservations"]);
            localStorage.setItem('ReservationProductID', data["Restaurants"][0]["ReservationProductID"]);
            localStorage.setItem('ReservationPriceID', data["Restaurants"][0]["ReservationPriceID"]);
            localStorage.setItem('DineInProductID', data["Restaurants"][0]["DineInProductID"]);
            localStorage.setItem('DineInPriceID', data["Restaurants"][0]["DineInPriceID"]);
            localStorage.setItem('POS', data["Restaurants"][0]["POS"]);
            localStorage.setItem('restaurantStripeBusinessID', data["Restaurants"][0]["StripeBusinessID"]);
            localStorage.setItem('storeExpressActive', data["Restaurants"][0]["ExpressAccountActive"]);
            localStorage.setItem('storeType', data["Restaurants"][0]["Type"]);
            localStorage.setItem('storeDescription', data["Restaurants"][0]["Description"]);
            localStorage.setItem('storeCity', data["Restaurants"][0]["City"]);

            if (data["Restaurants"][0]["OnlineOrderSubscriptionID"] != null) {
              localStorage.setItem('OnlineOrderSubscriptionID', data["Restaurants"][0]["OnlineOrderSubscriptionID"]);
            }

            if (data["Restaurants"][0]["DineInSubscriptionID"] != null) {
              localStorage.setItem('DineInSubscriptionID', data["Restaurants"][0]["DineInSubscriptionID"]);
            }
            else {
              localStorage.setItem('DineInSubscriptionID', '');
            }

            if (data["Restaurants"][0]["ReservationSubscriptionID"] != null) {
              localStorage.setItem('ReservationSubscriptionID', data["Restaurants"][0]["ReservationSubscriptionID"]);
            }
            else {
              localStorage.setItem('ReservationSubscriptionID', '');
            }

          }
          else {
            localStorage.setItem("allRestaurants", "null");
            localStorage.setItem('restaurantID', "null");
            localStorage.setItem('restaurantName', "null");
            localStorage.setItem('restaurantLatitude', "null");
            localStorage.setItem('restaurantLongitude', "null");
            localStorage.setItem('restaurantContactName', "null");
            localStorage.setItem('restaurantAddress', "null");
            localStorage.setItem('restaurantPhone', "null");
            localStorage.setItem('restaurantUserType', 'null');
            localStorage.removeItem('restaurantTimeZone');
            localStorage.removeItem('ownDelivery');
            localStorage.removeItem('restaurantType');
            localStorage.removeItem('LinkName');
            localStorage.removeItem('restaurantOrderTypeIDs');
            localStorage.removeItem('restaurantOrderTypeNames');
            localStorage.removeItem('restaurantOrderTypePercentages');
            localStorage.setItem('DineInSubscriptionID', '');
            localStorage.setItem('ReservationSubscriptionID', '');
            localStorage.removeItem('restaurantStripeBusinessID');
            localStorage.removeItem('POS');
            this.emp.loggedInUserType = 6
          }

          localStorage.setItem('userType', data["User"][0]["UserType"]);
          localStorage.setItem('firstName', data['User'][0].FirstName);
          localStorage.setItem('AuthenticationToken', post.Token);
          localStorage.setItem('UserID', data['User'][0].UserID);
          localStorage.setItem('Email', data['User'][0].Email);
          localStorage.setItem('restaurantStripeCustomerID', data['User'][0].StripeCustomerID);

          if (this.storeType == 'sellerstand') {
            this.router.navigateByUrl('/services/expenditure');
          }
          else {
            this.router.navigateByUrl('/services');
          }

        } else {
          this.router.navigateByUrl('/login');
        }
      }
    );

  }

}
