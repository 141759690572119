// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  ul li {
    border: 1px solid #ddd;
    margin-top: -1px; /* Prevent double borders */
    background-color: #f6f6f6;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
    position: relative;
  }
  
  ul li:hover {
    background-color: #eee;
  }
  
  .close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0%;
    padding: 12px 16px;
    transform: translate(0%, -50%);
  }
  
  .close:hover {background: #bbb;}`, "",{"version":3,"sources":["webpack://./src/app/structure/printer-setup/printer-setup.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,gBAAgB,EAAE,2BAA2B;IAC7C,yBAAyB;IACzB,aAAa;IACb,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA,cAAc,gBAAgB,CAAC","sourcesContent":["* {\n    box-sizing: border-box;\n  }\n  \n  ul {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  ul li {\n    border: 1px solid #ddd;\n    margin-top: -1px; /* Prevent double borders */\n    background-color: #f6f6f6;\n    padding: 12px;\n    text-decoration: none;\n    font-size: 18px;\n    color: black;\n    display: block;\n    position: relative;\n  }\n  \n  ul li:hover {\n    background-color: #eee;\n  }\n  \n  .close {\n    cursor: pointer;\n    position: absolute;\n    top: 50%;\n    right: 0%;\n    padding: 12px 16px;\n    transform: translate(0%, -50%);\n  }\n  \n  .close:hover {background: #bbb;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
