// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.saving{

    background-color: #0A9928;

}

.edit{

    background-color: #ffa430;

}

.cancel{

    background-color: #bec0c3;

}
.delete{
    color:white;
    background-color: #c3363a;

}`, "",{"version":3,"sources":["webpack://./src/app/structure/driver/show-drivers/show-drivers.component.css"],"names":[],"mappings":";;;AAGA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,yBAAyB;;AAE7B;;AAEA;;IAEI,yBAAyB;;AAE7B;AACA;IACI,WAAW;IACX,yBAAyB;;AAE7B","sourcesContent":["\n\n\n.saving{\n\n    background-color: #0A9928;\n\n}\n\n.edit{\n\n    background-color: #ffa430;\n\n}\n\n.cancel{\n\n    background-color: #bec0c3;\n\n}\n.delete{\n    color:white;\n    background-color: #c3363a;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
