// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topright {
    position: absolute;
    top: -4px;
    right: -5px;
    font-size: 12px;
    border-radius: 10px;
    text-align: center;
    height: 20px;
    justify-content: center;
    width: 20px;
    color: #ffffff;
    background-color: black;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/add-option/add-option.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,uBAAuB;AAC3B","sourcesContent":[".topright {\n    position: absolute;\n    top: -4px;\n    right: -5px;\n    font-size: 12px;\n    border-radius: 10px;\n    text-align: center;\n    height: 20px;\n    justify-content: center;\n    width: 20px;\n    color: #ffffff;\n    background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
