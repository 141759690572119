import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import './bootstrap-clockpicker.min.js';
import './jquery-clockpicker.min.js';
import {AddShiftService} from './add-shift.service'
import {Meta} from "@angular/platform-browser";

declare var $: any;


@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.css'],
  encapsulation: ViewEncapsulation.None,

})





export class AddShiftComponent implements OnInit {


  mondayShiftStart = '00:00';
  mondayShiftEnd = '00:00';
  mondayShifts = []
  mondayCity = "";
  availableShifts = [];

  tuesdayCity = "";
  wednesdayCity = "";
  thursdayCity = "";
  fridayCity = "";
  saturdayCity = "";
  sundayCity = "";


  tuesdayShiftStart = '00:00';
  tuesdayShiftEnd = '00:00';

  wednesdayShiftStart = '00:00';
  wednesdayShiftEnd = '00:00';


  thursdayShiftStart = '00:00';
  thursdayShiftEnd = '00:00';


  fridayShiftStart = '00:00';
  fridayShiftEnd = '00:00';


  saturdayShiftStart = '00:00';
  saturdayShiftEnd = '00:00';


  sundayShiftStart = '00:00';
  sundayShiftEnd = '00:00';


  tuesdayShifts = []
  wednesdayShifts = []
  thursdayShifts = []
  fridayShifts = []
  saturdayShifts = []
  sundayShifts = []

  shiftToAssign: any;
  subDrivers = [];
  selectedDriver: any;



  constructor(private addShiftsService:AddShiftService) { }

  ngOnInit(): void {

    $(function () {
      $('.clockpicker').clockpicker({
           autoclose: true,
           afterDone: function() {
              $('.clockpicker').trigger("change");
          }
      });
  });

  let self = this;
  $('#assign_shift_modal').on('shown.bs.modal', function (event) {
    self.getSubDriversOfCity();
  });

  this.getAllShifts();

  }

  isTaken(item){

    if (item.Taken === 0) {
      return 'No'
    } else {
      return item.FirstName
    }
  }

  editMondayShift(index) {


    if (this.mondayShifts[index]['editing'] == null || this.mondayShifts[index]['editing'] == undefined) {
      this.mondayShifts[index]['editing'] = 'true';
    }else{
      this.mondayShifts[index]['StartHour'] = $("#mondayShiftEdit" + index).val();
      this.mondayShifts[index]['EndHour'] = $("#mondayShiftEditEnd" + index).val();
      this.mondayShifts[index]['editing'] = undefined;



      const post = {
        StartHour: this.mondayShifts[index]['StartHour'],
        EndHour:this.mondayShifts[index]['EndHour'],
        ShiftID:this.mondayShifts[index]['DriverAvailableShiftID']
      };
  
      this.addShiftsService.updateAvailableShift(post).subscribe(
          data => {
            if (data['Success']) {


      
    }
  });
    
  }





    $(function () {
      $('.clockpicker').clockpicker({
        autoclose: true,
        format: 'HH:mm:ss',
          afterDone: function() {
              $('.clockpicker').trigger("change");
          }
      });
  });
  }



editTuesdayShift(index) {


  if (this.tuesdayShifts[index]['editing'] == null || this.tuesdayShifts[index]['editing'] == undefined) {
    this.tuesdayShifts[index]['editing'] = 'true';
  }else{
    this.tuesdayShifts[index]['StartHour'] = $("#tuesdayShiftEdit" + index).val();
    this.tuesdayShifts[index]['EndHour'] = $("#tuesdayShiftEditEnd" + index).val();
    this.tuesdayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.tuesdayShifts[index]['StartHour'],
      EndHour:this.tuesdayShifts[index]['EndHour'],
      ShiftID:this.tuesdayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}





editWednesdayShift(index) {


  if (this.wednesdayShifts[index]['editing'] == null || this.wednesdayShifts[index]['editing'] == undefined) {
    this.wednesdayShifts[index]['editing'] = 'true';
  }else{
    this.wednesdayShifts[index]['StartHour'] = $("#wednesdayShiftEdit" + index).val();
    this.wednesdayShifts[index]['EndHour'] = $("#wednesdayShiftEditEnd" + index).val();
    this.wednesdayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.wednesdayShifts[index]['StartHour'],
      EndHour:this.wednesdayShifts[index]['EndHour'],
      ShiftID:this.wednesdayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}




editThursdayShift(index) {


  if (this.thursdayShifts[index]['editing'] == null || this.thursdayShifts[index]['editing'] == undefined) {
    this.thursdayShifts[index]['editing'] = 'true';
  }else{
    this.thursdayShifts[index]['StartHour'] = $("#thursdayShiftEdit" + index).val();
    this.thursdayShifts[index]['EndHour'] = $("#thursdayShiftEditEnd" + index).val();
    this.thursdayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.thursdayShifts[index]['StartHour'],
      EndHour:this.thursdayShifts[index]['EndHour'],
      ShiftID:this.thursdayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}




editFridayShift(index) {


  if (this.fridayShifts[index]['editing'] == null || this.fridayShifts[index]['editing'] == undefined) {
    this.fridayShifts[index]['editing'] = 'true';
  }else{
    this.fridayShifts[index]['StartHour'] = $("#fridayShiftEdit" + index).val();
    this.fridayShifts[index]['EndHour'] = $("#fridayShiftEditEnd" + index).val();
    this.fridayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.fridayShifts[index]['StartHour'],
      EndHour:this.fridayShifts[index]['EndHour'],
      ShiftID:this.fridayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}




editSaturdayShift(index) {


  if (this.saturdayShifts[index]['editing'] == null || this.saturdayShifts[index]['editing'] == undefined) {
    this.saturdayShifts[index]['editing'] = 'true';
  }else{
    this.saturdayShifts[index]['StartHour'] = $("#saturdayShiftEdit" + index).val();
    this.saturdayShifts[index]['EndHour'] = $("#saturdayShiftEditEnd" + index).val();
    this.saturdayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.saturdayShifts[index]['StartHour'],
      EndHour:this.saturdayShifts[index]['EndHour'],
      ShiftID:this.saturdayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}




editSundayShift(index) {


  if (this.sundayShifts[index]['editing'] == null || this.sundayShifts[index]['editing'] == undefined) {
    this.sundayShifts[index]['editing'] = 'true';
  }else{
    this.sundayShifts[index]['StartHour'] = $("#sundayShiftEdit" + index).val();
    this.sundayShifts[index]['EndHour'] = $("#sundayShiftEditEnd" + index).val();
    this.sundayShifts[index]['editing'] = undefined;



    const post = {
      StartHour: this.sundayShifts[index]['StartHour'],
      EndHour:this.sundayShifts[index]['EndHour'],
      ShiftID:this.sundayShifts[index]['DriverAvailableShiftID']
    };

    this.addShiftsService.updateAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {

  }
});
  
}



$(function () {
  $('.clockpicker').clockpicker({
    autoclose: true,
    format: 'HH:mm:ss',
      afterDone: function() {
          $('.clockpicker').trigger("change");
      }
  });
});
}








  getAllShifts(){


    this.tuesdayShifts = []
    this.wednesdayShifts = []
    this.thursdayShifts = []
    this.fridayShifts = []
    this.saturdayShifts = []
    this.sundayShifts = []
    this.mondayShifts = []

    
    const post = {
      PartnerID: localStorage.getItem('PartnerID'),
      City: localStorage.getItem('partnerDataCityName')
    };

    this.addShiftsService.getShifts(post).subscribe(
        data => {

          if (data['Success']) {
            for(let x in data['Response']){

              switch (data["Response"][x]["Day"]) {

              case 0:
                this.mondayShifts.push(data["Response"][x])
                  break;
              case 1:
                this.tuesdayShifts.push(data["Response"][x])

                break;

              case 2:


                this.wednesdayShifts.push(data["Response"][x])


                break;

              case 3:

                this.thursdayShifts.push(data["Response"][x])

                break;

              case 4:

                this.fridayShifts.push(data["Response"][x])

                break;

              case 5:

                this.saturdayShifts.push(data["Response"][x])

                break;

              case 6:

                this.sundayShifts.push(data["Response"][x])

                break;

              default:

                break;


              }

              this.availableShifts.push(data["Response"][x])
            }
          }
        })
  }



  addShift(Day,dayName) {

    this.mondayShiftStart = $("#" + dayName + "ShiftStart").val();
    this.mondayShiftEnd = $("#" + dayName + "ShiftEnd").val();
    const post = {
      StartHour: this.mondayShiftStart,
      EndHour: this.mondayShiftEnd,
      Day: Day,
      City: this.mondayCity,
      PartnerID: localStorage.getItem('PartnerID')
    };
    
    this.addShiftsService.addShiftsService(post).subscribe(
        data => {

          if (data['Success']) {
            this.getAllShifts();
          }
        });
  }

  removeShift(shiftID) {

    const post = {
      ShiftID: shiftID
    };
    
    this.addShiftsService.removeShiftsService(post).subscribe(
        data => {
          if (data['Success']) {
              this.getAllShifts();
          }
        });
  }

  resetShift(day: number, item) {

    const post = {
      ShiftID: item.DriverAvailableShiftID
    };
    
    this.addShiftsService.resetShift(post).subscribe(
        data => {
          if (data['Success']) {
            switch (day) {
              case 0:
                this.updateResetShiftArray(this.mondayShifts, item);
                break;
              case 1:
                this.updateResetShiftArray(this.tuesdayShifts, item);
                break;
              case 2:
                this.updateResetShiftArray(this.wednesdayShifts, item);
                break;
              case 3:
                this.updateResetShiftArray(this.thursdayShifts, item);
                break;
              case 4:
                this.updateResetShiftArray(this.fridayShifts, item);
                break;
              case 5:
                this.updateResetShiftArray(this.saturdayShifts, item);
                break;
              case 6:
                this.updateResetShiftArray(this.sundayShifts, item);
                break;
            }
          }
        });
  }

  updateResetShiftArray(shifts, item) {
    let index = shifts.indexOf(item);
    shifts[index]['Taken'] = 0;
    shifts[index]['SubDriverID'] = null;
    shifts[index]['FirstName'] = null;
  }

  updateTakenShift(item) {

    const post = {
      ShiftID: item.DriverTakenShiftID
    };
    
    this.addShiftsService.updateWeekForTakenShift(post).subscribe(
        data => {
          if (data['Success']) {
              alert('Shift Updated!');
          }
          else {
            alert('Something went wrong!');
          }
        });
  }

  assignShiftModal(item) {
    this.shiftToAssign = item;
    $('#assign_shift_modal').modal('show');
  }

  getSubDriversOfCity() {

    $('#assign_shift_spinner').show();
    $('#assign_shift_content').hide();

    const post = {
      City: this.shiftToAssign.City,
      PartnerID: this.shiftToAssign.PartnerID
    };
    
    this.addShiftsService.getSubDriversOfCity(post).subscribe(
        data => {
          $('#assign_shift_spinner').hide();
          $('#assign_shift_content').show();
          if (data['Success']) {
            if (data['SubDrivers'].length > 0) {
              this.subDrivers = data['SubDrivers'];
              if (this.subDrivers.length > 0) {
                this.selectedDriver = this.subDrivers[0];
              }
            }
            else {
              $('#assign_shift_modal').modal('hide');
              alert('Drivers could not be found at this time.');
            }
          }
          else {
            alert('Something went wrong!');
          }
        });
  }

  assignShift() {

    $('#assign_shift_spinner').show();
    $('#assign_shift_content').hide();
    
    const post = {
      Shifts: [this.shiftToAssign.DriverAvailableShiftID],
      SubDriverID: this.selectedDriver.SubDriverID,
      OverlapAllowed: true
    };

    this.addShiftsService.assignSubDriverShift(post).subscribe(
      data => {
        if (data['Success']) {
          $('#assign_shift_modal').modal('hide');
          this.getAllShifts();
        }
        else {
          $('#assign_shift_modal').modal('hide');
          alert('Something went wrong!');
        }
      });
  }

  resetDayShifts(day:number) {

    let shift = [];
    
    switch (day) {
      case 0:
        shift = this.mondayShifts;
        break;
      case 1:
        shift = this.tuesdayShifts;
        break;
      case 2:
        shift = this.wednesdayShifts;
        break;
      case 3:
        shift = this.thursdayShifts;
        break;
      case 4:
        shift = this.fridayShifts;
        break;
      case 5:
        shift = this.saturdayShifts;
        break;
      case 6:
        shift = this.sundayShifts;
        break;
    }

    let shiftID = [];
    for (let i in shift) {
      shiftID.push(shift[i]['DriverAvailableShiftID']);
    }

    const post = {
      ShiftID: shiftID
    };
    
    this.addShiftsService.resetDayShifts(post).subscribe(
        data => {
          if (data['Success']) {
              this.getAllShifts();
          }
        });
  }

  pauseAvailableShift(day:number, item) {

    const post = {
      ShiftID: item.DriverAvailableShiftID,
      Paused: item.Paused == 0 ? 1 : 0
    };
    
    this.addShiftsService.pauseAvailableShift(post).subscribe(
        data => {
          if (data['Success']) {
            switch (day) {
              case 0:
                this.updatePausedShiftsArray(this.mondayShifts, item, post['Paused']);
                break;
              case 1:
                this.updatePausedShiftsArray(this.tuesdayShifts, item, post['Paused']);
                break;
              case 2:
                this.updatePausedShiftsArray(this.wednesdayShifts, item, post['Paused']);
                break;
              case 3:
                this.updatePausedShiftsArray(this.thursdayShifts, item, post['Paused']);
                break;
              case 4:
                this.updatePausedShiftsArray(this.fridayShifts, item, post['Paused']);
                break;
              case 5:
                this.updatePausedShiftsArray(this.saturdayShifts, item, post['Paused']);
                break;
              case 6:
                this.updatePausedShiftsArray(this.sundayShifts, item, post['Paused']);
                break;
            }
          }
          else {
            alert('Something went wrong!');
          }
        });
  }

  updatePausedShiftsArray(shifts, item, Paused) {
    let index = shifts.indexOf(item);
    shifts[index]['Paused'] = Paused;
  }
  


}
