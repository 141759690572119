import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Url } from '../../models/url';



export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  
  getRestaurantTablesURL = this.url.getDomain() + '/v3/GetRestaurantTables';
  getPastReservationsURL = this.url.getDomain() + '/v3/GetReservationsBetweenDates';

  getAllAvailabilitiesURL = this.url.getDomain() + '/v3/GetAllAvailabilities';
  addAvailabilityURL = this.url.getDomain() + '/v3/AddAvailability';
  removeAvailabilityURL = this.url.getDomain() + '/v3/RemoveAvailability';
  getReservationsURL = this.url.getDomain() + '/v3/GetReservations';
  confirmReservationURL = this.url.getDomain() + '/v3/ConfirmReservation';
  confirmCheckReservationURL = this.url.getDomain() + '/v3/ConfirmCheckReservation';

  cancelReservationURL = this.url.getDomain() + '/v3/CancelReservation';
  updateTableNumberURL = this.url.getDomain() + '/v3/UpdateReservationTableNumber';
  getTableNumbersForAssigningURL = this.url.getDomain() + '/v3/getTablesForAssigningTable';

  addTableURL = this.url.getDomain() + '/v3/AddTable';
  updateTableURL = this.url.getDomain() + '/v3/UpdateTable';
  updateTableNumberIDURL = this.url.getDomain() + '/v3/UpdateTableNumber';
  deleteTableURL = this.url.getDomain() + '/v3/DeleteTable';

  getCityURL = this.url.getDomain() + '/v3/GetRestaurantsWithReservationForCity';
  getTableTimesURL = this.url.getDomain() + '/v3/GetTableTimesOfDayForCustomer';
  getAvailableTimeAndTableOfDayURl = this.url.getDomain() + '/v3/GetAvailableTimeAndTableOfDay';
  getBatchAvailabilityURL = this.url.getDomain() + '/v3/BatchAvailabilityLookup';
  createBookingURL = this.url.getDomain() + '/v3/createbooking';
  getPromotionUsersListURL = this.url.getDomain() + '/v3/GetPromotionUsersList';
 
  constructor(private http: HttpClient, private url: Url) { }


  getCity(res) {
    return this.http.post<Res>(this.getCityURL, res);
  } 

  getTableTimes(res) {
    return this.http.post<Res>(this. getTableTimesURL, res);
  } 

  getAvailableTimeAndTable(res) {
    return this.http.post<Res>(this. getAvailableTimeAndTableOfDayURl, res);
  } 

  getBatchAvailability(res) {
    return this.http.post<Res>(this.getBatchAvailabilityURL, res);
  } 

  createBooking(res) {
    return this.http.post<Res>(this.createBookingURL, res);
  } 

  getPastReservations(res) {
    return this.http.post<Res>(this.getPastReservationsURL, res);
  }

  confirmReservation(res) {
    return this.http.post<Res>(this.confirmReservationURL, res);
  }
  confirmCheckReservation(res) {
    return this.http.post<Res>(this.confirmCheckReservationURL, res);
  }


  
  cancelReservation(res) {
    return this.http.post<Res>(this.cancelReservationURL, res);
  }

  getReservations(res) {
    return this.http.post<Res>(this.getReservationsURL, res);
  }

  updateTableNumberReservation(res) {
    return this.http.post<Res>(this.updateTableNumberURL, res);
  }


  getRestaurantTables(res) {
    return this.http.post<Res>(this.getRestaurantTablesURL, res);
  }

  getAllAvailabilities(res) {
    return this.http.post<Res>(this.getAllAvailabilitiesURL, res);
  }

  addAvailability(res) {
    return this.http.post<Res>(this.addAvailabilityURL, res);
  }

  addTable(res) {
    return this.http.post<Res>(this.addTableURL, res);
  }

  updateTable(res) {
    return this.http.post<Res>(this.updateTableURL, res);
  }

  deleteTable(res) {
    return this.http.post<Res>(this.deleteTableURL, res);
  }

  updateTableNumber(res) {
    return this.http.post<Res>(this.updateTableNumberIDURL, res);
  }

  removeAvailability(res) {
    return this.http.post<Res>(this.removeAvailabilityURL, res);
  }

  getTableNumbersForAssigning(res) {
    return this.http.post<Res>(this.getTableNumbersForAssigningURL, res);
  }

  getPromotionUsersList(res) {
    return this.http.post<Res>(this.getPromotionUsersListURL, res);
  } 

}
