import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReservationsService } from '../../structure/reservations/reservations.service';
import { formatDate } from '@angular/common';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { MenuService } from "../menu/menu.service";
import { Url } from "../../models/url";

import { Router } from "@angular/router";
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-find-reservation',
  templateUrl: './find-reservation.component.html',
  styleUrls: ['./find-reservation.component.css', '../../../assets/css/aos.css', '../../../assets/css/custom.css', '../../../assets/css/ts-style.css', '../../../assets/css/fonts.css', '../../../assets/css/fx.css', '../../../assets/css/magnific-popup.css'],

})
export class FindReservationComponent implements OnInit {

  merchantID: any;
  restaurantInfo = []

  partySize = 2
  restaurantName: any;
  preReserveMsg: any;
  getTimes = []
  dateToday = new Date()
  idName = ""
  day: any;
  myEpoch: any;
  timeInterval: any;
  durationSec: any;
  email: any;
  firstName = '';
  lastName = '';
  phone = '';
  timeAvailable: any;
  restaurantNameLink = '';
  removeNav = false;
  restaurantPhone = '';
  customerComments = '';

  countries = [
    { "name": "United States", "code": "+1" },
    { "name": "Canada", "code": "+1" },
    { "name": "United Kingdom", "code": "+44" },
    { "name": "Australia", "code": "+61" },
    { "name": "Germany", "code": "+49" },
    { "name": "France", "code": "+33" },
    { "name": "India", "code": "+91" },
    { "name": "Japan", "code": "+81" },
    { "name": "China", "code": "+86" },
    { "name": "Brazil", "code": "+55" },
    { "name": "Russia", "code": "+7" },
    { "name": "South Africa", "code": "+27" },
    { "name": "Mexico", "code": "+52" },
    { "name": "Italy", "code": "+39" },
    { "name": "Spain", "code": "+34" },
    { "name": "South Korea", "code": "+82" },
    { "name": "Argentina", "code": "+54" },
    { "name": "Turkey", "code": "+90" },
    { "name": "Indonesia", "code": "+62" },
    { "name": "Saudi Arabia", "code": "+966" },
    { "name": "Nigeria", "code": "+234" },
    { "name": "Egypt", "code": "+20" },
    { "name": "Pakistan", "code": "+92" },
    { "name": "Thailand", "code": "+66" },
    { "name": "Vietnam", "code": "+84" },
    { "name": "Philippines", "code": "+63" },
    { "name": "Malaysia", "code": "+60" },
    { "name": "Netherlands", "code": "+31" },
    { "name": "Sweden", "code": "+46" }
  ];

  selectedCountryCode: string = this.countries[0].code;

  constructor(private resService: ReservationsService, private router: Router, private urlObject: Url,
    private zone: NgZone,
    private title: Title,
    private menuService: MenuService,
    private meta: Meta) { }

  ngOnInit(): void {

    let self = this;
    $('#no_reservation_msg').hide();
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (self.checkFormValidation()) {
          self.bookTable();
        }
      }, false);
    });

    $(document).on('show.bs.offcanvas', function() {
      self.hideAllErrors();
    });

    $('#phone').on('input', function () {
      var phone = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      $(this).val(phone.slice(0, 10));
      self.phone = phone.slice(0, 10);
    });

    let restaurant_info = window.location.href.toString().split('/').pop();
    this.merchantID = restaurant_info.split('#')[1];
    this.restaurantName = decodeURIComponent(restaurant_info.split('#')[0]);


    if (this.merchantID == null) {
      this.restaurantNameLink = window.location.href.split('/').pop()
      let x = this.restaurantNameLink.split("?");
      this.restaurantNameLink = x[0];
      this.getRestaurantByLinkForReservations()
    }
    else {
      this.getTimeSlot()
      this.checkLogin()
    }
  }

  validateEmail(email) {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Test if email matches the pattern
    return pattern.test(email);
  }

  validatePhoneNumber(phoneNumber) {
    var regex = /^\d{10}$/; // regular expression to match 10 digits
    return regex.test(phoneNumber); // test the phone number against the regex
  }

  hideAllErrors() {
    $('#fname_error').hide();
    $('#lname_error').hide();
    $('#email_error').hide();
    $('#phone_error').hide();
  }

  checkFormValidation(): boolean {
    
    this.hideAllErrors();
    
    let valid = true;
    
    if ($('#fname').val().trim() == '') {
      $('#fname_error').show();
      valid = false;
    }

    if ($('#lname').val().trim() == '') {
      $('#lname_error').show();
      valid = false;
    }

    if (!this.validateEmail($('#email').val())) {
      $('#email_error').show();
      valid = false;
    }

    if (!this.validatePhoneNumber($('#phone').val())) {
      $('#phone_error').show();
      valid = false;
    }

    return valid;
  }


  async getRestaurantByLinkForReservations() {

    return new Promise(resolve => {

      const post = {
        RestaurantLinkName: this.restaurantNameLink
      };
      this.menuService.GetRestaurantByLinkNameForReservations(post).subscribe(async data => {

        if (data["Success"]) {
          if (data["Restaurants"].length == 0) {
            this.router.navigateByUrl('/');
            return;
          }

          this.removeNav = true
          var chosenRestaurant = data["Restaurants"][0];
          this.restaurantName = data["Restaurants"][0]["Name"];
          this.restaurantPhone = this.formatPhoneNumber(data["Restaurants"][0]["Phone"]);
          this.preReserveMsg = data["Restaurants"][0]["PreReserveMsg"];
          this.merchantID = data["Restaurants"][0]["RestaurantID"];
          localStorage.setItem('restaurantName', chosenRestaurant['Name'])
          chosenRestaurant['DefaultDeliveryFee'] = data["Restaurants"][0]['DeliveryFee'];
          this.title.setTitle(chosenRestaurant['Name'] + ' | Reservation Software by TopServe');
          this.meta.updateTag({ name: 'description', content: chosenRestaurant['MetaDescription'] });
          this.meta.addTag({ name: 'robots', content: 'INDEX, FOLLOW' }, true);
          this.meta.updateTag({ name: 'twitter:description', content: 'TopServe Restaurant Reservation Software' });
          this.meta.addTag({ name: 'author', content: 'TopServe' }, true);
          this.meta.updateTag({ name: 'keywords', content: chosenRestaurant['Name'] + ', TopServe Restaurant Software' });
          this.meta.addTag({ httpEquiv: 'Content-Type', content: 'text/html' }, true);
          this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
          this.meta.updateTag({ property: 'og:title', content: chosenRestaurant['Name'] + ' | Reservation Software by TopServe' });
          this.meta.addTag({ property: 'og:type', content: "website" });
          this.meta.addTag({ charset: 'UTF-8' });


          this.getTimeSlot()
          this.checkLogin()


        }
        resolve(true)
      });
    });
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }


  checkLogin() {
    if (localStorage.getItem('isLogedIn') == 'true') {
      this.firstName = localStorage.getItem('FirstName');
      this.lastName = localStorage.getItem('LastName');
      if (localStorage.getItem('guestAccount') == 'true') {
        this.email = localStorage.getItem('GuestEmail');
      }
      else {
        this.email = localStorage.getItem('Email');
      }
      this.phone = localStorage.getItem('Phone');

    }
  }
  matStartDateEvent(event: MatDatepickerInputEvent<Date>) {
    this.dateToday = event.value
    this.getTimeSlot()
  }


  getTimeSlot() {

    $('#no_reservation_msg').hide();
    $('#progress-modal').modal('show');
    this.day = this.dateToday.getDay() - 1
    if (this.day < 0) {
      this.day = 6
    }

    const post = {
      ReservationDate: formatDate(this.dateToday, 'yyyy-MM-dd', 'en-US'),
      day: this.day,
      RestaurantID: this.merchantID,
      PartySize: this.partySize,
    };

    this.restaurantInfo = []
    this.resService.getTableTimes(post).subscribe(data => {
      if (data["Success"]) {
        this.preReserveMsg = data['Store']['PreReserveMsg']
        let available = false;
        for (let x in data["Response"]) {
          if (data["Response"][x]['available']) {
            available = true;
          }
          data["Response"][x]['selected'] = false;
          this.restaurantInfo.push(data["Response"][x]);
        }
        $('#progress-modal').modal('hide');
        if (data['Response'].length == 0 || !available) {
          $('#no_reservation_msg').show();
          if (data['isClosed']) {
            $('#no_reservation_msg').html('This store is not accepting any reservations for selected date.');
          }
          else {
            $('#no_reservation_msg').html('This store has no open slots for reservations on the selected date.');
          }
        }
      }
      else {
        $('#progress-modal').modal('hide');
        alert('Something went wrong! Please contact topserve!');
      }
    })
  }

  minPartySize() {
    if (this.partySize > 2) {
      this.partySize -= 1
      this.getTimeSlot()
    }
  }
  addPartySize() {
    this.partySize += 1
    this.getTimeSlot()
  }

  continue() {

    this.durationSec = this.restaurantInfo[this.timeInterval]["TimeInterval"]

      console.log('here')
      const post = {
        merchant_id: this.merchantID,
        slot_time: [{
          duration_sec: this.durationSec,
          service_id: this.merchantID + 0,
          resource_ids: { party_size: this.partySize },
          start_sec: this.myEpoch
        }]

      };


      this.resService.getBatchAvailability(post).subscribe(data => {
        var timeAvailability = data["slot_time_availability"][0]
        if (timeAvailability["available"]) {
          this.checkLogin();
          $('#offcanvasBtn').click();
          // Show Off canvas for booking
        }
        else {
          alert('Sorry, this time is not available.');
        }
      })

  }


  // compareTime(time){

  // const d = new Date();
  // let hour = d.getHours();
  // let min = d.getMinutes();
  // let day = d.getDate();

  // let chosenDate = this.dateToday.getDate();

  // if(chosenDate <= day ){
  // if((hour + ":" + min) >  time){
  //   return false
  // }else{
  //   return true
  // }
  // }else{
  //   return true
  // }

  // }


  goBackCarousel() {
    $('#menu-item-carousel').carousel(0);
    document.getElementById(this.idName).style.backgroundColor = "Firebrick";
    document.getElementById(this.idName).style.color = "White";
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.selectedCountryCode = this.countries[0].code;
    this.email = '';
    const btn = document.querySelector("#btn");
    const btnText = document.querySelector("#btnText");
    btnText.innerHTML = "Book your Table";
    btn.classList.remove("active1");
  }
  timeClicked(timeIndex) {
    this.timeInterval = timeIndex
    this.timeAvailable = this.restaurantInfo[timeIndex]["available"]
    this.myEpoch = this.restaurantInfo[timeIndex]["DateTime"]
    if (this.idName != "") {
      document.getElementById(this.idName).style.backgroundColor = "transparent";
      document.getElementById(this.idName).style.color = "black";
      document.getElementById(this.idName).style.border = "1px solid black";
    }
    this.idName = 'selTime' + timeIndex
    document.getElementById(this.idName).style.backgroundColor = "#b00505";
    document.getElementById(this.idName).style.color = "White";
    document.getElementById(this.idName).style.border = "1px solid #b00505";
  }

  timeSelected(timeIndex) {
    this.timeInterval = timeIndex
    this.timeAvailable = this.restaurantInfo[timeIndex]["available"]
    this.myEpoch = this.restaurantInfo[timeIndex]["DateTime"]
    this.restaurantInfo.map(time => time.selected = false);
    this.restaurantInfo[timeIndex].selected = true;
    this.continue();
  }

  bookTable() {
    if (this.firstName != '' && this.phone != '' && this.email != '') {

      $('#progress-modal').modal('show');

      let token = this.randomString(22) + "web"
      const post = {
        slot: {
          service_id: this.merchantID + "0",
          start_sec: String(this.myEpoch),
          duration_sec: String(this.durationSec),
          confirmation_mode: "CONFIRMATION_MODE_ASYNCHRONOUS",
          merchant_id: this.merchantID,
          resources: { party_size: String(this.partySize) },
        },

        payment_information: {
          prepayment_status: "PREPAYMENT_NOT_PROVIDED"
        },

        user_information: {
          telephone: this.selectedCountryCode + this.phone,
          given_name: this.firstName,
          email: this.email,
          family_name: this.lastName,
        },
        
        idempotency_token: token,
        send_user_email: true,
        customerComments: this.customerComments,
      };

      this.resService.createBooking(post).subscribe(data => {
        $('#progress-modal').modal('hide');
        console.log(data)
        if (data['booking'] != undefined && data["booking"]["status"] == "CONFIRMED") {
          $('#offcanvasBtn').click();
          $('#success-reservation-modal').modal('show');
          $('#reservation-status').html('Reservation confirmed!');
          $('#reservation-message').html('Your reservation has been confirmed at ' + this.restaurantName + '. You will receive an email or text message confirmation of your reservation!');

        } else if (data['booking'] != undefined && data["booking"]["status"] == "PENDING_MERCHANT_CONFIRMATION") {
          $('#offcanvasBtn').click();
          $('#success-reservation-modal').modal('show');
          $('#reservation-status').html('Reservation awaiting confirmation!');
          $('#reservation-message').html('Your reservation has been sent to ' + this.restaurantName + '. You will receive an email or text message once confirmed or declined!' );

        } else {
          console.log(data);
          alert('Something went wrong! Could not make a reservation please contact restaurant to create your reservation!');
        }
      })
    }
  }

  randomString(len) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < len; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  gotoFirst() {
    this.router.navigateByUrl('/table-reservation')
  }

  reload() {
    window.location.reload();
  }
}