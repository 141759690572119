// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .offcanvas-backdrop {
    background-color: #0e0b20;
}

::ng-deep .offcanvas-backdrop.fade.show {
    opacity: .5 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/services-navigation/services-navigation.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["::ng-deep .offcanvas-backdrop {\n    background-color: #0e0b20;\n}\n\n::ng-deep .offcanvas-backdrop.fade.show {\n    opacity: .5 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
