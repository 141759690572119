import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-agreement',
  templateUrl: './driver-agreement.component.html',
  styleUrls: ['./driver-agreement.component.css']
})
export class DriverAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
