import { Component, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RestaurantService } from '../restaurant.service';
import { Url } from "../../../models/url";
import { EmpService } from "../../../emp.service";
import { DomSanitizer } from "@angular/platform-browser";
// import { } from "@agm/core/services/google-maps-types";
import { environment } from 'environments/environment.prod';


declare var $: any;
const AWS = require('aws-sdk');

@Component({
    selector: 'app-show-details',
    templateUrl: './show-details.component.html',
    styleUrls: ['./show-details.component.css'],
    encapsulation: ViewEncapsulation.None,

})
export class ShowDetailsComponent implements OnInit {
    selectedCategories = [];
    item: any;
    resID: any;
    name: any;
    description = "";
    address1 = "";
    address2 = "";
    city = "";
    province = "";
    country = "";
    postal = "";
    phone = "";
    email = "";
    contactName = "";
    formattedAddress: string;
    contactEmail = "";
    confirmEmail = "";
    monday = true;
    mondayOpen = '00:00';
    mondayClose = '00:00';
    tuesday = true;
    tuesdayOpen = '00:00';
    tuesdayClose = '00:00';
    wednesday = true;
    wednesdayOpen = '00:00';
    wednesdayClose = '00:00';
    thursday = true;
    thursdayOpen = '00:00';
    thursdayClose = '00:00';
    friday = true;
    fridayOpen = '00:00';
    fridayClose = '00:00';
    saturday = true;
    saturdayOpen = '00:00';
    saturdayClose = '00:00';
    sunday = true;
    sundayOpen = '00:00';
    sundayClose = '00:00';
    addressChosen = "";
    dineIn = false;
    takeOut = false;
    delivery = false;
    menuOnly = false;

    imageUrl = "";
    fileInput: any;
    restaurantImage: any;
    image = '';
    lat = 46.236226;
    lng = -63.126524;
    position = '';
    center = '';

    itemAdded = false;
    loading = false;
    restaurantID = 0;

    hoursOpen = [];
    hoursClose = [];

    orderTypes = [];
    orderTypeIds = [];

    orderTypesStatus = [];


    constructor(public zone: NgZone, private sanitizer: DomSanitizer, private emp: EmpService, private route: ActivatedRoute, private router: Router, private ResService: RestaurantService, private url: Url) {

    }


    async ngOnInit() {

        //todo geGoogleMapt hours working.

        $(function () {
            $('.clockpicker').clockpicker({
                // autoclose: true,
                afterDone: function () {
                    $('.clockpicker').trigger("change");
                }
            });
        });










        var restaurantID = this.route.params;

        restaurantID.subscribe(params => {
            //console.log(params)
            restaurantID = params["id"];
        });


        const param = {
            UserID: localStorage.getItem('UserID')
        };
        await this.ResService.getRes(param).subscribe(
            data => {

                var newDate = [];
                for (let d in data) {

                    if (data[d]["RestaurantID"] == restaurantID) {
                        newDate = data[d];
                    }
                }

                try {
                    this.name = newDate["Name"];
                    this.description = newDate["Description"];
                    this.address1 = newDate["Address1"];
                    this.address2 = newDate["Address2"];
                    this.restaurantID = newDate["RestaurantID"];
                    this.city = newDate["City"];
                    this.province = newDate["Province"];
                    this.country = newDate["Country"];
                    this.postal = newDate["Postal"];
                    this.phone = newDate["Phone"];
                    this.contactName = newDate["ContactName"];
                    this.confirmEmail = newDate["EmailAddress"];
                    this.email = newDate["EmailAddress"];
                    this.imageUrl = newDate["ImageURL"];
                    this.position = newDate["Latitude"] + "," + newDate["Longitude"];
                    this.lat = newDate["Latitude"];
                    this.lng = newDate["Longitude"];
                    this.center = this.province + ", " + this.city + ", " + this.country;
                    this.hoursOpen = newDate["HoursOpen"].split(",");

                    let selectedCats = newDate["Category"].split(",");
                    selectedCats.forEach(async (item, n) => {
                        this.selectedCategories.push(item);
                    });

                    this.addCategories();





                    this.hoursClose = newDate["HoursClose"].split(",");
                    this.orderTypes = newDate["OrderTypes"].split(",");
                    let status = newDate["OrderTypesStatus"].split(",");



                    status.forEach(async (item, n) => {
                        let value = item.substr(item.length - 1);

                        this.orderTypesStatus.push(value);
                    });

                    this.orderTypeIds = data[0]["OrderTypesID"].split(",");
                    this.setHours();
                    this.setOrderTypes();
                    this.getImages();
                } catch {
                    // an error has occured

                }
            });


        let self = this
        $(function () {      //my logic here
            self.setupGoogleMaps()
        });


        this.resID = this.route.snapshot.paramMap.get('id');
    }


    addCategories() {


        let dataCate = [];

        this.selectedCategories.forEach(async (item, n) => {

            dataCate.push({
                id: n,
                text: item,
                "selected": true
            });
        });

        dataCate.push({
            id: dataCate.length,
            text: "Indian",
            "selected": false
        });
        dataCate.push({
            id: dataCate.length,
            text: "Pizza",
            "selected": false
        });
        dataCate.push({
            id: dataCate.length,
            text: "Family",
            "selected": false
        });
        dataCate.push({
            id: dataCate.length,
            text: "Breakfast",
            "selected": false
        });
        dataCate.push({
            id: dataCate.length,
            text: "Thai",
            "selected": false
        });
        dataCate.push({
            id: dataCate.length,
            text: "Chinese",
            "selected": false
        });



        $("#selectedCategory").select2({
            data: dataCate,
            multiple: true,
            tags: true,
            maximumSelectionLength: 2
        });



        $('#selectedCategory').on('select2:select', (e) => {
            this.selectedCategories.push({ "Name": e.params.data.text })
        });
        $('#selectedCategory').on('select2:unselect', (e) => {
            for (let n in this.selectedCategories) {
                if (e.params.data.text == this.selectedCategories[n]) {
                    this.selectedCategories.splice(parseInt(n), 1)
                }
            }
        });



    }


    setupGoogleMaps() {


        var input = <HTMLInputElement>document.getElementById("pac-input");
        var autocomplete = new google.maps.places.Autocomplete(input);
        let self = this;


        autocomplete.addListener('place_changed', function () {
            var mylocation = autocomplete.getPlace();

            let formatted_address = mylocation.formatted_address;
            let address = mylocation.address_components;
            let json_address = JSON.stringify(address);

            let street_number, route, town, province, country, postal_code

            for (let i in address) {
                if (address[i]['types'].toString().includes('street_number')) {
                    street_number = address[i].short_name;
                }
                else if (address[i]['types'].toString().includes('route')) {
                    route = address[i].short_name;
                }
                else if (address[i]['types'].toString().includes('postal_town') || address[i]['types'].toString().includes('locality')) {
                    town = address[i].short_name;
                }
                else if (!json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_1')) {
                    province = address[i].short_name;
                }
                else if (json_address.includes('administrative_area_level_2') && address[i]['types'].toString().includes('administrative_area_level_2')) {
                    province = address[i].short_name;
                }
                else if (address[i]['types'].toString().includes('country')) {
                    country = address[i].long_name;
                }
                else if (address[i]['types'].toString().includes('postal_code')) {
                    postal_code = address[i].short_name;
                }
            }

            self.address1 = street_number ? street_number + ' ' + route : route;
            self.address2 = street_number ? street_number + ' ' + route : route;
            self.city = town ? town : '';
            self.province = province ? province : '';
            self.country = country ? country : '';
            self.postal = postal_code ? postal_code : '';

            self.center = mylocation.formatted_address;
            self.position = mylocation.geometry.location.lat().toString() + "," + mylocation.geometry.location.lng()
            self.lat = mylocation.geometry.location.lat();
            self.lng = mylocation.geometry.location.lng();

        })
    }


    getAddress(place: object) {
        var address = this.address1;

        //this.phone = this.getPhone(place);
        this.formattedAddress = place['formatted_address'];
        this.zone.run(() => this.formattedAddress = place['formatted_address']);
    }
    setOrderTypes() {



        if (this.orderTypes.includes("1")) {
            let x = this.orderTypes.indexOf("1");
            if (this.orderTypesStatus[x] == "1") {
                this.takeOut = true
            }
        }


        if (this.orderTypes.includes("2")) {
            let x = this.orderTypes.indexOf("2");
            if (this.orderTypesStatus[x] == "1") {
                this.delivery = true
            }
        }


        if (this.orderTypes.includes("3")) {
            let x = this.orderTypes.indexOf("3");
            if (this.orderTypesStatus[x] == "1") {
                this.dineIn = true
            }
        }


        if (this.orderTypes.includes("4")) {
            let x = this.orderTypes.indexOf("4");
            if (this.orderTypesStatus[x] == "1") {
                this.menuOnly = true
            }
        }
    }

    setHours() {
        if (this.hoursClose.length > 0) {
            this.hoursClose.forEach(async (item, n) => {
                this.addHours(n, item, false)

            });
        }
        if (this.hoursOpen.length > 0) {
            this.hoursOpen.forEach(async (item, n) => {
                this.addHours(n, item, true)
            });
        }
    }


    addHours(index, value, open) {

        value = value.substring(0, value.length - 1);
        switch (index) {
            case 0:
                open ? this.mondayOpen = value : this.mondayClose = value;
                if (value == "00:00:00") {
                    this.monday = false
                }
                break;
            case 1:
                if (value == "00:00:00") {
                    this.tuesday = false
                }
                open ? this.tuesdayOpen = value : this.tuesdayClose = value;
                break;
            case 2:
                if (value == "00:00:00") {
                    this.wednesday = false
                }
                open ? this.wednesdayOpen = value : this.wednesdayClose = value;
                break;
            case 3:
                if (value == "00:00:00") {
                    this.thursday = false
                }
                open ? this.thursdayOpen = value : this.thursdayClose = value;
                break;
            case 4:
                if (value == "00:00:00") {
                    this.friday = false
                }
                open ? this.fridayOpen = value : this.fridayClose = value;
                break;
            case 5:
                if (value == "00:00:00") {
                    this.saturday = false
                }
                open ? this.saturdayOpen = value : this.saturdayClose = value;
                break;
            case 6:
                if (value == "00:00:00") {
                    this.sunday = false
                }
                open ? this.sundayOpen = value : this.sundayClose = value;
                break;
            default:
                break
        }

    }



    onMapReady(map) {
        // console.log('map', map);
        // console.log('markers', map.markers);  // to get all markers as an array
    }
    onIdle(event) {
        // console.log('map', event.target);
    }
    onMarkerInit(marker) {
        // console.log('marker', marker);

    }


    onMapClick(event) {


        const regExp = /\(([^)]+)\)/;
        const matches = regExp.exec(event.latLng);

        let geocoder = new google.maps.Geocoder;

        var self = this;
        var latLong = matches[1].split(",");
        if (latLong.length > 1) {
            this.lat = parseFloat(latLong[0]);
            this.lng = parseFloat(latLong[1]);
        }



        geocoder.geocode({ 'location': event.latLng }, function (results) {
            if (results[0]) {

                let splittedAddress = results[0].formatted_address.split(",");

                if (splittedAddress.length > 3) {
                    self.address1 = splittedAddress[0];
                    self.address2 = splittedAddress[0];

                    self.city = splittedAddress[1];
                    self.postal = splittedAddress[2];
                    self.country = splittedAddress[4];
                }
                self.center = results[0].formatted_address;
            } else {

            }
        });
        //  }
        this.position = matches[1].toString();

        event.target.panTo(event.latLng);
    }




    async updateRestaurant() {

        let x = await this.updateRestaurantMainData();

        let y = await this.updateHours();




        if (x["Success"] && y["Success"]) {
            this.loading = false;
            let q = this.uploadImage();

        } else {
            this.loading = false
        }
    }

    async updateRestaurantMainData() {
        return new Promise(resolve => {
            this.loading = true;
            let categories = "";
            this.selectedCategories.forEach(async (item, n) => {

                if (n == this.selectedCategories.length - 1) {
                    categories += item
                } else {
                    categories += item + ","
                }

            });


            if (this.address1 == "" || this.fileInput === undefined || this.province == "" || this.country == "" || this.postal == "") {
                $("#error").show();
                this.loading = false;

            }


            const post = {
                RestaurantInformation: { "RestaurantID": this.restaurantID, "Name": this.name, "Category": categories, "Description": this.description, "Address1": this.address1, "Address2": this.address2, "City": this.city, "Country": this.country, "Province": this.province, "Postal": this.postal, "Phone": this.phone, "EmailAddress": this.email, "ContactName": this.contactName, "Longitude": this.lng, "Latitude": this.lat },
            };


            this.ResService.updateRes(post).subscribe(
                data => {

                    this.loading = false;
                    if (data["Success"]) {
                        resolve({ "Success": true });
                    } else {
                        resolve({ "Success": false });
                    }
                });
        })
    }

    updateHours() {


        this.mondayOpen = $("#mondayOpen").val();
        this.mondayClose = $("#mondayClose").val();
        this.tuesdayClose = $("#tuesdayClose").val();
        this.tuesdayOpen = $("#tuesdayOpen").val();
        this.wednesdayClose = $("#wednesdayClose").val();
        this.wednesdayOpen = $("#wednesdayOpen").val();
        this.thursdayClose = $("#thursdayClose").val();
        this.thursdayOpen = $("#thursdayOpen").val();
        this.fridayClose = $("#fridayClose").val();
        this.fridayOpen = $("#fridayOpen").val();
        this.saturdayOpen = $("#saturdayOpen").val();
        this.saturdayClose = $("#saturdayClose").val();
        this.sundayClose = $("#sundayClose").val();
        this.sundayOpen = $("#sundayOpen").val();




        return new Promise(resolve => {





            if (!this.monday) {
                this.mondayOpen = "00:00:00";
                this.mondayClose = "00:00:00";
            }
            if (!this.tuesday) {
                this.tuesdayClose = "00:00:00";
                this.tuesdayOpen = "00:00:00";
            }
            if (!this.wednesday) {
                this.wednesdayClose = "00:00:00";
                this.wednesdayOpen = "00:00:00";
            }
            if (!this.thursday) {
                this.thursdayClose = "00:00:00";
                this.thursdayOpen = "00:00:00";
            }
            if (!this.friday) {
                this.fridayClose = "00:00:00";
                this.fridayOpen = "00:00:00";
            }
            if (!this.saturday) {
                this.saturdayOpen = "00:00:00";
                this.saturdayClose = "00:00:00";
            }
            if (!this.sunday) {
                this.sundayClose = "00:00:00";
                this.sundayOpen = "00:00:00";
            }


            const post = {
                RestaurantID: this.restaurantID,
                RestaurantHours: [
                    {
                        Day: '0',
                        Open: this.mondayOpen,
                        Close: this.mondayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '1',
                        Open: this.tuesdayOpen,
                        Close: this.tuesdayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '2',
                        Open: this.wednesdayOpen,
                        Close: this.wednesdayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '3',
                        Open: this.thursdayOpen,
                        Close: this.thursdayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '4',
                        Open: this.fridayOpen,
                        Close: this.fridayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '5',
                        Open: this.saturdayOpen,
                        Close: this.saturdayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    },
                    {
                        Day: '6',
                        Open: this.sundayOpen,
                        Close: this.sundayClose,
                        Status: 'y',
                        Date: '2009-00-00 00:00:00'
                    }
                ]
            };



            this.ResService.updateResHours(post).subscribe(
                data => {


                    this.loading = false;
                    if (data["Success"]) {
                        resolve({ "Success": true });
                    } else {
                        resolve({ "Success": false });
                    }
                });
        })

    }


    updateOrderTypes() {

        return new Promise(resolve => {

            const post = {
                RestaurantID: this.restaurantID,
                OrderTypes: {
                    "type": "1",
                    "type1": "2",
                    "type2": "3",
                    "type3": "4"

                }, Active: {
                    "type": String(+this.takeOut),
                    "type1": String(+this.delivery),
                    "type2": String(+this.dineIn),
                    "type3": String(+this.menuOnly),
                }, OrderTypeID: {
                    "type": this.orderTypeIds[0],
                    "type1": this.orderTypeIds[1],
                    "type2": this.orderTypeIds[2],
                    "type3": this.orderTypeIds[3]
                }
            };

            this.ResService.updateResOrderTypes(post).subscribe(
                data => {
                    this.loading = false;
                    if (data["Success"]) {
                        resolve({ "Success": true });
                    } else {
                        resolve({ "Success": false });
                    }
                });
        })

    }


    fileEvent(fileInput: any) {

        this.fileInput = fileInput.target.files[0];

        const file = fileInput.target.files[0];
        const fileType = file['type'];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

        if (validImageTypes.includes(fileType)) {
            let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
            this.restaurantImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            //this.menuImage = fileInput.target.files[0];
        }
    }


    async getImages() {

        var self = this;

        const AWSService = AWS;
        const region = 'ca-central-1';


        let bucketName = "";
        if (this.url.getDev() == "dev") {
            bucketName = 'topserve-restaurant-main-images-dev-canada';
        } else {
            bucketName = 'topserve-restaurant-main-images-prod-canada';

        }


        // const bucketName = 'menu-item-images-meeno '+ this.url.getDev();
        const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
        // Configures the AWS service and initial authorization

        await AWSService.config.update({
            accessKeyId: environment.awsAccess,
            secretAccessKey: environment.awsSecret,
            region: region
        });
        // adds the S3 service, make sure the api version and bucket are correct
        const s3 = new AWSService.S3({
            apiVersion: '2012-10-17',
            params: { Bucket: bucketName }
        });





        await s3.getObject({ Key: this.imageUrl }, async function (err, file) {


            if (!err) {
                let blob = new Blob([file.Body], { type: "image/jpeg" });
                self.restaurantImage = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
                self.fileInput = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            } else {
                // self.restaurantImage = require('assets/img/no-image.png.png');
                // self.fileInput = require('assets/img/no-image.png.png');
            }
        }, 1);

    }


    async uploadImage() {


        return new Promise(resolve => {

            const AWSService = AWS;
            const region = 'ca-central-1';



            let bucketName = ""
            if (this.url.getDev() == "dev") {
                bucketName = 'topserve-restaurant-main-images-dev-canada';
            } else {
                bucketName = 'topserve-restaurant-main-images-prod-canada';

            }


            // const bucketName = 'menu-item-images-meeno';
            const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
            // Configures the AWS service and initial authorization
            AWSService.config.update({
                accessKeyId: environment.awsAccess,
                secretAccessKey: environment.awsSecret,
                region: region
            });
            // adds the S3 service, make sure the api version and bucket are correct
            const s3 = new AWSService.S3({
                apiVersion: '2012-10-17',
                params: { Bucket: bucketName }
            });
            // I store this in a variable for retrieval later
            ///  this.image = this.fileInput.name;
            let self = this;
            s3.upload({
                Key: this.imageUrl,
                Bucket: bucketName,
                Body: this.fileInput,
                ACL: 'public-read'
            }, function (err, data) {

                if (err) {
                    resolve(false)
                    self.router.navigateByUrl('/services/show-restaurant')
                } else {
                    self.router.navigateByUrl('/services/show-restaurant')
                    resolve(true)
                }
            });

        });

    }





}
