import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import {FormsModule, NgModel} from "@angular/forms";






export interface Profile  {
  data: any;
}

@Injectable({
  providedIn: 'root'
})


export class ProfileService {

  


  constructor(private http: HttpClient, private url: Url) { }

  getDriverProfileURL= this.url.getDomain() + '/Users/GetDriverProfile';
  getParnerProfileURL= this.url.getDomain() + '/Users/GetPartnerProfile';

  getProfileURL= this.url.getDomain() + '/Users/GetProfile';


  // tslint:disable-next-line: member-ordering
  checkPasswordURL = this.url.getDomain() + '/Users/CheckPassword';


  updatePasswordURL= this.url.getDomain() + '/Users/UpdatePassword';
  updateUserPhoneURL= this.url.getDomain() + '/Users/UpdateUserPhone';


  UpdateUserInformationURL= this.url.getDomain() + '/Users/UpdateUserProfileInformation';

  updateUserInformationV3URL= this.url.getDomain() + '/Users/UpdateUserInformationV3';


  updatePhone(post) {
    return this.http.post<Profile>(this.updateUserPhoneURL, post);
  }
  updatePassword(post) {
    return this.http.post<Profile>(this.updatePasswordURL, post);
  }

  checkPassword(post) {
    return this.http.post<Profile>(this.checkPasswordURL, post);
  }

  


  getPartnerProfile(post) {
    return this.http.post<Profile>(this.getParnerProfileURL, post);
  }

  getDriverProfile(post) {
    return this.http.post<Profile>(this.getDriverProfileURL, post);
  }

  getProfile(post) {
    return this.http.post<Profile>(this.getProfileURL, post);
  }

  updateProfile(post) {
    return this.http.post<Profile>(this.UpdateUserInformationURL, post);
  }

  updateUserInformationV3(post) {
    return this.http.post<Profile>(this.updateUserInformationV3URL, post);
  }
}
