import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

@Injectable({
  providedIn: 'root'
})
export class TodaysOrdersService {

  getLiveOrderURL = this.url.getDomain() + '/Orders/GetLiveOrders';
  getRefundOrderURL = this.url.getDomain() + '/Orders/RefundOrderForAdmin';

  constructor(private http: HttpClient, private url: Url) { }

  getLiveOrder(post) {
   return this.http.post(this.getLiveOrderURL, post);
 }

 refundOrder(post) {
  return this.http.post(this.getRefundOrderURL, post);
}



 

 
}
