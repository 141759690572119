import { Component, OnInit } from '@angular/core';
import { MasterAccountService } from '../master-account/master-account.service';
import { TodaysOrdersService } from "../todays-orders/todays-orders.service";

const Pusher = require('pusher-js');
declare var $: any;

@Component({
  selector: 'app-todays-orders',
  templateUrl: './todays-orders.component.html',
  styleUrls: ['./todays-orders.component.css']
})
export class TodaysOrdersComponent implements OnInit {

  orderedItems = [];
  refundAmount = 0;
  restaurantTransferAmount = 0 ;
  reverseRestaurantTransfer = false;
  fullRefund = true;
  ownerEmail = false;
  customMessage = '';

  selectedOrder: any;

  constructor(private orderService: TodaysOrdersService, private masterServices: MasterAccountService) { }

  ngOnInit(): void {
    const self0 = this;

    this.getLiveOrders();


    const pusher = new Pusher('1112b666d809129183c1', {
      cluster: 'us2',
      forceTLS: true
    });

    const channel = pusher.subscribe('Partner' + localStorage.getItem('PartnerID'));
    channel.bind('my-event', function (data) {
      if (data.hasOwnProperty('message')) {
        $('.modal').modal('hide');
        if (data['message'] === 'orderUpdate') {
          console.log('in order update')
          self0.getLiveOrders();


          //   } else if (data['message'] === 'update') {
          //       self0.orderID = data['orderID'];
          //       self0.getTodaysOrders(true);
          //       self0.orderedItemsProcess = [];
          //       self0.isUpdated = true
          //   } else if (data['message'] === 'take order') {
          //       self0.orderID = data['orderID'];
          //       self0.getTodaysOrders(true);
          //   } else {
          //         const messageArray = data['message'].split(' ');
          //         if (messageArray[0] === 'driverAdded' && messageArray.length > 2) {
          //             self0.orderID = messageArray[3];
          //             self0.isUpdated = true
          //             self0.updateDriver(messageArray[1],messageArray[2]);
          //         }
        }
      }
    });

  }




  getOrderPosition(item) {


    if (item.OrderDetails.OrderStatus == 1) {
      return 'inQueue'

    } else if (item.OrderDetails.OrderStatus == 2) {
      return 'orderInProgress'

    } else if (item.OrderDetails.OrderStatus == 3 && item.OrderDetails.OrderType == 2) {
      return 'orderInProgress'

    } else if (item.OrderDetails.OrderStatus == 4 || item.OrderDetails.OrderStatus == 3 && this.getStatus(item.OrderDetails.OrderType) != 'Delivery') {
      return 'orderCompleted'

    } else if (item.OrderDetails.OrderStatus == 5) {
      return 'orderCancelled'

    } else if (item.OrderDetails.OrderStatus == 7) {
      return 'orderRefunded'

    }


  }

  getStatus(orderType) {


    if (orderType == "1") {
      return "Dine In"
    } else if (orderType == "2") {
      return "Delivery"
    } else if (orderType == "3") {
      return "Pick Up"
    } else if (orderType == "4") {
      return "error"
    } else if (orderType == "5") {
      return "Cancelled"

    } else {
      return ""
    }


  }


  getDriverStatus(driverName) {

    if (driverName.DriverName == "null" || driverName.DriverName == null || driverName.DriverName == undefined) {
      return false;
    } else {

      return true;
    }

  }


  getDriver(driverName) {

    if (driverName.DriverName == "null" || driverName.DriverName == null || driverName.DriverName == undefined) {
      return "No Driver Yet"
    } else {

      if (driverName.TrackingURL == "NA" || driverName.TrackingURL == null || driverName.TrackingURL == undefined) {
        return driverName.DriverName + ' Onway'
      } else {
        return driverName.DriverName + ' Got'

      }


    }

  }

  getOrderStatus(status, estimateTime) {

    if (status == "0") {
      return ""
    } else if (status == "1") {
      return "In Queue"
    } else if (status == "2") {
      return "In Progress " + "Prep: " + estimateTime + " mins"
    } else if (status == "3") {
      return "Ready At Venue"
    } else if (status == "4") {
      return "Completed"
    } else if (status == "5") {
      return "Cancelled"
    } else if (status == "7") {
      return "Refunded"
    } else {
      return ""
    }
  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }


  async getLiveOrders() {

    const post = {
      PartnerID: localStorage.getItem('PartnerID'),
      Token: localStorage.getItem('AuthenticationToken'),
      City: localStorage.getItem('partnerDataCityName')
    };
    
    await this.orderService.getLiveOrder(post).subscribe(async data => {

      data = data[0];
      if (data["Success"]) {

        let orderedItems = []
        for (let i in data["Response"]) {
          let orders = []
          let orderedObject = {};
          let orderDetails = {};

          let prepareTime = new Date(data["Response"][i]["AllOrderResult"].PrepareDate).toLocaleString("en-US", { timeZone: "America/Halifax" });
          let prepareTime0 = new Date(prepareTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["PrepareDate"] = prepareTime0;


          let orderTime = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          let orderTime0 = new Date(orderTime).toLocaleString();
          data["Response"][i]["AllOrderResult"]["Date"] = orderTime0;
          data["Response"][i]["AllOrderResult"]["OriginalDate"] = new Date(data["Response"][i]["AllOrderResult"].Date).toLocaleString("en-US", { timeZone: "America/Halifax" });
          orderedObject["OrderDetails"] = data["Response"][i]["AllOrderResult"];
          for (let x in data["Response"][i]["OrderedItems"]) {
            //  console.log(data["Response"][i]["OrderSides"][x]);

            let t = {
              "Primary": data["Response"][i]["OrderedItems"][x],
              "Sides": data["Response"][i]["OrderSides"][x]
            };

            orders.push(t);
          }
          orderedObject["Order"] = orders;
          orderedItems.push(orderedObject);
        }
        this.orderedItems = orderedItems
        // $('#dtBasicExample').DataTable();
       

      }
    });
  }

  assignRefundModalValues(order) {
    this.selectedOrder = order
    this.refundAmount = order.OrderDetails.Total;
    this.restaurantTransferAmount = order.OrderDetails.NetTotal;
  }

  toggleFullRefund(isFullRefund) {
    this.fullRefund = isFullRefund;
  }

  toggleReverseRestaurantTransfer() {
    this.reverseRestaurantTransfer = !this.reverseRestaurantTransfer;
  }

  async refundOrder(order) {
    const post = {
      OrderID: order.OrderID,
      PaymentID: order.PaymentID,
      UserID: order.UserID,
      City: localStorage.getItem('partnerDataCityName'),
      RefundAmount: this.fullRefund ? Math.round(order.Total * 100)  : Math.round(this.refundAmount * 100),
      fullRefundWithTransfers: this.fullRefund,
      ReverseRestaurantTransfer: this.reverseRestaurantTransfer,
      RestaurantTransferAmount: Math.round(this.restaurantTransferAmount * 100),
      MessageForUser: this.customMessage,
      sendOwnerEmail: this.ownerEmail,
    };

    await this.orderService.refundOrder(post).subscribe(async data => {
      if(data['Success']) {
        this.refundAmount = 0;
        this.restaurantTransferAmount = 0;
        this.reverseRestaurantTransfer = false;
        this.customMessage = '';
        alert(data['Message']);
        window.location.reload();
      }
      else {
        this.refundAmount = 0;
        this.restaurantTransferAmount = 0;
        this.reverseRestaurantTransfer = false;
        this.customMessage = '';
        alert(data['Message'] + '\n' + data['Transfer_Message']);
      }
    });
  }

  sendReminderCall(orderIndex) {
    const post = {
      Phone: this.orderedItems[orderIndex]['OrderDetails']['RestaurantPhone'],
    };

    this.masterServices.sendReminderCall(post).subscribe(
      data => {
    });

    alert('Reminder Call Sent To ' + this.orderedItems[orderIndex]['OrderDetails']['RestaurantName']);
  }
}
