import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import {Router} from "@angular/router";


export interface User  {
  data: any;
}


@Injectable({
  providedIn: 'root'
})
export class UserServiceService {


  getOrderTemplateURL = this.url.getDomain() + '/Orders/GetOrderTemplate';

  constructor(private router:Router,private http: HttpClient, private url: Url) { }

  getPastOrdersForUser(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post<User>(this.getOrderTemplateURL, post, httpOptions);
  }




}
