// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker table tr td span {
    width: 100% !important;
}

.bootstrap-datetimepicker-widget {

width:100% !important;
background: red;

}




.orderInQueue{

    background-color: white;
        
    }
    
    
    
    .orderInProgress{
    
        background-color: rgb(255, 200, 132);
    
        
    }
    
    
    .orderCompleted{
    
        background-color: rgb(208, 251, 143);
    
        
    }
    
    
    .orderTypeCancelled{
    
        background-color: rgb(251, 143, 143);
    
        
    }`, "",{"version":3,"sources":["webpack://./src/app/structure/find-order/find-order.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;;AAEA,qBAAqB;AACrB,eAAe;;AAEf;;;;;AAKA;;IAEI,uBAAuB;;IAEvB;;;;IAIA;;QAEI,oCAAoC;;;IAGxC;;;IAGA;;QAEI,oCAAoC;;;IAGxC;;;IAGA;;QAEI,oCAAoC;;;IAGxC","sourcesContent":[".datepicker table tr td span {\n    width: 100% !important;\n}\n\n.bootstrap-datetimepicker-widget {\n\nwidth:100% !important;\nbackground: red;\n\n}\n\n\n\n\n.orderInQueue{\n\n    background-color: white;\n        \n    }\n    \n    \n    \n    .orderInProgress{\n    \n        background-color: rgb(255, 200, 132);\n    \n        \n    }\n    \n    \n    .orderCompleted{\n    \n        background-color: rgb(208, 251, 143);\n    \n        \n    }\n    \n    \n    .orderTypeCancelled{\n    \n        background-color: rgb(251, 143, 143);\n    \n        \n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
