import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { EmpService } from 'app/emp.service';
import { NotificationService } from 'app/structure/notification.service';
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-services-navigation',
  templateUrl: './services-navigation.component.html',
  styleUrls: ['./services-navigation.component.css', '../../../assets/css/ts-style.css']
})

export class ServicesNavigationComponent implements OnInit, OnDestroy {

  private notifyServicesNavbarSubscription: Subscription;

  sidebarVisible = true;
  loggedInType = '';
  dropDownName = "Your Store";
  restaurantList = [];
  allRestaurants = [];
  restaurantIds = [];

  //For Web Push Notifications
  readonly VAPID_PUBLIC_KEY = "BIYpFOeJXQkH6bop0qEPeamtRkPXvrVgEMEsRl4v4U-ePEtlTVwzhnKxZwngibXc-zr9QJG7J398eC-oa8Wi6eA";
  webPushNotificationEnabled = false;

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  constructor(private emp: EmpService, private router: Router, private swPush: SwPush, private notificationService: NotificationService) {
    this.notifyServicesNavbarSubscription = this.emp.updateServicesNavbar$.subscribe((value) => {
      if (value) {
        this.manageProfileAndSideNavType();
      }
    });
  }

  async ngOnInit() {

    if (localStorage.getItem('sidebar-visibility') == undefined) {
      localStorage.setItem('sidebar-visibility', 'true');
    }
    else {
      this.sidebarVisible = localStorage.getItem('sidebar-visibility') == 'true';
    }

    this.manageProfileAndSideNavType();
    this.manageSideNavItemsOpenClose();

    this.allRestaurants = localStorage.getItem('allRestaurants') !== 'null' ? JSON.parse(localStorage.getItem('allRestaurants')) : [];
    if (localStorage.getItem('allRestaurants') == null) {
      this.restaurantIds = localStorage.getItem('allRestaurantID').split(',');
    } else {
      this.allRestaurants.forEach((restaurant) => {
        this.restaurantIds.push(restaurant['RestaurantID']);
      });
    }

    this.webPushNotificationEnabled = localStorage.getItem('web-push-enabled') === 'true' ? true : false;
    this.subscribeToNotifications();

  }

  public refreshRestaurantName(restaurantName) {
    this.dropDownName = restaurantName;
    document.getElementById('dropDownName').innerHTML = this.dropDownName;
  }

  changeRestaurant(restaurant) {
    let url = this.router.url;
    this.router.navigateByUrl('/services', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));

    this.dropDownName = restaurant.Name;

    localStorage.setItem("restaurantID", restaurant.RestaurantID.toString());
    localStorage.setItem("restaurantName", restaurant.Name.toString());
    localStorage.setItem("restaurantLatitude", restaurant.Latitude);
    localStorage.setItem("restaurantLongitude", restaurant.Longitude);
    localStorage.setItem("restaurantContactName", restaurant.ContactName);
    localStorage.setItem("restaurantAddress", restaurant.Address1);
    localStorage.setItem("restaurantPhone", restaurant.Phone);
    localStorage.setItem('restaurantUserType', restaurant["UserType"]);
    localStorage.setItem('restaurantTimeZone', restaurant["TimeZone"]);
    localStorage.setItem('ownDelivery', restaurant["OwnDelivery"]);
    localStorage.setItem('restaurantType', restaurant["RestaurantType"]);
    localStorage.setItem('LinkName', restaurant["LinkName"]);
    localStorage.setItem('restaurantOrderTypeIDs', restaurant["OrderTypeIDs"]);
    localStorage.setItem('restaurantOrderTypeNames', restaurant["OrderTypeNames"]);
    localStorage.setItem('restaurantOrderTypeStatuses', restaurant["OrderTypeStatuses"]);
    localStorage.setItem('restaurantOrderTypePercentages', restaurant["OrderTypePercentages"]);
    localStorage.setItem('Reservations', restaurant["Reservations"]);
    localStorage.setItem('ReservationProductID', restaurant["ReservationProductID"]);
    localStorage.setItem('ReservationPriceID', restaurant["ReservationPriceID"]);
    localStorage.setItem('DineInProductID', restaurant["DineInProductID"]);
    localStorage.setItem('DineInPriceID', restaurant["DineInPriceID"]);
    localStorage.setItem('POS', restaurant["POS"]);
    localStorage.setItem('restaurantStripeBusinessID', restaurant["StripeBusinessID"]);
    localStorage.setItem('storeExpressActive', restaurant["ExpressAccountActive"]);
    localStorage.setItem('storeType', restaurant["Type"]);
    localStorage.setItem('storeDescription', restaurant["Description"]);

    if (restaurant["OnlineOrderSubscriptionID"] != null) {
      localStorage.setItem('OnlineOrderSubscriptionID', restaurant["OnlineOrderSubscriptionID"]);
    }

    if (restaurant["DineInSubscriptionID"] != null) {
      localStorage.setItem('DineInSubscriptionID', restaurant["DineInSubscriptionID"]);
    }
    else {
      localStorage.setItem('DineInSubscriptionID', '');
    }

    if (restaurant["ReservationSubscriptionID"] != null) {
      localStorage.setItem('ReservationSubscriptionID', restaurant["ReservationSubscriptionID"]);
    }
    else {
      localStorage.setItem('ReservationSubscriptionID', '');
    }

    window.location.reload();
  }

  changeCity(city) {
    this.dropDownName = city;
    if (city === 'Show All') {
      localStorage.setItem('partnerDataCityName', '');
    }
    else {
      localStorage.setItem('partnerDataCityName', city);
    }
    window.location.reload();
  }

  //Prompt request notification pop-up and store end-point in database
  subscribeToNotifications() {
    if (this.swPush.isEnabled && localStorage.getItem('storeType') != 'sellerstand' && localStorage.getItem('storeType') != 'driver' && localStorage.getItem('storeType') != 'partner') {

      this.swPush.notificationClicks.subscribe(({ action, notification }) => { });

      this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
        .then(sub => {
          localStorage.setItem('web-push-enabled', 'true');
          this.webPushNotificationEnabled = true;
          const post = {
            'RestaurantID': this.restaurantIds.toString(),
            'PushEndPoint': JSON.stringify(sub),
            'UserID': localStorage.getItem('UserID')
          }

          this.notificationService.subscribeWebPush(post).subscribe(async data => {
            if (data['Success']) {
            }
          })
        })
        .catch(err => {
          localStorage.setItem('web-push-enabled', 'false');
          this.webPushNotificationEnabled = false;
        });

      this.swPush.messages.subscribe(message => {
        if (message['notification']['body'] === 'New Order') {
          // this.getTodaysOrders(false);
        }
      });
    }
  }

  sidebarToggle() {
    if (window.innerWidth >= 992) {
      this.sidebarVisible = !this.sidebarVisible;
      localStorage.setItem('sidebar-visibility', this.sidebarVisible.toString());
    }
    else {
      this.sidebarVisible = true;
      localStorage.setItem('sidebar-visibility', 'true');
    }
  }

  collapseAll() {
    $('.collapse').collapse('hide');
  }

  public manageProfileAndSideNavType(): void {

    $('#restaurants_sidebar').hide();
    $('#sellerstand_sidebar').hide();
    $('#reservations_sidebar').hide();
    $('#orders_sidebar').hide();
    $('#partners_sidebar').hide();
    $('#driver_sidebar').hide();
    $('#general_sidebar').hide();

    this.loggedInType = localStorage.getItem('type');

    if (this.loggedInType == 'partner') {

      if (localStorage.getItem('partnerDataCityName') == null || localStorage.getItem('partnerDataCityName') == '') {
        this.dropDownName = "Show All";
        localStorage.setItem('partnerDataCityName', '');
      }
      else {
        this.dropDownName = localStorage.getItem('partnerDataCityName');
      }
      $('#partners_sidebar').show();

    } else if (this.loggedInType == 'driver') {

      this.dropDownName = "Driver Portal";
      $('#driver_sidebar').show();

    } else {

      if (localStorage.getItem('restaurantName') != "null") {
        this.restaurantList = JSON.parse(localStorage.getItem('allRestaurants'));;
        this.dropDownName = localStorage.getItem('restaurantName');
      }

      if (localStorage.getItem('storeType') == 'sellerstand') {
        $('#sellerstand_sidebar').show();
      }
      else if (localStorage.getItem('restaurantType') == '3') {
        $('#reservations_sidebar').show();
      }
      else if (localStorage.getItem('restaurantType') == '0' && localStorage.getItem('Reservations') == '1') {
        $('#restaurants_sidebar').show();
      }
      else if (localStorage.getItem('restaurantType') == '0') {
        $('#orders_sidebar').show();
      }
      else {
        $('#general_sidebar').show();
      }

    }
  }


  manageSideNavItemsOpenClose() {

    $(document).ready(() => {
      this.collapseAll();
      switch (window.location.pathname) {
        case '/services/incoming-orders':
          $('#res-orders-sub-items').collapse('show');
          $('#orders-sub-items').collapse('show');
          break;
        case '/services/request-driver':
          $('#res-orders-sub-items').collapse('show');
          $('#orders-sub-items').collapse('show');
          break;
        case '/services/past-orders':
          $('#res-orders-sub-items').collapse('show');
          $('#orders-sub-items').collapse('show');
          break;
        case '/services/order-refund':
          $('#res-orders-sub-items').collapse('show');
          $('#orders-sub-items').collapse('show');
          break;
        case '/services/add-item':
          $('#res-menu-sub-items').collapse('show');
          $('#menu-sub-items').collapse('show');
          break;
        case '/services/show-items':
          $('#res-menu-sub-items').collapse('show');
          $('#menu-sub-items').collapse('show');
          break;
        case '/services/menu-view':
          $('#res-menu-sub-items').collapse('show');
          $('#menu-sub-items').collapse('show');
          break;
        case '/services/pause-menu-modifiers':
          $('#res-menu-sub-items').collapse('show');
          $('#menu-sub-items').collapse('show');
          break;
        case '/services/pause-category':
          $('#res-menu-sub-items').collapse('show');
          $('#menu-sub-items').collapse('show');
          break;
        case '/services/show-restaurant':
          $('#res-restaurant-sub-items').collapse('show');
          $('#restaurant-sub-items').collapse('show');
          break;
        case '/services/pause-restaurant':
          $('#res-restaurant-sub-items').collapse('show');
          $('#restaurant-sub-items').collapse('show');
          break;
        case '/services/add-restaurant':
          $('#restaurant-sub-items').collapse('show');
          break;
        case '/services/printer-setup':
          $('#res-restaurant-sub-items').collapse('show');
          $('#restaurant-sub-items').collapse('show');
          break;
        case '/services/reservations':
          $('#res-reservation-sub-items').collapse('show');
          $('#reservation-sub-items').collapse('show');
          break;
        case '/services/reservation-manager':
          $('#res-reservation-sub-items').collapse('show');
          $('#reservation-sub-items').collapse('show');
          break;
        case '/services/past-reservations':
          $('#res-reservation-sub-items').collapse('show');
          $('#reservation-sub-items').collapse('show');
          break;
        case '/services/expenditure':
          $('#res-payment-sub-items').collapse('show');
          $('#order-payment-sub-items').collapse('show');
          $('#seller-payment-sub-items').collapse('show');
          $('#driver-payment-sub-items').collapse('show');
          break;
        case '/services/expenditure-help':
          $('#res-payment-sub-items').collapse('show');
          $('#order-payment-sub-items').collapse('show');
          $('#seller-payment-sub-items').collapse('show');
          $('#driver-payment-sub-items').collapse('show');
          break;
        case '/services/publish-promotions':
          $('#res-promotion-sub-items').collapse('show');
          $('#promotion-sub-items').collapse('show');
          break;
        case '/services/view-subscribers':
          $('#res-promotion-sub-items').collapse('show');
          $('#promotion-sub-items').collapse('show');
          break;
        case '/services/todays-orders':
          $('#orders-manage-sub-items').collapse('show');
          break;
        case '/services/find-order':
          $('#orders-manage-sub-items').collapse('show');
          break;
        case '/services/driver-shift':
          $('#driver-manage-sub-items').collapse('show');
          break;
        case '/services/add-shift':
          $('#driver-manage-sub-items').collapse('show');
          break;
        case '/services/show-driver':
          $('#driver-manage-sub-items').collapse('show');
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.notifyServicesNavbarSubscription.unsubscribe();
  }
}
