import { Component, OnInit } from '@angular/core';
import { SquareIntegrationService } from './square-integration.service';
import { Url } from '../../models/url';

declare var $: any;


@Component({
  selector: 'app-square-integration',
  templateUrl: './square-integration.component.html',
  styleUrls: ['./square-integration.component.css']
})


export class SquareIntegrationComponent implements OnInit {
  
  
  permissions = '';
isIntegrated = false

  constructor(private squareAPI:SquareIntegrationService,private url:Url) { }



 

  ngOnInit(): void {

    this.getSquareInfo()
  }


  async revokeToken(code){
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      POS:"Square"

    };
 
    await this.squareAPI.revokeToken(post).subscribe(async data => {
 
        if(data["Success"]){
          console.log(data["Response"])
            // $('#connectToStripe').hide();
            // $('#tableOfPayouts').show();
 
            // this.getPayoutDate();
            // this.getPayoutInfo();
        }else{
 alert("Error")
            // $('#connectToStripe').show();
            // $('#tableOfPayouts').hide()
 
        }
 
 
      //save the account number and get the stripe weekly balance
 
 
 
    });


  }

  


  async authorizeSquare(code){

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      code: code,
      POS:"Square"

    };
 
    await this.squareAPI.addSquareAuth(post).subscribe(async data => {
 
        if(data["Success"]){
          console.log(data["Response"])
            // $('#connectToStripe').hide();
            // $('#tableOfPayouts').show();
            this.isIntegrated = true

            // this.getPayoutDate();
            // this.getPayoutInfo();
        }else{
 alert("Error")
            // $('#connectToStripe').show();
            // $('#tableOfPayouts').hide()
 
        }
 
 
      //save the account number and get the stripe weekly balance
 
 
 
    });


  }

  async getSquareInfo(){

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      POS:"Square"

    };
 

    await this.squareAPI.getSquareAuth(post).subscribe(async data => {
 
      console.log(data)
        if(data["Success"]){

if(data["Response"].length > 0){
console.log("here i am in authorized")
 this.permissions = data["Response"][0]["Permissions"];
 this.isIntegrated = true

}else{
  
  let code = this.gup('code',window.location.href);
  if(code != null && code != undefined){
    this.authorizeSquare(code)
  }else{

  }
}

         
        }
    });




  }

  authorize(){

if(this.url.getDev() === "dev"){
  window.location.href='https://connect.squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-T-63iGyJDeub1m1TWh5irw&scope=ORDERS_WRITE+ORDERS_READ+PAYMENTS_WRITE+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ&session=False&state=82201dd8d83d23cc8a48caf52b'

}else{
  window.location.href='https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-y-bQ2CYcx038kL52HXYsMA&scope=ORDERS_WRITE+ORDERS_READ+PAYMENTS_WRITE+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ&session=False&state=82201dd8d83d23cc8a48caf52b'

}
    



  }


  gup( name, url ) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
  }




}
