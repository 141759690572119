import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../../models/url';



export interface Res {
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class PastReservationsService {

  getPastReservationsURL = this.url.getDomain() + '/v3/GetReservationsBetweenDates';


  constructor(private http: HttpClient, private url: Url) { }



  getPastReservations(res) {
    return this.http.post<Res>(this.getPastReservationsURL, res);
  }



}
