import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../restaurant.service';

declare var $: any;

@Component({
  selector: 'app-pause-restaurant',
  templateUrl: './pause-restaurant.component.html',
  styleUrls: ['./pause-restaurant.component.css','../../../../assets/css/ts-style.css']
})
export class PauseRestaurantComponent implements OnInit {

  pauseTime = 0;
  pauseReason = '';

  constructor(private resService: RestaurantService) { }

  ngOnInit(): void {

    $('#pauseDiv').hide();
    $('#unpauseDiv').hide();
    this.checkRestaurantPaused();
  }

  checkRestaurantPaused() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    }

    this.resService.checkRestaurantPausedForCertainHours(post).subscribe(
      data => {
        if (data['Success']) {
          if (data['Response']['Paused'] == 1) {
            $('#unpauseDiv').show();
          }
          else {
            $('#pauseDiv').show();
          }
        }
        else {
          alert('Something went wrong.\nPlease contact TopServe at support@topserve.ca');
        }
      });
  }

  pauseRestaurant() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      PauseTime: this.pauseTime,
      PauseReason: this.pauseReason
    }

    this.resService.pauseRestaurantForCertainHours(post).subscribe(
      data => {
        if(data['Success']) {
          $('#pauseDiv').hide();
          $('#unpauseDiv').show();
          this.pauseTime = 0;
          this.pauseReason = '';
        }
        else {
          alert('Something went wrong in pausing restaurant.\nPlease contact TopServe at support@topserve.ca');
        }
    });
  }

  unpauseRestaurant() {
    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
    }

    this.resService.unpauseRestaurantFromCertainHours(post).subscribe(
      data => {
        if(data['Success']) {
          $('#unpauseDiv').hide();
          $('#pauseDiv').show();
        }
        else {
          alert('Something went wrong in unpausing restaurant.\nPlease contact TopServe at support@topserve.ca');
        }
    });
    
  }
}
