import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';
import { FormsModule, NgModel } from "@angular/forms";

export interface ViewSubscribers {
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class ViewSubscribersService {
  constructor(private http: HttpClient, private url: Url) { }

  getAllRestaurantSubscribersURL = this.url.getDomain() + '/Restaurants/GetAllRestaurantSubscribers';

  getSubscribers(post) {
    return this.http.post<ViewSubscribersService>(this.getAllRestaurantSubscribersURL, post);
  }
}
