import { Component, OnInit } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import { ReservationsSubscriptionService } from './reservations-subscription.service';
import { LoginService } from 'app/structure/login/login.service';
import { PaymentService } from 'app/services/payments/payment.service';
import { Router } from '@angular/router';
import { RestaurantService } from 'app/structure/restaurant/restaurant.service';
import { EmpService } from 'app/emp.service';

declare var $: any;

@Component({
  selector: 'app-reservations-subscription',
  templateUrl: './reservations-subscription.component.html',
  styleUrls: ['./reservations-subscription.component.css', '../../../../assets/css/ts-style.css']
})
export class ReservationsSubscriptionComponent implements OnInit {

  subscriptionID = '';
  current_plan: any;
  stripePromise = loadStripe(environment.stripeKey);

  products = [];
  paymentCards = [];
  newCardSelected = false;
  selected_product_price_id = '';
  toast_message = '';
  link_valid = false;

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }


  constructor(private emp: EmpService, private reservationsSubscriptionService: ReservationsSubscriptionService, private loginService: LoginService, private paymentService: PaymentService, private router: Router, private restaurantService: RestaurantService) { }

  ngOnInit(): void {
    let self = this;

    $(document).on('hidden.bs.modal', function () {
      $('.canvas-modal-backdrop').removeClass('show');
    });

    $('#payment-method-modal').on('shown.bs.modal', function () {
      self.getCards();
    });

    $('#card_number').on('input', function () {
      var cardNumber = $(this).val().replace(/\s/g, ''); // Remove existing spaces
      var formattedCardNumber = self.formatCardNumber(cardNumber);
      $(this).val(formattedCardNumber.slice(0, 19)); // Apply maximum input length of 19 characters
    });

    $('#card_expiry').on('input', function () {
      var expiry = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      var formattedExpiry = self.formatExpiry(expiry);
      $(this).val(formattedExpiry);
    });

    $('#card_cvc').on('input', function () {
      var cvc = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      $(this).val(cvc.slice(0, 4));
    });

    $(document).ready(function () {
      $('#link_input').on('input', function () {
        var inputValue = $(this).val();
        var sanitizedValue = inputValue.replace(/[^\w]/gi, '');
        $(this).val(sanitizedValue);
        if (sanitizedValue.length > 0) {
          self.checkLinkNameAvailability();
        }
      });
    });

    this.setupPage();
  }

  formatCardNumber(cardNumber) {
    var formattedNumber = cardNumber.replace(/\D/g, ''); // Remove non-numeric characters
    var chunkSize = 4;
    var regex = new RegExp('.{1,' + chunkSize + '}', 'g');
    var formattedChunks = formattedNumber.match(regex);
    if (formattedChunks) {
      return formattedChunks.join(' ');
    }
    return formattedNumber;
  }

  formatExpiry(expiry) {
    expiry = parseInt(expiry[0]) > 1 ? '0' + expiry : expiry = parseInt(expiry.slice(0, 2)) > 12 ? '0' + expiry : expiry;
    var formattedExpiry = expiry.slice(0, 2) + '/' + expiry.slice(2); // Add '/' after first 2 digits
    var slashIndex = formattedExpiry.indexOf('/');
    if (slashIndex !== 2 && slashIndex !== -1) {
      formattedExpiry = formattedExpiry.slice(0, slashIndex) + formattedExpiry.slice(slashIndex + 1);
    } else if (slashIndex === -1 && formattedExpiry.length > 2) {
      formattedExpiry = formattedExpiry.slice(0, 2) + '/' + formattedExpiry.slice(2);
    } else if (slashIndex === 2 && formattedExpiry.length === 3) {
      formattedExpiry = formattedExpiry.slice(0, 2);
    }
    return formattedExpiry.slice(0, 5); // Limit to 'MM/YY' format
  }

  async setupPage() {

    this.subscriptionID = localStorage.getItem('ReservationSubscriptionID');

    await this.getReservationProducts();

    this.manageSubscriptionContent();

  }

  async manageSubscriptionContent() {

    $('#reservation_link_update').hide();
    $('#reservation_link_success').hide();
    $('#link_error_msg').hide();

    $('#new_subscription_content').hide();
    $('#existing_subscription_content').hide();

    if (this.subscriptionID == '' || this.subscriptionID == undefined || this.subscriptionID == 'null' || this.subscriptionID == null) {

      $('#new_subscription_content').show();
      $('#existing_subscription_content').hide();

      if (localStorage.getItem('LinkName') != null && localStorage.getItem('LinkName') !== 'null' && localStorage.getItem('LinkName') !== '') {
        $('#reservation_link_success').show();
        this.link_valid = true;
      }
      else {
        $('#reservation_link_update').show();
      }

      if (this.products.length >= 3) {
        $('#pricing_plans').show();
      }
      else {
        $('#pricing_plans').hide();
      }
    }
    else {
      $('#new_subscription_content').hide();
      this.getCurrentSubscription();
    }
  }

  async checkLinkNameAvailability() {

    if ($('#link_input').val().toString().toLowerCase() == 'null') {
      $('#link_error_msg').show();
      return;
    }
    const post = {
      "link": $('#link_input').val().toString().toLowerCase()
    }

    this.restaurantService.checkLinkAvailability(post).subscribe(data => {
      if (data['Success']) {
        if (data['LinkAvailable']) {
          $('#link_error_msg').hide();
          this.link_valid = true;
        }
        else {
          $('#link_error_msg').show();
          this.link_valid = false;
        }
      }
    });
  }

  async saveReservationLink() {

    const post = {
      "RestaurantID": localStorage.getItem('restaurantID'),
      "LinkName": $('#link_input').val().toString().toLowerCase()
    }

    this.restaurantService.updateLinkNameOfStore(post).subscribe(data => {
      if (data['Success']) {
        localStorage.setItem('LinkName', $('#link_input').val().toString().toLowerCase());
        $('#reservation_link_success').show();
        $('#reservation_link_update').hide();

      }
      else {
        this.toast_message = 'Something went wrong while saving link name. Please try again!'
        this.showToast(false);
      }
    });
  }

  async createSubscribeSession(index) {

    if (localStorage.getItem('LinkName') == null || localStorage.getItem('LinkName') == 'null' || localStorage.getItem('LinkName') == '') {
      this.toast_message = 'Before proceeding with subscription, please make sure to create a reservation link in first step.';
      this.showToast(false);
      return;
    }

    if (this.products.length >= index + 1) {
      this.selected_product_price_id = this.products[index]['StripePriceID'];
      this.openCanvasBackdropModal('#payment-method-modal');
    }
  }

  async getReservationProducts() {

    return new Promise(resolve => {
      const post = {}

      this.reservationsSubscriptionService.getReservationProducts(post).subscribe(async data => {
        if (data['Success']) {
          this.products = data['Response'];
          this.products.map(product => product['selected'] = false);
        }
        resolve(true);
      });
    });
  }

  async getCurrentSubscription() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      subscriptionID: this.subscriptionID
    }

    await this.reservationsSubscriptionService.retrieveSubscription(post).subscribe(async data => {

      if (data['Success']) {

        let subscription = data['Response'];

        if (subscription['status'] == 'active') {
          if (subscription['plan'] != undefined && this.products.length > 0) {
            for (let i in this.products) {
              if (this.products[i]['StripePriceID'] == subscription['plan']['id']) {
                this.products[i]['selected'] = true;
              }
              else {
                this.products[i]['selected'] = false;
              }
            }
          }
          $('#existing_subscription_content').show();
        }
        else {
          this.subscriptionID = '';
          localStorage.setItem('ReservationSubscriptionID', '');
          localStorage.setItem('Reservations', '0');
          this.manageSubscriptionContent();
        }

      }
    });

  }

  async cancelSubscription() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      subscriptionID: this.subscriptionID
    }

    await this.reservationsSubscriptionService.cancelSubscription(post).subscribe(async data => {
      if (data['Success']) {
        this.subscriptionID = '';
        localStorage.setItem('ReservationSubscriptionID', '');
        localStorage.setItem('Reservations', '0');
        this.manageSubscriptionContent();
        this.toast_message = 'Your reservations has been canceled.';
        this.showToast(true);
        this.emp.notifyServicesNavbar(true);
      }
      else {
        // this.subscriptionCancelMsg = data['message'] + ' Please contact support!';
        this.toast_message = data['message'] + ' Please contact support!';
        this.showToast(false);
      }
    })
  }

  openCanvasBackdropModal(id) {
    $(id).modal('show');
    $('.canvas-modal-backdrop').addClass('show');
  }

  getCards() {

    this.paymentCards = [];

    const stripeID = localStorage.getItem('restaurantStripeCustomerID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const post = {
      customer_id: stripeID
    };


    this.loginService.getPaymentMethods(post).subscribe(
      data => {

        if (data['success']) {
          let cardData = data['response']['data'];
          if (cardData.length > 0) {
            this.newCardSelected = false;
            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {
                card['selected'] = true;
              }
              this.paymentCards.push(card);
            }
          }
          else {
            this.newCardSelected = true;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });

  }

  validateCardDetails(): boolean {

    if (!this.newCardSelected) {
      let selected = false;
      this.paymentCards.filter(card => {
        if (card.selected) {
          selected = true;
        }
      })
      return !selected;
    }
    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    // Get input values
    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const cardCvc = cardCvcInput.val()?.toString();

    // Validate card number
    if (cardNumber && cardNumber.length !== 16) {
      return true;
    }

    // Validate card expiry
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;

    if (
      !cardExpiryMonth ||
      !cardExpiryYear ||
      isNaN(cardExpiryMonth) ||
      isNaN(cardExpiryYear) ||
      cardExpiryMonth < 1 ||
      cardExpiryMonth > 12 ||
      cardExpiryYear < currentYear ||
      (cardExpiryYear === currentYear && cardExpiryMonth < currentMonth)
    ) {
      return true;
    }

    // Validate card CVC
    if (!cardCvc || isNaN(parseInt(cardCvc)) || cardCvc.length < 3 || cardCvc.length > 4) {
      return true;
    }

    // All validations passed
    return false;
  }

  changeDefaultCard(i: number) {
    if (i == -1) {
      this.newCardSelected = true;
      this.paymentCards.filter(card => card.selected = false);
    }
    else {
      const post = {
        customer_id: localStorage.getItem('restaurantStripeCustomerID'),
        payment_method_id: this.paymentCards[i]['id']
      }

      this.paymentService.updateDefaultPaymentMethod(post).subscribe(async data => {
        if (data['success']) {
          this.paymentCards.filter(i => i.selected = false);
          this.paymentCards[i]['selected'] = true;
          this.newCardSelected = false;
        }
        else {
          alert('Something went wrong, please try again later!!');
          this.router.navigateByUrl('/logout');
        }
      });
    }
  }

  async handleSubscription() {
    if (this.newCardSelected) {
      this.CreatePaymentMethod();
    }
    else {
      this.purchaseSubscription();
    }
  }

  async purchaseSubscription() {
    
    if (this.selected_product_price_id == '') {
      return;
    }
    
    $('#progress-modal').modal('show');
    $('#payment-method-modal').modal('hide');

    const post = {
      customer_id: localStorage.getItem('restaurantStripeCustomerID'),
      price_id: this.selected_product_price_id,
      RestaurantID: localStorage.getItem('restaurantID')
    }

    this.reservationsSubscriptionService.createSubscription(post).subscribe(async data => {
      if (data['Success']) {
        localStorage.setItem('ReservationSubscriptionID', data['Response']['id']);
        localStorage.setItem('Reservations', '1');
        this.subscriptionID = data['Response']['id'];
        this.manageSubscriptionContent();
        $('#progress-modal').modal('hide');
        this.toast_message = 'Thank you for subscribing reservations with TopServe.'
        this.showToast(true);
        this.emp.notifyServicesNavbar(true);
      }
      else if (data['Error'] != null) {
        $('#progress-modal').modal('hide');
        this.toast_message = data['Error']['message'];
        this.showToast(false);
      }
      else {
        const subscription = data['Response'];
        const paymentIntent = subscription['payment_intent'];
        let self = this;
        (await this.stripePromise).confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentIntent.payment_method,
        })
          .then(function (result) {
            if (result.paymentIntent) {
              if (result.paymentIntent.status == 'succeeded') {
                self.updateSubscriptionStatus(subscription.id);
                localStorage.setItem('ReservationSubscriptionID', subscription.id);
                localStorage.setItem('Reservations', '1');
                self.subscriptionID = subscription.id;
                self.manageSubscriptionContent();
                $('#progress-modal').modal('hide');
                self.toast_message = 'Thank you for subscribing reservations with TopServe.'
                self.showToast(true);
                self.emp.notifyServicesNavbar(true);
              }
              else {
                $('#progress-modal').modal('hide');
                self.toast_message = "Something went wrong! Please contact TopServe at support@topserve.ca";
                self.showToast(false);
              }
            }
            else {
              $('#progress-modal').modal('hide');
              self.toast_message = result.error.message;
              self.showToast(false);
            }
          });
      }
    });
  }
  
  updateSubscriptionStatus(id: String) {
    
    const post = {
      SubscriptionID: id,
      RestaurantID: localStorage.getItem('restaurantID')
    }
    this.reservationsSubscriptionService.updateSubscription(post).subscribe(async data => {});

  }

  async CreatePaymentMethod() {

    const stripeID = localStorage.getItem('restaurantStripeCustomerID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const currentYear = new Date().getFullYear();
    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;
    const cardCvc = cardCvcInput.val()?.toString();


    const post = {
      customer_id: stripeID,
      card_number: cardNumber,
      exp_month: cardExpiryMonth,
      exp_year: cardExpiryYear,
      cvc: cardCvc
    };

    await this.reservationsSubscriptionService.createAndAttachPaymentMethod(post).subscribe(async data => {

      if (data['Success']) {
        this.purchaseSubscription();
      }
      else {
        alert('Something went wrong, please try again later!!');
        this.router.navigateByUrl('/logout');
      }
    });

  }

  showToast(success) {
    if (success) {
      $('#subscription_toast').addClass('show');
      setTimeout(() => {
        $('#subscription_toast').removeClass('show');
      }, 2000);
    }
    else {
      $('#subscription_error_toast').addClass('show');
    }
  }

  copyIframeSnippet(): void {
    const codeSnippet = document.querySelector('#iframe_code');
    const code = codeSnippet.textContent.trim();
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code snippet copied to clipboard!');
      })
      .catch((error) => {
        console.error('Unable to copy code snippet:', error);
      });
  }

  socialLink(): string {
    let link = 'https://topserve.ca/find-reservation/';
    link += encodeURIComponent(localStorage.getItem('restaurantName'));
    link += '#' + localStorage.getItem('restaurantID');
    return link;
  }

  copyLinkSnippet(): void {
    const codeSnippet = document.querySelector('#social_link_code');
    const code = codeSnippet.textContent.trim();
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code snippet copied to clipboard!');
      })
      .catch((error) => {
        console.error('Unable to copy code snippet:', error);
      });
  }

}
