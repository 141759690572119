import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReservationsService } from '../reservations.service';

declare var $: any;

@Component({
  selector: 'app-restaurant-table-component',
  templateUrl: './restaurant-table-component.component.html',
  styleUrls: ['./restaurant-table-component.component.css', '../../../../assets/css/ts-style.css']
})

export class RestaurantTableComponentComponent implements OnInit {

  @Input('tableData')
  tableData = '';

  @Output() updateReservation = new EventEmitter();
  @Output() deleteReservation = new EventEmitter();

  table = {
    partySize: String,
    interval: String,
    tableID: String,
    day: Number,
    isEditing: Boolean,
    changeTableNumber: Boolean,
    hours: Array(),
    index: Number
  }

  isMobile = false;
  interval = 0;
  intervalWording = ""

  //id = "29"
  tablePartySize = [];
  tableAvailability = [];
  tableAvailability24hr = [];
  tableDays = [];
  numbersOnly = false;
  saveEnabled = false;


  dataAvailability = [
    {
      id: 0,
      text: "8:00 AM",
      "24hrText": "08:00:00",
      "selected": false
    },
    {
      id: 1,
      text: "8:15 AM",
      "24hrText": "08:15:00",
      "selected": false
    },
    {
      id: 2,
      text: "8:30 AM",
      "24hrText": "08:30:00",
      "selected": false
    },
    {
      id: 3,
      text: "8:45 AM",
      "24hrText": "08:45:00",
      "selected": false
    },
    {
      id: 4,
      text: "9:00 AM",
      "24hrText": "09:00:00",
      "selected": false
    },
    {
      id: 5,
      text: "9:15 AM",
      "24hrText": "09:15:00",
      "selected": false
    },
    {
      id: 6,
      text: "9:30 AM",
      "24hrText": "09:30:00",
      "selected": false
    },
    {
      id: 7,
      text: "9:45 AM",
      "24hrText": "09:45:00",
      "selected": false
    },
    {
      id: 8,
      text: "10:00 AM",
      "24hrText": "10:00:00",
      "selected": false
    },
    {
      id: 9,
      text: "10:15 AM",
      "24hrText": "10:15:00",
      "selected": false
    },
    {
      id: 10,
      text: "10:30 AM",
      "24hrText": "10:30:00",
      "selected": false
    },
    {
      id: 11,
      text: "10:45 AM",
      "24hrText": "10:45:00",
      "selected": false
    },
    {
      id: 12,
      text: "11:00 AM",
      "24hrText": "11:00:00",
      "selected": false
    },
    {
      id: 13,
      text: "11:15 AM",
      "24hrText": "11:15:00",
      "selected": false
    },
    {
      id: 14,
      text: "11:30 AM",
      "24hrText": "11:30:00",
      "selected": false
    },
    {
      id: 15,
      text: "11:45 AM",
      "24hrText": "11:45:00",
      "selected": false
    },
    {
      id: 16,
      text: "12:00 PM",
      "24hrText": "12:00:00",
      "selected": false
    },
    {
      id: 17,
      text: "12:15 PM",
      "24hrText": "12:15:00",
      "selected": false
    },
    {
      id: 18,
      text: "12:30 PM",
      "24hrText": "12:30:00",
      "selected": false
    },
    {
      id: 19,
      text: "12:45 PM",
      "24hrText": "12:45:00",
      "selected": false
    },
    {
      id: 20,
      text: "1:00 PM",
      "24hrText": "13:00:00",
      "selected": false
    },
    {
      id: 21,
      text: "1:15 PM",
      "24hrText": "13:15:00",
      "selected": false
    },
    {
      id: 22,
      text: "1:30 PM",
      "24hrText": "13:30:00",
      "selected": false
    },
    {
      id: 23,
      text: "1:45 PM",
      "24hrText": "13:45:00",
      "selected": false
    },
    {
      id: 24,
      text: "2:00 PM",
      "24hrText": "14:00:00",
      "selected": false
    },
    {
      id: 25,
      text: "2:15 PM",
      "24hrText": "14:15:00",
      "selected": false
    },
    {
      id: 26,
      text: "2:30 PM",
      "24hrText": "14:30:00",
      "selected": false
    },
    {
      id: 27,
      text: "2:45 PM",
      "24hrText": "14:45:00",
      "selected": false
    },
    {
      id: 28,
      text: "3:00 PM",
      "24hrText": "15:00:00",
      "selected": false
    },
    {
      id: 29,
      text: "3:15 PM",
      "24hrText": "15:15:00",
      "selected": false
    },
    {
      id: 30,
      text: "3:30 PM",
      "24hrText": "15:30:00",
      "selected": false
    },
    {
      id: 31,
      text: "3:45 PM",
      "24hrText": "15:45:00",
      "selected": false
    },
    {
      id: 32,
      text: "4:00 PM",
      "24hrText": "16:00:00",
      "selected": false
    },
    {
      id: 33,
      text: "4:15 PM",
      "24hrText": "16:15:00",
      "selected": false
    },
    {
      id: 34,
      text: "4:30 PM",
      "24hrText": "16:30:00",
      "selected": false
    },
    {
      id: 35,
      text: "4:45 PM",
      "24hrText": "16:45:00",
      "selected": false
    },
    {
      id: 36,
      text: "5:00 PM",
      "24hrText": "17:00:00",
      "selected": false
    },
    {
      id: 37,
      text: "5:15 PM",
      "24hrText": "17:15:00",
      "selected": false
    },
    {
      id: 38,
      text: "5:30 PM",
      "24hrText": "17:30:00",
      "selected": false
    },
    {
      id: 39,
      text: "5:45 PM",
      "24hrText": "17:45:00",
      "selected": false
    },
    {
      id: 40,
      text: "6:00 PM",
      "24hrText": "18:00:00",
      "selected": false
    },
    {
      id: 41,
      text: "6:15 PM",
      "24hrText": "18:15:00",
      "selected": false
    },
    {
      id: 42,
      text: "6:30 PM",
      "24hrText": "18:30:00",
      "selected": false
    },
    {
      id: 43,
      text: "6:45 PM",
      "24hrText": "18:45:00",
      "selected": false
    },
    {
      id: 44,
      text: "7:00 PM",
      "24hrText": "19:00:00",
      "selected": false
    },
    {
      id: 45,
      text: "7:15 PM",
      "24hrText": "19:15:00",
      "selected": false
    },
    {
      id: 46,
      text: "7:30 PM",
      "24hrText": "19:30:00",
      "selected": false
    },
    {
      id: 47,
      text: "7:45 PM",
      "24hrText": "19:45:00",
      "selected": false
    },
    {
      id: 48,
      text: "8:00 PM",
      "24hrText": "20:00:00",
      "selected": false
    },
    {
      id: 49,
      text: "8:15 PM",
      "24hrText": "20:15:00",
      "selected": false
    },
    {
      id: 50,
      text: "8:30 PM",
      "24hrText": "20:30:00",
      "selected": false
    },
    {
      id: 51,
      text: "8:45 PM",
      "24hrText": "20:45:00",
      "selected": false
    },
    {
      id: 52,
      text: "9:00 PM",
      "24hrText": "21:00:00",
      "selected": false
    },
    {
      id: 53,
      text: "9:15 PM",
      "24hrText": "21:15:00",
      "selected": false
    },
    {
      id: 54,
      text: "9:30 PM",
      "24hrText": "21:30:00",
      "selected": false
    },
    {
      id: 55,
      text: "9:45 PM",
      "24hrText": "21:45:00",
      "selected": false
    },
    {
      id: 56,
      text: "10:00 PM",
      "24hrText": "22:00:00",
      "selected": false
    },
    {
      id: 57,
      text: "10:15 PM",
      "24hrText": "22:15:00",
      "selected": false
    },
    {
      id: 58,
      text: "10:30 PM",
      "24hrText": "22:30:00",
      "selected": false
    },
    {
      id: 59,
      text: "10:45 PM",
      "24hrText": "22:45:00",
      "selected": false
    },
    {
      id: 60,
      text: "11:00 PM",
      "24hrText": "23:00:00",
      "selected": false
    },
    {
      id: 61,
      text: "11:15 PM",
      "24hrText": "23:15:00",
      "selected": false
    },
    {
      id: 62,
      text: "11:30 PM",
      "24hrText": "23:30:00",
      "selected": false
    }
  ];

  // for selecting Days and preloading data

  dataDays = [
    {
      id: 0,
      text: "Monday",
      "selected": false
    },
    {
      id: 1,
      text: "Tuesday",
      "selected": false
    },
    {
      id: 2,
      text: "Wednesday",
      "selected": false
    },
    {
      id: 3,
      text: "Thursday",
      "selected": false
    },
    {
      id: 4,
      text: "Friday",
      "selected": false
    },
    {
      id: 5,
      text: "Saturday",
      "selected": false
    },
    {
      id: 6,
      text: "Sunday",
      "selected": false
    }
  ];



  constructor(private resService: ReservationsService) { }

  async ngOnInit() {

    this.table = JSON.parse(this.tableData);

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.isMobile = true;
    }

    //Prepare Preselected data for Table Availability
    for (let i in this.dataAvailability) {
      for (let hour in this.table.hours) {
        if (this.dataAvailability[i]['24hrText'] === this.table.hours[hour].startHours) {
          this.dataAvailability[i].selected = true;
          this.tableAvailability.push(this.dataAvailability[i]['24hrText']);
        }
      }
    }

    //Prepare Preselected data for Table Days
    let selectedDays = this.table.day.toString().split(",");
    for (let i in this.dataDays) {
      for (let day in selectedDays) {
        if (this.dataDays[i].id.toString() === selectedDays[day]) {
          this.dataDays[i].selected = true
          this.tableDays.push(this.dataDays[i].id);
        }
      }
    }
  }

  ngAfterViewInit() {

    let self = this;

    //for displaying selected time interval duration in hour format
    this.interval = parseInt(this.table.interval.toString());
    this.intervalWording = Math.floor(this.interval / 3600) + "hr"

    // for selecting party size and preloading data
    let dataPartySize = []

    for (let partySize in this.table.partySize.toString().split(",")) {

      dataPartySize.push({
        id: partySize,
        text: this.table.partySize.toString().split(",")[partySize],
        "selected": true
      })

      this.tablePartySize.push(this.table.partySize.toString().split(",")[partySize]);
    }



    $("#tablePartySize" + this.table.tableID.toString()).select2({
      data: dataPartySize,
      multiple: true,
      tags: true,
    });

    let tbID = this.table.tableID
    //for allowing only numbers and non-zero value to be entered in party size
    $(document).on('keypress', '.select2-search__field', function (e) {

      const target = e.target;
      //console.log(target.attributes)
      if ('aria-controls' in target.attributes) {
        if (target.attributes['aria-controls'].textContent == "select2-tablePartySize" + tbID + "-results") {
          this.numbersOnly = true
        }
        else {
          this.numbersOnly = false
        }

      }

      if (this.numbersOnly) {
        //$(this).val($(this).val().replace(/[^\d].+/, ""));
        if ((e.which < 48 || e.which > 57) || (this.value.length == 0 && e.which == 48)) {
          e.preventDefault();
        }

      }


    });



    $("#tablePartySize" + this.table.tableID.toString()).on('select2:select', (e) => {
      var data = e.params.data.text;



      if (this.tablePartySize.length > 0) {
        for (let i in this.tablePartySize) {
          if (parseInt(data) < parseInt(this.tablePartySize[i])) {
            this.tablePartySize.splice(parseInt(i), 0, data);
            break
          }
          else if (parseInt(i) == this.tablePartySize.length - 1) {
            this.tablePartySize.splice(parseInt(i + 1), 0, data);
            break
          }
          else {
            continue
          }
        }
      }
      else {
        this.tablePartySize.push(data);
      }

      //check for data change in table to enable or disable save button
      if (this.tablePartySize.toString() == this.table.partySize.toString()) {
        this.saveEnabled = false;
        console.log(this.saveEnabled)
      }
      else {
        this.saveEnabled = true
        console.log(this.saveEnabled)
      }

      // console.log("here in select")
      // console.log(this.tablePartySize)

    });

    $("#tablePartySize" + this.table.tableID.toString()).on('select2:unselect', (e) => {

      for (let n in this.tablePartySize) {
        if (e.params.data.text == this.tablePartySize[n]) {
          this.tablePartySize.splice(parseInt(n), 1)
        }
      }

      //check for data change in table to enable or disable save button
      if (this.tablePartySize.toString() == this.table.partySize.toString()) {
        this.saveEnabled = false;
        console.log(this.saveEnabled)
      }
      else {
        this.saveEnabled = true
        console.log(this.saveEnabled)
      }

      // console.log("here in unselect")
      // console.log(this.tablePartySize)
    });

    $(function () {

      //Initialize Selectpicker For Table Availability
      $('#tableAvailabilityPicker' + self.table.tableID.toString()).selectpicker({
        size: 5,
        noneSelectedText: '',
        actionsBox: true,
        iconBase: 'fa',
        tickIcon: 'fa-check',
        mobile: self.isMobile
      });

      //Table Availability Change Listener
      $('#tableAvailabilityPicker' + self.table.tableID.toString()).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        self.saveEnabled = false;
        if (self.tableAvailability.length == self.table.hours.length) {
          for (let pickedHour in self.tableAvailability) {
            for (let hour in self.table.hours) {
              if (self.tableAvailability[pickedHour] == self.table.hours[hour].startHours) {
                break;
              }
              else {
                if (parseInt(hour) == self.table.hours.length - 1) {
                  self.saveEnabled = true
                  break;
                }
                else {
                  self.saveEnabled = false;
                }
              }
            }
            if (self.saveEnabled) {
              break;
            }
          }
        }
        else {
          self.saveEnabled = true;
        }
      });


      //Initialize Selectpicker For Table Days
      $('#tableDaysPicker' + self.table.tableID.toString()).selectpicker({
        noneSelectedText: '',
        actionsBox: true,
        iconBase: 'fa',
        tickIcon: 'fa-check',
        mobile: self.isMobile
      });

      //Table Availability Change Listener
      $('#tableDaysPicker' + self.table.tableID.toString()).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        if (self.tableDays.toString() == self.table.day.toString()) {
          self.saveEnabled = false;
        }
        else {
          self.saveEnabled = true;
        }
      });
    });

  }

  //for changing interval duration into hours and changing to new interval duration
  didSelectDropDown(interval) {
    this.intervalWording = this.getIntervalInHour(interval);

    //check for data change in table to enable or disable save button
    if (this.interval == parseInt(this.table.interval.toString())) {
      this.saveEnabled = false
    }
    else {
      this.saveEnabled = true
    }
  }

  getIntervalInHour(interval) {
    this.interval = interval;
    if (interval == 3600) {

      return "1hr";
    } else if (interval == 7200) {

      return "2hr";
    } else {

      return "3hr";
    }
  }

  //for updating table
  didClickUpdate() {

    if (this.interval == 0 || this.tablePartySize.length == 0 || this.tableAvailability.length == 0 || this.tableDays.length == 0) {
      alert('Please select all values');
      return
    }

    const param = {
      RestaurantTableID: this.table.tableID,
      RestaurantID: localStorage.getItem('restaurantID'),
      Day: this.tableDays.toString(),
      PartySize: this.tablePartySize.toString(),
      TimeInterval: this.interval,
      TableHours: this.tableAvailability,
      TimeZone: localStorage.getItem('restaurantTimeZone')
    };


    this.resService.updateTable(param).subscribe(data => {
      this.updateReservation.emit()

      //console.log(data);

      if (data["Success"] == "true") {
        alert("Table Updated!")
      }

    });
  }


  //for removing table
  didClickRemove() {

    const param = {
      RestaurantTableID: this.table.tableID,
      RestaurantID: localStorage.getItem('restaurantID'),
      Day: this.tableDays.toString(),
      PartySize: this.tablePartySize.toString(),
      TimeInterval: this.interval,
      TableHours: this.tableAvailability
    };

    this.resService.deleteTable(param).subscribe(data => {
      if (data['Success'] == "false") {
        alert(data['Message']);
      }
      else {
        this.deleteReservation.emit();
      }
      //console.log(data);
    });

  }

  updateTableNumber(table) {

    // RestaurantTableID
    //TableNumber
    const param = {
      RestaurantTableID: this.table.tableID,
      TableNumber: table.tableNumber,
      RestaurantID: localStorage.getItem('restaurantID'),
      Day: this.tableDays.toString(),
      PartySize: this.tablePartySize.toString(),
      TimeInterval: this.interval,
      TableHours: this.tableAvailability
    };

    this.resService.updateTableNumber(param).subscribe(data => {
      //this.deleteReservation.emit()
      //console.log(data);
      table.changeTableNumber = false
    });

  }

  editTable(table) {
    table.changeTableNumber = !table.changeTableNumber
  }

  getTableNumber(table) {
    if (table.tableNumber == null) {
      return "unassaigned"
    } else {
      return table.tableNumber
    }


  }

}
