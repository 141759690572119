import { Component, OnInit, NgZone } from '@angular/core';
import { EmpService } from "../../emp.service";
import { OrderServiceService } from "./order-service.service";
import { DomSanitizer, Title, Meta, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Url } from "../../models/url";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment.prod';


declare var $: any;
const AWS = require('aws-sdk');

declare const google: any;


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css', '../../../assets/css/ts-style.css']

})
export class OrderComponent implements OnInit {
  lat = 0.00;
  lng = 0.00;
  isLoading = true;
  restaurants = [];
  restaurantID = 'order';
  autocompleteInput: string;
  position = '43.7315479,-79.76241770000001';
  center = 'Prince Edward Island, Canada';
  address1 = "";
  address = "";
  address2 = "";
  city = "";
  province = "";
  country = "";
  postal = "";
  formattedAddress: string;
  searchCityName = "";

  constructor(private zone: NgZone, private router: Router, public emp: EmpService, private orderService: OrderServiceService, private sanitizer: DomSanitizer, private url: Url, private title: Title, private meta: Meta, private route: ActivatedRoute) {
    route.params.subscribe(val => {

      this.searchCityName = val.city.trim();
      this.searchCityName = this.searchCityName.charAt(0).toUpperCase() + this.searchCityName.slice(1);

      this.title.setTitle(this.searchCityName + ' Restaurants Take Out, Food Delivery & Dine In | TopServe')
      this.meta.updateTag({ name: 'description', content: this.searchCityName + ' restaurants Take Out, Food Delivery & Dine In, Order Online Food Through TopServe.' });
      this.meta.addTag({ name: 'robots', content: 'INDEX, FOLLOW' }, true);
      this.meta.updateTag({ name: 'twitter:description', content: 'Order food delivery and take out online in charlottetown prince edward island through topserve. Order food online now!' });
      this.meta.addTag({ name: 'author', content: 'TopServe' }, true);
      this.meta.updateTag({ name: 'keywords', content: this.searchCityName + ', TopServe, Deliveries, PEI, Nova Scotia, New Brunswick, Moncton Deliveries, Fredericton Deliveries , For Restaurants' });
      this.meta.addTag({ httpEquiv: 'Content-Type', content: 'text/html' }, true);
      this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
      this.meta.updateTag({ property: 'og:title', content: this.searchCityName + ' Restaurants Take Out, Food Delivery & Dine In | TopServe' });
      this.meta.addTag({ property: 'og:type', content: "website" });
      this.meta.addTag({ charset: 'UTF-8' });
    });
  }

  async ngOnInit() {

    if (window.location.href.endsWith('order') || window.location.href.endsWith('order/')) {
      this.router.navigate(['/find-restaurants/montréal'])
      return;
    }

    // console.log(JSON.stringify( ))
    $("#successAlert").hide();
    $("#failAlert").hide();
    this.emp.orderedItems = [];

    if (this.emp.orderLat == 0 || this.emp.orderLng == 0) {
      var orderInformation = JSON.parse(localStorage.getItem('orderInformation'));

      if (orderInformation != null && orderInformation.city.toLowerCase() == window.location.href.split('/').pop().toLowerCase()) {
        orderInformation.travelKM = undefined;
        orderInformation.delivery = undefined;
        orderInformation.isMealService = undefined;
        this.emp.orderInformation = orderInformation;
        this.emp.orderLat = parseFloat(orderInformation.lat.toFixed(6));
        this.emp.orderLng = parseFloat(orderInformation.long.toFixed(6));
      }
      else {
        localStorage.removeItem('orderInformation');
      }
    }
    this.lat = this.emp.orderLat;
    this.lng = this.emp.orderLng;
    this.emp.orderInformation.lat = this.lat;
    this.emp.orderInformation.long = this.lng;

    if (this.lat != 0 && this.lng != 0) {
      this.getLatLngRestaurants()
    } else {
      this.getCityRestaurants();
    }

  }

  async getLatLngRestaurants() {
    const post = {
      Lat: this.lat,
      Long: this.lng
    };

    await this.orderService.getRestaurants(post).subscribe(async data => {
      if (data["Restaurants"]) {
        this.isLoading = false
        for (let x in data["Restaurants"]) {
          if (data["Restaurants"][x]['StripeBusinessID'] != null) {
            await this.getDeliveryFee(data["Restaurants"][x]);
            this.restaurants.push(data["Restaurants"][x]);
          }
        }

        this.getHours();
        this.getOrderTypes();
        this.getImages()
        this.getDisplayImages();
      }
    }, err => {
      alert("there was an error getting the restaurants. please try again later.")
    })

  }

  async getCityRestaurants() {

    const post = {
      City: this.searchCityName,
    };

    await this.orderService.getRestaurantsCity(post).subscribe(async data => {
      this.isLoading = false
      if (data["success"]) {
        for (let x in data["Restaurants"]) {
          if (data["Restaurants"][x]['StripeBusinessID'] != null) {

            this.restaurants.push(data["Restaurants"][x]);

          }
        }

        this.getHours();
        this.getOrderTypes();
        this.getImages()
        this.getDisplayImages();
      }
    }, err => {
      alert("There was an error getting the restaurants. Please try again later.")
    })

  }

  roundNumber(num) {
    var number = Math.round(num * 100) / 100;
    return number.toFixed(2);
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getDeliveryFee(restaurant) {

    //calculates great-circle distances between the two points – 
    //that is, the shortest distance over the earth’s surface – using the ‘Haversine’ formula.
    var lat1 = restaurant.Latitude;
    var lon1 = restaurant.Longitude;
    var lat2 = this.emp.orderInformation.lat;
    var lon2 = this.emp.orderInformation.long;
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var kmTravelled = R * c; // Distance in km

    kmTravelled += 1; // adding one for the driving distance

    restaurant["TravelDistance"] = kmTravelled;
    var fee = kmTravelled * restaurant["DeliveryRate"];
    if (kmTravelled * restaurant["DeliveryRate"] >= restaurant["DeliveryFee"]) {
      restaurant["DeliveryFee"] = kmTravelled * restaurant["DeliveryRate"]
    }

  }


  tConvert(time) {
    // Check correct time format and split into components
    if (time == null) {
      return
    }
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[3] = ""
    }
    return time.join(''); // return adjusted time or original string
  }


  getOrderTypes() {
    console.log(this.restaurants)
    for (let index in this.restaurants) {

      let res = this.restaurants[index];
      this.restaurants[index]["Takeout"] = false;

      this.restaurants[index]["TakeoutMessage"] = "";
      this.restaurants[index]["Delivery"] = false;
      this.restaurants[index]["DeliveryMessage"] = "";
      let orderTypes = res["OrderTypes"].split(",");
      let resDeliveryStart = res["DeliveryStart"];


      let currentHour = new Date().getHours();
      let currentMinute = new Date().getMinutes();
      let resDeliveryStartHours = new Date('1970/01/01 ' + resDeliveryStart).getHours();
      let resDeliveryStartMin = new Date('1970/01/01 ' + resDeliveryStart).getMinutes();
      let hourOfOp = this.tConvert(resDeliveryStart);



      for (let x in orderTypes) {

        if (orderTypes[x] == "2") {
          this.restaurants[index]["IsDelivering"] = true;

          if (currentHour >= resDeliveryStartHours && this.restaurants[index]["isOpen"] && !this.restaurants[index]["isClosed"]) {
            if (currentHour == resDeliveryStartHours) {
              if (currentMinute > resDeliveryStartMin) {
                this.restaurants[index]["Delivery"] = true;
                this.restaurants[index]["DeliveryMessage"] = "Delivering";
                localStorage.setItem('IsDelivery', 'true');

              } else {
                this.restaurants[index]["Delivery"] = false;
                this.restaurants[index]["DeliveryMessage"] = "Delivering at " + hourOfOp;
                //    localStorage.setItem('delivery','false') 
                localStorage.setItem('IsDelivery', 'false');

              }
            } else {
              this.restaurants[index]["DeliveryMessage"] = "Delivering";
              this.restaurants[index]["Delivery"] = true;
              //    localStorage.setItem('delivery','true')
              localStorage.setItem('IsDelivery', 'true');

            }
          } else {

            this.restaurants[index]["Delivery"] = false;
            this.restaurants[index]["DeliveryMessage"] = "Delivering at " + hourOfOp
            //   localStorage.setItem('delivery','false') 
            localStorage.setItem('IsDelivery', 'false');

          }
        } else if (orderTypes[x] == "3") {

          this.restaurants[index]["Takeout"] = true;
          this.restaurants[index]["TakeoutMessage"] = "Take out";
          localStorage.setItem('isTakeout', 'true');
        }
      }

    }



  }


  getHours() {

    for (let index in this.restaurants) {


      let res = this.restaurants[index];
      let currentHour = new Date().getHours();
      let currentMinute = new Date().getMinutes();


      //todo big error here.

      let resOpenHours = new Date('1970/01/01 ' + res.Open).getHours();
      let resOpenMins = new Date('1970/01/01 ' + res.Open).getMinutes();

      let resClosedHours = new Date('1970/01/01 ' + res.Close).getHours();
      let resClosedMins = new Date('1970/01/01 ' + res.Close).getMinutes();



      let hourOfOp = this.tConvert(res.Open);
      let hourOfOpClosed = this.tConvert(res.Close);


      var isOpen = false;
      var isClose = false;



      if (currentHour <= resOpenHours) {
        if (currentHour == resOpenHours) {
          if (currentMinute > resOpenMins) {
            isOpen = true
          }
        } else {
          isOpen = false
        }
      } else {
        isOpen = true
      }

      if (currentHour <= resClosedHours || (resClosedHours < 12 && currentHour >= resClosedHours)) {
        if (currentHour == resClosedHours) {
          if (currentMinute >= resClosedMins) {
            isClose = true
          }
        }
      } else {
        isClose = true
      }

      if (resClosedHours === 0 && resOpenHours === 0) {
        isClose = true
        isOpen = false
      }

      if (isClose || !isOpen || res.ResPaused == 1) {
        this.restaurants[index]["Delivery"] = false;
        this.restaurants[index]["Takeout"] = false;

        this.restaurants[index]["isOpen"] = false;
        this.restaurants[index]["isClosed"] = true;
        this.restaurants[index]["Open"] = false;
        if (isClose) {
          this.restaurants[index]["isOpen"] = false;
          this.restaurants[index]["isClosed"] = true;
          this.restaurants[index]["hoursMessage"] = "Closed At " + hourOfOpClosed;
          if (hourOfOpClosed == "12:00AM") {
            this.restaurants[index]["hoursMessage"] = "Closed Today";
          }

        } else if (!isOpen) {
          this.restaurants[index]["isOpen"] = false;
          this.restaurants[index]["hoursMessage"] = "Opens At " + hourOfOp;
        } else if (res.ResPaused == 1) {
          this.restaurants[index]["isOpen"] = false;
          this.restaurants[index]["isClosed"] = true;
          this.restaurants[index]["hoursMessage"] = "Restaurant currently on pause by staff";
        }
      } else {
        // let rez = this.restaurants[index];
        // this.restaurants.splice(parseInt(index), 1);
        // this.restaurants.push(rez);
        this.restaurants[index]["Open"] = true;
        this.restaurants[index]["isOpen"] = true;
        this.restaurants[index]["isClosed"] = false;
        this.restaurants[index]["hoursMessage"] = "Open Now";
      }

    }

    this.restaurants.sort(function (x, y) {
      // true values first
      return (x.isOpen === y.isOpen) ? 0 : x.isOpen ? -1 : 1;
      // false values first
      // return (x === y)? 0 : x? 1 : -1;
    });





  }


  async getDisplayImages() {

    var self = this;

    const AWSService = AWS;
    const region = 'ca-central-1';


    let bucketName = ""
    if (this.url.getDev() == "dev") {
      bucketName = 'topserve-restaurant-main-images-display-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-main-images-display-prod-canada';
    }


    // const bucketName = 'menu-item-images-meeno '+ this.url.getDev();
    // Configures the AWS service and initial authorization

    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
      secretAccessKey: environment.awsSecret,
      region: region
    });
    // adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });


    self.restaurants.forEach(async (item, n) => {
      await s3.getObject({ Key: item["ImageURL"] + 'display' }, async function (err, file) {
        if (!err && item) {
          let blob = new Blob([file.Body], { type: "image/jpeg" });
          self.restaurants[n]["displayImage"] = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        } else {
          self.restaurants[n]["displayImage"] = null;
        }
      }, 1);
    });
  }



  async getImages() {

    var self = this;

    const AWSService = AWS;
    const region = 'ca-central-1';


    let bucketName = ""
    if (this.url.getDev() == "dev") {
      bucketName = 'topserve-restaurant-main-images-dev-canada';
    } else {
      bucketName = 'topserve-restaurant-main-images-prod-canada';

    }


    // const bucketName = 'menu-item-images-meeno '+ this.url.getDev();
    // Configures the AWS service and initial authorization

    await AWSService.config.update({
      accessKeyId: environment.awsAccess,
      secretAccessKey: environment.awsSecret,
      region: region
    });


    // adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-10-17',
      params: { Bucket: bucketName }
    });



    self.restaurants.forEach(async (item, n) => {

      await s3.getObject({ Key: item["ImageURL"] }, async function (err, file) {

        if (!err && item) {

          let blob = new Blob([file.Body], { type: "image/jpeg" });
          self.restaurants[n]["image"] = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        } else {
          // self.restaurants[n]["image"] = require('assets/img/no-image.png');
        }
      }, 1);
    });
  }


  chooseOption(index, isDelivery) {

    if (isDelivery) {
      this.emp.orderInformation.delivery = true;

    } else {
      this.emp.orderInformation.delivery = undefined;
      localStorage.removeItem('delivery');
    }

    if (this.restaurants[index]["isOpen"] && !this.restaurants[index]["isClosed"]) {

      this.emp.orderInformation.travelKM = this.restaurants[index]['TravelDistance'];

      var chosenRestaurant = this.restaurants[index];
      chosenRestaurant['DefaultDeliveryFee'] = this.restaurants[index]['DeliveryFee'];
      this.emp.chosenRestaurant = chosenRestaurant
      this.router.navigate(['/order', this.emp.chosenRestaurant.LinkName.toString().toLowerCase()]);

    } else {
      alert("this restaurant is closed at the moment.")
    }

  }


  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }


  didChooseRestaurant(index) {
    if (this.restaurants[index]["isOpen"] && !this.restaurants[index]["isClosed"]) {

      this.emp.orderInformation.travelKM = this.restaurants[index]['TravelDistance'];

      var chosenRestaurant = this.restaurants[index];
      chosenRestaurant['DefaultDeliveryFee'] = this.restaurants[index]['DeliveryFee'];
      this.emp.chosenRestaurant = chosenRestaurant
      this.router.navigate(['/order', this.emp.chosenRestaurant.LinkName.toString().toLowerCase()]);

    } else {
      alert("this restaurant is closed at the moment.")
    }
  }

}
