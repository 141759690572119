import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {LocationStrategy} from '@angular/common';
import {PathLocationStrategy} from '@angular/common';
import { AppComponent } from './app.component';
import {EditItemServiceService} from './services/edit-item-service.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ProfileComponent} from "./structure/profile/profile/profile.component";
// import {NguiMapModule} from "@ngui/map";
import { GoogleMapsModule } from '@angular/google-maps';
import {RoleguardService} from "./services/roleguard.service";
import { LogoutComponent } from './structure/login/logout/logout.component';
import {EmpService} from "./emp.service";
import {ProfileService} from "./structure/profile/profile.service";
import { AddReservationComponent } from './components/add-reservation/add-reservation.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {PauseRestaurantComponent} from './structure/restaurant/pause-restaurant/pause-restaurant.component'
import {PauseCategoryComponent} from './structure/pause-category/pause-category.component';
import { ServicesNavigationComponent } from './components/services-navigation/services-navigation.component'
import { AppRoutingModule } from './app-routing.module';
import { LandingComponent } from './structure/landing/landing.component';
import { ProductContactlessComponent } from './structure/products/product-contactless/product-contactless.component';
import { ProductDeliveryComponent } from './structure/products/product-delivery/product-delivery.component';
import { ProductReservationsComponent } from './structure/products/product-reservations/product-reservations.component';
import { NavBarComponent } from './structure/nav-bar/nav-bar.component';
import { FooterComponent } from './structure/footer/footer.component';
import { ForRestaurantsComponent } from './structure/forRestaurants/for-restaurants/for-restaurants.component';
import { OnlineOrderingComponent } from './structure/online-ordering/online-ordering.component';
import { CustomerMarketingComponent } from './structure/customer-marketing/customer-marketing.component';
import { DriverSignupComponent } from './structure/driver/driver-signup/driver-signup.component';
import { FarmSignUpComponent } from './structure/farm/farm-sign-up/farm-sign-up.component';
import { StoreSignUpComponent } from './structure/restaurant/store-sign-up/store-sign-up.component';
import { DriverLoginComponent } from './structure/driver/driver-login/driver-login.component';
import { LoginComponent } from './structure/login/login.component';
import { TopLoginComponent } from './structure/top-login/top-login.component';
import { FarmLoginComponent } from './structure/farm/farm-login/farm-login.component';
import { PrivacyComponent } from './structure/TOS/privacy/privacy.component';
import { DriverAgreementComponent } from './structure/driver-agreement/driver-agreement.component';
import { TablereservationComponent } from './structure/tablereservation/tablereservation.component';
import { FindReservationComponent } from './structure/find-reservation/find-reservation.component';
import { UserProfileComponent } from './components/user-profile/user-profile/user-profile.component';
import { DashboardsAlpha } from './structure/dashboards/alpha.page';
import { DeliveryDashboardComponent } from './structure/driver/delivery-dashboard/delivery-dashboard.component';
import { OrderComponent } from './structure/order/order.component';
import { OrderCartComponent } from './structure/order-cart/order-cart/order-cart.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ExpenditureComponent } from './structure/expenditure/payouts/expenditure.component';
import { ExpenditureDetailsComponent } from './structure/expenditure/expenditure-details/expenditure-details.component';
import { ExpenditureHelpComponent } from './structure/expenditure/expenditure-help/expenditure-help.component';
import { IncomingOrdersComponent } from './structure/orders/incoming-orders/incoming-orders.component';
import { CreateRestaurantOrderComponent } from './structure/create-restaurant-order/create-restaurant-order.component';
import { PastOrdersComponent } from './structure/orders/past-orders/past-orders.component';
import { OrderRefundComponent } from './structure/order-refund/order-refund.component';
import { OrderTrackComponent } from './structure/order-track/order-track.component';
import { ResetpasswordComponent } from './structure/resetpassword/resetpassword.component';
import { DynamicMenuComponent } from './structure/dynamic-menu/dynamic-menu.component';
import { DeliveryExpenditureComponent } from './structure/driver/delivery-expenditure/delivery-expenditure.component';
import { MasterAccountComponent } from './structure/master-account/master-account.component';
import { TodaysOrdersComponent } from './structure/todays-orders/todays-orders.component';
import { FindOrderComponent } from './structure/find-order/find-order.component';
import { ShowDriversComponent } from './structure/driver/show-drivers/show-drivers.component';
import { DriverShiftComponent } from './structure/driver-shift/driver-shift.component';
import { AddShiftComponent } from './structure/add-shift/add-shift.component';
import { FarmPaymentsComponent } from './structure/farm/farm-payments/farm-payments.component';
import { ShowAdminComponent } from './structure/admin/show-admin/show-admin.component';
import { FarmQrComponent } from './structure/farm/farm-qr/farm-qr.component';
import { ServiceOnlineOrderingComponent } from './structure/service-online-ordering/service-online-ordering.component';
import { ServiceQrPayComponent } from './structure/service-qr-pay/service-qr-pay.component';
import { ReservationsSubscriptionComponent } from './structure/reservations/reservations-subscription/reservations-subscription.component';
import { AddItemComponent } from './structure/menu/add-item/add-item.component';
import { ShowItemsComponent } from './structure/menu/show-items/show-items.component';
import { EditItemComponent } from './structure/menu/edit-item/edit-item.component';
import { PauseMenuModifiersComponent } from './structure/pause-menu-modifiers/pause-menu-modifiers.component';
import { MenuViewComponent } from './structure/menu-view/menu-view.component';
import { ShowRestaurantComponent } from './structure/restaurant/show-restaurant/show-restaurant.component';
import { ShowDetailsComponent } from './structure/restaurant/show-details/show-details.component';
import { AddRestaurantComponent } from './structure/restaurant/add-restaurant/add-restaurant.component';
import { PrinterSetupComponent } from './structure/printer-setup/printer-setup.component';
import { PublishPromotionsComponent } from './structure/publish-promotions/publish-promotions.component';
import { ViewSubscribersComponent } from './structure/view-subscribers/view-subscribers.component';
import { SquareIntegrationComponent } from './structure/square-integration/square-integration.component';
import { ReservationComponent } from './structure/reservations/reservations/reservations.component';
import { PastReservationsComponent } from './structure/reservations/past-reservations/past-reservations.component';
import { ReservationManagerComponent } from './structure/reservations/reservation-manager/reservation-manager.component';
import { ChangePasswordComponent } from './structure/change-password/change-password.component';
import { RestaurantTableComponentComponent } from './structure/reservations/restaurant-table-component/restaurant-table-component.component';
import { FarmOrderComponent } from './structure/farm/farm-order/farm-order.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { PageNotFoundComponent } from './structure/page-not-found/page-not-found.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';
import { AddOptionComponent } from './components/add-option/add-option.component';
import { AddFullOptionComponent } from './components/add-full-option/add-full-option.component';
import { Url } from './models/url';
import { GoogleReservationsComponent } from './structure/google-reservations/google-reservations.component';

declare var NProgress: any;

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    LandingComponent,
    OrderComponent,
    OrderCartComponent,
    ProductContactlessComponent,
    ProductReservationsComponent,
    ProductDeliveryComponent,
    ForRestaurantsComponent,
    OnlineOrderingComponent,
    CustomerMarketingComponent,
    DriverSignupComponent,
    FarmSignUpComponent,
    StoreSignUpComponent,
    DriverLoginComponent,
    LoginComponent,
    TopLoginComponent,
    FarmLoginComponent,
    PrivacyComponent,
    DriverAgreementComponent,
    TablereservationComponent,
    FindReservationComponent,
    UserProfileComponent,
    LogoutComponent,
    ServicesNavigationComponent,
    DeliveryDashboardComponent,
    DeliveryExpenditureComponent,
    DashboardsAlpha,
    ProfileComponent,
    ExpenditureComponent,
    ExpenditureDetailsComponent,
    ExpenditureHelpComponent,
    IncomingOrdersComponent,
    CreateRestaurantOrderComponent,
    PastOrdersComponent,
    OrderRefundComponent,
    OrderTrackComponent,
    ResetpasswordComponent,
    DynamicMenuComponent,
    MasterAccountComponent,
    TodaysOrdersComponent,
    FindOrderComponent,
    ShowDriversComponent,
    DriverShiftComponent,
    AddShiftComponent,
    FarmPaymentsComponent,
    ShowAdminComponent,
    FarmQrComponent,
    ServiceOnlineOrderingComponent,
    ServiceQrPayComponent,
    ReservationsSubscriptionComponent,
    AddItemComponent,
    ShowItemsComponent,
    EditItemComponent,
    PauseMenuModifiersComponent,
    PauseCategoryComponent,
    MenuViewComponent,
    ShowRestaurantComponent,
    ShowDetailsComponent,
    AddRestaurantComponent,
    PauseRestaurantComponent,
    PrinterSetupComponent,
    PublishPromotionsComponent,
    ViewSubscribersComponent,
    SquareIntegrationComponent,
    ReservationComponent,
    ReservationManagerComponent,
    PastReservationsComponent,
    ChangePasswordComponent,
    RestaurantTableComponentComponent,
    FarmOrderComponent,
    PageNotFoundComponent,
    AddReservationComponent,
    AddCategoryComponent,
    AddOptionComponent,
    AddFullOptionComponent,
    ChangePasswordComponent,
    GoogleReservationsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // HttpModule,
    // RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    // NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyCDxQPebrOY_Y46g5IhnBF88Flak43Iut0'}),
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatCardModule,
    SocialLoginModule,
  ],
  providers: [
    Url,
    EmpService,
    EditItemServiceService,
    RoleguardService, 
    {provide:LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('154489018492810')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  
  ],
  bootstrap: [AppComponent],

})

export class AppModule {
  constructor(private profileService:ProfileService,private router: Router,private emp:EmpService) {
    const isLogedIn = localStorage.getItem('isLogedIn');
    if (isLogedIn === 'true') {

      const loggedInType = localStorage.getItem('type');
      if (loggedInType == 'user') {
        this.emp.isNormalUser = true;
      }
    }


      router.events.subscribe((event) => {

      if (event instanceof NavigationStart) {
        NProgress.start();
      }

      if (event instanceof NavigationEnd) {
        setTimeout(function () {
          NProgress.done();
        }, 200);
      }

    });
  }
}
