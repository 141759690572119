import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Url } from '../../models/url';

export interface Menu {
  data: any;
}
export interface Item {
  data: any;
}

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  getPausedRestaurantURL = this.url.getDomain() + '/Restaurants/GetHoursPaused';

  getTipsURL = this.url.getDomain() + '/Restaurants/GetRestaurantTips';


  updatePausedRestaurantURL = this.url.getDomain() + '/Restaurants/UpdatePausedRestaurant';
  getTodaysNewOrdersUrl = this.url.getDomain() + '/Orders/GetTodaysNewOrders';
  changeOrderStatusAndTimeURL = this.url.getDomain() + '/Orders/ChangeOrderStatusAndTime';
  changeOrderStatusURL = this.url.getDomain() + '/Orders/ChangeOrderStatus';

  getOrdersInProgressUrl = this.url.getDomain() + '/Orders/GetOrdersInProgress';

  GetOrdersTodayURL =  this.url.getDomain() + '/Orders/GetOrdersToday';
  getOrdersTodayWithNewAndInProgressURL = this.url.getDomain() + '/Orders/getOrdersTodayWithNewAndInProgress';
  CancelOrdeURL =  this.url.getDomain() + '/Orders/CancelOrder';
  cancelDriveThroughOrderURL = this.url.getDomain() + '/Orders/CancelDriveThroughOrder';
  getOrdersFromDateURL =  this.url.getDomain() + '/Orders/GetOrdersFromDate';

  GetDriveThroughOrdersTodayURL =  this.url.getDomain() + '/Orders/GetDriveThroughOrdersToday';
  markOrderIsPaidURL = this.url.getDomain() + '/Stripe/makrOrderAsPaid'
  changeDriveThroughOrderStatusURL = this.url.getDomain() + '/Orders/ChangeDriveThroughOrderStatus';
  confirmCancelationOfOrderURL = this.url.getDomain() + '/Orders/ConfirmCancelationOfOrder';

  

  constructor(private http: HttpClient, private url: Url) { }





  getTodaysNewOrders(restaurantId) {

     return this.http.post(this.getTodaysNewOrdersUrl, { 'Token':'NA','RestaurantID': restaurantId});

  }

  getPausedRestaurant(post){
    return this.http.post(this.getPausedRestaurantURL, post);
  }

  updatePausedRestaurant(post){
    return this.http.post(this.updatePausedRestaurantURL, post);
  }

  changeOrderStatusAndTime(post){
    return this.http.post(this.changeOrderStatusAndTimeURL, post);
  }
  changeOrderStatus(post){
    return this.http.post(this.changeOrderStatusURL, post);
  }
  getOrdersTodayURL(post) {
    return this.http.post(this.GetOrdersTodayURL, post);
  }

  getOrdersTodayWithNewAndInProgress(post) {
    return this.http.post(this.getOrdersTodayWithNewAndInProgressURL, post);
  }

  getOrdersInProgress(restaurantId) {
    return this.http.post(this.getOrdersInProgressUrl, { 'Token':'NA','RestaurantID': restaurantId});
  }
  cancelOrder(post) {
    return this.http.post(this.CancelOrdeURL, post);
  }
  // getOrdersFromDate(post) {
  //   return this.http.post(this.getOrdersFromDateURL, post);
  // }
  getOrdersFromDate(post) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      })
    };

    return this.http.post(this.getOrdersFromDateURL, post, httpOptions);
  }

  getDriveThroughOrdersToday(post) {
    return this.http.post(this.GetDriveThroughOrdersTodayURL, post);
  }

  markOrderIsPaid(post){
    return this.http.post(this.markOrderIsPaidURL, post);
  }

  changeDriveThroughOrderStatus(post){
    return this.http.post(this.changeDriveThroughOrderStatusURL, post);
  }

  cancelDriverThroughOrder(post){
    return this.http.post(this.cancelDriveThroughOrderURL, post);
  }

  confirmCalcelationOfOrder(post){
    return this.http.post(this.confirmCancelationOfOrderURL, post);
  }

  getRestaurantTips(post){
    return this.http.post(this.getTipsURL, post);
  }


  

}
