import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from '../orders.service';
import { ReservationsService } from '../../reservations/reservations.service';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from 'app/structure/notification.service';
import { interval } from 'rxjs';
import { PrinterSetupComponent } from '../../printer-setup/printer-setup.component';
import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { Console } from 'console';
import { FORMERR } from 'dns';
import * as moment from 'moment';
import {EmpService} from "../../../emp.service";

const Pusher = require('pusher-js');



declare var $: any;


@Component({
    selector: 'app-incoming-orders',
    templateUrl: './incoming-orders.component.html',
    styleUrls: ['./incoming-orders.component.css','../../restaurant/show-details/show-details.component.css', '../../../../assets/css/aos.css', '../../../../assets/css/custom.css', '../../../../assets/css/fonts.css', '../../../../assets/css/fx.css', '../../../../assets/css/magnific-popup.css','../../../../assets/css/ts-style.css']
})

export class IncomingOrdersComponent implements OnInit, OnDestroy {


    allRestaurants = [];
    restaurantIds = [];


    //For Web Push Notifications
    readonly VAPID_PUBLIC_KEY = "BIYpFOeJXQkH6bop0qEPeamtRkPXvrVgEMEsRl4v4U-ePEtlTVwzhnKxZwngibXc-zr9QJG7J398eC-oa8Wi6eA";
    webPushNotificationEnabled = false;

    mainMenu: any;
    orderedItems = [];
    tempOrderedItems = [];

    orderedItemsProcess = [];
    orderedItemsCompleted = [];

    isFulfillingOrder = false;
    customTime = 10;
    customInfo = '';
    soundTimer: any;
    timers = [];
    noOrders = true;
    audio = new Audio('../../assets/sounds/orderAlert.wav');
    audioOn = false;
    ownDelivery = false;
    likes: any;
    orderID = 0;
    pauseFor = 'Stop accepting orders!';
    pausedOrders = false;
    onDestroyWasCalled = false;
    isUpdated = false;

    Reservations = false;
    reservations = [];

    //For Lone Oak Timing to get orders;
    hideGroupOrder = true;
    allOrdersAtOnce = false;
    refreshOrdersTimer: any;
    status: boolean = false;
    primName="";
    mealNum = "";
    mealName ="";
    mealPrice: any;
    mealSName = "";
    mealSTitle = "";
    mealSPrice: any;
    msideTtitle = "";
    mealQty:any;
    mealSplreq = "";
    mealSQty:any;
    mealAddCom =""
    PrinterIP = "";
    webPrintDriver: WebPrintDriver;
    usbPrintDriver:UsbDriver;
    showTipDialog = false
    startHour = '00:00'
    endHour = '00:00'
    tipsAndTables = []
    tipCheck = false;

    constructor(public emp:EmpService,private ordersService: OrdersService, private printService: PrintService,private secondPrintService:PrintService, private routes: Router, private resService: ReservationsService, private swPush: SwPush, private notificationService:NotificationService) {
        
    }
   
    async ngOnInit() {

        $(function () {
            $('.clockpicker').clockpicker({
                // autoclose: true,
                afterDone: function() {
                    $('.clockpicker').trigger("change");
                }
            });
        });

        this.allRestaurants = localStorage.getItem('allRestaurants') !== 'null' ? JSON.parse(localStorage.getItem('allRestaurants')) : [];
        if(localStorage.getItem('allRestaurants') == null){
            this.restaurantIds = localStorage.getItem('allRestaurantID').split(',');
        } else {
            this.allRestaurants.forEach((restaurant) => {
                this.restaurantIds.push(restaurant['RestaurantID']);
            });
        }

        if(localStorage.getItem('DineInSubscriptionID') != null && localStorage.getItem('DineInSubscriptionID') != '') {
            this.showTipDialog = true
          
        }

       
       
       // printer setup ends
        this.PrinterIP = localStorage.getItem('printerIp')
        if(this.PrinterIP != ""){
            this.webPrintDriver = new WebPrintDriver(this.PrinterIP);
            this.printService.setDriver(this.webPrintDriver, "WebPRNT");
        }
        this.printService.isConnected.subscribe((result) => {
            this.status = result;        
        });
                    
        if(this.status = false){
            $('#Print').prop('disabled', true);
        }
        else{
            $('#Print').prop('disabled', false);
            
        }

        $(window).on('load', function () {
            $('#startOrdersModal').modal('show');
        });

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });


        if (localStorage.getItem('ownDelivery') === '1') {
            this.ownDelivery = true
        }

        if (localStorage.getItem('Reservations') === '1') {
            this.Reservations = true
        }

        const d = new Date();
        const n = d.getDay() - 1;
        const post = {
            Day: n,
            RestaurantID: localStorage.getItem('restaurantID'),
        };

        this.ordersService.getPausedRestaurant(post).subscribe(async data => {
            if (data['Success']) {
                this.pauseFor = 'Start accepting orders!';
                this.pausedOrders = true;
            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login')
                alert('Updates have been made, please log back in and perform this action.');
            } else {
                this.pauseFor = 'Stop accepting orders!';
                this.pausedOrders = false;
            }
        }, () => {
            alert('there was an error pausing your restaurant. please contact support if this is critical.')
        });

        this.hideGroupOrder = !this.restaurantIds.includes(63);
        this.allOrdersAtOnce = localStorage.getItem('allOrdersAtOnce') === 'true';
        if(this.allOrdersAtOnce) {
            this.startAllOrdersAtOnce();
        }


        const pusher = new Pusher('1112b666d809129183c1', {
            cluster: 'us2',
            forceTLS: true
        });

        if(this.restaurantIds.length >= 1) {
            for(let i in this.restaurantIds) {
                const channel = pusher.subscribe(this.restaurantIds[i].toString());
                const self0 = this;
                channel.bind('my-event', function (data) {

                    if (data.hasOwnProperty('message')) {
                        if (data['message'] === 'new reservation' && self0.Reservations) {
                            self0.getNewReservations();
                        }
                        else if (data['message'] === 'update reservation' && self0.Reservations) {
                            self0.getNewReservations();
                        }
                        else if (data['message'] === 'cancel reservation' && self0.Reservations) {
                            self0.getNewReservations();
                        }
                        else if (data['message'] === 'new order') {
                            if (self0.restaurantIds[i] == 63 && self0.allOrdersAtOnce) {
                                return;
                            }
                            self0.getTodaysOrders(false);
                        } else if (data['message'] === 'update') {
                            self0.orderID = data['orderID'];
                            self0.getTodaysOrders(true);
                            self0.orderedItemsProcess = [];
                            self0.isUpdated = true
                        } else if (data['message'] === 'take order') {
                            self0.orderID = data['orderID'];
                            self0.getTodaysOrders(true);
                        } else {
                            const messageArray = data['message'].split(' ');
                            if (messageArray[0] === 'driverAdded' && messageArray.length > 2) {
                                self0.orderID = messageArray[3];
                                self0.isUpdated = true
                                self0.updateDriver(messageArray[1], messageArray[2]);
                            }
                        }
                    }
                });
            }
        }

        $('#alert').hide();

        this.getTodaysOrders(false);

        if (this.Reservations) {
            this.getNewReservations();
        }
        
        await this.getOrdersCompleted();

        this.isUpdated = false;
    }

    setupClock(){
        console.log("clock set up")
        $('.clockpicker').clockpicker();
    }



    ngAfterViewInit(){
       

     

    }


    filterTableTips(){

        let d = new Date(new Date().toLocaleString("en-US", {timeZone: localStorage.getItem('restaurantTimeZone')})); // timezone ex: Asia/Jerusalem
   
        let month = d.getMonth() + 1
        
       let startHourx = d.getFullYear() + "-" + month + "-" + d.getDate() + "T" + $("#startHour").val() + ":00"
       let endHourx = d.getFullYear() + "-" + month + "-" + d.getDate() + "T" + $("#endHour").val() + ":00"

        const post = {
            startHour: startHourx,
            endHour: endHourx,
            RestaurantID:localStorage.getItem('restaurantID'),
            TimeZone:localStorage.getItem('restaurantTimeZone')
        };

        this.ordersService.getRestaurantTips(post).subscribe(async data => {
            this.tipsAndTables = []
            this.tipCheck = false
            var tables = []
            let self = this

            if (data['Success']) {
                let tips = data['Tips'];
                Object.keys(tips).forEach(function(key, index) {
                    if(tables.includes(tips[index]["TableNumber"])){
                        self.tipCheck = true
                        for(let x in self.tipsAndTables){
                            if(self.tipsAndTables[x]["TableNumber"] ==tips[index]["TableNumber"] ) {
                                self.tipsAndTables[x]["Tip"] += tips[index]["Tip"]
                            }
                        }
                    }else{
                        self.tipCheck = true
                        self.tipsAndTables.push(tips[index])
                        tables.push(tips[index]["TableNumber"])
                    }
                });
            }
            else {
                alert('Something went wrong! Could not get tips for selected time. Please contact support@topserve.ca for help.');
            }

        });
        
    }

    
formatCurrency(amount){
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(amount)
}
    
   // print current order 
    print(orderid){


        this.printService.init()

        for(let ordId in this.orderedItems){             
            let orderNum = this.orderedItems[ordId].OrderDetails.OrderID
            //procced only if id match
            if (orderNum == orderid){
                //header
                let orderNo = "Order # " + orderNum
                this.printService
                .setSize("normal")
                .setJustification("center")
                .writeLine(orderNo)
                //orderType
                if(this.orderedItems[ordId].OrderDetails.OrderType == '1'){
                    let otype =  this.getOrderType(   this.orderedItems[ordId].OrderDetails.OrderType) + " Order Table " +  this.orderedItems[ordId].OrderDetails.TableNumber
                    this.printService
                    .setBold(true) 
                    .writeLine( otype )
                    .setBold(false)


                }else{
                    let otype =  this.getOrderType(   this.orderedItems[ordId].OrderDetails.OrderType) + " Order"
                    this.printService
                    .setBold(true) 
                    .writeLine( otype )
                    .setBold(false)
                }
                //OrderedAt
                let orderAt =  "Ordered at: " + this.orderedItems[ordId].OrderDetails.OriginalDate
                this.printService.writeLine( orderAt )
             
                //menu list
                let num=1
                let allMenu = this.orderedItems[ordId].Order
                this.mealAddCom = this.orderedItems[ordId].OrderDetails.AdditionalComments.toString().trim()
              
                for (let i in  allMenu){
                    this.mealQty =''
                    let menu = allMenu[i].Primary
            
                    for(let j in menu){
                        this.mealNum  = "Meal " + num
                      
                        let mainMenu = menu[j]
                        if(j == 'Name'){
                            this.mealName = mainMenu
                           
                        }
                        if(j == 'Price'){
                           
                            this.mealPrice = "$" +  this.roundNumber(mainMenu * menu['ItemNumbers'])
                        }
                        if(j == 'ItemNumbers'){
                            if (mainMenu > 1){
                                this.mealQty = " X " + mainMenu
                            }
                           
                        }
                        if(j == 'SpecialRequest'){
                            this.mealSplreq = mainMenu
                        }
                    }
                    this.printService
                    .setJustification("left")
                    .setUnderline(true)
                    .writeLine( this.mealNum )
                    .setUnderline(false)
                   if(this.mealQty != undefined ||   this.mealQty == '' ){
                    this.printService.writeLine(this.mealName + this.mealQty)
                   }
                   else{
                    this.printService.writeLine(this.mealName )
                   }
                   
                   this.printService
                    .setJustification("right")
                    .writeLine( this.mealPrice )
                    .setJustification("left")
                    
                   
                   
                    let sideMenu = allMenu[i].Sides
                 
                    if(sideMenu != ''){
                        
                        for(let j in sideMenu){
                            
                            this.mealSTitle = sideMenu[j].Title
                            this.printService
                            .setBold(true) 
                            .writeLine(this.mealSTitle)
                            .setBold(false)
                           
                            for(let sid in sideMenu[j].Sides)
                            {
                                this.mealSName = sideMenu[j].Sides[sid].sideName
                              
                                this.mealSPrice = "$" + this.roundNumber(sideMenu[j].Sides[sid].sidePrice * sideMenu[j].Sides[sid]['ItemNumbers'])
                              
                                if (sideMenu[j].Sides[sid]['ItemNumbers'] > 1){
                                    this.mealSQty = " X " + sideMenu[j].Sides[sid]['ItemNumbers']
                                }
                                
                                      
                                if(this.mealSQty != undefined ||   this.mealSQty == '' ){
                                this.printService.writeLine(this.mealSName + this.mealSQty)
                                }
                                else{
                                this.printService.writeLine(this.mealSName )
                                }
                                this.printService
                                .setJustification("right")
                                .writeLine(this.mealSPrice)
                                .setJustification("left")
                               
                            }
                        }
                    }
                    if (this.mealSplreq != ''){
                        if (this.mealSplreq != "NA"){
                            this.printService
                            .setBold(true) 
                            .writeLine("Special Request For Meal")
                            .setBold(false)
                            .writeLine(this.mealSplreq)
                        }
                    }
                    num++
                }


                if (this.mealAddCom != ""){
                    if (this.mealAddCom != undefined){
                        this.printService
                        .setBold(true) 
                        .setUnderline(true)
                        .writeLine("Additional Comments")
                        .setBold(false)
                        .setUnderline(false)
                        .writeLine(this.mealAddCom)
                    }
                }


                this.printService
                .setJustification("right")
                .writeLine(" ")
                .setBold(true) 
                .writeLine("Subtotal")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItems[ordId].OrderDetails.Subtotal))


                this.printService
                .setBold(true) 
                .writeLine("Tax")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItems[ordId].OrderDetails.Tax))

                if(this.orderedItems[ordId].OrderDetails.DeliveryFee > 0){
                    this.printService
                .setBold(true) 
                .writeLine("Delivery Fee")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItems[ordId].OrderDetails.DeliveryFee))

                }

                this.printService
                .setBold(true) 
                .writeLine("Tip")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItems[ordId].OrderDetails.Tip))

                this.printService
                .setBold(true) 
                .writeLine("Total")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItems[ordId].OrderDetails.Total))
                                
            }
      
        }
       
        





        this.printService.cut("full")
        this.printService.flush();

    }

   //print in progress order
//     printInProc(orderid){
//         console.log("print in progress + " + this.orderedItems)
//         this.printService.init()
    
//         for(let ordId in  this.orderedItems){                                    
//             let orderNum =  this.orderedItems[ordId].OrderDetails.OrderID
//             //procced only if id match
//             if (orderNum == orderid){
             
//                 //header
//                 let orderNo = "Order # " + orderNum
//                 this.printService
//                 .setSize("normal")
//                 .setJustification("center")
//                 .writeLine(orderNo)
//                 //orderType
//                 let otype =  this.getOrderType( this.orderedItems[ordId].OrderDetails.OrderType) + " Order"
//                 this.printService
//                 .setBold(true) 
//                 .writeLine( otype )
//                 .setBold(false)
//                 //OrderedAt
//                 let orderAt =  "Ordered at: " +  this.orderedItems[ordId].OrderDetails.OriginalDate
//                 this.printService.writeLine( orderAt )
             
//                 //menu list
//                 let num=1
//                 let allMenu =  this.orderedItems[ordId].Order
//                 this.mealAddCom =  this.orderedItems[ordId].OrderDetails.AdditionalComments.toString().trim()
               
//                 for (let i in  allMenu){
//                     this.mealQty =''
//                     let menu = allMenu[i].Primary
            
//                     for(let j in menu){
//                         this.mealNum  = "Meal " + num
                      
//                         let mainMenu = menu[j]
//                         if(j == 'Name'){
//                             this.mealName = mainMenu
                           
//                         }
//                         if(j == 'Price'){
                           
//                             this.mealPrice = "$" +  this.roundNumber(mainMenu * menu['ItemNumbers'])
//                         }
//                         if(j == 'ItemNumbers'){
//                             if (mainMenu > 1){
//                                 this.mealQty = " X " + mainMenu
//                             }
                           
//                         }
//                         if(j == 'SpecialRequest'){
//                             this.mealSplreq = mainMenu
//                         }
//                     }
//                     this.printService
//                     .setJustification("left")
//                     .setUnderline(true)
//                     .writeLine( this.mealNum )
//                     .setUnderline(false)
//                    if(this.mealQty != undefined ||   this.mealQty == '' ){
//                     this.printService.writeLine(this.mealName + this.mealQty)
//                    }
//                    else{
//                     this.printService.writeLine(this.mealName )
//                    }
                   
//                    this.printService
//                     .setJustification("right")
//                     .writeLine( this.mealPrice )
//                     .setJustification("left")
                  
                 
//                     let sideMenu = allMenu[i].Sides
                 
//                     if(sideMenu != ''){
                        
//                         for(let j in sideMenu){
                            
//                             this.mealSTitle = sideMenu[j].Title
//                             this.printService
//                             .setBold(true) 
//                             .writeLine(this.mealSTitle)
//                             .setBold(false)
                           
//                             for(let sid in sideMenu[j].Sides)
//                             {
//                                 this.mealSName = sideMenu[j].Sides[sid].sideName
                              
//                                 this.mealSPrice = "$" + this.roundNumber(sideMenu[j].Sides[sid].sidePrice * sideMenu[j].Sides[sid]['ItemNumbers'])
                              
//                                 if (sideMenu[j].Sides[sid]['ItemNumbers'] > 1){
//                                     this.mealSQty = " X " + sideMenu[j].Sides[sid]['ItemNumbers']
//                                 }
                                
                                      
//                                 if(this.mealSQty != undefined ||   this.mealSQty == '' ){
//                                 this.printService.writeLine(this.mealSName + this.mealSQty)
//                                 }
//                                 else{
//                                 this.printService.writeLine(this.mealSName )
//                                 }
//                                 this.printService
//                                 .setJustification("right")
//                                 .writeLine(this.mealSPrice)
//                                 .setJustification("left")
                               
//                             }
//                         }
//                     }
//                     if (this.mealSplreq != ''){
//                         if (this.mealSplreq != "NA"){
//                             this.printService
//                             .setBold(true) 
//                             .writeLine("Special Request For Meal")
//                             .setBold(false)
//                             .writeLine(this.mealSplreq)
//                         }
//                     }
                  
//                     num++
//                 }
                
//             }
      
//         }
//         if (this.mealAddCom != ""){
//             if (this.mealAddCom != undefined){
//                 this.printService
//                 .setBold(true) 
//                 .setUnderline(true)
//                 .writeLine("Additional Comments")
//                 .setBold(false)
//                 .setUnderline(false)
//                 .writeLine(this.mealAddCom)
//             }
//         }
//         this.printService.cut("full")
//         this.printService.flush();

//     }

  //print completed order
    printCompOrdr(orderid){
        this.printService.init()
    
        for(let ordId in    this.orderedItemsCompleted){                                    
            let orderNum =    this.orderedItemsCompleted[ordId].OrderDetails.OrderID
            //procced only if id match
            if (orderNum == orderid){

                let orderNo = "Order # " + orderNum
                this.printService
                .setSize("normal")
                .setJustification("center")
                .writeLine(orderNo)
             

                if(this.orderedItemsCompleted[ordId].OrderDetails.OrderType == '1'){
                    let otype =  this.getOrderType(   this.orderedItemsCompleted[ordId].OrderDetails.OrderType) + " Order Table " +  this.orderedItemsCompleted[ordId].OrderDetails.TableNumber
                    this.printService
                    .setBold(true) 
                    .writeLine( otype )
                    .setBold(false)


                }else{
                    let otype =  this.getOrderType(   this.orderedItemsCompleted[ordId].OrderDetails.OrderType) + " Order"
                    this.printService
                    .setBold(true) 
                    .writeLine( otype )
                    .setBold(false)
                }

              
             
                let orderAt =  "Ordered at: " +    this.orderedItemsCompleted[ordId].OrderDetails.OriginalDate
                this.printService.writeLine( orderAt )
             
                let num=1
                let allMenu =    this.orderedItemsCompleted[ordId].Order
                this.mealAddCom =   this.orderedItemsCompleted[ordId].OrderDetails.AdditionalComments.toString().trim()
               
                for (let i in  allMenu){
                    this.mealQty =''
                    let menu = allMenu[i].Primary
            
                    for(let j in menu){
                        this.mealNum  = "Meal " + num
                      
                        let mainMenu = menu[j]
                        if(j == 'Name'){
                            this.mealName = mainMenu
                           
                        }
                        if(j == 'Price'){
                           
                            this.mealPrice = "$" +  this.roundNumber(mainMenu * menu['ItemNumbers'])
                        }
                        if(j == 'ItemNumbers'){
                            if (mainMenu > 1){
                                this.mealQty = " X " + mainMenu
                            }
                           
                        }
                        if(j == 'SpecialRequest'){
                            this.mealSplreq = mainMenu
                        }
                    }
                    this.printService
                    .setJustification("left")
                    .setUnderline(true)
                    .writeLine( this.mealNum )
                    .setUnderline(false)
                   if(this.mealQty != undefined ||   this.mealQty == '' ){
                    this.printService.writeLine(this.mealName + this.mealQty)
                   }
                   else{
                    this.printService.writeLine(this.mealName )
                   }
                   
                   this.printService
                    .setJustification("right")
                    .writeLine( this.mealPrice )
                    .setJustification("left")
               
                   
                    let sideMenu = allMenu[i].Sides
                 
                    if(sideMenu != ''){
                        
                        for(let j in sideMenu){
                            
                            this.mealSTitle = sideMenu[j].Title
                            this.printService
                            .setBold(true) 
                            .writeLine(this.mealSTitle)
                            .setBold(false)
                           
                            for(let sid in sideMenu[j].Sides)
                            {
                                this.mealSName = sideMenu[j].Sides[sid].sideName
                              
                                this.mealSPrice = "$" + this.roundNumber(sideMenu[j].Sides[sid].sidePrice * sideMenu[j].Sides[sid]['ItemNumbers'])
                              
                                if (sideMenu[j].Sides[sid]['ItemNumbers'] > 1){
                                    this.mealSQty = " X " + sideMenu[j].Sides[sid]['ItemNumbers']
                                }
                                
                                      
                                if(this.mealSQty != undefined ||   this.mealSQty == '' ){
                                this.printService.writeLine(this.mealSName + this.mealSQty)
                                }
                                else{
                                this.printService.writeLine(this.mealSName )
                                }
                                this.printService
                                .setJustification("right")
                                .writeLine(this.mealSPrice)
                                .setJustification("left")
                               
                            }
                        }
                    }
                    if (this.mealSplreq != ''){
                        if (this.mealSplreq != "NA"){
                            this.printService
                            .setBold(true) 
                            .writeLine("Special Request For Meal")
                            .setBold(false)
                            .writeLine(this.mealSplreq)
                        }
                    }
                  
                    num++
                }


                if (this.mealAddCom != ""){
                    if (this.mealAddCom != undefined){
                        this.printService
                        .setBold(true) 
                        .setUnderline(true)
                        .writeLine("Additional Comments")
                        .setBold(false)
                        .setUnderline(false)
                        .writeLine(this.mealAddCom)
                    }
                }


                this.printService
                .setJustification("right")
                .writeLine(" ")
                .setBold(true) 
                .writeLine("Subtotal")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItemsCompleted[ordId].OrderDetails.Subtotal))


                this.printService
                .setBold(true) 
                .writeLine("Tax")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItemsCompleted[ordId].OrderDetails.Tax))

                if(this.orderedItemsCompleted[ordId].OrderDetails.DeliveryFee > 0){
                    this.printService
                .setBold(true) 
                .writeLine("Delivery Fee")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItemsCompleted[ordId].OrderDetails.DeliveryFee))

                }

                this.printService
                .setBold(true) 
                .writeLine("Tip")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItemsCompleted[ordId].OrderDetails.Tip))

                this.printService
                .setBold(true) 
                .writeLine("Total")
                .setBold(false) 
                this.printService
                .writeLine("$" + this.roundNumber(this.orderedItemsCompleted[ordId].OrderDetails.Total))


                
            }
      
        }
      

        this.printService.cut("full")
        this.printService.flush();
    }

    stopOrders() {
        const d = new Date();
        const n = d.getDay() - 1;
        if (this.pausedOrders == false) {
            this.pausedOrders = true;
            this.updatePausedRestaurant(n, 1);
            this.pauseFor = 'Start accepting orders!';

        } else {
            this.pauseFor = 'Stop accepting orders!';
            this.pausedOrders = false;
            this.updatePausedRestaurant(n, 0);


        }

    }

    async updateDriver(phone, name) {
        for (const x in this.orderedItems) {
            if (this.isUpdated && this.orderedItems[x].OrderDetails.OrderID == this.orderID) {
                this.orderedItems[x]['OrderDetails']["DriverPhone"] = phone;
                this.orderedItems[x]['OrderDetails']["DriverName"] = name;
            }
        }
    }

    async updatePausedRestaurant(day, pauseStatus) {

        const post = {
            Day: day,
            RestaurantID: localStorage.getItem('restaurantID'),
            Paused: pauseStatus,
        };


        this.ordersService.updatePausedRestaurant(post).subscribe(async data => {

            if (data['Success']) {


            } else if (data['Status'] === 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login');
                alert('Updates have been made, please log back in and perform this action.');
            } else {
                if (pauseStatus == false) {
                    this.pauseFor = 'Stop accepting orders!';
                    this.pausedOrders = false;
                } else {
                    this.pauseFor = 'Start accepting orders!';
                    this.pausedOrders = true;
                }

                alert('Could not pause restaurant please call customer support')
            }

        }, () => {
            alert('there was an error pausing your restaurant. please contact support if this is critical.')
        })


    }

    async getTodaysOrders(update) {

        // todo get the order id and check them then just do orderitem [x] = order item boom

        return new Promise(resolve => {
            const post = {
                Token: localStorage.getItem('AuthenticationToken'),
                RestaurantID: this.restaurantIds.toString(),
            };

            this.ordersService.getOrdersTodayWithNewAndInProgress(post).subscribe(async data => {
                data = data[0];
                if (data['Success']) {
                    if (this.orderedItems.length < data['Response'].length && data['Response'].length != 0 || update) {
                        this.noOrders = false;


                        const self = this;

                        if (data['AlertSound']) {
                            self.alertRestaurant();
                        }

                        // tslint:disable-next-line: forin
                        for (const i in data['Response']) {
                            const orders = [];
                            const orderedObject = {};

                            // tslint:disable-next-line: max-line-length
                            data['Response'][i]['AllOrderResult']['PrepareDate'] = (data['Response'][i]['AllOrderResult'].PrepareDate + ' UTC').toString();
                            // tslint:disable-next-line: prefer-const
                            let timeOrdered = (data['Response'][i]['AllOrderResult'].PrepareDate + ' UTC').toString();
                            // tslint:disable-next-line: max-line-length
                            data['Response'][i]['AllOrderResult']['OriginalDate'] = new Date(timeOrdered).toLocaleString('en-CA', { timeZone: 'America/Halifax' });

                            orderedObject['OrderDetails'] = data['Response'][i]['AllOrderResult'];
                            // tslint:disable-next-line: forin


                            // tslint:disable-next-line: forin
                            for (const x in data['Response'][i]['OrderedItems']) {
                                let itemNum = 1
                                for (const index in data['Response'][i]['OrderedItems']) {
                                    if (x !== index) {
                                        if (JSON.stringify(data['Response'][i]['OrderedItems'][x]) === JSON.stringify(data['Response'][i]['OrderedItems'][index]) && data['Response'][i]['OrderedItems'][index]['isDuplicate'] !== '1' && JSON.stringify(data['Response'][i]['OrderSides'][x]) === JSON.stringify(data['Response'][i]['OrderSides'][index])) {
                                            itemNum += 1
                                            data['Response'][i]['OrderedItems'][index]['isDuplicate'] = '1';
                                        }
                                    }
                                }
                                data['Response'][i]['OrderedItems'][x]['ItemNumbers'] = itemNum.toString();
                            };




                            for (let ii = 0; ii < data['Response'][i]['OrderedItems'].length; ii++) {
                                if (data['Response'][i]['OrderedItems'][ii]['isDuplicate'] === '1') {
                                    data['Response'][i]['OrderedItems'].splice(ii, 1);
                                    data['Response'][i]['OrderSides'].splice(ii, 1);
                                    ii--;
                                }
                            }




                            // tslint:disable-next-line: forin
                            for (const x in data['Response'][i]['OrderedItems']) {

                                // GET THE DUPLICATE SIDES.
                                // tslint:disable-next-line: triple-equals
                                for (const sideIndex in data['Response'][i]['OrderSides'][x]) {
                                    // tslint:disable-next-line: forin
                                    for (const sideIndex1 in data['Response'][i]['OrderSides'][x][sideIndex]['Sides']) {
                                        let itemNum = 1
                                        // tslint:disable-next-line: forin
                                        for (const sideIndex2 in data['Response'][i]['OrderSides'][x][sideIndex]['Sides']) {
                                            if (sideIndex1 !== sideIndex2) {
                                                // tslint:disable-next-line: max-line-length
                                                if (JSON.stringify(data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex1]) === JSON.stringify(data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex2])) {
                                                    itemNum += 1
                                                    data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex2]['isDuplicate'] = '1'
                                                }
                                            }
                                        }

                                        data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex1]['ItemNumbers'] = (itemNum * parseInt((data['Response'][i]['OrderedItems'][x]["ItemNumbers"]))).toString();
                                    }
                                }

                                for (const sideIndex in data['Response'][i]['OrderSides'][x]) {
                                    for (let ii = 0; ii < data['Response'][i]['OrderSides'][x][sideIndex]['Sides'].length; ii++) {
                                        if (data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][ii]['isDuplicate'] === '1') {
                                            data['Response'][i]['OrderSides'][x][sideIndex]['Sides'].splice(ii, 1);
                                            ii--;
                                        }

                                    }
                                }




                                const t = {
                                    'Primary': data['Response'][i]['OrderedItems'][x],
                                    'Sides': data['Response'][i]['OrderSides'][x]
                                };
                                orders.push(t);
                            }
                            orderedObject['Order'] = orders;


                            let found = false;

                            for (const x in this.orderedItems) {
                                // tslint:disable-next-line: triple-equals
                                if (this.orderedItems[x].OrderDetails.OrderID == orderedObject['OrderDetails'].OrderID) {

                                    if (update && this.orderedItems[x].OrderDetails.OrderID == this.orderID) {
                                        this.orderedItems[x]['OrderDetails'] = orderedObject['OrderDetails'];
                                        this.orderedItems[x]['Order'] = orderedObject['Order'];
                                    }
                                    found = true
                                }
                            }
                            if (!found) {
                                this.orderedItems.push(orderedObject)
                            }

                        }
                    } else {
                        this.noOrders = true
                    }




                    this.orderedItems.sort((a,b) => a.OrderDetails.OrderStatus - b.OrderDetails.OrderStatus);
                    resolve(true)
                } else if (data['Status'] == 700) {
                    localStorage.clear();
                    this.routes.navigateByUrl('/login');
                    alert('Updates have been made, please log back in and perform this action.');


                } else {
                    resolve(false)
                }

            }, () => {
                alert('there was an error getting todays orders. please contact support if this is critical.')
            });

        });

    }

    async getNewReservations() {

        return new Promise(resolve => {
            const param = {
                RestaurantID: localStorage.getItem('restaurantID'),
                restaurantCheck: '0'
            };

            this.resService.getReservations(param).subscribe(data => {

                // console.log("here in response");
                // console.log(data);

                if (data["Success"]) {
                    if (data["Response"].length != 0) {
                        this.alertRestaurant();
                        this.reservations = [];
                        this.reservations = data["Response"];
                    }
                }
                resolve(true);
            });
        });
    }



    alertRestaurant() {
        $('#alert').show();
        const self = this;
        self.audioOn = true;
        this.soundTimer = setInterval(function () {
            if (self.audioOn) {
                self.audio.play();
            };
        }, 12000);
    };

    stopAlert() {
        $('#alert').hide();
        this.audio.pause();
        this.audioOn = false
        clearInterval(this.soundTimer);
        this.soundTimer = []
    }

    getDateOrderedMessage(date, dateFormatted) {
        const x = new Date().toLocaleDateString('en-US', { timeZone: 'America/Halifax' });

        const n = new Date(x);
        const nTime = new Date();
        const y = n.getFullYear();
        const m = n.getMonth() + 1;
        const d = n.getDate();

        const u = nTime.getHours() + ':' + nTime.getMinutes() + ':' + nTime.getSeconds();

        const currentTime = new Date(m + '/' + d + '/' + y + ', ' + u);

        const seconds = (currentTime.getTime() - new Date(date).getTime()) / 1000;
        const hour = Math.floor(seconds / 3600);
        const minute = Math.floor((seconds - hour * 3600) / 60);

        if (hour < 0 || minute < 0) {
            return (dateFormatted);

        } else {
            return ('Prepare Now');

        }

    }

    getUserExpectsOrderMessage(index) {
        const x = new Date().toLocaleDateString('en-US', { timeZone: 'America/Halifax' });

        const n = new Date(x);
        const nTime = new Date();
        const y = n.getFullYear();
        const m = n.getMonth() + 1;
        const d = n.getDate();

        const u = nTime.getHours() + ':' + nTime.getMinutes() + ':' + nTime.getSeconds();

        const currentTime = new Date(m + '/' + d + '/' + y + ', ' + u);

        const seconds = (currentTime.getTime() - new Date(this.orderedItems[index]['OrderDetails']['StartedPreparing']).getTime()) / 1000;
        const hour = Math.floor(seconds / 3600);
        const minute = Math.floor((seconds - hour * 3600) / 60);

        let estimated_time = this.orderedItems[index]['OrderDetails']['EstimatedTime'];
        if (hour <= 0 && estimated_time - minute > 0) {
            this.orderedItems[index]['OrderDetails']['UserExpect'] = this.orderedItems[index]['OrderDetails']['OrderType'] == 2 ? 'Courier is expected to get the order in '  + (estimated_time - minute) +' minutes.' : 'User expects the order in ' + (estimated_time - minute) +' minutes.';
            return true;

        } else {
            if (hour > 0) {
                this.orderedItems[index]['OrderDetails']['UserExpect'] = this.orderedItems[index]['OrderDetails']['OrderType'] == 2 ? 'Courier expected the prepped order ' + hour + ' hour ' + minute + ' minutes before.' : 'User expected the order ' + hour + ' hour ' + minute + ' minutes before.';
            }
            else {
                this.orderedItems[index]['OrderDetails']['UserExpect'] = this.orderedItems[index]['OrderDetails']['OrderType'] == 2 ? 'Courier expected the prepped order ' + (minute - estimated_time) + ' minutes before.' : 'User expected the order ' + (minute - estimated_time) + ' minutes before.';
            }
            return false;

        }

    }

    getTodaysCompleted() {

        this.getOrdersCompleted()

    }

    async getOrdersCompleted() {

        const post = {
            Token: localStorage.getItem('AuthenticationToken'),
            RestaurantID: this.restaurantIds.toString(),
            OrderStatus: 3,
        };


        this.ordersService.getOrdersTodayURL(post).subscribe(async (data) => {

            data = data[0];
            if (data['Success']) {
                this.orderedItemsCompleted = [];
                for (const i in data['Response']) {
                    const orders = [];
                    const orderedObject = {};
                    for (const x in data['Response'][i]['OrderedItems']) {
                        let itemNum = 1;
                        for (const index in data['Response'][i]['OrderedItems']) {
                            if (x !== index) {
                                if (JSON.stringify(data['Response'][i]['OrderedItems'][x]) === JSON.stringify(data['Response'][i]['OrderedItems'][index]) && data['Response'][i]['OrderedItems'][index]['isDuplicate'] !== '1' && JSON.stringify(data['Response'][i]['OrderSides'][x]) === JSON.stringify(data['Response'][i]['OrderSides'][index])) {
                                    itemNum += 1;

                                    data['Response'][i]['OrderedItems'][index]['isDuplicate'] = '1';
                                }
                            }
                        }
                        data['Response'][i]['OrderedItems'][x]['ItemNumbers'] = itemNum.toString();
                    };

                    for (let ii = 0; ii < data['Response'][i]['OrderedItems'].length; ii++) {
                        if (data['Response'][i]['OrderedItems'][ii]['isDuplicate'] === '1') {
                            data['Response'][i]['OrderedItems'].splice(ii, 1);
                            data['Response'][i]['OrderSides'].splice(ii, 1);

                            ii--;
                        }
                    }

                    const orderTime = new Date(data['Response'][i]['AllOrderResult'].PrepareDate).toLocaleString('en-US', { timeZone: 'America/Halifax' });
                    data['Response'][i]['AllOrderResult']['PrepareDate'] = (data['Response'][i]['AllOrderResult'].PrepareDate + ' UTC').toString();
                    const timeOrdered = (data['Response'][i]['AllOrderResult'].PrepareDate + ' UTC').toString();
                    data['Response'][i]['AllOrderResult']['OriginalDate'] = new Date(timeOrdered).toLocaleString('en-CA', { timeZone: 'America/Halifax' });
                    orderedObject['OrderDetails'] = data['Response'][i]['AllOrderResult'];
                    for (const x in data['Response'][i]['OrderedItems']) {



                        // GET THE DUPLICATE SIDES.
                        // tslint:disable-next-line: triple-equals
                        for (const sideIndex in data['Response'][i]['OrderSides'][x]) {
                            // tslint:disable-next-line: forin
                            for (const sideIndex1 in data['Response'][i]['OrderSides'][x][sideIndex]['Sides']) {
                                let itemNum = 1;
                                // tslint:disable-next-line: forin
                                for (const sideIndex2 in data['Response'][i]['OrderSides'][x][sideIndex]['Sides']) {
                                    if (sideIndex1 !== sideIndex2) {
                                        // tslint:disable-next-line: max-line-length
                                        if (JSON.stringify(data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex1]) === JSON.stringify(data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex2])) {
                                            itemNum += 1;
                                            data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex2]['isDuplicate'] = '1';
                                        }
                                    }
                                }

                                // tslint:disable-next-line: max-line-length
                                data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][sideIndex1]['ItemNumbers'] = (itemNum * parseInt((data['Response'][i]['OrderedItems'][x]["ItemNumbers"]))).toString();
                            }
                        }

                        for (const sideIndex in data['Response'][i]['OrderSides'][x]) {
                            for (let ii = 0; ii < data['Response'][i]['OrderSides'][x][sideIndex]['Sides'].length; ii++) {
                                if (data['Response'][i]['OrderSides'][x][sideIndex]['Sides'][ii]['isDuplicate'] === '1') {
                                    data['Response'][i]['OrderSides'][x][sideIndex]['Sides'].splice(ii, 1);
                                    ii--;
                                }

                            }
                        }

                        const t = { 'Primary': data['Response'][i]['OrderedItems'][x], 'Sides': data['Response'][i]['OrderSides'][x] };
                        orders.push(t);
                    }
                    orderedObject['Order'] = orders;
                    this.orderedItemsCompleted.push(orderedObject);

                }
            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login');
                alert('Updates have been made, please log back in and perform this action.');


            }
        }, () => {
            alert('there was an error getting todays orders. please contact support if this is critical.');
        });
    }


    closeOrder(orderIndex) {
        $('#todaysCompletedOrderModal').modal('show');
        $('#Order-' + orderIndex.toString()).modal('hide');
    }

    showOrder(orderIndex) {
        $('#CompletedOrder-' + orderIndex.toString()).modal('show');
    }

    showNewOrder(orderIndex, date, dateFormatted) {

        ///nick
        this.stopAlert()
        const x = new Date().toLocaleDateString('en-US', { timeZone: 'America/Halifax' });

        const n = new Date(x);
        const nTime = new Date();
        const y = n.getFullYear();
        const m = n.getMonth() + 1;
        const d = n.getDate();

        const u = nTime.getHours() + ':' + nTime.getMinutes() + ':' + nTime.getSeconds();

        const currentTime = new Date(m + '/' + d + '/' + y + ', ' + u);



        const seconds = (currentTime.getTime() - new Date(date).getTime()) / 1000;
        const hour = Math.floor(seconds / 3600);
        const minute = Math.floor((seconds - hour * 3600) / 60);

        // return true

        // if (hour >= -1) {
        //     $('#Order-' + orderIndex.toString()).modal('show');
        // } else {
        //     alert('This order was ordered for a future date, can only open one hour before the date & time');
        // }
        $('#Order-' + orderIndex.toString()).modal('show');
    }

    confirmNewReservation(reservation,reservationId, reservationIndex) {

        this.stopAlert();

        const param = {
            ReservationID: reservationId,
        };
        if (reservation.IdempotencyToken.includes('web') || reservation.IdempotencyToken.includes('android') || reservation.IdempotencyToken.includes('ios')) {


            this.resService.confirmReservation(param).subscribe(data => {

                if (data["Success"]) {
                    this.removeFadeOutReservationItem(document.getElementById('ReservationItem-' + reservationId), reservationIndex);
                }
            });
    
    
          
        }else{

            this.removeFadeOutReservationItem(document.getElementById('ReservationItem-' + reservationId), reservationIndex);

        //     const param2 = {
        //         booking: {
        //             booking_id: reservation.IdempotencyToken,
        //             status: 'CONFIRMED'
        //         },
        //         reservation: {
        //          RestaurantCheck:1
        //         }
        //     };

        // this.resService.cancelReservation(param2).subscribe(data => {

        //     if (data["status"] == "CONFIRMED") {
        //         this.removeFadeOutReservationItem(document.getElementById('ReservationItem-' + reservationId), reservationIndex);

        //         // this.removeFadeOutReservationItem(document.getElementById('ReservationItem-' + reservation.ReservationID), reservationIndex);
        //     }
        //     else {
        //         alert('Something went wrong while confirming the reservation.\nPleasw try again.')

        //     }

        //  });
        }







        

    }

    cancelNewReservation(reservation, reservationIndex) {
        
        this.stopAlert();

        const param = {
            booking: {
                booking_id: reservation.IdempotencyToken,
                status: 'DECLINED_BY_MERCHANT'
            },
            reservation: {
             RestaurantCheck:1
            }
        };

        this.resService.cancelReservation(param).subscribe(data => {

            if (data["status"] == "DECLINED_BY_MERCHANT") {
                this.removeFadeOutReservationItem(document.getElementById('ReservationItem-' + reservation.ReservationID), reservationIndex);
            }
            else {
                alert('Something went wrong while cancelling the reservation.\nPleasw try again.')

            }

        });

    }

    getIntervalInHour(interval) {
        if (interval == 3600) {

            return "1hr";
        } else if (interval == 7200) {

            return "2hr";
        } else {

            return "3hr";
        }
    }


    isValidDate(orderIndex, date, dateFormatted) {

        ///nick

        const x = new Date().toLocaleDateString('en-US', { timeZone: 'America/Halifax' });

        const n = new Date(x);
        const nTime = new Date();
        const y = n.getFullYear();
        const m = n.getMonth() + 1;
        const d = n.getDate();

        const u = nTime.getHours() + ':' + nTime.getMinutes() + ':' + nTime.getSeconds();

        const currentTime = new Date(m + '/' + d + '/' + y + ', ' + u);



        const seconds = (currentTime.getTime() - new Date(date).getTime()) / 1000;
        const hour = Math.floor(seconds / 3600);
        const minute = Math.floor((seconds - hour * 3600) / 60);


        return true
        // if (hour < 0 || minute < 0 ) {
        //     return(dateFormatted);

        // } else {
        //     return('NOW');

        // }
    }


    changeOrderStatus(id, orderIndex, loader, status, userID) {

        this.isFulfillingOrder = true;
        let post = {};
        let message = '';
        if (loader == 'Progress') {
            message = 'Order: ' + id + ' changed from in progress to in queue.';
        } else if (loader == 'Complete') {
            message = 'Order: ' + id + ' changed from completed to in progress.';
        }




        post = {
            Token: localStorage.getItem('AuthenticationToken'),
            OrderID: id,
            Status: status,
            UserID: userID,
            MessageForUser: message,
            RestaurantID: localStorage.getItem('restaurantID')
        };

        this.ordersService.changeOrderStatus(post).subscribe(async data => {
            this.isFulfillingOrder = false;

            data = data[0];
            if (data['Success']) {

                if (loader == 'Progress') {
                    this.removeFadeOut(document.getElementById('ProgressItem-' + id), orderIndex, 'fromProcess');
                } else if (loader == 'Complete') {
                    this.removeFadeOut(document.getElementById('CompleteItem-' + id), orderIndex, 'fromComplete');
                }

            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login')
                alert('Updates have been made, please log back in and perform this action.');
            }
        }, () => {
            alert('there was an error changing the order status. please contact support if this is critical.')
        });
    }



    fulfillOrder(id, time, orderIndex, userID) {
      
        this.isFulfillingOrder = true;
        let post = {}
        if (this.orderedItems[orderIndex]['OrderDetails']['OrderType'] == '2') {
            post = {
                Token: localStorage.getItem('AuthenticationToken'),
                OrderID: id,
                Status: 2,
                RestaurantID: localStorage.getItem('restaurantID'),
                EstimatedTime: time,
                UserID: userID,
                MessageForUser: 'Order: ' + id + ' is being prepared, estimated time: ' + time + ' minutes',
                DeliveryInfo: this.orderedItems[orderIndex]['OrderDetails']
            };
        } else {
            post = {
                Token: localStorage.getItem('AuthenticationToken'),
                OrderID: id,
                Status: 2,
                EstimatedTime: time,
                UserID: userID,
                RestaurantID: localStorage.getItem('restaurantID'),
                MessageForUser: 'Order: ' + id + ' is being prepared, estimated time: ' + time + ' minutes'
            };
        }

        this.ordersService.changeOrderStatusAndTime(post).subscribe(async data => {
            this.isFulfillingOrder = false;

            data = data[0];
            if (data['Success']) {
                this.orderedItems[orderIndex]['OrderDetails']['OrderStatus'] = 2;
                this.orderedItems[orderIndex]['OrderDetails']['EstimatedTime'] = time;
                
                let dateReg = new Date();
                
                let nowNz = moment.utc(dateReg).format('YYYY-MM-DD HH:mm:00');

                this.orderedItems[orderIndex]['OrderDetails']['StartedPreparing'] = nowNz;

                this.orderedItems.sort((a,b) => {

                    if (a.OrderDetails.OrderStatus != b.OrderDetails.OrderStatus) {
                        return a.OrderDetails.OrderStatus - b.OrderDetails.OrderStatus
                    }
                    else if (new Date(a.OrderDetails.Date).getTime() - new Date(b.OrderDetails.Date).getTime() == 0) {
                        return a.OrderDetails.OrderID - b.OrderDetails.OrderID
                    }
                    else {
                        return new Date(a.OrderDetails.Date).getTime() - new Date(b.OrderDetails.Date).getTime()
                    }
                })
                
            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login')
                alert('Updates have been made, please log back in and perform this action.');
            }
        }, () => {
            alert('There was an error changing the order status. please contact support if this is critical.')
        });
       
        this.print(id);
        
    }



    moveItemToProcess(orderIndex) {
        this.orderedItems.splice(orderIndex, 1);
    }

    moveItemToComplete(orderIndex) {

        this.orderedItemsCompleted.push(this.orderedItemsProcess[orderIndex]);
        this.orderedItems.splice(orderIndex, 1);
    }

    moveItemFromComplete(orderIndex) {
        this.orderedItemsCompleted.splice(orderIndex, 1);
    }
    moveItemFromProcess(orderIndex) {
        this.orderedItems.push(this.orderedItemsProcess[orderIndex]);
    }



    removeFadeOut(el, index, move) {

        el.style.transition = 'opacity ' + 1 + 's ease';

        el.style.opacity = 0;
        const self = this;


        setTimeout(function () {
            el.parentNode.removeChild(el);
            if (move == 'toProcess') {
                self.moveItemToProcess(index);
            } else if (move == 'fromProcess') {
                self.moveItemFromProcess(index)
            } else if (move == 'toComplete') {
                self.moveItemToComplete(index)
            } else {
                self.moveItemFromComplete(index)
            }


        }, 1000);
    }

    removeFadeOutCancelledItem(el, index) {

        el.style.transition = 'opacity ' + 1 + 's ease';

        el.style.opacity = 0;
        const self = this;
        setTimeout(function () {
            el.parentNode.removeChild(el);
            self.orderedItems.splice(index, 1);

        }, 700);
    }

    removeFadeOutReservationItem(el, index) {

        el.style.transition = 'opacity ' + 1 + 's ease';

        el.style.opacity = 0;
        const self = this;
        setTimeout(function () {
            el.parentNode.removeChild(el);
            self.reservations.splice(index, 1);

        }, 700);
    }

    removeFadeOutStamp(el, index, id) {

        el.style.transition = 'opacity ' + 1 + 's ease';
        el.style.opacity = 0;

        const self = this;
        setTimeout(function () {
            el.parentNode.removeChild(el);
            self.moveItemToComplete(index);
        }, 700);
    }

    // Complete Order

    completeOrder(event,orderType, id, orderIndex, userID) {

        if (event !== null) {
            event.stopPropagation();
        }

        this.isFulfillingOrder = true;
        let MessageForUser = '';
        if (orderType === 2) {
            MessageForUser = 'Order: ' + id + ', Prepping Completed';
        } else if (orderType === 1) {
            MessageForUser = 'Order: ' + id + ' is Ready!';
        } else {
            MessageForUser = 'Order: ' + id + ' is Ready!';
        }

        const post = {
            Token: localStorage.getItem('AuthenticationToken'),
            OrderID: id,
            OrderType: orderType,
            Status: 3,
            UserID: userID,
            MessageForUser: MessageForUser,
            RestaurantID: localStorage.getItem('restaurantID')
        };

        this.ordersService.changeOrderStatus(post).subscribe(async data => {
            this.isFulfillingOrder = false;

            data = data[0];
            if (data['Success']) {
                this.removeFadeOutStamp(document.getElementById('Item-' + id), orderIndex, id);
            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login')
                alert('Updates have been made, please log back in and perform this action.');
            }
        }, () => {
            alert('there was an error changing the order status. please contact support if this is critical.')
        });
    }


    cancelOrder(id, reason, orderIndex, userID) {

        const post = {
            Token: localStorage.getItem('AuthenticationToken'),
            OrderID: id,
            OrderStatus: 5,
            CancelledInformation: reason,
            UserID: userID,
            MessageForUser: 'Order: ' + id + ' has been cancelled, you will be refunded to the card used. - ' + this.customInfo,
            RestaurantID: localStorage.getItem('restaurantID')

        };


        this.ordersService.cancelOrder(post).subscribe(async data => {
            data = data[0];
            if (data['Success']) {
                this.customInfo = '';
                this.removeFadeOutCancelledItem(document.getElementById('Item-' + id), orderIndex);
            } else if (data['Status'] == 700) {
                localStorage.clear();
                this.routes.navigateByUrl('/login')
                alert('Updates have been made, please log back in and perform this action.');
            }
        }, () => {
            alert('There was an error cancelling the order. please contact support if this is critical.')
        })
    }

    getOrderType(orderType) {
        if (orderType == '1') {
            return 'Dine In'
        } else if (orderType == '2') {
            return 'Delivery'
        } else if (orderType == '3') {
            return 'Pick Up'
        } else if (orderType == '4') {
            return 'error'

        } else if (orderType == '5') {
            return 'Cancelled'

        } else {
            return ''
        }
    }

    roundNumber(num) {
        var number = Math.round(num * 100) / 100;
        return number.toFixed(2);
    }

    toggleRequired() {

        this.allOrdersAtOnce = !this.allOrdersAtOnce;
        let self = this;
        if(this.allOrdersAtOnce) {
            localStorage.setItem('allOrdersAtOnce', 'true');
            this.startAllOrdersAtOnce();
        }
        else {
            localStorage.setItem('allOrdersAtOnce', 'false');
            clearInterval(this.refreshOrdersTimer);
            self.getTodaysOrders(false);
        }
      }

      startAllOrdersAtOnce() {
        let self = this;
        this.refreshOrdersTimer = setInterval(function () {
            self.getTodaysOrders(false);
        }, 120000);
      }


    ngOnDestroy() {
        clearInterval(this.soundTimer);
        this.audioOn = false;
        if (this.audio !== null) {
            this.audio.pause();
            this.audio = null;
        }

        this.onDestroyWasCalled = true;
        for (const x in this.timers) {
            clearInterval(this.timers[x]);
        }
    }

}
