import { Component, OnInit } from '@angular/core';
import { RestaurantService } from '../restaurant/restaurant.service';
import { Router } from '@angular/router';
import { EmpService } from 'app/emp.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { LoginService } from '../login/login.service';
import { PaymentService } from 'app/services/payments/payment.service';


declare var $: any;

@Component({
  selector: 'app-service-online-ordering',
  templateUrl: './service-online-ordering.component.html',
  styleUrls: ['./service-online-ordering.component.css', '../../../assets/css/ts-style.css']
})
export class ServiceOnlineOrderingComponent implements OnInit {

  deliveryType = '';
  link_valid = false;

  subscriptionID = '';
  current_plan: any;
  stripePromise = loadStripe(environment.stripeKey);

  paymentCards = [];
  newCardSelected = false;
  selected_product_price_id = 'price_1NihiCGZUkEjBqahIQLH6PgO';

  toast_message = '';

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }
  
  constructor(private restaurantService: RestaurantService, private router: Router, private emp: EmpService, private loginService: LoginService, private paymentService: PaymentService) { }

  ngOnInit(): void {

    let self = this;

    $(document).ready(function() {
      
      $('#topserve_delivery_service').on('change', function() {
        if ($(this).is(':checked')) {
          $('#own_delivery_service').prop('checked', false);
          self.deliveryType = 'topserve_delivery_service';
        }
      });

      $('#own_delivery_service').on('change', function() {
        if ($(this).is(':checked')) {
          $('#topserve_delivery_service').prop('checked', false);
          self.deliveryType = 'own_delivery_service';
        }
      });

      $('#order_link').on('input', function() {
        var inputValue = $(this).val();
        var sanitizedValue = inputValue.replace(/[^\w]/gi, '');
        $(this).val(sanitizedValue);
        if (sanitizedValue.length > 0) {
          self.checkLinkNameAvailability();
        }
      });

    });

    $(document).on('hidden.bs.modal', function () {
      $('.canvas-modal-backdrop').removeClass('show');
    });

    $('#payment-method-modal').on('shown.bs.modal', function () {
      self.getCards();
    });

    $('#card_number').on('input', function () {
      var cardNumber = $(this).val().replace(/\s/g, ''); // Remove existing spaces
      var formattedCardNumber = self.formatCardNumber(cardNumber);
      $(this).val(formattedCardNumber.slice(0, 19)); // Apply maximum input length of 19 characters
    });

    $('#card_expiry').on('input', function () {
      var expiry = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      var formattedExpiry = self.formatExpiry(expiry);
      $(this).val(formattedExpiry);
    });

    $('#card_cvc').on('input', function () {
      var cvc = $(this).val().replace(/\D/g, ''); // Remove non-numeric characters
      $(this).val(cvc.slice(0, 4));
    });

    this.manageOnlineOrderingContent();
  }

  formatCardNumber(cardNumber) {
    var formattedNumber = cardNumber.replace(/\D/g, ''); // Remove non-numeric characters
    var chunkSize = 4;
    var regex = new RegExp('.{1,' + chunkSize + '}', 'g');
    var formattedChunks = formattedNumber.match(regex);
    if (formattedChunks) {
      return formattedChunks.join(' ');
    }
    return formattedNumber;
  }

  formatExpiry(expiry) {
    expiry = parseInt(expiry[0]) > 1 ? '0' + expiry : expiry = parseInt(expiry.slice(0, 2)) > 12 ? '0' + expiry : expiry;
    var formattedExpiry = expiry.slice(0, 2) + '/' + expiry.slice(2); // Add '/' after first 2 digits
    var slashIndex = formattedExpiry.indexOf('/');
    if (slashIndex !== 2 && slashIndex !== -1) {
      formattedExpiry = formattedExpiry.slice(0, slashIndex) + formattedExpiry.slice(slashIndex + 1);
    } else if (slashIndex === -1 && formattedExpiry.length > 2) {
      formattedExpiry = formattedExpiry.slice(0, 2) + '/' + formattedExpiry.slice(2);
    } else if (slashIndex === 2 && formattedExpiry.length === 3) {
      formattedExpiry = formattedExpiry.slice(0, 2);
    }
    return formattedExpiry.slice(0, 5); // Limit to 'MM/YY' format
  }

  manageOnlineOrderingContent() {

    $('#oo_setup').hide();
    $('#order_link_update').hide();
    $('#link_error_msg').hide();
    $('#order_link_success').hide();
    $('#oo_subscription').hide();
    $('#oo_active').hide();
    $('#btn_oo_enable').html('Enable Online Ordering');

    if (localStorage.getItem('OnlineOrderSubscriptionID') == null|| localStorage.getItem('OnlineOrderSubscriptionID') == '') {
      $('#oo_setup').show();
      
      if (localStorage.getItem('LinkName') != null && localStorage.getItem('LinkName') !== 'null' && localStorage.getItem('LinkName') !== '') {
        $('#order_link_success').show();
        this.link_valid = true;        
      }
      else {
        $('#order_link_update').show();
      }

      if (localStorage.getItem('storeCity') == null || (localStorage.getItem('storeCity').toLowerCase().trim() !== 'charlottetown' &&  localStorage.getItem('storeCity').toLowerCase().trim() !== 'summerside')) {
        $('#topserve_delivery_service').prop('disabled', true);
        $('#oo_subscription').show();
        $('#btn_oo_enable').html('Subscribe & Enable Online Ordering');
      }
      else {
        $('#topserve_delivery_service').prop('disabled', false);
        $('#oo_subscription').hide();
        $('#btn_oo_enable').html('Enable Online Ordering');
      }
    }
    else {
      $('#oo_active').show();
      if (localStorage.getItem('storeCity') == null || (localStorage.getItem('storeCity').toLowerCase().trim() !== 'charlottetown' &&  localStorage.getItem('storeCity').toLowerCase().trim() !== 'summerside')) {
        $('#oo_current_subscription').show();
      }
      else {
        $('#oo_current_subscription').hide();
      }
    }
  }

  async checkLinkNameAvailability() {

    if ($('#order_link').val().toString().toLowerCase() == 'null') {
      $('#link_error_msg').show();
      return;
    }
    
    const post = {
      "link": $('#order_link').val().toString().toLowerCase()
    }

    this.restaurantService.checkLinkAvailability(post).subscribe(data => {
      if (data['Success']) {
        if (data['LinkAvailable']) {
          $('#link_error_msg').hide();
          this.link_valid = true;
        }
        else {
          $('#link_error_msg').show();
          this.link_valid = false;
        }
      }
    });
  }

  async saveOrderLink() {

    const post = {
      "RestaurantID": localStorage.getItem('restaurantID'),
      "LinkName": $('#order_link').val().toString().toLowerCase()
    }

    this.restaurantService.updateLinkNameOfStore(post).subscribe(data => {
      if (data['Success']) {
        localStorage.setItem('LinkName', $('#order_link').val().toString().toLowerCase());
        this.manageOnlineOrderingContent();
      }
      else {
        this.toast_message = 'Something went wrong while saving link name. Please try again!'
        this.showToast(false);
      }
    });
  }
  
  handleOnlineOrderingSession() {
    
    if (localStorage.getItem('LinkName') == null || localStorage.getItem('LinkName') == 'null' || localStorage.getItem('LinkName') == '') {
      this.toast_message = 'Before proceeding with online ordering, please make sure to create a order link in first step.';
      this.showToast(false);
      return;
    }

    if (localStorage.getItem('storeCity') == null || (localStorage.getItem('storeCity').toLowerCase().trim() !== 'charlottetown' &&  localStorage.getItem('storeCity').toLowerCase().trim() !== 'summerside')) {
      this.openCanvasBackdropModal('#payment-method-modal');
    }
    else {
      this.enableOnlineOrdering();
    }
  }

  enableOnlineOrdering() {
    
    $('#progress-modal').modal('show');
    
    const post = {
      'OwnDelivery': this.deliveryType == 'own_delivery_service' ? 1 : 0,
      'TopServeDelivery': this.deliveryType == 'topserve_delivery_service' ? 1 : 0,
      'DeliveryPercentage': this.deliveryType == 'own_delivery_service' ? 5 : 15,
      'RestaurantID': localStorage.getItem('restaurantID'),
      'OnlineOrderSubscriptionID': this.subscriptionID == '' ? 'transfer' : this.subscriptionID
    }

    this.restaurantService.enableOnlineOrdering(post).subscribe(data => {
      $('#progress-modal').modal('hide');
      if (data['Success']) {
        if (data['OrderTypes'].length > 0) {
          
          localStorage.setItem('OnlineOrderSubscriptionID', post['OnlineOrderSubscriptionID']);
          localStorage.setItem('restaurantOrderTypeIDs', data["OrderTypes"][0]["OrderTypeIDs"]);
          localStorage.setItem('restaurantOrderTypeNames', data["OrderTypes"][0]["OrderTypeNames"]);
          localStorage.setItem('restaurantOrderTypeStatuses', data["OrderTypes"][0]["OrderTypeStatuses"]);
          localStorage.setItem('restaurantOrderTypePercentages', data["OrderTypes"][0]["OrderTypePercentages"]);
          localStorage.setItem('restaurantType', '0');

          this.manageOnlineOrderingContent();
          this.toast_message = 'Thank you for activating online ordering with TopServe.';
          this.showToast(true);
          this.emp.notifyServicesNavbar(true);
        }
        else {
          alert('Online Ordering has been successfully activated. However, there seems to be an issue in retrieving the response. To ensure the changes are updated, kindly log out and log back in.');
          this.router.navigateByUrl('/logout');
        }
      }
      else {
        this.toast_message = 'Something went wrong while activating your online ordering.\nPlease contact us at support@topserve.ca';
        this.showToast(false);
      }
    });
  }

  getCards() {

    this.paymentCards = [];

    const stripeID = localStorage.getItem('restaurantStripeCustomerID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const post = {
      customer_id: stripeID
    };


    this.loginService.getPaymentMethods(post).subscribe(
      data => {

        if (data['success']) {
          let cardData = data['response']['data'];
          if (cardData.length > 0) {
            this.newCardSelected = false;
            for (let i in cardData) {
              let card = cardData[i]['card'];
              card['selected'] = false;
              card['id'] = cardData[i]['id'];
              card['brand'] = card['brand'].toString().substring(0, 1).toUpperCase() + card['brand'].toString().substring(1);
              if (card['id'] == data['response']['default_payment_method']) {
                card['selected'] = true;
              }
              this.paymentCards.push(card);
            }
          }
          else {
            this.newCardSelected = true;
          }
        }
        else {
          alert('Could not get cards! Please try again later!');
        }
      });

  }

  openCanvasBackdropModal(id) {
    $(id).modal('show');
    $('.canvas-modal-backdrop').addClass('show');
  }

  validateCardDetails(): boolean {

    if (!this.newCardSelected) {
      let selected = false;
      this.paymentCards.filter(card => {
        if (card.selected) {
          selected = true;
        }
      })
      return !selected;
    }
    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    // Get input values
    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const cardCvc = cardCvcInput.val()?.toString();

    // Validate card number
    if (cardNumber && cardNumber.length !== 16) {
      return true;
    }

    // Validate card expiry
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;

    if (
      !cardExpiryMonth ||
      !cardExpiryYear ||
      isNaN(cardExpiryMonth) ||
      isNaN(cardExpiryYear) ||
      cardExpiryMonth < 1 ||
      cardExpiryMonth > 12 ||
      cardExpiryYear < currentYear ||
      (cardExpiryYear === currentYear && cardExpiryMonth < currentMonth)
    ) {
      return true;
    }

    // Validate card CVC
    if (!cardCvc || isNaN(parseInt(cardCvc)) || cardCvc.length < 3 || cardCvc.length > 4) {
      return true;
    }

    // All validations passed
    return false;
  }

  changeDefaultCard(i: number) {
    if (i == -1) {
      this.newCardSelected = true;
      this.paymentCards.filter(card => card.selected = false);
    }
    else {
      const post = {
        customer_id: localStorage.getItem('restaurantStripeCustomerID'),
        payment_method_id: this.paymentCards[i]['id']
      }

      this.paymentService.updateDefaultPaymentMethod(post).subscribe(async data => {
        if (data['success']) {
          this.paymentCards.filter(i => i.selected = false);
          this.paymentCards[i]['selected'] = true;
          this.newCardSelected = false;
        }
        else {
          alert('Something went wrong, please try again later!!');
          this.router.navigateByUrl('/logout');
        }
      });
    }
  }

  async handleSubscription() {
    if (this.newCardSelected) {
      this.CreatePaymentMethod();
    }
    else {
      this.purchaseSubscription();
    }
  }

  async purchaseSubscription() {
    
    if (this.selected_product_price_id == '') {
      return;
    }
    
    $('#progress-modal').modal('show');
    $('#payment-method-modal').modal('hide');

    const post = {
      customer_id: localStorage.getItem('restaurantStripeCustomerID'),
      price_id: this.selected_product_price_id,
      RestaurantID: localStorage.getItem('restaurantID')
    }

    this.paymentService.createSubscription(post).subscribe(async data => {
      if (data['Success']) {
        this.subscriptionID = data['Response']['id'];
        this.enableOnlineOrdering();
      }
      else if (data['Error'] != null) {
        $('#progress-modal').modal('hide');
        this.toast_message = data['Error']['message'];
        this.showToast(false);
      }
      else {
        const subscription = data['Response'];
        const paymentIntent = subscription['payment_intent'];
        let self = this;
        (await this.stripePromise).confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentIntent.payment_method,
        })
          .then(function (result) {
            if (result.paymentIntent) {
              if (result.paymentIntent.status == 'succeeded') {
                self.subscriptionID = subscription.id;
                self.enableOnlineOrdering();
              }
              else {
                $('#progress-modal').modal('hide');
                self.toast_message = "Something went wrong! Please contact TopServe at support@topserve.ca";
                self.showToast(false);
              }
            }
            else {
              $('#progress-modal').modal('hide');
              self.toast_message = result.error.message;
              self.showToast(false);
            }
          });
      }
    });
  }

  async CreatePaymentMethod() {

    const stripeID = localStorage.getItem('restaurantStripeCustomerID');

    if (stripeID === undefined || stripeID == null || stripeID === '') {
      return;
    }

    const cardNumberInput = $('#card_number');
    const cardExpiryInput = $('#card_expiry');
    const cardCvcInput = $('#card_cvc');

    const cardNumber = cardNumberInput.val()?.toString().replace(/\s/g, '');
    const cardExpiryMonth = cardExpiryInput.val()?.toString().split('/')[0] || undefined;
    let cardExpiryYear = cardExpiryInput.val()?.toString().split('/')[1] || undefined;
    const currentYear = new Date().getFullYear();
    cardExpiryYear = cardExpiryYear ? parseInt(currentYear.toString().slice(0, 2) + cardExpiryYear.toString()) : undefined;
    const cardCvc = cardCvcInput.val()?.toString();


    const post = {
      customer_id: stripeID,
      card_number: cardNumber,
      exp_month: cardExpiryMonth,
      exp_year: cardExpiryYear,
      cvc: cardCvc
    };

    await this.paymentService.createAndAttachPaymentMethod(post).subscribe(async data => {

      if (data['Success']) {
        this.purchaseSubscription();
      }
      else {
        alert('Something went wrong, please try again later!!');
        this.router.navigateByUrl('/logout');
      }
    });

  }

  async cancelSubscription() {

    const post = {
      RestaurantID: localStorage.getItem('restaurantID'),
      subscriptionID: localStorage.getItem('OnlineOrderSubscriptionID'),
      Type: 'OnlineOrder'
    }

    await this.paymentService.cancelSubscription(post).subscribe(async data => {
      if (data['Success']) {
        this.subscriptionID = '';
        localStorage.removeItem('OnlineOrderSubscriptionID');
        this.manageOnlineOrderingContent();
        this.toast_message = 'Your online ordering subscription has been canceled.';
        this.showToast(true);
        this.emp.notifyServicesNavbar(true);
      }
      else {
        // this.subscriptionCancelMsg = data['message'] + ' Please contact support!';
        this.toast_message = data['message'] + ' Please contact support!';
        this.showToast(false);
      }
    })
  }

  showToast(success) {
    if (success) {
      $('#online_ordering_toast').addClass('show');
      setTimeout(() => {
        $('#online_ordering_toast').removeClass('show');
      }, 2000);
    }
    else {
      $('#online_ordering_error_toast').addClass('show');
    }
  }

  copyIframeSnippet(): void {
    const codeSnippet = document.querySelector('#iframe_code');
    const code = codeSnippet.textContent.trim();
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code snippet copied to clipboard!');
      })
      .catch((error) => {
        console.error('Unable to copy code snippet:', error);
      });
  }

  socialLink(): string {
    let link = 'https://topserve.ca/order/';
    link += encodeURIComponent(localStorage.getItem('LinkName'));
    return link;
  }

  copyLinkSnippet(): void {
    const codeSnippet = document.querySelector('#social_link_code');
    const code = codeSnippet.textContent.trim();
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code snippet copied to clipboard!');
      })
      .catch((error) => {
        console.error('Unable to copy code snippet:', error);
      });
  }

}
