import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductContactlessComponent } from './structure/products/product-contactless/product-contactless.component';
import { LandingComponent } from './structure/landing/landing.component';
import { ProductReservationsComponent } from './structure/products/product-reservations/product-reservations.component';
import { OnlineOrderingComponent } from './structure/online-ordering/online-ordering.component';
import { CustomerMarketingComponent } from './structure/customer-marketing/customer-marketing.component';
import { ForRestaurantsComponent } from './structure/forRestaurants/for-restaurants/for-restaurants.component';
import { StoreSignUpComponent } from './structure/restaurant/store-sign-up/store-sign-up.component';
import { FarmSignUpComponent } from './structure/farm/farm-sign-up/farm-sign-up.component';
import { DriverSignupComponent } from './structure/driver/driver-signup/driver-signup.component';
import { DriverLoginComponent } from './structure/driver/driver-login/driver-login.component';
import { LoginComponent } from './structure/login/login.component';
import { FarmLoginComponent } from './structure/farm/farm-login/farm-login.component';
import { TopLoginComponent } from './structure/top-login/top-login.component';
import { PrivacyComponent } from './structure/TOS/privacy/privacy.component';
import { DriverAgreementComponent } from './structure/driver-agreement/driver-agreement.component';
import { TablereservationComponent } from './structure/tablereservation/tablereservation.component';
import { FindReservationComponent } from './structure/find-reservation/find-reservation.component';
import { UserProfileComponent } from './components/user-profile/user-profile/user-profile.component';
import { LogoutComponent } from './structure/login/logout/logout.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ShowDriversComponent } from './structure/driver/show-drivers/show-drivers.component';
import { DeliveryDashboardComponent } from './structure/driver/delivery-dashboard/delivery-dashboard.component';
import { ServicesNavigationComponent } from './components/services-navigation/services-navigation.component';
import { RoleguardService } from './services/roleguard.service';
import { DashboardsAlpha } from './structure/dashboards/alpha.page';
import { OrderComponent } from './structure/order/order.component';
import { OrderCartComponent } from './structure/order-cart/order-cart/order-cart.component';
import { ExpenditureHelpComponent } from './structure/expenditure/expenditure-help/expenditure-help.component';
import { ExpenditureDetailsComponent } from './structure/expenditure/expenditure-details/expenditure-details.component';
import { ExpenditureComponent } from './structure/expenditure/payouts/expenditure.component';
import { IncomingOrdersComponent } from './structure/orders/incoming-orders/incoming-orders.component';
import { CreateRestaurantOrderComponent } from './structure/create-restaurant-order/create-restaurant-order.component';
import { OrderRefundComponent } from './structure/order-refund/order-refund.component';
import { PastOrdersComponent } from './structure/orders/past-orders/past-orders.component';
import { OrderTrackComponent } from './structure/order-track/order-track.component';
import { ResetpasswordComponent } from './structure/resetpassword/resetpassword.component';
import { DynamicMenuComponent } from './structure/dynamic-menu/dynamic-menu.component';
import { DeliveryExpenditureComponent } from './structure/driver/delivery-expenditure/delivery-expenditure.component';
import { ProfileComponent } from './structure/profile/profile/profile.component';
import { MasterAccountComponent } from './structure/master-account/master-account.component';
import { TodaysOrdersComponent } from './structure/todays-orders/todays-orders.component';
import { FindOrderComponent } from './structure/find-order/find-order.component';
import { DriverShiftComponent } from './structure/driver-shift/driver-shift.component';
import { AddShiftComponent } from './structure/add-shift/add-shift.component';
import { FarmPaymentsComponent } from './structure/farm/farm-payments/farm-payments.component';
import { ShowAdminComponent } from './structure/admin/show-admin/show-admin.component';
import { ShowItemsComponent } from './structure/menu/show-items/show-items.component';
import { PauseMenuModifiersComponent } from './structure/pause-menu-modifiers/pause-menu-modifiers.component';
import { AddItemComponent } from './structure/menu/add-item/add-item.component';
import { EditItemComponent } from './structure/menu/edit-item/edit-item.component';
import { MenuViewComponent } from './structure/menu-view/menu-view.component';
import { PauseCategoryComponent } from './structure/pause-category/pause-category.component';
import { ShowRestaurantComponent } from './structure/restaurant/show-restaurant/show-restaurant.component';
import { ShowDetailsComponent } from './structure/restaurant/show-details/show-details.component';
import { AddRestaurantComponent } from './structure/restaurant/add-restaurant/add-restaurant.component';
import { PauseRestaurantComponent } from './structure/restaurant/pause-restaurant/pause-restaurant.component';
import { PrinterSetupComponent } from './structure/printer-setup/printer-setup.component';
import { PublishPromotionsComponent } from './structure/publish-promotions/publish-promotions.component';
import { ViewSubscribersComponent } from './structure/view-subscribers/view-subscribers.component';
import { SquareIntegrationComponent } from './structure/square-integration/square-integration.component';
import { ReservationManagerComponent } from './structure/reservations/reservation-manager/reservation-manager.component';
import { ReservationComponent } from './structure/reservations/reservations/reservations.component';
import { PastReservationsComponent } from './structure/reservations/past-reservations/past-reservations.component';
import { PageNotFoundComponent } from './structure/page-not-found/page-not-found.component';
import { GoogleReservationsComponent } from './structure/google-reservations/google-reservations.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'landing', redirectTo: '', pathMatch: 'full' },
  { path: 'incoming-orders', redirectTo: '/services/incoming-orders', pathMatch: 'full' },
  { path: 'table-ordering', component: ProductContactlessComponent },
  { path: 'google-reservations', component: ProductReservationsComponent },
  { path: 'reservations', component: GoogleReservationsComponent },
  { path: 'online-ordering', component: OnlineOrderingComponent },
  { path: 'customer-marketing', component: CustomerMarketingComponent },
  { path: 'delivery-takeout', component: ForRestaurantsComponent },
  { path: 'dynamic-menu', component: DynamicMenuComponent },
  { path: 'driver-signup', component: DriverSignupComponent },
  { path: 'driver-login', component: DriverLoginComponent },
  { path: 'store-signup', component: StoreSignUpComponent },
  { path: 'farm-signup', component: FarmSignUpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'top-login', component: TopLoginComponent },
  { path: 'farm-login', component: FarmLoginComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'driver-agreement', component: DriverAgreementComponent },
  { path: 'table-reservation', component: TablereservationComponent },
  { path: 'find-reservation/:res-info', component: FindReservationComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'find-restaurants/:city', component: OrderComponent },
  { path: 'search/:city', component: OrderComponent },
  { path: 'restaurants/:city', component: OrderCartComponent },
  { path: 'order', component: OrderComponent },
  { path: 'order/:city', component: OrderCartComponent },
  { path: 'order-cart', component: OrderCartComponent },
  { path: 'order/:city/table/:number', component: OrderCartComponent },
  { path: 'order-track/:number', component: OrderTrackComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'services',
    component: ServicesNavigationComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        canActivate: [RoleguardService],
        component: DashboardsAlpha,
      },
      {
        path: 'alpha',
        canActivate: [RoleguardService],
        component: DashboardsAlpha,
      },
      {
        path: 'profile',
        canActivate: [RoleguardService],
        component: ProfileComponent,
      },
      {
        path: 'delivery-dashboard',
        component: DeliveryDashboardComponent,
      },
      {
        path: 'delivery-expenditure',
        //  canActivate: [DriverGuardService],
        component: DeliveryExpenditureComponent,
      },
      {
        path: 'master-account',
        //   canActivate: [DriverGuardService],
        component: MasterAccountComponent,
      },
      {
        path: 'todays-orders',
        //   canActivate: [DriverGuardService],
        component: TodaysOrdersComponent,
      },
      {
        path: 'find-order',
        //   canActivate: [DriverGuardService],
        component: FindOrderComponent,
      },
      {
        path: 'show-drivers',
        //   canActivate: [DriverGuardService],
        component: ShowDriversComponent,
      },
      {
        path: 'driver-shift',
        //   canActivate: [DriverGuardService],
        component: DriverShiftComponent,
      },
      {
        path: 'add-shift',
        //   canActivate: [DriverGuardService],
        component: AddShiftComponent,
      },
      {
        path: 'expenditure',
        canActivate: [RoleguardService],
        component: ExpenditureComponent,
      },
      {
        path: 'expenditure-help',
        canActivate: [RoleguardService],
        component: ExpenditureHelpComponent,
      },
      {
        path: 'expenditure-details',
        canActivate: [RoleguardService],
        component: ExpenditureDetailsComponent,
      },
      {
        path: 'incoming-orders',
        component: IncomingOrdersComponent,
      },
      {
        path: 'request-driver',
        component: CreateRestaurantOrderComponent,
      },
      {
        path: 'past-orders',
        component: PastOrdersComponent,
      },
      {
        path: 'order-refund',
        component: OrderRefundComponent,
      },
      {
        path: 'farm-earnings',
        component: FarmPaymentsComponent,
      },
      {
        path: 'show-admin',
        canActivate: [RoleguardService],
        component: ShowAdminComponent,
      },
      {
        path: 'show-items',
        canActivate: [RoleguardService],
        component: ShowItemsComponent,
      },
      {
        path: 'pause-menu-modifiers',
        canActivate: [RoleguardService],
        component: PauseMenuModifiersComponent,
      },
      {
        path: 'add-item',
        canActivate: [RoleguardService],
        component: AddItemComponent,
      },
      {
        path: 'edit-item',
        canActivate: [RoleguardService],
        component: EditItemComponent,
      },
      {
        path: 'menu-view',
        canActivate: [RoleguardService],
        component: MenuViewComponent,
      },
      {
        path: 'pause-category',
        component: PauseCategoryComponent,
      },
      {
        path: 'add-restaurant',
        canActivate: [RoleguardService],
        component: AddRestaurantComponent,
      },
      {
        path: 'show-restaurant',
        canActivate: [RoleguardService],
        component: ShowRestaurantComponent,
      },
      {
        path: 'show-restaurant-details/:id',
        canActivate: [RoleguardService],
        component: ShowDetailsComponent,
      },
      {
        path: 'pause-restaurant',
        canActivate: [RoleguardService],
        component: PauseRestaurantComponent,
      },
      {
        path: 'printer-setup',
        component: PrinterSetupComponent,
      },
      {
        path: 'reservations',
        component: ReservationComponent,
      },
      {
        path: 'reservation-manager',
        component: ReservationManagerComponent,
      },
      {
        path: 'past-reservations',
        component: PastReservationsComponent,
      },
      {
        path: 'publish-promotions',
        canActivate: [RoleguardService],
        component: PublishPromotionsComponent,
      },
      {
        path: 'view-subscribers',
        canActivate: [RoleguardService],
        component: ViewSubscribersComponent,
      },
      {
        path: 'square-integration',
        canActivate: [RoleguardService],
        component: SquareIntegrationComponent,
      },
    ]
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
