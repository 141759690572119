import { Component, OnInit } from '@angular/core';
import {AdminService} from "../admin/admin.service";
import {RegisterService} from "../register/register.service";
import {MasterAccountService} from "./master-account.service";
import {EmpService} from "../../emp.service";
declare var $: any;

@Component({
  selector: 'app-master-account',
  templateUrl: './master-account.component.html',
  styleUrls: ['./master-account.component.css']
})
export class MasterAccountComponent implements OnInit {
  restaurantID = 0;
  userType = 0;
  partners = [];
  tempUsers = [];
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  restaurantName = '';
  passwordConfirmed = '';
  restaurantNames = [];
  restaurantIds = [];
  phone = '';
  newResID = '';
  keyword = '';
  newCityName = '';

  restaurants = [];
  subrestaurants = [];
  selectedRestaurants = [];
  topServeRestaurants = [];
  topServeCities = [];
  newResName = '';


   constructor(private emp:EmpService,private admin: AdminService, private registerService: RegisterService, private masterServices: MasterAccountService) {
  }

  async ngOnInit() {
    //  $('.dropdown-toggle').dropdown()

    let self = this;
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    let self0 = self;
    var validation = Array.prototype.filter.call(forms, function (form) {
      let self1 = self0;
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {


          if (event.target.id == "register") {
            self1.addTeamMember()
          }
        }
        form.classList.add('was-validated');
      }, false);
    });


    this.getCities();

    $(".dropdown-menu li").click(function () {

      $(this).parents(".btn-group").find('.selection').text($(this).text());
      $(this).parents(".btn-group").find('.selection').val($(this).text());

    });

    await this.getUsers()




    //dropdown



    // $("#selectRestaurant").select2({
    //   data: dataPrim,
    //   multiple:true,
    //   tags: true,
    // });




    // $('#selectRestaurant').on('select2:select', (e) => {
    //   //  var data = e.params.data.text;
    //   // let categoryFiltered = this.primaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);

    
    //   // this.selectedPrimaryCategories.push({"Name":e.params.data.text})
      
    // });


    // $('#selectedCateselectRestaurantgory').on('select2:unselect', (e) => {
    //   // for(let n in this.selectedPrimaryCategories){
    //   //   if(e.params.data.text == this.selectedPrimaryCategories[n].Name){
    //   //     this.selectedPrimaryCategories.splice(parseInt(n),1)
    // //    }
    // //  }
    // });









  }



  selectedDropCityNew(resIndex){
    this.topServeRestaurants = [];
    this.newCityName = this.topServeCities[resIndex].Name
    localStorage.setItem('newCityName', this.newCityName);
    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      City: this.newCityName,
    };

    this.masterServices.getRestaurantsForMaster(post).subscribe(
        data => {
          for(let res in data["Restaurants"]){
            this.newResName =  data["Restaurants"][res]["Name"];
            this.newResID = data["Restaurants"][res]["RestaurantID"];
            this.topServeRestaurants.push( data["Restaurants"][res]);
          }
        }
    );




  }

  getCities() {

    const post = {
      Token:'adsf'
    };

    
    this.masterServices.getCities(post).subscribe(
      data => {
        for(let city in data["Cities"]){
          this.newCityName =  data["Cities"][city]["Name"];
         this.topServeCities.push( data["Cities"][city]);
        }

        const post = {
          Token:localStorage.getItem('AuthenticationToken'),
          Lat:this.topServeCities[this.topServeCities.length - 1]["Lat"],
          Long:this.topServeCities[this.topServeCities.length - 1]["Long"]
        };

        this.masterServices.getRestaurants(post).subscribe(
            data => {
              this.topServeRestaurants = [];
              for(let res in data["Restaurants"]){
                this.newResName =  data["Restaurants"][res]["Name"];
                this.newResID = data["Restaurants"][res]["RestaurantID"];
                this.topServeRestaurants.push( data["Restaurants"][res]);
              }
            }
        );

      }
  );

  }


 
  

  addRestaurant(userindex) {


    var partnerID =  this.emp.loggedInDriver["PartnerID"];
      const post = {
        Token:localStorage.getItem('AuthenticationToken'),
        RestaurantID:this.selectedRestaurants,
        PartnerID:this.partners[userindex]["PartnerID"],

      };
console.log(JSON.stringify(post));
      

      this.masterServices.addRestaurantToPartner(post).subscribe(
          data => {

            if(data["Success"]) {
             // $('#registerError').show();
            //  document.getElementById("registerError").innerHTML =  "Restaurant Added";
     //        $('#addRestauant' + userindex.toString()).modal('toggle');
             $('#addRestauant-' + userindex.toString()).modal('hide');

            //  this.getRestaurants();
            }else{
              $('#registerError').show();
              document.getElementById("registerError").innerHTML = data['Message'];

            }
          })
    
  }


  async getUsers() {


    ///get the users
    this.partners = [];
    this.tempUsers = [];
    let partnerID = localStorage.getItem('PartnerID');
    if (partnerID != null || partnerID != undefined) {

      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        PartnerID:partnerID
      };


      await this.masterServices.getPartners(post).subscribe(async data => {
        if (data["Success"]) {
          for (let partner in data["Response"]) {


            this.tempUsers.push({
              "Name": data["Response"][partner]["Partner"],
              "PartnerID": data["Response"][partner]["PartnerID"],
              "Editing":false,
            })



            this.partners.push({
              "Name": data["Response"][partner]["Partner"],
              "PartnerID": data["Response"][partner]["PartnerID"],
              "Editing":false,
            })


          //  this.getRestaurantsForSubDriver(subDrive);

          }


        }
      })//end of subscriib
    }else{

    }
  }

  getRestaurantsForPartner(index,city) {

    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      PartnerID: this.partners[index]["PartnerID"],
    };

    this.masterServices.getRestaurantsForPartner(post).subscribe(
        data => {
            var subres = [];
            for (let resIndex in data["Restaurants"]) {
              subres.push(data["Restaurants"][resIndex])
            }
            this.partners[index]["Restaurants"] = subres
        })
  }


  selectedDropDownRestaurantNew(resIndex,userIndex) {
    this.newResName = this.topServeRestaurants[resIndex].Name;
    this.newResID = this.topServeRestaurants[resIndex].RestaurantID
    let values = $('#restaurantSelected-' + userIndex).val();
    this.selectedRestaurants = values
    

  }

  selectedDropDownRestaurant(resIndex, userIndex) {
    this.partners[userIndex].RestaurantName = this.restaurantNames[resIndex]


  }

  selectedDropDownNew(userType) {
    this.userType = userType;
  }

  selectedDropDown(selectedUserType, userIndex) {
    this.partners[userIndex].UserType = selectedUserType
    //  this.userType = selectedUserType

  }


  cancelUser(userIndex) {


    this.partners[userIndex] = this.tempUsers[userIndex];
    this.partners[userIndex].Editing = false;
    var userEditingBtn = $("#userBtn" + userIndex);
    var userEditing = $("#user" + userIndex);

    userEditingBtn.addClass('edit');
    userEditingBtn.removeClass('saving');
    userEditing.addClass('icmn-pencil');
    userEditing.removeClass('icmn-upload');
  }

  async editUser(userIndex) {

    var userEditing = $("#user" + userIndex);
    var userEditingBtn = $("#userBtn" + userIndex);

    if (this.partners[userIndex].Editing) {
      userEditing.addClass('icmn-pencil');
      userEditing.removeClass('icmn-upload');
      userEditingBtn.addClass('edit');
      userEditingBtn.removeClass('saving');
    } else {
      userEditingBtn.addClass('saving');
      userEditingBtn.removeClass('edit');
      userEditing.removeClass('icmn-pencil');
      userEditing.addClass('icmn-upload');
    }


    // if (this.subDrivers[userIndex].Editing) {



    //   const post = {
    //     Token: localStorage.getItem('AuthenticationToken'),
    //     FirstName: this.subDrivers[userIndex].Name,
    //     Phone: this.subDrivers[userIndex].Phone,
    //     SubDriverID: this.subDrivers[userIndex].SubDriverID
    //   };



    //   await this.masterServices.updateSubDriver(post).subscribe(async data => {
    //     if (data["Success"]) {
    //       alert("Updated")
    //     } else {
    //       alert("Something went wrong, did not update")
    //     }



    //   });//end of subscriib

    //   this.tempUsers[userIndex] = this.subDrivers[userIndex];
    // }

    this.partners[userIndex].Editing = !this.partners[userIndex].Editing;

  }

  makeRandom() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789%$@#&*)(!';
    for (let i = 0; i < 40; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  addTeamMember() {

    // this.users.push({"Name":"","Email":"","RestaurantName":"","UserType":1,"Editing":true});
    // let index = this.users.length;
    // var userEditingBtn =  $("#userBtn" + index.toString());
    // userEditingBtn.addClass('saving');
    // userEditingBtn.removeClass('edit');
    //

    let driverID = localStorage.getItem('DriverID');
    if (driverID != null || driverID != undefined) {


      const post = {
        Phone: this.phone,
        FirstName: this.firstName,
        LastName: this.lastName,
        DriverID: driverID
      };


      this.masterServices.addSubDriver(post).subscribe(data => {
        if (data["userAdded"]) {
          this.getUsers();
        } else {
          alert("Something went wrong, may not have updated")
        }
      })

    } else {
      alert("Something went wrong, may not have updated")
    }


  }

  async deleteUser(userIndex) {


    const post = {
      Token: localStorage.getItem('AuthenticationToken'),
      SubDriverID: this.partners[userIndex].SubDriverID,
    };


    await this.masterServices.deleteSubDriver(post).subscribe(async data => {
      if (data["Success"]) {
        this.partners.splice(parseInt(userIndex), 1);
        alert("Deleted");

      } else {
        alert("Something went wrong, did not delete");
      }


    });//end of subscriib

  }

}

