import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  Directive, Injectable,
  OnDestroy,
  OnInit,
  ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuService } from '../menu.service';
const AWS = require('aws-sdk');
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { ViewChild } from "@angular/core";
import { AddCategoryComponent } from "../../../components/add-category/add-category.component";
import { AddFullOptionComponent } from "../../../components/add-full-option/add-full-option.component";
import { Url } from "../../../models/url";
import { EmpService } from "../../../emp.service";
import { environment } from 'environments/environment.prod';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css', '../../../../assets/css/custom.css'],
  encapsulation: ViewEncapsulation.None,
})

export class AddItemComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild('addCategoryContainer', { read: ViewContainerRef }) addCategoryContainer;
  @ViewChild('addOptionContainer', { read: ViewContainerRef }) addOptionContainer;
  @ViewChild('addFullOptionContainer', { read: ViewContainerRef }) addFullOptionContainer;

  public mains = ['Lunch', 'Dinner', 'Breakfast', 'Supper'];
  public sides = ['Salades'];
  public chosenMains = [];
  name: any;
  category: string[] = [];
  subCategory: string[] = [];
  selectedIngredients = [];
  shortDesc: any;
  desc: any;
  menuItemPrice: any;
  menuItemID = "na";
  selectedFile: any;
  menuFile: any;
  menuItemName: any;
  menuItemIngredients = "";
  menuItemDescription = "";
  discountPrice: any;
  menuImageUrl: any;
  fileInput: any;
  image = '';
  itemAdded = false;
  loading = false;
  primaryCat: any;
  secondaryCat: any;
  optionCategorys: string[] = [];
  optionName: string[] = [];
  optionDesc: string[] = [];
  optionPrice: string[] = [];
  optionCategory: string[] = [];
  categoryIndexes: number = 0;
  targetRestaurant: any;
  restaurant: any;
  menuImage: any;
  selectedPrimaryCategories = [];
  selectedSubCategories = [];
  selectedCategoryArray = [];
  menuItemOptions = [];
  menuSecondaryCategoriesFiltered = [];
  categoryIndex = 0;
  optionCategoryIndex = 0;
  addedCategories = [];
  addedOptions = [];
  mainMenu: any;
  tempFalse = false;
  allDay: boolean;
  servedStart = '00:00';
  servedEnd = '00:00';
  addAnotherItemClicked = false;
  addDuplicateItemClicked = false;
  hideInsertSuccessMsg = true;
  option = [
    {
      'option0': this.optionCategory,
      'option1': this.optionName,
      'option2': this.optionDesc,
      'option3': this.optionPrice,
    }
  ];

  opt: any;

  menuItemToEdit: any;
  constructor(
    private route: ActivatedRoute,
    private menuService: MenuService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private url: Url,
    private emp: EmpService,
    private meta: Meta) {
    meta.updateTag({ name: 'description', content: 'TopServe Add item to your delivery restaurant menus in charlottetown' });
    meta.updateTag({ name: 'robots', content: 'INDEX, FOLLOW' });
    meta.updateTag({ name: 'author', content: 'TopServe' });
    meta.updateTag({ name: 'keywords', content: 'TopServe, Deliveries, PEI charlottetown,Add item' });
    meta.updateTag({ property: 'og:title', content: "Add Item" });
  }

  ngAfterContentInit(): void {
  }

  async ngOnInit() {
    const forms = document.getElementsByClassName('needs-validation');
    const self1 = this;

    const validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          self1.updateItem();
        }
        form.classList.add('was-validated');
      });
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      $('#insert-success-msg').hide();
    })

    await this.getOptionCategories();
    await this.getCategory();
    let dataSub = [];
    let dataPrim = [];

    this.secondaryCat.forEach(async (item, n) => {
      dataSub.push({
        id: n,
        text: item["Name"],
        "selected": false
      })
    });

    this.primaryCat.forEach(async (item, n) => {
      dataPrim.push({
        id: n,
        text: item["Name"],
        "selected": false
      })
    });

    $("#subCategory").select2({
      data: dataSub,
      multiple: true,
      tags: true,
    });

    $("#selectedIngredients").select2({
      multiple: true,
      tags: true,
    });

    $("#selectedCategory").select2({
      data: dataPrim,
      multiple: true,
      tags: true,
    });

    $('#selectedIngredients').on('select2:select', (e) => {
      this.selectedIngredients.push({ "Name": e.params.data.text })
    });

    $('#selectedIngredients').on('select2:unselect', (e) => {
      for (let n in this.selectedIngredients) {
        if (e.params.data.text == this.selectedIngredients[n].Name) {
          this.selectedIngredients.splice(parseInt(n), 1)
        }
      }
    });

    $('#selectedCategory').on('select2:select', (e) => {
      //  var data = e.params.data.text;

      let categoryFiltered = this.primaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);

      if (categoryFiltered.length > 0) {
        this.selectedPrimaryCategories.push(categoryFiltered[0]);
      } else {
        this.selectedPrimaryCategories.push({ "Name": e.params.data.text })
      }
    });
    $('#selectedCategory').on('select2:unselect', (e) => {
      for (let n in this.selectedPrimaryCategories) {
        if (e.params.data.text == this.selectedPrimaryCategories[n].Name) {
          this.selectedPrimaryCategories.splice(parseInt(n), 1)
        }
      }
    });

    $('#subCategory').on('select2:select', (e) => {
      //  var data = e.params.data.text;
      let categoryFiltered = this.secondaryCat.filter(filteredCat => filteredCat.Name == e.params.data.text);
      if (categoryFiltered.length > 0) {
        this.selectedSubCategories.push(categoryFiltered[0]);
      } else {
        this.selectedSubCategories.push({ "Name": e.params.data.text })
      }
      this.tabClicked(this.selectedPrimaryCategories[0]["Name"])
      // var value = $(this).value;
    });

    $('#subCategory').on('select2:unselect', (e) => {
      for (let n in this.selectedSubCategories) {
        if (e.params.data.text == this.selectedSubCategories[n].Name) {
          this.selectedSubCategories.splice(parseInt(n), 1)
        }
      }
    });

    var x = { "Primary": this.selectedPrimaryCategories, "Secondary": this.selectedSubCategories };
    this.selectedCategoryArray.push(x);
    //pretend the first tab is clicked so we filted the sub categories
    if (this.selectedPrimaryCategories.length > 0) {
      this.tabClicked(this.selectedPrimaryCategories[0]["Name"]);
    }
    $(function () {
      $('.clockpicker').clockpicker();
      $('.selectpicker').selectpicker();
      $('.dropify').dropify();
    });

  }


  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, "UTF-8");
    fileReader.onload = () => {
      var json = JSON.parse(fileReader.result as string)

      for (let x in json) {
        switch (x.toString()) {
          case "Name":
            this.menuItemName = json[x];
            break;
          case "Description":
            this.menuItemDescription = json[x];
            break;
          case "Price":
            this.menuItemPrice = json[x];
            break;
          case "Ingredients":
            let ingre = json[x].toString().split(" ");
            var dataCat = [];
            ingre.forEach(async (item, n) => {
              dataCat.push({
                id: n,
                text: item,
                "selected": true
              })
            });
            $("#selectedIngredients").select2({
              data: dataCat,
              multiple: true,
              tags: true,
            });
            break;
          case "SelectedCategoriesObject":
            json[x].forEach(async (item, n) => {
              if (n == 0) {
                var dataCat = [];
                var subDataCat = [];

                let primCat = item["parentCategories"]["parentCategories"];
                primCat.forEach(async (item, n) => {
                  this.selectedPrimaryCategories.push({ "Name": item });
                  dataCat.push({
                    id: n,
                    text: item,
                    "selected": true
                  })
                });

                primCat.forEach(async (item, n) => {
                  var newOption = new Option(dataCat[n].text, dataCat[n].id, false, true);
                  $('#selectedCategory').append(newOption).trigger('change');
                });

                let subCat = item["childCategories"]["childCategories"];
                subCat.forEach(async (item, n) => {
                  this.selectedSubCategories.push({ "Name": item })
                  subDataCat.push({
                    id: n,
                    text: item,
                    "selected": true
                  })
                });
                subDataCat.forEach(async (item, n) => {
                  var newOption = new Option(subDataCat[n].text, subDataCat[n].id, false, true);
                  $('#subCategory').append(newOption).trigger('change');
                });
              } else {
                // $('#addCategory').find(".select2").each(function (index) {
                //   if ($(this).data('select2')) {
                //     $(this).select2('destroy');
                //   }
                // });

                let x = this.resolver.resolveComponentFactory(AddCategoryComponent); //here we add a reference to AddCategoryComponent and assign its variables
                let t = this.addCategoryContainer.createComponent(x);
                t.instance.categoryIndex = this.categoryIndex;
                t.instance.showClose = true;

                let primCat = item["parentCategories"]["parentCategories"];
                var primaryCategories = []
                primCat.forEach(async (item, n) => {
                  primaryCategories.push({ "Name": item })
                });

                let subCat = item["childCategories"]["childCategories"];
                var subCategories = [];
                subCat.forEach(async (item, n) => {
                  subCategories.push({ "Name": item })
                });
                t.instance.selectedSubCategories = subCategories;
                t.instance.selectedPrimaryCategories = primaryCategories;

                var y = { "Primary": t.instance.selectedPrimaryCategories, "Secondary": t.instance.selectedSubCategories };
                this.selectedCategoryArray.push(y);
                this.categoryIndex += 1;
                this.addedCategories.push(t);
                //this.selectedPrimaryCategories += t.instance.selectedPrimaryCategories;
                // $('.select2').select2();
              }
            });
            break;
          case "MenuOptions":
            json[x].forEach(async (item, n) => {

              var x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
              const t = this.addFullOptionContainer.createComponent(x);
              t.instance.showClose = true;

              item.sides.forEach(async (item, n) => {
                t.instance.optionSides.push(item);
              });

              let title = { "Name": item.sideCategoryName, "Selectables": item.Selectable, "Required": item.Required, "AbleAmount": item.AbleAmount };
              t.instance.optionTitle = title;
              t.instance.optionCategoryIndex = this.optionCategoryIndex;
              this.optionCategoryIndex += 1;

              //testing
              this.menuItemOptions.push({
                "sides": t.instance.options,
                "title": t.instance.optionTitle,
                "isActive": t.instance.isActive
              });
              //testing
              this.addedOptions.push(t);
            });
          default:
            break;
        }
      }
    };
    fileReader.onerror = (error) => {
    }
  }


  // Upload an existing menu json file to Menu Options
  uploadMenu(event) {

    this.menuFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.menuFile, "UTF-8");
    fileReader.onload = () => {

      var json = JSON.parse(fileReader.result as string)
      for (let x in json) {
        if (x.toString() == "MenuOptions") {
          json[x].forEach(async (item, n) => {
            var x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
            const t = this.addFullOptionContainer.createComponent(x);
            t.instance.showClose = true;

            item.sides.forEach(async (item, n) => {
              t.instance.optionSides.push(item);
            });

            let title = { "Name": item.sideCategoryName, "Selectables": item.Selectable, "Required": item.Required, "AbleAmount": item.AbleAmount };
            t.instance.optionTitle = title;
            t.instance.optionCategoryIndex = this.optionCategoryIndex;
            this.optionCategoryIndex += 1;

            //testing
            this.menuItemOptions.push({
              "sides": t.instance.options,
              "title": t.instance.optionTitle,
              "isActive": t.instance.isActive
            });
            //testing
            this.addedOptions.push(t);
          });
        }
      }
    };
    fileReader.onerror = (error) => {
    }
  }


  // Export the Menu Options as a json file to local disk. 
  // File name: "menuOptions.json"
  exportMenu() {
    var menuOptions = [];
    this.menuItemOptions.forEach(async (item, n) => {
      if (item.isActive && item.sides.length > 0) {
        let sides = [];
        for (let x in item.sides) {
          if (item.sides[x].isActive && item.sides[x].optionName != '') {
            sides.push({ "Name": item.sides[x].optionName, "Price": item.sides[x].optionPrice, "Description": "" })
          }
        }
        if (sides.length > 0) {
          menuOptions.push({ "sides": sides, "sideCategoryName": item.title.Name, "Selectable": item.title.Selectables, "Required": item.title.Required, "AbleAmount": item.title.AbleAmount })
        }
      }
    });

    const post = {
      MenuOptions: menuOptions
    };

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(post));
    var dlMenu = document.getElementById('downloadMenu');
    dlMenu.setAttribute("href", dataStr);
    var valueJson = "menuOptions.json";
    dlMenu.setAttribute("download", valueJson);
  }


  async getCategory() {
    return new Promise(resolve => {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID')
      };
      this.menuService.getCategories(post).subscribe(
        data => {
          this.primaryCat = data['PrimaryCategories'];
          this.secondaryCat = data['SecondaryCategories']
          resolve(true);
        }
      );
    });
  }

  async getOptionCategories() {
    return new Promise(resolve => {
      //nick restaurant id
      this.menuService.getOptionCategories(localStorage.getItem('restaurantID'), localStorage.getItem('UserID')).subscribe(
        data => {
          for (let x in data) {
            this.optionCategorys.push(data[x]);
          }
          resolve(true);
        }
      );
    });
  }


  async updateItem() {
    this.loading = true;
    await this.addObject()
    //this.loading = false;
  }

  async addObject() {
    const verify0 = await this.updateMenuItemPrimaryData();
    if (verify0) {
      await this.upload();
      await this.updateMenuItemCategoryData();
      await this.updateMenuItemSideData();
      //return
      if (this.addAnotherItemClicked) {
        alert('One Menu Item Added!')
        let url = this.router.url;
        this.router.navigateByUrl('/services', { skipLocationChange: true }).then(() =>
          this.router.navigate([url]));
      }
      else if (this.addDuplicateItemClicked) {
        this.addDuplicateItemClicked = false;
        $('#mainInfo').removeClass('was-validated');
        $('#insert-success-msg').show();
        document.getElementById('insert-item-div').scrollIntoView({ behavior: 'smooth' });
        setTimeout(function () {
          $('#insert-success-msg').hide();
        }, 2000);
      }
      else {
        this.router.navigateByUrl('/services/show-items');
      }
    }
  }


  async updateMenuItemSideData() {
    var menuOptions = [];

    this.menuItemOptions.forEach(async (item, n) => {
      if (item.isActive && item.sides.length > 0) {
        let sides = [];
        for (let x in item.sides) {
          if (item.sides[x].isActive && item.sides[x].optionName != '') {
            sides.push({ "name": item.sides[x].optionName, "price": item.sides[x].optionPrice, "description": "" })
          }
        }
        if (sides.length > 0) {
          menuOptions.push({ "sides": sides, "sideCategoryName": item.title.Name, "selectable": item.title.Selectables, "required": item.title.Required, "ableAmount": item.title.AbleAmount })
        }
      }
    });

    if (menuOptions.length > 0) {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID'),
        MenuItemID: this.menuItemID,
        MenuOptions: menuOptions
      };

      await this.updateMenuItemSides(post);
    }
  }

  cancelUpdate() {
    this.router.navigateByUrl('/services/show-items')
  }

  async updateMenuItemSides(options) {
    return new Promise(resolve => {
      this.menuService.AddMenuItemSides(options).subscribe(
        data => {
          if (data['Success']) {
            // this.menuImageUrl = data['MenuImageURL'];
            resolve(true)
          } else {
            resolve(true)
          }
        }
      );
    });
  }


  async updateMenuItemCategoryData() {
    return new Promise(resolve => {
      this.selectedCategoryArray.forEach(async (item, n) => {
        var categoryToSubmitTemp = [];
        var categoryToSubmit = [];

        item.Primary.forEach(async (primaryItem, n) => {
          categoryToSubmitTemp.push(primaryItem.Name)
        });
        let p = { "parentCategories": categoryToSubmitTemp }
        item.Secondary.forEach(async (subItem, n) => {
          categoryToSubmit.push(subItem.Name)
        });
        let s = { "childCategories": categoryToSubmit }
        await this.updateMenuItemCategories(p, s);
        if ((n + 1) == this.selectedCategoryArray.length) {
          resolve(true);
        }
      });
    })
  }


  async updateMenuItemCategories(prim, sub) {
    return new Promise(resolve => {
      const post = {
        Token: localStorage.getItem('AuthenticationToken'),
        RestaurantID: localStorage.getItem('restaurantID'),
        MenuItemID: this.menuItemID,
        parentCategories: prim,
        childCategories: sub
      };

      this.menuService.updateMenuItemCategories(post).subscribe(
        data => {
          if (data['Success']) {
            // this.menuImageUrl = data['MenuImageURL'];
            resolve(true)
          } else {
            resolve(false)
          }
        }
      );
    });
  }


  async updateMenuItemPrimaryData() {
    return new Promise(resolve => {

      this.selectedIngredients.forEach(async (item, n) => {
        if (item.Name != undefined && item.Name != 'N/A') {
          this.menuItemIngredients += item.Name + ",";
        }
      });

      if (this.menuItemIngredients.length>0) {
        this.menuItemIngredients = this.menuItemIngredients.substr(0,this.menuItemIngredients.length-1);
      }
      else {
        this.menuItemIngredients = "N/A";
      }

      this.servedStart = $("#servedStart").val();
      this.servedEnd = $("#servedEnd").val();

      if (!this.allDay) {
        this.servedStart = null;
        this.servedEnd = null;
      }

      const post = {
        Name: this.menuItemName,
        RestaurantID: localStorage.getItem('restaurantID'),
        Description: this.menuItemDescription,
        Price: this.menuItemPrice,
        Ingredients: this.menuItemIngredients,
        ServedStart: this.servedStart,
        ServedEnd: this.servedEnd,
        itemHasImage: (this.fileInput != undefined && this.fileEvent !=null) ? true : false
      };

      this.menuService.addMenuItem(post).subscribe(
        data => {
          if (data['Success']) {
            this.menuItemID = data['MenuItemID'];
            resolve(true)
          } else {
            resolve(false)
          }
        }
      );
    });
  }


  fileEvent(fileInput: any) {
    this.fileInput = fileInput.target.files[0];
    const file = fileInput.target.files[0];
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

    if (validImageTypes.includes(fileType)) {
      let blob = new Blob([fileInput.target.files[0]], { type: "image/jpeg" });
      this.menuImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    }
  }


  //add error handling on this
  async upload() {
    return new Promise(resolve => {
      const AWSService = AWS;
      const region = 'ca-central-1';

      let bucketName = "";
      if (this.url.getDev() == "dev") {
        bucketName = 'topserve-restaurant-menu-images-dev-canada';
      } else {
        bucketName = 'topserve-restaurant-menu-images-prod-canada';

      }

      //  const bucketName = 'menu-item-images-meeno' + this.url.getDev();
      const IdentityPoolId = 'us-west-2:2c897f30-d4b4-448e-8eac-1b5b24dcdc8d';
      // Configures the AWS service and initial authorization
      AWSService.config.update({
        accessKeyId: environment.awsAccess,
        secretAccessKey: environment.awsSecret,
        region: region
      });
      // adds the S3 service, make sure the api version and bucket are correct
      const s3 = new AWSService.S3({
        apiVersion: '2012-10-17',
        params: { Bucket: bucketName }
      });

      if (this.fileInput != null || this.fileInput != undefined) {
        this.image = this.fileInput;
      }

      if (this.image != '' && this.image != undefined) {
        s3.upload({
          Key: this.menuItemID.toString() + ".jpeg",
          Bucket: bucketName,
          Body: this.image,
          ACL: 'public-read'
        }, function (err, data) {
          if (err) {
            resolve(false)
          } else {
            resolve(true)
          }
        });
      }
      else {
        resolve(true)
      }
    });
  }

  addCategory() {
    // $('#addCategory').find(".select2").each(function (index) {
    //   if ($(this).data('select2')) {
    //     $(this).select2('destroy');
    //   }
    // });

    let x = this.resolver.resolveComponentFactory(AddCategoryComponent); //here we add a reference to AddCategoryComponent and assign its variables
    let t = this.addCategoryContainer.createComponent(x);
    t.instance.categoryIndex = this.categoryIndex;
    t.instance.showClose = true;

    var y = { "Primary": t.instance.selectedPrimaryCategories, "Secondary": t.instance.selectedSubCategories };
    this.selectedCategoryArray.push(y);
    this.categoryIndex += 1;
    this.addedCategories.push(t);
    //this.selectedPrimaryCategories += t.instance.selectedPrimaryCategories;
    // $('.select2').select2();
  }

  addOptions() {
    // $('#allOptions').find(".select2").each(function (index) {
    //   if ($(this).data('select2')) {
    //     $(this).select2('destroy');
    //   }
    // });

    let x = this.resolver.resolveComponentFactory(AddFullOptionComponent);
    const ty = this.addFullOptionContainer.createComponent(x);
    ty.instance.showClose = true;
    ty.instance.optionCategoryIndex = this.optionCategoryIndex;
    this.optionCategoryIndex += 1;


    this.addedOptions.push(ty);

    //testing
    this.menuItemOptions.push({ "sides": ty.instance.options, "title": ty.instance.optionTitle, "isActive": ty.instance.isActive });
    //testing

    // $('.select2').select2();

  }

  tabClicked(category) {
    this.selectedCategoryArray.forEach(async (item, n) => {
      item.Primary.forEach(async (primaryName, n) => {
        if (primaryName["Name"] == category) {
          this.menuSecondaryCategoriesFiltered = item.Secondary
        }
      })
    });
  }

  closeCategoryCheck() {
  }

  exportJson() {
    if (this.selectedIngredients.length > 0) {
      this.selectedIngredients.forEach(async (item, n) => {
        if (item.Name != undefined) {
          if (n > 0) {
            this.menuItemIngredients += " ";
          }
          this.menuItemIngredients += item.Name;
        }
      });
    } else {
      this.menuItemIngredients = "N/A";
    }
    var SelectedCategoriesObject = []

    this.selectedCategoryArray.forEach(async (item, n) => {
      var categoryToSubmitTemp = [];
      var categoryToSubmit = [];
      item.Primary.forEach(async (primaryItem, n) => {
        categoryToSubmitTemp.push(primaryItem.Name)
      });

      let p = { "parentCategories": categoryToSubmitTemp }

      item.Secondary.forEach(async (subItem, n) => {
        categoryToSubmit.push(subItem.Name)
      });
      let s = { "childCategories": categoryToSubmit }

      var x = {
        parentCategories: p,
        childCategories: s
      };
      if (categoryToSubmitTemp.length > 0) {
        SelectedCategoriesObject.push(x);
      }
    });

    var menuOptions = [];
    this.menuItemOptions.forEach(async (item, n) => {
      if (item.isActive && item.sides.length > 0) {
        let sides = [];
        for (let x in item.sides) {
          if (item.sides[x].isActive && item.sides[x].optionName != '') {
            sides.push({ "Name": item.sides[x].optionName, "Price": item.sides[x].optionPrice, "Description": "" })
          }
        }
        if (sides.length > 0) {
          menuOptions.push({ "sides": sides, "sideCategoryName": item.title.Name, "Selectable": item.title.Selectables, "Required": item.title.Required, "AbleAmount": item.title.AbleAmount })
        }
      }
    });

    const post = {
      Name: this.menuItemName,
      RestaurantID: localStorage.getItem('restaurantID'),
      Description: this.menuItemDescription,
      Price: this.menuItemPrice,
      Ingredients: this.menuItemIngredients,
      SelectedCategoriesObject,
      MenuOptions: menuOptions
    };

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(post));
    var dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute("href", dataStr);
    var valueJson = "menuItem.json";
    dlAnchorElem.setAttribute("download", valueJson);
  }

  ngOnDestroy(): void {
    this.categoryIndexes = 0
  }

  clearMenuItemImage(event) {
    this.menuImage = undefined;
    this.fileInput = null;
    event.stopPropagation();
  }
}